import { useNotificationReducer } from "../ReducerHooks/ReducerHooks"


const useReducerNotifAction = () => {

    const { store: notifStore } = useNotificationReducer()

    const UPDATE_NOTIFICATIONS_NUMBER = (data) => {
        notifStore({
            type: 'UPDATE_NOTIFICATIONS_NUMBER',
            payload: data
        })
    }

    const REFRESH_NOTIFICATIONS = (data) => {
        notifStore({
            type: 'REFRESH_NOTIFICATIONS',
            payload: data
        })
    }

    const ADD_NOTIFICATIONS = (data) => {
        notifStore({
            type: 'ADD_NOTIFICATIONS',
            payload: data
        })
    }

    const UPDATE_NOTIFICATION = (data) => {
        notifStore({
            type: 'UPDATE_NOTIFICATION',
            payload: data
        })
    }

    const DELETE_NOTIFICATION = (data) => {
        notifStore({
            type: 'DELETE_NOTIFICATION',
            payload: data
        })
    }

    const CLEAR_NOTIFICATIONS = (data) => {
        notifStore({
            type: 'CLEAR_NOTIFICATIONS',
            payload: data
        })
    }

    return { UPDATE_NOTIFICATIONS_NUMBER, REFRESH_NOTIFICATIONS, ADD_NOTIFICATIONS, UPDATE_NOTIFICATION, DELETE_NOTIFICATION, CLEAR_NOTIFICATIONS }
}

export default useReducerNotifAction