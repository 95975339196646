import './translations/IMLocalize'
import AppStack from './routes/AppStack';
import AuthStack from './routes/AuthStack';
import { useAuthReducer } from './hooks/ReducerHooks/ReducerHooks'
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';


function App() {

  const { data: AuthData } = useAuthReducer()
  localStorage.setItem('language', AuthData.language)

  if (AuthData.isChangeAccount) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner style={{ margin: '45vh' }} />
      </div>
    )
  } else if (!AuthData?.authorization) {
    return <AuthStack />
  } else {
    return <AppStack />
  }

}

export default App;
