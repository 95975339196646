const initialState = {
    accounts: [],
};


const AccountReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'ADD_ACCOUNT':
            return {
                ...state,
                accounts: state.accounts?.some(account => account.user.user_id === action.payload?.user?.user_id) ?
                    state.accounts : [...state.accounts, action.payload]
            }

        case 'DELETE_ACCOUNT':
            return {
                ...state,
                accounts: state.accounts.filter(account =>
                    account.user.user_id !== action.payload,
                )
            }

        case 'CLEAR_ACCOUNTS':
            return {
                ...state,
                accounts: []
            }

        default:
            return state;
    }
}

export default AccountReducer;