import React from 'react'
import styles from './Public.module.css'
import { useTranslation } from 'react-i18next';
import RecentOpinions from './RecentOpinions/RecentOpinions';
import ViralOpinions from './ViralOpinions/ViralOpinions';
import TopBar from 'components/Topbar/Topbar';
import { isMobile } from 'react-device-detect';


const Public = () => {

  const { t, i18n } = useTranslation()
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  const Header = () => {
    if (isMobile) {
      return (
        <TopBar />
      )
    }
  }

  return (
    <div className={styles.page}>
      <Header />
      {/* TAB */}
      <div className={styles.tab}>
        <span onClick={() => handleTabChange(0)} className={tabIndex === 0 ? styles.tabLabelActive : styles.tabLabel}>
          {t('recent')}
        </span>
        <span onClick={() => handleTabChange(1)} className={tabIndex === 1 ? styles.tabLabelActive : styles.tabLabel}>
          {t('viral')}
        </span>
      </div>
      {/* TAB PANEL */}
      <div className={tabIndex === 0 ? styles.tabViewActive : styles.tabView}>
        <RecentOpinions />
      </div>
      <div className={tabIndex === 1 ? styles.tabViewActive : styles.tabView}>
        <ViralOpinions />
      </div>
    </div>
  )
}

export default Public