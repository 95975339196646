import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import styles from './AutoCompleteSupervisor.module.css'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import { api } from 'api/api'
import { Autocomplete, TextField } from '@mui/material'


const AutoCompleteSupervisor = ({
    defaultValue = {label: ''},
    value = {},
    onChangeText = () => null,
}) => {

    const accessToken = useAccessToken()
    const [suggestionsList, setSuggestionsList] = useState([{label: defaultValue}])

const [isLoading, setIsLoading] = useState(false)

    const fetchSuggestions = useCallback(async q => {
        try {
            // console.log('getSuggestions:', q)
            if (!q) {
                return
            }
            const filterToken = q?.toLowerCase()
            if (typeof q !== 'string' || q.length < 3) {
                setSuggestionsList([{label: defaultValue}])
                return
            }
            setIsLoading(true)
            const request = await api(`api/rh/employees?search=${q}`, 'GET', {}, accessToken)
            const items = await request.json()
            setIsLoading(false)
            // console.log('Fetch data response employees:', items)
            if (request.status === 200) {
                const suggestions = items
                    .filter(item =>
                        item?.user_surname?.toLowerCase()?.includes(filterToken) ||
                        item?.user_name?.toLowerCase()?.includes(filterToken)
                    )
                    .map(item => ({
                        value: item?.user_id,
                        label: `${item?.user_surname || ''} ${item?.user_name || ''}`,
                        // user_username: `${item?.user_username}`,
                    }))
                setSuggestionsList(suggestions)
                console.log(suggestions)
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error);
        }
    }, [])

    
    return (
        <div className={styles.container}>
            <Autocomplete
                defaultValue={{label: defaultValue}}
                multiple={false}
                options={suggestionsList}
                getOptionSelected ={ (option) => option?.label === defaultValue?.label}
                getOptionLabel={(option) => option?.label} // Adjust this based on your API response format
                onInputChange={(event, newValue) => fetchSuggestions(newValue)}
                onChange={(event, value) => onChangeText({ user_id: value?.value })}
                renderInput={(params) => (
                    <TextField {...params} label="Superviseur" variant="outlined" />
                )}
                size='small'
            />
        </div>
    )
}

export default AutoCompleteSupervisor
