import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonSubmit from '../ButtonSubmit/ButtonSubmit'
import Header from '../Header/Header'
import TextInput from '../TextInput/TextInput'
import Select from 'react-select'
import { api } from '../../api/api'
import { ValidateEmail, ValidatePseudo } from '../../hooks/Utils/parsing'
import { useAccessToken } from '../../hooks/ReducerHooks/ReducerHooks'
import useBadge from '../../hooks/useBadgeHooks/useBadgeHooks'
import { color } from '../../hooks/Utils/color'
import useReducerPeopleMatchAction from '../../hooks/ReducerHooksAction/useReducerPeopleMatchAction'


const ModalEditBadges = ({
    user,
    show = false,
    setShow,
    refresh =()=>null
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { REFRESH_PEOPLE } = useReducerPeopleMatchAction()
    const { goal_data, business_data, hobby_data, food_data, color_data } = useBadge()
    const [isLoading, setIsLoading] = useState('')
    const [error, setError] = useState('')

    const [user_badge_goal, setUser_badge_goal] = useState(user?.user_badge_goal)
    const [user_badge_business, setUser_badge_business] = useState(user?.user_badge_business)
    const [user_badge_hobby, setUser_badge_hobby] = useState(user?.user_badge_hobby)
    const [user_badge_food, setUser_badge_food] = useState(user?.user_badge_food)
    const [user_badge_color, setUser_badge_color] = useState(user?.user_badge_color)


    const handleEditProfile = async (e) => {
        try {
            e.preventDefault();
            if (!user_badge_goal || !user_badge_business || !user_badge_hobby || !user_badge_food || !user_badge_color) {
                setError(t('select_at_least_one_badge_per_category'))
                return
            }

            setIsLoading(true)
            const data = {
                user_badge_goal,
                user_badge_business,
                user_badge_hobby,
                user_badge_food,
                user_badge_color,
            }

            const request = await api(`api/user/update-badges`, 'PUT', data, accessToken,)
            const response = await request.json()
            console.log('Response update badge:', response)
            if (request.status == 200) {
                if (response.success) {
                    refresh()
                    fetchBuddiesYouKnow()
                    setShow(false)
                } else {
                    setError(t('you_cannot_modify_your_badges'))
                }
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.error('Error update badge:', e);
        }
    }

    const fetchBuddiesYouKnow = async () => {
        try {
            const request = await api('api/user/buddies-you-may-want-to-know', 'GET', null, accessToken)
            const response = await request.json()
            console.log('Response fetch buddy you may know:', response?.result.length)
            if (request.ok && request.status === 200) {
                if (response.success) {
                   REFRESH_PEOPLE(response.result)
                }
            }
        } catch (error) {
            fetchBuddiesYouKnow()
            console.log('Error get buddies-to-know:', error)
            throw new Error(error.message)
        }
    }

    const renderItem = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                <img src={props.data.icon} width={25} height={23} />
                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
            </div>
        );
    }


    const renderItemSingle = (props) => (
        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
            <img src={props.data.icon} width={25} height={23} />
            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
        </div>
    )

    const renderItemColor = (props) => {
        const { innerProps, innerRef } = props;
        return (
            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                <div style={{ backgroundColor: props.data.icon, width: '25px', height: '23px' }} />
                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
            </div>
        );
    }

    const renderItemSingleColor = (props) => (
        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
            <div style={{ backgroundColor: props.data.icon, width: '25px', height: '23px' }} />
            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
        </div>
    )


    return (
        <Modal scrollable={true} show={show}>

            <Header title='Edit badge' type='modal' goBack={() => setShow(false)} />
            <span style={{fontSize: '13px', textAlign: 'center', color: 'red', marginTop: '10px', marginLeft: '30px', marginRight: '30px'}}>
                {error}
            </span>
            <div style={{ height: '480px', paddingLeft: '15%', paddingRight: '15%', paddingTop: '3%', paddingBottom: '3%', overflowY: 'scroll' }}>

                <form onSubmit={handleEditProfile}>

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                        {t('goal')}
                    </span>
                    <div style={{ marginBottom: '20px' }}>
                        <Select
                            placeholder={t('goal')}
                            options={goal_data}
                            defaultValue={goal_data.filter(item => item?.value === user?.user_badge_goal)}
                            onChange={newValue => setUser_badge_goal(newValue.value)}
                            components={{ Option: renderItem, SingleValue: renderItemSingle }}
                        />
                    </div>

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                        {t('business')}
                    </span>
                    <div style={{ marginBottom: '20px' }}>
                        <Select
                            placeholder={t('business')}
                            options={business_data}
                            isSearchable={false}
                            defaultValue={business_data.filter(item => item?.value === user?.user_badge_business)}
                            onChange={newValue => setUser_badge_business(newValue.value)}
                            components={{ Option: renderItem, SingleValue: renderItemSingle }}
                        />
                    </div>

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                        {t('hobby')}
                    </span>
                    
                    <div style={{ marginBottom: '20px' }}>
                        <Select
                            placeholder={t('hobby')}
                            options={hobby_data}
                            isSearchable={false}
                            defaultValue={hobby_data.filter(item => item?.value === user?.user_badge_hobby)}
                            onChange={newValue => setUser_badge_hobby(newValue.value)}
                            components={{ Option: renderItem, SingleValue: renderItemSingle }}
                        />
                    </div>

                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                        {t('food')}
                    </span>
                    <div style={{ marginBottom: '20px' }}>
                        <Select
                            placeholder={t('food')}
                            options={food_data}
                            isSearchable={false}
                            defaultValue={food_data.filter(item => item?.value === user?.user_badge_food)}
                            onChange={newValue => setUser_badge_food(newValue.value)}
                            components={{ Option: renderItem, SingleValue: renderItemSingle }}
                        />
                    </div>


                    <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                        {t('color')}
                    </span>
                    <div style={{ marginBottom: '20px' }}>
                        <Select
                            placeholder={t('color')}
                            options={color_data}
                            isSearchable={false}
                            defaultValue={color_data.filter(item => item?.value === user?.user_badge_color)}
                            onChange={newValue => setUser_badge_color(newValue.value)}
                            components={{ Option: renderItemColor, SingleValue: renderItemSingleColor }}
                        />
                    </div>


                    <ButtonSubmit
                        value={t('update')}
                        isLoading={isLoading}
                        loadBoostrapColor={'light'}
                        scrollable={false}
                        style={{
                            width: '100%',
                            color: 'white',
                            marginBottom: '5%',
                            backgroundColor: color.primary
                        }}
                    />
                </form>

            </div>

        </Modal>
    )
}

export default ModalEditBadges