import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styles from './SearchOpinion.module.css'
import { api } from 'api/api'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import OpinionCard from './OpinionCard/OpinionCard'


const SearchOpinion = ({
  value = '',
  isLoading = false,
  setIsLoading = () => null
}) => {

  const { t, i18n } = useTranslation()
  const accessToken = useAccessToken()

  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)


  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  const fetchData = async () => {
    try {
      setData([])
      const body = { search: value }
      const request = await api(`api/search/search_opinions?page=${1}`, 'POST', body, accessToken)
      const response = await request?.json()
      setIsLoading(false)
      console.log('Search person response:', response)
      if (request?.status === 200) {
        if (response?.success) {
          if (response?.search_opinion?.next_page_url) {
            setHasMore(true)
          } else {
            setHasMore(false)
          }
          setData(response?.search_opinion?.data || [])
          setPage(2)
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Search people error:', error)
    }
  }


  const loadMore = async () => {
    try {
      if (hasMore && !isLoading && !isLoadingMore) {
        setIsLoadingMore(true)
        const body = { search: value }
        const request = await api(`api/search/search_opinions?page=${page}`, 'POST', body, accessToken)
        const response = await request?.json()
        setIsLoadingMore(false)
        // console.log('Search person response:', response)
        if (request?.status === 200) {
          if (response?.success) {
            if (response?.search_opinion?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            setData(data => [...data, ...response?.search_opinion?.data || []])
            setPage(page => page + 1)
          }
        }
      }
    } catch (error) {
      setIsLoadingMore(false)
      console.error(error)
    }
  }


  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '15px', padding: '25%' }}>
        <Spinner variant='secondary' />
      </div>
    )
  }


  return (
    <div id="scrollableDiv" className={styles.container}>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        hasMore={hasMore}
        loader={
          hasMore &&
          <div style={{ textAlign: 'center', padding: '15px' }}>
            <Spinner variant='secondary' />
          </div>
        }
        scrollableTarget="scrollableDiv">
        {data.map((item, index) => (
          <div key={index.toString()} >
            <OpinionCard opinion={item} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default SearchOpinion