import React from 'react'
import styles from './Feed.module.css'
import { useTranslation } from 'react-i18next';
import TopBar from 'components/Topbar/Topbar';
import { isMobile } from 'react-device-detect';
import RecentFeed from './RecentFeed/RecentFeed';
import ViralFeed from './ViralFeed/ViralFeed';


const Feed = () => {

    const { t, i18n } = useTranslation()
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (index) => {
        setTabIndex(index);
    };

    const Header = () => {
        if (isMobile) {
            return (
                <TopBar />
            )
        }
    }

    return (
        <div className={styles.page}>
            <Header />
            {/* TAB */}
            <div className={styles.tab}>
                <span onClick={() => handleTabChange(0)} className={tabIndex === 0 ? styles.tabLabelActive : styles.tabLabel}>
                    {t('recent')}
                </span>
                <span onClick={() => handleTabChange(1)} className={tabIndex === 1 ? styles.tabLabelActive : styles.tabLabel}>
                    {t('viral')}
                </span>
            </div>
            {/* TAB PANEL */}
            <div className={tabIndex === 0 ? styles.tabViewActive : styles.tabView}>
                <RecentFeed />
            </div>
            <div className={tabIndex === 1 ? styles.tabViewActive : styles.tabView}>
                <ViralFeed />
            </div>
        </div>
    )
}

export default Feed