import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useBadge from '../../hooks/useBadgeHooks/useBadgeHooks'
import { color } from '../../hooks/Utils/color'
import { useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'

const Badges = ({
    user,
    size = 18
}) => {

    const { data: authData } = useAuthReducer()
    const { goal_data, business_data, hobby_data, food_data, color_data } = useBadge()
    const [goalIcon, setGoalIcon] = useState()
    const [businessIcon, setBusinessIcon] = useState()
    const [hobbyIcon, setHobbyIcon] = useState()
    const [foodIcon, setFoodIcon] = useState()
    const [colorIcon, setColorIcon] = useState()


    useEffect(() => {

        goal_data.map(item => {
            if (user?.user_badge_goal == item?.value) {
                setGoalIcon(item.icon)
            }
        })

        business_data.map(item => {
            if (user?.user_badge_business == item?.value) {
                setBusinessIcon(item.icon)
            }
        })


        hobby_data.map(item => {
            if (user?.user_badge_hobby == item?.value) {
                setHobbyIcon(item.icon)
            }
        })

        food_data.map(item => {
            if (user?.user_badge_food == item?.value) {
                setFoodIcon(item.icon)
            }
        })

        color_data.map(item => {
            if (user?.user_badge_color == item?.value) {
                setColorIcon(item.icon)
            }
        })
    }, [user])

    if (user?.user_badge_goal) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', }}>
                <img src={goalIcon} width={size} style={{ marginRight: '5px', }} alt='' />
                <img src={businessIcon} width={size} style={{ marginRight: '5px' }} alt='' />
                <img src={hobbyIcon} width={size} style={{ marginRight: '5px' }} alt='' />
                <img src={foodIcon} width={size} style={{ marginRight: '5px' }} alt='' />
                <div style={{ width: '15px', height: '15px', backgroundColor: colorIcon }}>&nbsp;</div>
                {user?.user_id !== authData?.user?.user_id &&
                    <span style={{ marginLeft: '10px', color: color.primary, fontWeight: '500' }}>{user?.user_match_value}%</span>
                }
            </div>
        )
    }

}

export default Badges