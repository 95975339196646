import React, { useEffect } from 'react'
import styles from './Accounts.module.css'
import { useAccountReducer, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction'
import { useNavigate } from 'react-router-dom'
import AccountCard from './AccountCard/AccountCard'


const Accounts = () => {

    const navigate = useNavigate()
    const { data: authReducerState } = useAuthReducer()
    const { state: accountReducerState } = useAccountReducer()
    const { state, dispatch } = useAccountReducer()
    const { LOGIN, LOGOUT } = useReducerAuthAction()

    // useEffect(() => {
    //   console.log(accountReducerState.accounts)
    // }, [])


    const handleChangeAccount = (account) => {
        dispatch({
            type: 'USER_CHANGE_ACCOUNT',
            payload: true
        })
        LOGOUT()
        setTimeout(() => {
            const data = {
                type: 'Bearer',
                access_token: account.authorization.replace('Bearer ', ''),
                user: account.user
            }
            LOGIN(data)
            navigate('/')
        }, 1000);
        setTimeout(() => {
            dispatch({
                type: 'USER_CHANGE_ACCOUNT',
                payload: false
            })
        }, 2000);
    }

    return (
        <div className={styles.container}>
            {/* <button
                onClick={() => {
                    dispatch({
                        type: 'CLEAR_ACCOUNTS'
                    })
                }}
            >
                clear
            </button> */}
            {accountReducerState?.accounts?.length !== 0 &&
                accountReducerState?.accounts
                    .filter(account => account?.owner === authReducerState?.user?.user_id &&
                        account?.user?.user_id !== authReducerState?.user?.user_id
                    )
                    .map((account, index) => {
                        return (
                            <div key={index.toString()}>
                                <AccountCard account={account} />
                            </div>
                        )
                    })}
        </div>
    )
}

export default Accounts