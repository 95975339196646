import React, { useState } from 'react'
import styles from './HolidayBalance.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header from '../../../components/Header/Header'
import { useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../../hooks/Utils/color'
import ProfilePicture from '../../../components/ProfilePicture/ProfilePicture'

const HolidayBalance = () => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { data: authReducerState } = useAuthReducer()

    const [balance, setbalance] = useState(authReducerState?.user?.user_holiday_balance || 0)

    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)
    const goToRequest = () => navigate('/intranet/request')

    return (
        <div className={styles.container}>
            <Header title={t('myBalance')} goBack={goBack} />
            <div className={styles.container}>


                <div className={styles.balanceContainer}>
                    <div>
                        <h6>{t('acquiredDayOff')}</h6>
                        <h5 style={{ color: color.primary }}> {balance > 1 ? `${balance} ${t('days')}` : `${balance} ${t('day')}`}</h5>
                    </div>
                    <ProfilePicture
                        user={authReducerState?.user}
                        uri={authReducerState?.user?.profile?.prof_picture}
                        size={60}
                    />
                </div>

                <div onClick={goToRequest} className={styles.pressable}>
                    <img src={require('../../../assets/icons/rh_request_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myRequests')}</h6>
                        <p className={styles.descPressable}>{t('myRequestDesc')}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HolidayBalance