import { api } from 'api/api'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './MembersPending.module.css'
import MemberCard from '../MemberCard/MemberCard'
import { Spinner } from 'react-bootstrap'


const MembersPending = ({ ago_id, agm_user_role }) => {

  const accessToken = useAccessToken()
  const { t, i18n } = useTranslation()
  const { data: authData } = useAuthReducer()

  const [members, setMembers] = useState([])
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const [searchText, setSearchText] = useState('')
  const [isSearching, setSearching] = useState(false)
  const [isSearchData, setSearchData] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const request = await api(`api/agoras/pending_users/${ago_id}?paginate&page=${1}`, 'GET', {}, accessToken)
      const response = await request.json()
      // console.log('Fetch Agora members response:', response)
      if (request.ok && request.status === 200) {
        if (response.success) {

          if (response?.members?.next_page_url) {
            setHasMore(true)
          } else {
            setHasMore(false)
          }
          setPage(2)
          const membersList = response?.members?.data
          if (membersList?.length === 0) {
            setIsEmpty(true)
            return
          }
          setMembers(membersList)
          setData(membersList)

        }
      }
    } catch (error) {
      fetchData()
      console.error('Fetch Agora members error:', error)
    }
  }

  const loadMore = async () => {
    try {
      if (hasMore && !isLoading) {
        setIsLoading(true)
        const request = await api(`api/agoras/pending_users/${ago_id}?paginate&page=${page}`, 'GET', {}, accessToken)
        const response = await request.json()
        setIsLoading(false)
        // console.log('Fetch Agora members response:', response)
        if (request.ok && request.status === 200) {
          if (response.success) {

            if (response?.members?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            setPage(page => page + 1)
            const membersList = response?.members?.data
            if (membersList?.length === 0) {
              setIsEmpty(true)
              return
            }
            setData(prevState => [...prevState, ...membersList])

          }
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Fetch Agora members error:', error)
    }
  }



  const handleRemove = (id) => {
    setData(data => data?.filter((member) => member?.pivot?.agm_user !== id))
  }

  if (isEmpty) {
    return (
      <div className='text-center text-muted p-5'>
        {t('no_request_available')}
      </div>
    )
  }


  return (
    <div id="scrollableDiv" className={styles.container}>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<div style={{ textAlign: 'center', padding: '15px' }}><Spinner /></div>}
        scrollableTarget="scrollableDiv">
        {data.map((item, index) => (
          <div key={index.toString()}>
            <MemberCard
              key={index.toString()}
              ago_id={ago_id}
              member={item}
              agm_user_role={agm_user_role}
              agm_member_user_id={item?.pivot?.agm_user}
              agm_member_role={item?.pivot?.agm_role}
              agm_member_status={item?.pivot?.agm_status}
              refresh={fetchData}
              onRemove={handleRemove}
            />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default MembersPending