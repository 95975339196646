import React from 'react'

const TermsConditions = () => {
  return (
    <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
      
      <div>
        <h1 style={{paddingTop: '100px', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Terms of Services of the mobile application MyOpinion</h1>
        <p style={{paddingTop: '14pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>MYOPINION SARL (« the Company »), a company incorporated under Ivorian law, provides advanced digital systems and solutions. MYOPINION SARL develops technologies and services that allow everyone to connect, share opinions and build communities. These Terms govern your use of MyOpinion, products, features, applications, services, technologies and software that we offer (the MyOPINION Products or the Products), except where we expressly state that separate terms (and not these) apply. These Products are supplied to you by MYOPINION SARL.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>You must read these Terms Of Services (TOS) before creating an account to benefit from the services offered by myopinion.ci. The Company reserves the right to modify, at any time and without notice, these conditions. The creation of an account on myopinion.ci implies full acceptance of these conditions without any restriction. These TOS are subject to the provisions of Law No. 2014-546 of July 30, 2013 relating to electronic transactions, the Code of Rights and Obligations and the OHADA Uniform Acts applicable to these relations.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>You must read these Terms of Services (TOS) before creating an account to benefit from the services offered by the "MyOpinion" application. The Company reserves the right to modify these conditions at any time and without notice. The creation of an account on "MyOpinion" implies full acceptance of these terms, without any restriction. These Terms of Services are subject to the provisions of Law No. 2014-546 of July 30, 2013 relating to electronic transactions, the Code of Rights and Obligations and the OHADA Uniform Acts applicable to these relations.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 1 : Object</h2>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>The purpose of these Terms of Services is to define the terms and conditions for making the "MyOpinion" application available (hereinafter referred to as "application") and the conditions of use that apply to users. By using the "MyOpinion" application, you accept these Terms of Services in their entirety. If you do not agree with these Terms of Services or any part of these Terms of Services, then you should not use the application "MyOpinion" in this case.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 2 : Legal notices</h2>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>The legal notices of the Company are as follows :</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <ul id="l1">
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Company name: MYOPINION SARL;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Share capital: 1.000.000 Fcfa;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Head Office: Résidence GYAM, 7e étage Porte E, Plateau, Abidjan (Côte</p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>d’Ivoire);</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>Email: support@myopinion.ci;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>Phone number: +225 25 20 00 27 11 / +225 05 05 34 03 03;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Commercial and real estate credit register number: CI-ABJ-03-2022-B13-07853;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Tax Payer Account Number: 2243482K;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Approval number for the processing of personal data: 2020-0529, délivré</p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>par l’ARTCI.</p>
            <h2 style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 3 : Definition</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Whenever they are used in the text hereof, including in the preliminary description above, the title of the Articles and clauses, the terms and expressions used in the Terms of Services with initial capital letters have their meaning. given by the Terms of Services, without there being any need to distinguish depending on whether the defined term or expression is used in the singular or in the plural:</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Account: refers to the personal space that a user creates for himself in order to access the site and benefit from the services offered. It is recognizable by a username and a password;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content or User Content: refers to the data transmitted by the User in the various sections of the Site;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>Force majeure : désigne tout évènement imprévisible, irrésistible et</p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>extérieur à la volonté de la Société et de l’Utilisateur, qui empêche la Société d’exécuter son obligation et l’exonère de toute responsabilité;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Force majeure: refers to any unforeseeable, irresistible event outside the will of the Company and the User, which prevents the Company from fulfilling its obligation and exonerates it from all liability.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>In the context of these presents, cases of force majeure are, without this list being exhaustive, wars, riots, civil unrest, earthquakes, fires, explosions, storms, floods or other natural disasters. , confiscations, etc.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Information: refers to all data disseminated when creating an account;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Member: designates an identified user with an account;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Site: refers to the MyOpinion application;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>User: refers to any person who uses the application or one of the services offered on the application.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 4 : Use of the site</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Access to certain information disseminated on the application is subject to the creation of an Account. The User does not have the right to distribute or reproduce, free of charge or against payment, the information to which he has access. The User is not authorized to copy or transfer the information disseminated to any medium whatsoever. The user agrees not to infringe the information, images or trademarks contained on the application. The User agrees to comply with these Terms of Services and the other standards in force on the application<span className="s1">.</span></p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 5 : Access to the application and services</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Access to the application is through the creation of an account. Members are account holders. You agree to use a strong password and to keep it confidential and not to transfer one or more parts of your account. The user is responsible for protecting his account and we accept no responsibility for any loss or damage that may result. The User will be able to access the application, 24 hours a day, 7 days a week, except in cases of force majeure, and / or an event outside of the server center and subject to possible malfunctions of the Service. The user</p>
            <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>assumes all the risks and constraints associated with any use of the application. The Company excludes any express or implied guarantee, in particular any implicit guarantee of quality, of suitability for a particular use. The user is responsible for protecting his account and we accept no responsibility for any loss or damage that may result. The creation of a User Account is required in order to allow the identification of the User and to facilitate his access to the various services of the application. During this step, the User must read these Terms of Services and validate them in order to finalize the creation of their account.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 6 : Rules of use</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>By connecting to the application, you declare to accept the characteristics and limitations of said application and you acknowledge:</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>That the Company assumes no responsibility for the quality and veracity of the information accessible on the application and that it does not exercise any control, in any form whatsoever, over the nature or characteristics of the data that is contained on the application;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>That the information circulating on the application is not protected by intellectual property rights.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 7: Responsability</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>The User accepts the fact that the Company, its parent companies, subsidiaries, associated companies, managers, directors, employees, agents, representatives, partners and licensors, can in no way be held responsible for any direct or indirect damage of whatever nature and / or arising from the use or the inability to use the application. The Company is in no way responsible, without this list being exhaustive :</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>The completeness, accuracy, availability, timeliness, security or reliability of the Services or any Content;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>The content of the information consulted and, in general, any information and / or data disseminated on the services consulted;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>The operation of the application;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>Messages and information transmitted through mailboxes;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Any case of force majeure;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>In the event of an interruption in the access networks or accessible by any other technological means;</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>In the event of data loss.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>The Company cannot be held responsible in the event of legal proceedings against the User and / or any employees thereof, due to the use of the data collected on the application. The company is not responsible for any use of insulting, illegal, misleading or inappropriate information that you, or another user or a third party creates, downloads, publishes, sends, receives or stocks on or through our services. The User is liable towards the Company and / or third parties for any damage, direct or indirect of any nature whatsoever, caused by himself and / or by his potential employees when using the application by him. . The Company declines all responsibility in the event of use of the information</p>
            <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>obtained on the application in violation of the rules specified in these Terms of Services.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 8: Privacy</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Our privacy policy describes how we treat the information you provide to us when you use our services. We encourage you to pay close attention to the privacy policy because, by using our services, you agree that MYOPINION SARL collects, shares and uses your information in accordance with this policy.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 9 : Right of access to personal data - Complaints</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}><a href="mailto:support@myopinion.ci" className="a" target="_blank">The information requested and collected about you is necessary and obligatory to create your user account and to facilitate the identification and recognition of the User. In accordance with Law No. 2013-450 of June 19, 2013 on the protection of personal data, the User has the right to access, rectify, modify and delete data concerning him. However, he may not, in any way, modify the e-mail address he has provided. To exercise the above rights, the User will use the relevant tabs on the application. In general, all complaints must be made in writing and sent to the following email address: </a>support@myopinion.ci. The communication of User data and the validation of these Terms of Services authorize the Company to use said data until the User decides otherwise.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 10 : Applicable law - Competent jurisdiction</h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>The applicable law is Ivorian law. In the event of a dispute between the user and the Company in the execution or interpretation of these Terms of Services, they agree to a prior amicable resolution. If at the end of a period of fifteen (15) days from the start of the amicable resolution, the Parties do not reach an agreement, they will be free to refer to the competent Ivorian court.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Article 11 : MyOpinion Community Standards</h2>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Every day, people will use MyOpinion to share their experiences, connect with</p>
            <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>friends and family, and build communities. It’s a service for millions of people to freely express themselves across countries and cultures and in many languages.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Bah Blesson &amp; Company recognizes how important it is for MyOpinion to be a place where people feel empowered to communicate, and we take our role</p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>seriously in keeping abuse off the service. That’s why we developed standards</p>
            <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>for what is and isn’t allowed on MyOpinion.</p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>These standards are based on feedback from people and the advice of experts in fields like technology, public safety and human rights. To ensure everyone’s voice is valued, we take great care to create standards that include different views and beliefs, especially from people and communities that might otherwise be overlooked or marginalized.</p>
            <h3 style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify'}}>Please note that the English version of the Community Standards reflects the most up to date set of the policies and should be used as the primary document.</h3>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify'}}>Our commitment to freedom of speech and voice</p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>The goal of our Community Standards is to create a place for expression and give people a voice. MyOpinion wants people to be able to talk openly about the issues that matter to them, even if some may disagree or find them objectionable. In some cases, we allow content—which would otherwise go against our standards—if it’s newsworthy and in the public interest. We do this only after weighing the public interest value against the risk of harm, and we look to international human rights standards to make these judgments.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Our commitment to expression is paramount, but we recognize the internet creates new and increased opportunities for abuse. For these reasons, when we limit expression, we do it in service of one or more of the following values:</p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>RESPONSIBILITY</p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We want to make sure that people take full responsibility on the content they share or post on MyOpinion. Everyone must be held accountable for their behavior on MyOpinion and take full ownership of their consequences.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>AUTHENTICITY</p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We want to make sure the content people see on MyOpinion is authentic. We believe that authenticity creates a better environment for sharing, and that’s why we don’t want people using MyOpinion to misrepresent who they are or what they’re doing.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>SAFETY</p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We’re committed to making MyOpinion a safe place. We remove content that could contribute to a risk of harm to the physical security of persons. Content that threatens people has the potential to intimidate, exclude or silence others and isn’t allowed on MyOpinion.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>PRIVACY</p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We’re committed to protecting personal privacy and information. Privacy gives people the freedom to be themselves, choose how and when to share on MyOpinion and connect more easily.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>DIGNITY</p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We believe that all people are equal in dignity and rights. We expect that people will respect the dignity of others and not harass or degrade others.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Community Standards</p>
            <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Our Community Standards apply to everyone, all around the world, and to all types of content.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>Each section of our Community Standards starts with a “Policy Rationale” that</p>
            <p style={{paddingTop: '1pt', paddingLeft: '9pt', textIndent: '-3pt', lineHeight: '167%', textAlign: 'left'}}>sets out the aims of the policy followed by specific policy lines that outline: Content that's not allowed; and</p>
            <p style={{paddingLeft: '5pt', textIndent: '3pt', lineHeight: '108%', textAlign: 'left'}}>Content that requires additional information or context to enforce on, content that is allowed with a warning screen or content that is allowed but can only be viewed by adults aged 18 and older.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l2">
              <ol id="l3">
                <li data-list-text="11.A">
                  <p style={{paddingLeft: '70pt', textIndent: '-29pt', textAlign: 'left'}}>VIOLENCE AND CRIMINAL BEHAVIOR</p>
                  <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Violence and Incitement</h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We aim to prevent potential offline harm that may be related to content on MyOpinion. While we understand that people commonly express disdain or disagreement by threatening or calling for violence in non-serious ways, we remove language that incites or facilitates serious violence. We remove content, disable accounts and work with law enforcement when we believe there is a genuine risk of physical harm or direct threats to public safety. We also try to consider the language and context in order to distinguish casual statements from content that constitutes a credible threat to public or personal safety. In determining whether a threat is credible, we may also consider additional information like a person's public visibility and the risks to their physical safety.</p>
                  <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In some cases, we see aspirational or conditional threats directed at terrorists and other violent actors (e.g. "Terrorists deserve to be killed"), and we deem those non-credible, absent specific evidence to the contrary.</p>
                  <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                  <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Threats that could lead to death (and other forms of high-severity violence) and admission of past violence targeting people or places where threat is defined as any of the following:</p>
                  <ul id="l4">
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Statements of intent to commit high-severity violence. This includes content where a symbol represents the target and/or includes a visual of an armament or method to represent violence.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Calls for high-severity violence including content where no target is specified but a symbol represents the target and/or includes a visual of an armament or method that represents violence.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Statements advocating for high-severity violence.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Aspirational or conditional statements to commit high-severity violence.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Statements admitting to committing high-severity violence except when shared in a context of redemption, self-defense or when committed by law enforcement, military or state security personnel.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Content that asks, admits to offering or offers services for hire to kill others (for example, hitmen, mercenaries, assassins) or advocates for the use of a hitman, mercenary or assassin against a target</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Admissions, statements of intent or advocacy, calls to action, or aspirational or conditional statements to kidnap or abduct a target or that promotes, supports or advocates for kidnapping or abduction</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Content that depicts kidnappings or abductions if it is clear the content is not being shared by a victim or their family as a plea for help, or shared for informational, condemnation or awareness raising purposes</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Threats of high-severity violence using digitally-produced or altered imagery to target living people with armaments, methods of violence or dismemberment</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Threats that lead to serious injury (mid-severity violence) and admission of past violence toward private individuals, unnamed specified persons, minor public figures, high-risk persons or high-risk groups where threat is defined as any of the following:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Statements of intent to commit violence, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Statements advocating violence, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Calls for mid-severity violence including content where no target is specified but a symbol represents the target, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Aspirational or conditional statements to commit violence, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Statements admitting to committing mid-severity violence except when shared in a context of redemption, self-defence, fight-sports context or when committed by law enforcement, military or state security personnel.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Content about other target(s) apart from private individuals, minor public figures, high-risk persons or high-risk groups and any credible:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Statements of intent to commit violence, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Calls for action of violence, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Statements advocating for violence, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>Aspirational or conditional statements to commit violence</p>
                      <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Threats that lead to physical harm (or other forms of lower-severity violence) towards private individuals (self-reporting required) or minor public figures where threat is defined as any of the following:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Private individuals (name and/or face match are required) or minor public figures that includes:</p>
                      <ul id="l5">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '130pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Statements of intent, calls for action, advocating, aspirational or conditional statements to commit low-severity violence</p>
                          <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Instructions on how to make or use weapons if there is evidence of a goal to seriously injure or kill people through:</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-94pt', textAlign: 'right'}}>Language explicitly stating that goal, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Photos or videos that show or simulate the end result (serious injury or death) as part of the instruction.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Unless the aforementioned content is shared as part of recreational self-defense, for military training purposes, commercial video games, or news coverage (posted by a Page or with a news logo).</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Providing instructions on how to make or use explosives, unless there is clear context that the content is for a non-violent purpose (for example, part of commercial video games, clear scientific/educational purpose, fireworks or specifically for fishing)</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Any content containing statements of intent, calls for action, conditional or aspirational statements, or advocating for violence due to voting, voter registration or the administration or outcome of an election</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Statements of intent or advocacy, calls to action, or aspirational or conditional statements to bring weapons to locations, including but not limited to places of worship, educational facilities, polling places or locations used to count votes or administer an election (or encouraging others to do the same).</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                    </li>
                  </ul>
                </li>
              </ol>
            </ol>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-41pt', textAlign: 'right'}}>Violent threats against law enforcement officers.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Violent threats against people accused of a crime. We remove this content when we have reason to believe that the content is intended to cause physical harm.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Coded statements where the method of violence or harm is not clearly articulated, but the threat is veiled or implicit. Bah Blesson &amp; Company looks at the below signals to determine whether there is a threat of harm in the content.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Shared in a retaliatory context (e.g., expressions of desire to do something harmful to others in response to a grievance or threat that may be real, perceived or anticipated)</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>References to historical or fictional incidents of violence (e.g., content that threatens others by referring to known historical incidents of violence that have been executed throughout history or in fictional settings)</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'justify'}}>Acts as a threatening call-to-action (e.g. content inviting or encouraging others to carry out harmful acts or to join in carrying out the harmful acts)</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Indicates knowledge of or shares sensitive information that could expose others to harm (e.g. content that either makes note of or implies awareness of personal information that might make a threat of physical violence more credible. This includes implying knowledge of a person's residential address, their place of employment or education, daily commute routes or current location)</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Local context or subject matter expertise confirms that the statement in question could be threatening and/or could lead to imminent violence or physical harm.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>The subject of the threat reports the content to us.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Threats against election officials</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Implicit statements of intent or advocacy, calls to action, or aspirational or conditional statements to bring armaments to locations, including but not limited to places of worship, educational facilities, polling places, or locations used to count votes or administer an election (or encouraging others to do the same). We may also restrict calls to bring armaments to certain locations where there are temporarily signals of a heightened risk of violence or offline harm. This may be the case, for example, when there is a known protest and counter-protest planned or violence broke out at a protest in the same city within the last 7 days</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Dangerous Individuals and Organizations</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In an effort to prevent and disrupt real-world harm, we do not allow organizations or individuals that proclaim a violent mission or are engaged in violence to have a presence on MyOpinion. We assess these entities based on their behavior both online and offline, most significantly, their ties to violence. Under this policy, we designate individuals, organizations, and networks of people. These designations are divided into three tiers that indicate the level of content enforcement, with Tier 1 resulting in the most extensive enforcement because we believe these entities have the most direct ties to offline harm.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Tier 1 <span className="p">focuses on entities that engage in serious offline harms -including organizing or advocating for violence against civilians,</span></h3>
            <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>repeatedly dehumanizing or advocating for harm against people based on protected characteristics, or engaging in systematic criminal operations. Tier 1 entities include terrorist, hate, and criminal organizations. We remove praise, substantive support, and representation of Tier 1 entities as well as their leaders, founders, or prominent members. Tier 1 includes hate organizations; criminal organizations, including those designated by the United States government as Specially Designated Narcotics Trafficking Kingpins (SDNTKs); and terrorist organizations, including entities and individuals designated by the United States government as Foreign Terrorist Organizations (FTOs) or Specially Designated Global Terrorists (SDGTs). We remove praise, substantive support, and representation of Tier 1 entities as well as their leaders, founders, or prominent members.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In addition, we do not allow content that praises, substantively supports, or represents events that MyOpinion designates as violating violent events - including terrorist attacks, hate events, multiple-victim violence or attempted multiple-victim violence, multiple murders, or hate crimes. Nor do we allow praise, substantive support, or representation of the perpetrator(s) of such attacks. We also remove content that praises, substantively supports or represents ideologies that promote hate, such as Nazism and White Supremacy.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Tier 2 <span className="p">focuses on entities that engage in violence against state or military actors but do not generally target civilians -- what we call “Violent Non-State Actors.” We remove all substantive support and representation of these entities, their leaders, and their prominent members. We remove any praise of these groups’ violent activities.</span></h3>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Tier 3 <span className="p">focuses on entities that may repeatedly engage in violations of our Hate Speech or Dangerous Organizations policies on-or-off the platform or demonstrate strong intent to engage in offline violence in the near future, but have not necessarily engaged in violence to date or advocated for violence against others based on their protected characteristics. This includes Militarized Social Movements, Violence-Inducing Conspiracy Networks, and individuals and groups banned for promoting hatred. Tier 3 entities may not have a presence or coordinate on our platforms.</span></h3>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We recognize that users may share content that includes references to designated dangerous organizations and individuals to report on, condemn, or neutrally discuss them or their activities. Our policies are designed to allow room for these types of discussions while simultaneously limiting risks of potential offline harm. We thus require people to clearly indicate their intent when creating or sharing such content. If a user's intention is ambiguous or unclear, we default to removing content.</p>
            <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In line with international human rights law, our policies allow discussions about the human rights of designated individuals or members of designated dangerous entities, unless the content includes other praise, substantive support, or representation of designated entities or other policy violations, such as incitement to violence.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>We Remove:</p>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We remove Praise, Substantive Support, and Representation of various Dangerous Organizations. These concepts apply to the organizations themselves, their activities, and their members. These concepts do not proscribe peaceful advocacy for particular political outcomes.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Praise<span className="p">, defined as any of the below:</span></h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Speak positively about a designated entity or event;</p>
            <ul id="l6">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “The fighters for the Islamic State are really brave!”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Give a designated entity or event a sense of achievement;</p>
            <ul id="l7">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “Timothy McVeigh is a martyr.”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Legitimizing the cause of a designated entity by making claims that their hateful, violent, or criminal conduct is legally, morally, or otherwise justified or acceptable;</p>
            <ul id="l8">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “Hitler did nothing wrong.”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Aligning oneself ideologically with a designated entity or event.</p>
            <ul id="l9">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “I stand with Brenton Tarrant.”</p>
                <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>We remove Praise of Tier 1 entities and designated events. We will also remove praise of violence carried out by Tier 2 entities.</p>
                <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Substantive Support<span className="p">, defined as any of the below:</span></h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Any act which improves the financial status of a designated entity -including funneling money towards, or away from a designated entity;</p>
            <ul id="l10">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “Donate to the KKK!”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Any act which provides material aid to a designated entity or event;</p>
            <ul id="l11">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>E.g., “If you want to send care packages to the Sinaloa Cartel, use this address:”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Putting out a call to action on behalf of a designated entity or event;</p>
            <ul id="l12">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “Contact the Atomwaffen Division - (XXX) XXX-XXXX”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Recruiting on behalf of a designated entity or event;</p>
            <ul id="l13">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “If you want to fight for the Caliphate, DM me”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Channeling information or resources, including official communications, on behalf of a designated entity or event</p>
            <ul id="l14">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>E.g., Directly quoting a designated entity without caption that condemns, neutrally discusses, or is a part of news reporting.</p>
                <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>We remove Substantive Support of Tier 1 and Tier 2 entities and designated events.</p>
                <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Representation<span className="p">, defined as any of the below:</span></h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Stating that you are a member of a designated entity, or are a designated entity;</p>
            <ul id="l15">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., “I am a grand dragon of the KKK.”</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Creating a Page, Profile, Event, Group, or other MyOpinion entity that is or purports to be owned by a Designated Entity or run on their behalf, or is or purports to be a designated event.</p>
            <ul id="l16">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>E.g., A Page named “American Nazi Party.”</p>
                <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We remove Representation of Tier 1 and 2 Designated Organizations, Hate-Banned Entities, and designated events.</p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Types and Tiers of Dangerous Organizations</p>
                <p className="s2" style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Tier 1: Terrorism, organized hate, large-scale criminal activity, attempted multiple-victim violence, multiple victim violence, multiple murders, and violating violent events</p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We do not allow individuals or organizations involved in organized crime, including those designated by the United States government as specially designated narcotics trafficking kingpins (SDNTKs); hate; or terrorism, including entities designated by the United States government as Foreign Terrorist Organizations (FTOs) or Specially Designated Global Terrorists (SDGTs), to have a presence onthe platform. We also don't allow other people to represent these entities. We do not allow leaders or prominent members of these organizations to have a presence on the platform, symbols that represent them to be used on the platform, or content that praises them or their acts. In addition, we remove any coordination of substantive support for these individuals and organizations.</p>
                <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We do not allow content that praises, substantively supports, or represents events that Bah Blesson &amp; Company designates as terrorist attacks, hate events, multiple-victim violence or attempted multiple-victim violence, serial murders, hate crimes or violating violent events.</p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We also do not allow Praise, Substantive Support, or Representation of designated hateful ideologies.</p>
                <h3 style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Terrorist organizations and individuals<span className="p">, defined as a non-state actor that:</span></h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Engages in, advocates, or lends substantial support to purposive and planned acts of violence,</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Which causes or attempts to cause death, injury or serious harm to civilians, or any other person not taking direct part in the hostilities in a situation of armed conflict, and/or significant damage to property linked to death, serious injury or serious harm to civilians</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>With the intent to coerce, intimidate and/or influence a civilian population, government, or international organization</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>In order to achieve a political, religious, or ideological aim.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Hate Organizations<span className="p">, defined as an association of three or more people that:</span></h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>is organized under a name, sign, or symbol; and</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>has an ideology, statements, or physical actions that attack individuals based on characteristics, including race, religious affiliation, national origin, disability, ethnicity, gender, sex, sexual orientation, or serious disease.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Criminal Organizations<span className="p">, defined as an association of three or more people that:</span></h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>is united under a name, color(s), hand gesture(s) or recognized indicia; and</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>has engaged in or threatens to engage in criminal activity such as homicide, drug trafficking, or kidnapping.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Multiple-Victim Violence and Multiple Murders</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>We consider an event to be multiple-victim violence or attempted multiple-victim violence if it results in three or more casualties in one incident, defined as deaths or serious injuries. Any Individual who has committed such an attack is considered to be a perpetrator or an attempted perpetrator of multiple-victim violence.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>We consider any individual who has committed two or more murders over multiple incidents or locations a multiple murderer.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Hateful Ideologies</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>While our designations of organizations and individuals focus on behavior, we also recognize that there are certain ideologies and beliefs that are inherently tied to violence and attempts to organize people around calls for violence or exclusion of others based on their protected characteristics. In these cases, we designate the ideology itself and remove content that supports this ideology from our platform. These ideologies include:</p>
            <ul id="l17">
              <li data-list-text="">
                <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Nazism</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>White Supremacy</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>White Nationalism</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>White Separatism</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>We remove explicit Praise, Substantive Support, and Representation of these ideologies, and remove individuals and organizations that ascribe to one or more of these hateful ideologies.</p>
            <p className="s2" style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Tier 2: Violent Non-State Actors</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Organizations and individuals designated by Bah Blesson &amp; Company as Violent Non-state Actors are not allowed to have a presence on MyOpinion, or have a presence maintained by others on their behalf. As these groups are actively engaged in violence, substantive support of these entities is similarly not allowed. We will also remove praise of violence carried out by these entities.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Violent Non-State Actors<span className="p">, defined as any non-state actor that:</span></h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>engages in purposive and planned acts of violence primarily against a government military or other armed groups; and</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>that causes or attempts to</p>
            <ul id="l18">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>cause death to persons taking direct part in hostilities in an armed conflict, and/or</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>deprive communities of access to vital infrastructure and natural resources, and/or bring significant damage to property, linked to death, serious injury or serious harm to civilians</p>
                <p className="s2" style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Tier 3: Militarized Social Movements, Violence-Inducing Conspiracy Networks, and Hate Banned Entities</p>
                <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Pages, Groups, Events, and Profiles or other MyOpinion entities that are - or claim to be - maintained by, or on behalf of, Militarized Social Movements and Violence-Inducing Conspiracy Networks are prohibited. Admins of these pages, groups and events will also be removed.</p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We do not allow Representation of Organizations and individuals designated by Bah Blesson &amp; Company as Hate-Banned Entities.</p>
                <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Militarized Social Movements (MSMs)<span className="p">, which include:</span></h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Militia Groups<span className="p">, defined as non-state actors that use weapons as a part of their training, communication, or presence; and are structured or operate as unofficial military or security forces and:</span></h3>
            <ul id="l19">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Coordinate in preparation for violence or civil war; or</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Distribute information about the tactical use of weapons for combat; or</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Coordinate militarized tactical coordination in a present or future armed civil conflict or civil war.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Groups supporting violent acts amid protests<span className="p">, defined as non-State actors that repeatedly:</span></h3>
            <ul id="l20">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Coordinate, promote, admit to or engage in:</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Acts of street violence against civilians or law enforcement; or</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Arson, Looting, or other destruction of property; or</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Threaten to violently disrupt an election process; or</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Promote bringing weapons to a location when the stated intent is to intimidate people amid a protest.</p>
                <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Violence-Inducing Conspiracy Networks (VICNs)<span className="p">, defined as a Non-State Actor that:</span></h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Organizes under a name, sign, mission statement, or symbol; and</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Promote theories that attribute violent or dehumanizing behavior to people or organizations that have been debunked by credible sources; and</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Has inspired multiple incidents of real-world violence by adherents motivated by the desire to draw attention to or redress the supposed harms promoted by these debunked theories.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Hate-Banned Entities<span className="p">, defined as entities that engage in repeated hateful conduct or rhetoric, but do not rise to the level of a Tier 1 entity because they have not engaged in or explicitly advocated for violence, or because they lack sufficient connections to previously designated organizations or figures.</span></h3>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, we will allow content that may otherwise violate the Community Standards when it is determined that the content is satirical. Content will only be allowed if the violating elements of the content are being satirized or attributed to something or someone else in order to mock or criticize them.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Coordinating Harm and Promoting Crime</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In an effort to prevent and disrupt offline harm and copycat behavior, we prohibit people from facilitating, organizing, promoting or admitting to certain criminal or harmful activities targeted at people, businesses, property or animals. We allow people to debate and advocate for the legality of criminal and harmful activities, as well as draw attention to harmful or criminal activity that they may</p>
            <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>witness or experience as long as they do not advocate for or coordinate harm.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '168%', textAlign: 'left'}}>Do not post content that falls into the following categories: Harm against people</p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>Outing:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content that exposes the identity or locations affiliated with any individual who is alleged to:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Be a member of an outing-risk group; and/or</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Share familial and/or romantic relationships with a member(s) of an outing-risk group; and/or</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Have performed professional activities in support of an outing-risk group (except for political figures)</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that exposes the undercover status of law enforcement, military, or security personnel if:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>The content contains the agent’s full name or other explicit identification</p>
            <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>and explicitly mentions their undercover status.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>The imagery identifies the faces of the law enforcement personnel and explicitly mentions their undercover status.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Swatting, specifically statements of intent, calls to action, representing, supporting, advocating for, depicting, admitting to or speaking positively about it.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Depicting, promoting, advocating for or encouraging participation in a high-risk viral challenge.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Harm against animals</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Statements of intent, calls to action, representing, supporting or advocating for, or depicting, admitting to or promoting:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Acts of physical harm against animals committed by you or your associates except in cases of hunting, fishing, religious sacrifice, food preparation or processing, pest or vermin, self-defense or redemption.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '-52pt', lineHeight: '168%', textAlign: 'left'}}>Staged animal vs. animal fights, including acts committed by a third party. Harm against property</p>
            <p style={{paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Statements of intent, calls to action, representing, supporting or advocating for harm against property that depicts, admits to, or promotes the following acts committed by you or your associates:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Vandalism.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Hacking when the intent is to hijack a domain, corrupt or disrupt cyber systems, seek ransoms or gain unauthorized access to data systems.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Theft when committed by you or your associates, as well as positive statements about theft when committed by a third party.</p>
            <p className="s3" style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Voter and/or census fraud</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Offers to buy or sell votes with cash or gifts</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Statements that advocate, provide instructions, or show explicit intent to illegally participate in a voting or census process</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we include a label so that people are aware the content may be sensitive:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Imagery depicting a high-risk viral challenge if shared with a caption that condemns or raises awareness of the associated risks.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content that puts LGBTQI+ people at risk by revealing their sexual identity against their will or without permission.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that puts unveiled women at risk by revealing their images without veil against their will or without permission.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that puts non-convicted individuals at risk by revealing their identity and their status as a target of a sting operation as a sexual predator.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content revealing the identity of someone as a witness, informant, activist or individuals whose identity or involvement in a legal case has been restricted from public disclosure</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Imagery that is likely to deceive the public as to its origin if:</p>
            <ul id="l21">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>The entity depicted or an authorized representative objects to the imagery, and</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>The imagery has the potential to cause harm to members of the public.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Calls for coordinated interference that would affect an individual’s ability to</p>
            <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'justify'}}>participate in an official census or election.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content stating that census or voting participation may or will result in law enforcement consequences (for example, arrest, deport or imprisonment).</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Statements of intent, support or advocacy to go to an election site, voting location, or vote counting location when the purpose of going to the site is to monitor or watch voters or election officials’ activity using militaristic</p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'justify'}}>language (e.g. “war,” “army,” or “soldier” ) or an expressed goal to</p>
            <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify'}}>intimidate, exert control or display power (e.g. “Let’s show them who's boss!,” “If they’re scared, they won’t vote!”).</p>
            <h3 style={{paddingTop: '4pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Restricted Goods and Services</h3>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>To encourage safety and deter potentially harmful activities, we prohibit attempts by individuals, manufacturers, and retailers to purchase, sell, raffle, gift, transfer or trade certain goods and services on our platform. Brick-and-mortar and online retailers may promote firearms, alcohol, and tobacco items available for sale off of our services; however, we restrict visibility of this content for minors. We allow discussions about the sale of these goods in stores or by online retailers, as well as advocating for changes to regulations of goods and services covered in this policy.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Firearms</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempts to buy, sell, or trade, firearms, firearm parts, ammunition, explosives, or lethal enhancements except when posted by a Page, Group or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, websites, brands or government agencies (e.g. police department, fire department) or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Attempts to donate or gift firearms, firearm parts, ammunition, explosives, or lethal enhancements except when posted in the following contexts:</p>
            <ul id="l22">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Donating, trading in or buying back firearms and ammunition by a Page, Group or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, websites, brands or government agencies, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>An auction or raffle of firearms by legitimate brick-and-mortar entities, including retail businesses, government-affiliated organizations or non-profits, or private individuals affiliated with or sponsored by legitimate brick-and-mortar entities.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Asks for firearms, firearm parts, ammunition, explosives, or lethal enhancements</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Sells, gifts, exchanges, transfers, coordinates, promotes (by which we mean speaks positively about, encourages the use of) or provides access to 3D printing or computer-aided manufacturing instructions for firearms or firearms parts regardless of context or poster.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Non-medical drugs</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempts to buy, sell, trade, co-ordinate the trade of, donate, gift or asks for non-medical drugs.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Admits to buying, trading or co-ordinating the trade of non-medical drugs by the poster of the content by themselves or through others.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Admits to personal use without acknowledgment of or reference to recovery, treatment, or other assistance to combat usage. This content may not speak positively about, encourage use of, coordinate or provide instructions to make or use non-medical drugs.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Coordinates or promotes (by which we mean speaks positively about, encourages the use of, or provides instructions to use or make) non-medical drugs.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Pharmaceutical drugs</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Attempts to buy, sell or trade pharmaceutical drugs except when:</p>
            <ul id="l23">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Listing the price of vaccines in an explicit education or discussion context.</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Offering delivery when posted by legitimate healthcare e-commerce businesses.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Attempts to donate or gift pharmaceutical drugs</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Asks for pharmaceutical drugs except when content discusses the affordability, accessibility or efficacy of pharmaceutical drugs in a medical context</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Marijuana</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Content that attempts to buy, sell, trade, donate or gift or asks for marijuana.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Endangered species (wildlife and plants):</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempts to buy, sell, trade, donate,or gift or asks for endangered species or their parts.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Admits to poaching, buying or trading of endangered species or their parts committed by the poster of the content either by themselves or their associates through others. This does not include depictions of poaching by strangers.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Depicts poaching of endangered species or their parts committed by the poster of the content by themselves or their associates.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Shows coordination or promotion (by which we mean speaks positively about, encourages the poaching of, or provides instructions to use or make products from endangered species or their parts)</p>
            <h3 style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Live non-endangered animals excluding livestock</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that attempts to buy, sell or trade live non-endangered animals except when:</p>
            <ul id="l24">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Posted by a Page, Group or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, legitimate websites, brands, or rehoming shelters, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Posted in the context of donating or rehoming live non-endangered animals, including rehoming fees for peer-to-peer adoptions, selling an animal for a religious offering, or offering a reward for lost pets.</p>
                <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Human blood</h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content that attempts to buy, sell or trade human blood.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content that asks for human blood except for a donation or gift.</p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Alcohol / tobacco</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Attempts to buy, sell or trade alcohol or tobacco except when:</p>
            <ul id="l25">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Posted by a Page, Group, or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, websites or brands, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Content refers to alcohol/tobacco that will be exchanged or consumed on location at an event, restaurant, bar, party and so on.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempts to donate or gift alcohol or tobacco except when posted by a Page, Group, or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, websites or brands, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Asks for alcohol or tobacco</p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Weight loss products</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content about weight loss that contains a miracle claim and attempts to buy, sell, trade, donate or gift weight loss products.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Historical artifacts</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content that attempts to buy, sell, trade, donate or gift or asks for historical artifacts.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Except when any of the above occurs in a fictional or documentary context</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Hazardous Goods and Materials</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that attempts to buy, sell, trade, donate or gift or asks for hazardous goods and materials</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Except when any of the above occurs in a fictional or documentary context</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>For the following content, we restrict visibility to adults 21 years of age and older:</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Firearms</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content posted by or promoting legitimate brick-and-mortar store, entities, including retail businesses websites, brands, or government agencies which attempt to buy, sell, trade, donate or gift (including in the context of an auction or a raffle) firearms, firearm parts, ammunition, explosives, or lethal enhancements.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we restrict visibility to adults 18 years of age and older:</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Alcohol/tobacco</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content posted by or promoting legitimate brick-and-mortar entities, including retail businesses websites or brands, which attempt to buy, sell, trade, donate or gift of alcohol or tobacco products.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Bladed weapons</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content that attempts to buy, sell, trade, donate or gift bladed weapons.</p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Weight loss products and potentially dangerous cosmetic procedures</h3>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Attempts to buy, sell, trade, donate or gift weight loss products or potentially dangerous cosmetic procedures.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Admits to or depicts using a weight loss product or potentially dangerous cosmetic procedures, except when admitting to use in a disapproval context.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Shows coordination or promotion (by which we mean speaks positively, encourages the use of or provides instructions to use or make a diet product or perform dangerous cosmetic procedures).</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Sex toys and sexual enhancement products</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that attempts to buy, sell, trade, donate or gift sex toys and sexual enhancement products</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Real money gambling</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that attempts to sell or promote online gaming and gambling services where anything of monetary value (including cash or</p>
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>digital/virtual currencies, e.g. bitcoin) is required to play and anything of monetary value forms part of the prize.</p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, we will allow content that may otherwise violate the Community Standards when it is determined that the content is satirical. Content will only be allowed if the violating elements of the content are being satirized or attributed to something or someone else in order to mock or criticize them.</p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Fraud and Deception</h3>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In an effort to prevent fraudulent activity that can harm people or businesses, we remove content that purposefully deceives, willfully misrepresents or otherwise defrauds or exploits others for money or property. This includes content that seeks to coordinate or promote these activities using our services.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>We allow people to raise awareness and educate others as well as condemn these activities unless this includes content that contains sensitive information, such as personally identifiable information.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Content that provides instructions on, engages in, promotes, coordinates, encourages, facilitates, admits to, recruits for, or admits to the offering or solicitation of any of the following activities:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Deceiving others to generate a financial or personal benefit to the detriment of a third party or entity through:</p>
            <ul id="l26">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Investment or financial scams:</p>
                <ul id="l27">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Loan scams</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Advance fee scams.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Gambling scams</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Ponzi or pyramid schemes.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Money or cash flips or money muling.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Investment scams with promise of high rates of return.</p>
                  </li>
                </ul>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Inauthentic identity scams:</p>
                <ul id="l28">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Charity scams.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Romance or impersonation scams</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Establishment of false businesses or entities.</p>
                  </li>
                </ul>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Product or rewards scams:</p>
                <ul id="l29">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Grant and benefits scams.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Tangible, spiritual or illuminati scams.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Insurance scams, including ghost broking</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Fake jobs, work from home or get-rich-quick scams.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Debt relief or credit repair scams.</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Engaging and co-ordinating with others to fraudulently generate a financial or personal benefit at a loss for a third party, such as people, businesses or organisations through:</p>
            <ul id="l30">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Fake documents or financial instruments by:</p>
                <ul id="l31">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Creating, selling or buying of:</p>
                    <ul id="l32">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>Fake or forged documents.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>Fake or counterfeit currency or vouchers.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Fake or forged educational and professional certificates.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>Money laundering</p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Stolen information, goods, or services by:</p>
            <ul id="l33">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Credit card fraud and goods or property purchases with stolen financial information</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Trading, selling or buying of:</p>
                <ul id="l34">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Personal Identifiable Information.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Fake and misleading user reviews or ratings.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Credentials for subscription services.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Coupons.</p>
                  </li>
                </ul>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Sharing, selling, trading, or buying of:</p>
                <ul id="l35">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Future exam papers or answer sheets.</p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Betting manipulation (for example match fixing).</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Manipulation of measuring devices such as electricity or water meters in order to bypass their authorised or legal use.</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Content that engages in, promotes, encourages, facilitates, or admits to the following activities:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Bribery.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Embezzlement.</p>
          </li>
        </ul>
        <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, we will allow content that may otherwise violate the Community Standards when it is determined that the content is satirical. Content will only be allowed if the violating elements of the content are being satirized or attributed to something or someone else in order to mock or criticize them.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <ol id="l36">
          <ol id="l37">
            <li data-list-text="11.B">
              <p style={{paddingLeft: '70pt', textIndent: '-29pt', textAlign: 'left'}}>SAFETY</p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Suicide and Self-Injury</h3>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We care deeply about the safety of the people who use our apps. We regularly consult with experts in suicide and self-injury to help inform our policies and enforcement, and work with organizations around the world to provide assistance to people in distress.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>While we do not allow people to intentionally or unintentionally celebrate or promote suicide or self-injury, we do allow people to discuss these topics because we want MyOpinion to be a space where people can share their experiences, raise awareness about these issues, and seek support from one another.</p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We define self-injury as the intentional and direct injuring of the body, including self-mutilation and eating disorders. We remove any content that encourages suicide or self-injury, including fictional content such as memes or illustrations and any self-injury content which is graphic, regardless of context. We also remove content that identifies and negatively targets victims or survivors of suicide or self-injury seriously, humorously or rhetorically, as well as real time depictions of suicide or self-injury. Content about recovery of suicide or self-harm that is allowed, but may contain imagery that could be upsetting, such as a healed scar, is placed behind a sensitivity screen.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>When people post or search for suicide or self-injury- related content, we will direct them to local organizations that can provide support and if our Community Operations team is concerned about immediate harm we will contact local emergency services to get them help.</p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>With respect to live content, experts have told us that if someone is saying they intend to attempt suicide on a livestream, we should leave the content up for as long as possible, because the longer someone is talking to a camera, the more opportunity there is for a friend or family member to call emergency services.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>However, to minimize the risk of others being negatively impacted by viewing this content, we will stop the livestream at the point at which the threat turns into an attempt. As mentioned above, in any case, we will contact emergency services if we identify someone is at immediate risk of harming themselves.</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>for</p>
              <p style={{paddingTop: '3pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
              <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>Content that promotes, encourages, coordinates, or provides instructions</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <ul id="l38">
                <li data-list-text="">
                  <p style={{paddingLeft: '18pt', textIndent: '-18pt', textAlign: 'left'}}>Suicide.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '-18pt', textAlign: 'left'}}>Self-injury.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '-18pt', textAlign: 'left'}}>Eating disorders.</p>
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>Content that depicts graphic self-injury imagery</p>
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>It is against our policies to post content depicting a person who engaged in</p>
                  <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>a suicide attempt or death by suicide</p>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that focuses on depiction of ribs, collar bones, thigh gaps, hips, concave stomach, or protruding spine or scapula when shared together with terms associated with eating disorders</p>
                  <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that contains instructions for drastic and unhealthy weight loss when shared together with terms associated with eating disorders.</p>
                  <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that mocks victims or survivors of suicide, self-injury or eating disorders who are either publicly known or implied to have experienced suicide or self-injury</p>
                  <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we restrict content to adults over the age of 18, and include a sensitivity screen so that people are aware the content may be upsetting:</p>
                  <ul id="l39">
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Photos or videos depicting a person's death by suicide or suicide attempt that are determined to be newsworthy.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Photos or videos depicting a person who engaged in euthanasia/assisted suicide in a medical setting.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we include a sensitivity screen so that people are aware the content may be upsetting to some:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that depicts older instances of self-harm such as healed cuts or other non-graphic self-injury imagery in a self-injury, suicide or recovery context.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that depicts ribs, collar bones, thigh gaps, hips, concave stomach, or protruding spine or scapula in a recovery context.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>We provide resources to people who post written or verbal admissions of engagement in self injury, including:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Suicide.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Euthanasia/assisted suicide.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Self-harm.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Eating disorders.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Vague, potentially suicidal statements or references (including memes or stock imagery about sad mood or depression) in a suicide or self-injury context.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>We may remove suicide notes when we have confirmation of a suicide or suicide attempt. We try to identify suicide notes using several factors, including but not limited to, family or legal representative requests, media reports, law enforcement reports or other third party sources (e.g. government agencies, NGOs).</p>
                      <ul id="l40">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>A suicide note may also be removed when reported through the Report button of each opinion.</p>
                          <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                          <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Child Sexual Exploitation, Abuse and Nudity</h3>
                          <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>We do not allow content that sexually exploits or endangers children.</p>
                          <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>When we become aware of apparent child exploitation, we report it to the National Center for Missing and Exploited Children (NCMEC), in compliance with applicable law. We know that sometimes people share nude images of their own children with good intentions; however, we generally remove these images because of the potential for abuse by others and to help avoid the possibility of other people reusing or misappropriating the images.</p>
                          <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We also work with external experts to discuss and improve our policies and enforcement around online safety issues, especially with regard to children.</p>
                          <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                          <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Child sexual exploitation</h3>
                          <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that threatens, depicts, praises, supports, provides instructions for, makes statements of intent, admits participation in or shares links of the sexual exploitation of children (real or non-real minors, toddlers or babies), including but not limited to:</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sexual intercourse</p>
                      <ul id="l41">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Explicit sexual intercourse or oral sex, defined as mouth or genitals entering or in contact with another person's genitals or anus, where at least one person's genitals are nude.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Implied sexual intercourse or oral sex, including when contact is imminent or not directly visible.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Stimulation of genitals or anus, including when activity is imminent or not directly visible.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Presence of by-products of sexual activity.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Any of the above involving an animal.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Children with sexual elements, including but not limited to:</p>
                      <ul id="l42">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Restraints.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Focus on genitals.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Presence of aroused adult.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Presence of sex toys.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Sexualised costume.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Stripping.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Staged environment (for example, on a bed) or professionally shot (quality/focus/angles).</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Open-mouth kissing.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content of children in a sexual fetish context.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Content that supports, promotes, advocates or encourages participation in pedophilia unless it is discussed neutrally in an academic or verified health context.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Content that identifies or mocks alleged victims of child sexual exploitation by name or image.</p>
                      <h3 style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Solicitation</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Content that solicits</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Child Sexual Abuse Material (CSAM)</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Nude imagery of children</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sexualized imagery of children</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Real-world sexual encounters with children</p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Inappropriate interactions with children</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that constitutes or facilitates inappropriate interactions with children, such as:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Arranging or planning real-world sexual encounters with children</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Purposefully exposing children to sexually explicit language or sexual material</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Engaging in implicitly sexual conversations in private messages with children</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Obtaining or requesting sexual material from children in private messages</p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Exploitative intimate imagery and sextortion</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Content that attempts to exploit minors by:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Coercing money, favors or intimate imagery with threats to expose intimate imagery or information.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Sharing, threatening or stating an intent to share private sexual conversations or intimate imagery.</p>
                      <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Sexualization of children</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Content that solicits imagery of child sexual exploitation, or nude or sexualized images or videos of children</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content (including photos, videos, real-world art, digital content, and verbal depictions) that shows children in a sexualized context.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Groups, Pages and profiles targeted to sexualizing children.</p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Child nudity</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Content that depicts child nudity where nudity is defined as:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Close-ups of children’s genitalia</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Real nude toddlers, showing:</p>
                      <ul id="l43">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Visible genitalia, even when covered or obscured by transparent clothing.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Visible anus and/or fully nude close-up of buttocks.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Real nude minors, showing:</p>
                      <ul id="l44">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Visible genitalia (including genitalia obscured only by pubic hair or transparent clothing)</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Visible anus and/or fully nude close-up of buttocks.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Uncovered female nipples.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>No clothes from neck to knee - even if no genitalia or female nipples are showing.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Digitally-created depictions of nude minors, toddlers or babies unless the image is for health or educational purposes.</p>
                      <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Non-sexual child abuse</h3>
                      <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '22pt', textAlign: 'left'}}>Imagery that depicts non-sexual child abuse regardless of sharing intent Content that praises, supports, promotes, advocates for, provides</p>
                      <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>instructions for or encourages participation in non-sexual child abuse.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>For the following content, we include a warning screen so that people are aware the content may be disturbing and limit the ability to view the content to adults, ages eighteen and older:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Videos or photos that depict police officers or military personnel committing non-sexual child abuse.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Imagery of non-sexual child abuse, when law enforcement, child protection agencies, or trusted safety partners request that we leave the content on the platform for the express purpose of bringing a child back to safety.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we include a sensitivity screen so that people are aware the content may be upsetting to some:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Videos or photos of violent immersion of a child in water in the context of religious rituals.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we include a warning label so that people are aware that the content may be sensitive:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Imagery posted by a news agency that depicts child nudity in the context of famine, genocide, war crimes, or crimes against humanity, unless accompanied by a violating caption or shared in a violating context, in which case the content is removed.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We may also remove imagery depicting the aftermath of non-sexual child abuse when reported by news media partners, NGOs or other trusted safety partners.</p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Adult Sexual Exploitation</h3>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We recognize the importance of MyOpinion as a place to discuss and draw attention to sexual violence and exploitation. In an effort to create space for this conversation and promote a safe environment, we allow victims to share their experiences, but remove content that depicts, threatens or promotes sexual violence, sexual assault, or sexual exploitation. We also remove content that displays, advocates for or coordinates sexual acts with non-consenting parties to avoid facilitating non-consensual sexual acts.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>To protect victims and survivors, we remove images that depict incidents of sexual violence and intimate images shared without the consent of the person(s) pictured. As noted in the introduction, we also work with external safety experts to discuss and improve our policies and enforcement around online safety issues, and we may remove content when they provide information that content is linked to harmful activity.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In instances where content consists of any form of non-consensual sexual touching, necrophilia, or forced stripping, including:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Depictions (including real photos/videos except in a real-world art context), or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sharing, offering, asking for or threatening to share imagery, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Descriptions, unless shared by or in support of the victim/survivor, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Advocacy (including aspirational and conditional statements), or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Statements of intent, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Calls for action, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Admitting participation, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Mocking victims of any of the above.</p>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Content that attempts to exploit people by any of the following:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Sextortion: Coercing money, favors or intimate imagery from people with threats to expose their intimate imagery or intimate information</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Sharing, threatening, stating an intent to share, offering or asking for non-consensual intimate imagery that fulfills all of the 3 following conditions:</p>
                      <ul id="l45">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Imagery is non-commercial or produced in a private setting.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Person in the imagery is (near) nude, engaged in sexual activity or in a sexual pose.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Lack of consent to share the imagery is indicated by meeting any of the signals:</p>
                          <ul id="l46">
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Vengeful context (such as, caption, comments or page title).</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Independent sources (such as, law enforcement record) including entertainment media (such as, leak of images confirmed by media).</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>A visible match between the person depicted in the image and the person who has reported the content to us.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>The person who reported the content to us shares the same name as the person depicted in the image.</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Secretly taken non-commercial imagery of a real person's commonly sexualized body parts (breasts, groin, buttocks, or thighs) or of a real person engaged in sexual activity. This imagery is commonly known as "creepshots" or "upskirts" and includes photos or videos that mock, sexualize or expose the person depicted in the imagery.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Threatening or stating an intent to share private sexual conversations that meets the following criteria:</p>
                      <ul id="l47">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Lack of consent is indicated by:</p>
                          <ul id="l48">
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Vengeful context and/or threatening context, or</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>A visible match between the person depicted in the image and the person who has reported the content to us.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>The person who reported the content to us shares the same name as the person depicted in the image.</p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>For the following content, we include a warning screen so that people are aware the content may be disturbing:</p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Narratives and statements that contain a description of non-consensual sexual touching (written or verbal) that includes details beyond mere naming or mentioning the act if:</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Shared by the victim, or</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Shared by a third party (other than the victim) in support of the victim or condemnation of the act or for general awareness to be determined by context/caption.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Content mocking the concept of non-consensual sexual touching</p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We may restrict visibility to people over the age of 18 and include a warning label on certain fictional videos (e.g., digital and hand-drawn imagery, film and TV show scenes, comic books, music videos) that depict non-consensual sexual touching.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In addition to our at-scale policy of removing content that threatens or advocates rape or other non-consensual sexual touching, we may also disable the posting account.</p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Bullying and Harassment</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Bullying and harassment happen in many places and come in many different forms from making threats and releasing personally identifiable information to sending threatening messages and making unwanted malicious contact. We do not tolerate this kind of behavior because it prevents people from feeling safe and respected on MyOpinion.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We distinguish between public figures and private individuals because we want to allow discussion, which often includes critical commentary of people who are featured in the news or who have a large public audience. For public figures, we remove attacks that are severe as well as certain attacks where the public figure is directly tagged in the post or comment.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For private individuals, our protection goes further: We remove content that's meant to degrade or shame, including, for example, claims about someone's sexual personal activity. We recognize that bullying and harassment can have more of an emotional impact on minors, which is why our policies provide heightened protection for users between the ages of 13 and 18.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Context and intent matter, and we allow people to post and share if it is clear that something was shared in order to condemn or draw attention to</p>
                      <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>bullying and harassment. In certain instances, we require self-reporting because it helps us understand that the person targeted feels bullied or harassed. In addition to reporting such behavior and content, we encourage people to use all legal tools to help protect against it.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not:</p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Tier 1: Target anyone maliciously by:</h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Repeatedly contacting someone in a manner that is:</p>
                      <ul id="l49">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Unwanted, or</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Sexually harassing, or</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Directed at a large number of individuals with no prior solicitation.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attacking someone based on their status as a victim of sexual assault, sexual exploitation, sexual harassment, or domestic abuse.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Calling for self-injury or suicide of a specific person, or group of people.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attacking someone through derogatory terms related to sexual activity (for example: whore, slut).</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Posting content about a violent tragedy, or victims of violent tragedies that include claims that a violent tragedy did not occur.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Posting content about victims or survivors of violent tragedies or terrorist attacks by name or by image, with claims that they are:</p>
                      <ul id="l50">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Acting/pretending to be a victim of an event.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Otherwise paid or employed to mislead people about their role in the event.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Threatening to release an individual's private phone number, residential address or email address.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Making statements of intent to engage in a sexual activity or advocating for them to engage in a sexual activity.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Making severe sexualized commentary</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sharing derogatory sexualized photoshopped imagery or drawings</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Calling for, or making statements of intent to engage in, bullying and/or harassment.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Posting content that further degrades or expresses disgust toward individuals who are depicted in the process of, or right after, menstruating, urinating, vomiting, or defecating</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Creating Pages or Groups that are dedicated to attacking individual(s) by:</p>
                      <ul id="l51">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Calling for death, or to contract or develop a medical condition.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Making statements of intent of advocating to engage in sexual activity.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Making claims that the individual has or may have a sexually transmitted disease.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Sexualizing another adult.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Sending messages that contain the following attacks when aimed at an individual or group of individuals in the thread:</p>
                      <ul id="l52">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Attacks referenced in Tier 1, 2 and 4 of this policy.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Targeted cursing.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Calls for death, serious disease, disability, epidemic disease or physical harm.</p>
                          <h3 style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Tier 2: Target private individuals, limited scope public figures (for example, individuals whose primary fame is limited to their activism, journalism, or those who become famous through involuntary means) or public figures who are minors with:</h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Calls for death, or to contract or develop a medical condition.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Female-gendered cursing terms when used in a derogatory way.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Claims about sexual activity or sexually transmitted diseases except in the context of criminal allegations against adults about non-consensual sexual touching.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Pages or Groups created to attack through:</p>
                      <ul id="l53">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Targeted cursing.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Negative physical descriptions.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Claims about religious identity or blasphemy.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Expressions of contempt or disgust.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Female-gendered cursing terms when used in a derogatory way.</p>
                          <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Tier 3: Target public figures by purposefully exposing them to:</h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>For adults and minors:</p>
                      <ul id="l54">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Calls for death, or to contract or develop a medical condition.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Claims about sexually transmitted disease</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Female-gendered cursing terms when used in a derogatory way.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content that praises, celebrates or mocks their death or medical condition.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Attacks through negative physical descriptions.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>For minors:</p>
                      <ul id="l55">
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Comparisons to animals or insects that are culturally perceived as intellectually or physically inferior or to an inanimate object (“cow," “monkey” “potato”).</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content manipulated to highlight, circle or otherwise negatively draw attention to specific physical characteristics (nose, ear and so on).</p>
                          <h3 style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Tier 4: Target private individuals or limited scope public figures (for example, individuals whose primary fame is limited to their activism, journalism, or those who become famous through involuntary means) with:</h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Comparisons to animals or insects that are culturally perceived as intellectually or physically inferior or to an inanimate object (“cow," “monkey” “potato”).</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content manipulated to highlight, circle or otherwise negatively draw attention to specific physical characteristics (nose, ear and so on).</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Attacks through negative physical descriptions.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content that ranks individuals on physical appearance or personality.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content sexualizing another adult.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that further degrades individuals who are depicted being physically bullied except in self-defense and fight-sport contexts.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that praises, celebrates, or mocks their death or serious physical injury.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>In addition to the above, attacks through Pages or Groups:</p>
                      <ul id="l56">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Negative character or ability claims.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>First-person voice bullying only if the object targets more than one private individual.</p>
                          <h3 style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Tier 5: Target private adults (who must self-report) or any private minors or involuntary minor public figures with:</h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Targeted cursing.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Claims about romantic involvement, sexual orientation or gender identity.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Coordination, advocacy or promotion of exclusion.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Negative character or ability claims, except in the context of criminal allegations and business reviews against adults. We allow criminal allegations so that people can draw attention to personal experiences or offline events. In cases in which criminal allegations pose off-line harm to the named individual, however, we may remove them.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Expressions of contempt or disgust, except in the context of criminal allegations against adults.</p>
                      <h3 style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Tier 6: Target private individuals who are minors with:</h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Allegations about criminal or illegal behavior.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Videos of physical bullying shared in a non-condemning context.</p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Tier 7: Target private individuals (who must self-report) with:</h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>First-person voice bullying.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Unwanted manipulated imagery.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Comparison to other public, fictional or private individuals on the basis of physical appearance</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Claims about religious identity or blasphemy.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Comparisons to animals or insects that are not culturally perceived as</p>
                      <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>intellectually or physically inferior (“tiger," “lion").</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Neutral or positive physical descriptions.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Non-negative character or ability claims.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Any bullying or harassment violation, when shared in an endearing context.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-17pt', lineHeight: '168%', textAlign: 'left'}}>Attacks through derogatory terms related to a lack of sexual activity. We add a cover to this content so people can choose whether to see it:</p>
                      <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>Videos of physical bullying against minors shared in a condemning context</p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Post content that targets private individuals through unwanted Pages, Groups and Events. We remove this content when it is reported by the victim or an authorized representative of the victim.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Create accounts to contact someone who has blocked you.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Post attacks that use derogatory terms related to female gendered cursing. We remove this content when the victim or an authorized representative of the victim informs us of the content, even if the victim has not reported it directly.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Post content that would otherwise require the victim to report the content or an indicator that the poster is directly targeting the victim (for example: the victim is tagged in the post or comment). We will remove this content if we have confirmation from the victim or an authorized representative of the victim that the content is unwanted.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Post content praising, celebrating or mocking anyone's death. We also remove content targeting a deceased individual that we would normally require the victim to report.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Post content calling for or stating an intent to engage in behavior that would qualify as bullying and harassment under our policies. We will remove this content when we have confirmation from the victim or an authorized representative of the victim that the content is unwanted.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Post content sexualizing a public figure. We will remove this content when we have confirmation from the victim or an authorized representative of the victim that the content is unwanted.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Repeatedly contact someone to sexually harass them. We will remove this content when we have confirmation from the victim or an authorized representative of the victim that the content is unwanted.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Engage in mass harassment against individuals that targets them based on their decision to take or not take the COVID-19 vaccine with:</p>
                      <ul id="l57">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Statements of mental or moral inferiority based on their decision, or</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Statements that advocate for or allege a negative outcome as a result of their decision, except for widely proven and/or accepted COVID-19 symptoms or vaccine side effects.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Remove directed mass harassment, when:</p>
                      <ul id="l58">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Targeting, via any surface, ‘individuals at heightened risk of offline harm’, defined as:</p>
                          <ul id="l59">
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Human rights defenders</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Minors</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Victims of violent events/tragedies</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Opposition figures in at-risk countries during election periods</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Government dissidents who have been targeted based on their dissident status</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Ethnic and religious minorities in conflict zones</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Member of a designated and recognizable at-risk group</p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Targeting any individual via personal surfaces, such as inbox or profiles, with:</p>
                          <ul id="l60">
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that violates the bullying and harassment policies for private individuals or,</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Objectionable content that is based on a protected characteristic</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Disable accounts engaged in mass harassment as part of either</p>
                      <ul id="l61">
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>State or state-affiliated networks targeting any individual via any surface.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Adversarial networks targeting any individual via any surface with:</p>
                          <ul id="l62">
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that violates the bullying and harassment policies for private individuals or,</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content that targets them based on a protected characteristic, or,</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content or behavior otherwise deemed to be objectionable in local context</p>
                              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Human Exploitation</h3>
                              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>After consulting with outside experts from around the world, we are consolidating several existing exploitation policies that were previously housed in different sections of the Community Standards into one dedicated section that focuses on human exploitation and captures a broad range of harmful activities that may manifest on our platform. Experts think and talk about these issues under one umbrella human exploitation.</p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In an effort to disrupt and prevent harm, we remove content that facilitates or coordinates the exploitation of humans, including human trafficking. We define human trafficking as the business of depriving someone of liberty for profit. It is the exploitation of humans in order to force them to engagein commercial sex, labor or other activities against their will. It relies on deception, force and coercion, and degrades humans by depriving them of their freedom while economically or materially benefiting others.</p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Human trafficking is multi-faceted and global; it can affect anyone regardless of age, socioeconomic background, ethnicity, gender or location. It takes many forms, and any given trafficking situation can involve various stages of development. By the coercive nature of this abuse, victims cannot consent.</p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>While we need to be careful not to conflate human trafficking and smuggling, they can be related and exhibit overlap. The United Nations defines human smuggling as the procurement or facilitation of illegal entry into a state across international borders. Without necessity for coercion or force, it may still result in the exploitation of vulnerable individuals who are trying to leave their country of origin, often in pursuit of a better life. Human smuggling is a crime against a state, relying on movement, and human trafficking is a crime against a person, relying on exploitation.</p>
                              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Content or exhibited behavior that may lead to human exploitation, which includes any of the following:</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sex trafficking, covering minors and adults.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sales of children or illegal adoption.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Orphanage trafficking and orphanage volun-tourism.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Forced marriages.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Labor exploitation (including bonded labor).</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Domestic servitude.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Non-regenerative organ trafficking.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Forced criminal activity (e.g. forced begging, forced drug trafficking).</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-17pt', lineHeight: '168%', textAlign: 'left'}}>Recruitment of child soldiers. Content geared towards the:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Recruitment of potential victims through force, fraud, coercion, enticement, deception, blackmail or other non-consensual acts.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Facilitation of human exploitation by coordinating, transporting, transferring, harboring or brokering of victims prior or during the exploitation.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-17pt', lineHeight: '168%', textAlign: 'left'}}>Exploitation of humans by promoting, depicting or advocating for it. Content that offers to provide or facilitate human smuggling</p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Privacy Violation</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Privacy and the protection of personal information are fundamentally important values for MyOpinion. We work hard to safeguard your personal identity and information and we do not allow people to post personal or confidential information about yourself or of others.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We remove content that shares, offers or solicits personally identifiable information or other private information that could lead to physical or financial harm, including financial, residential, and medical information, as well as private information obtained from illegal sources. We also recognize that private information may become publicly available through news coverage, court filings, press releases, or other sources. When that happens, we may allow the information to be posted.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We also provide people ways to report imagery that they believe to be in violation of their privacy rights.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that shares or solicits any of the following private information, either on MyOpinion or through external links:</p>
                      <h3 style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Personally identifiable information about yourself or others</h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Personal identity: identifying individuals through government-issued numbers.</p>
                      <ul id="l63">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>National identification number (for example Social Security Number (SSN), Passport Number, National Insurance/Health Service Number, Personal Public Service Number (PPS), Individual Taxpayer Identification Number (ITIN)).</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Government IDs of law enforcement, military, or security personnel.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Personal information: directly identifying an individual, by indicating the ID number or registration information and the individual’s name.</p>
                      <ul id="l64">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Records or official documentation of civil registry information (marriage, birth, death, name change or gender recognition, and so on).</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Immigration and work status documents (for example, green cards, work permits, visas, or immigration papers).</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Driver’s licenses or license plates, except when license plates are</p>
                          <p style={{paddingTop: '1pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'center'}}>shared to facilitate finding missing people or animals.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Credit Privacy Number (CPN).</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Digital identity: authenticating access to an online identity</p>
                      <ul id="l65">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Email addresses with passwords.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Digital identities with passwords.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Passwords, pins or codes to access private information.</p>
                          <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Other private information</h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Personal contact information of others such as phone numbers, addresses and email addresses, except when shared or solicited to promote charitable causes, find missing people, animals, or objects, or contact business service providers.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Financial information.</p>
                      <ul id="l66">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Personal financial information about yourself or others, including:</p>
                          <ul id="l67">
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Non-public financial records or statements.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Bank account numbers with security or pin codes.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Digital payment method information with log in details, security or pin codes.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Credit or debit card information with validity dates or security pins or codes.</p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Financial information about businesses or organizations, unless originally shared by the organization itself, including:</p>
                          <ul id="l68">
                            <li data-list-text="">
                              <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Financial records or statements except when the financial records of the business are publicly available (for example, listed on stock exchanges or regulatory agencies, and so on)</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Bank account numbers accompanied by security or pin codes.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Digital payment method information accompanied by log in details, security or pin codes.</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Residential information</p>
                      <ul id="l69">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Imagery that displays the external view of private residences if all of the following conditions apply:</p>
                          <ul id="l70">
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>The residence is a single-family home, or the resident's unit number is identified in the image/caption.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>The city/neighborhood or GPS pin (for example, a pin from Google Maps) are identified.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>The content identifies the resident(s).</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>That same resident objects to the exposure of their private residence, or there is context of organizing protests against the resident (This does not include embassies that also serve as residences).</p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that exposes information about safe houses by sharing any of the below, unless the safe house is actively promoting information about their facility</p>
                          <ul id="l71">
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Actual address (Note: "Post Box only" is allowed.)</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Images of the safe house.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Identifiable city/neighborhood of the safe house.</p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Information exposing the identity of the safe house residents.</p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Medical information</p>
                      <ul id="l72">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Records or official documentation displaying medical, psychological, biometric, or genetic hereditary of others.</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Information obtained from hacked sources.</p>
                      <ul id="l73">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Except in limited cases of newsworthiness, content claimed or confirmed to come from a hacked source, regardless of whether the affected person is a public figure or a private individual.</p>
                          <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>The following content also may be removed:</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>A reported photo or video of people where the person depicted in the image is:</p>
                      <ul id="l74">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>A minor under 13 years old, and the content was reported by the minor or a parent or legal guardian.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>A minor between 13 and 18 years old, and the content was reported by the minor.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>An adult, where the content was reported by the adult from outside the United States and applicable law may provide rights to removal.</p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Any person who is incapacitated and unable to report the content on their own.</p>
                          <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                          <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that puts a defector at risk by outing the individual with personally identifiable information when the content is reported by credible government channels.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Depictions of someone in a medical or health facility if reported by the person pictured or an authorized representative.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, we will allow content that may otherwise violate the Community Standards when it is determined that the content is satirical. Content will only be allowed if the violating elements of the content are being satirized or attributed to something or someone else in order to mock or criticize them.</p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li data-list-text="11.C">
              <p style={{paddingLeft: '70pt', textIndent: '-29pt', textAlign: 'left'}}>OBJECTIONALE CONTENT</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Hate Speech</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We believe that people use their voice and connect more freely when they don’t feel attacked on the basis of who they are. That is why we don’t allow hate speech on MyOpinion. It creates an environment of intimidation and exclusion, and in some cases may promote offline violence.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We define hate speech as a direct attack against people — rather than concepts or institutions— on the basis of what we call protected characteristics: race, ethnicity, national origin, disability, religious affiliation, caste, sexual orientation, sex, gender identity and serious disease. We define attacks as violent or dehumanizing speech, harmful stereotypes, statements of inferiority, expressions of contempt, disgust or dismissal, cursing and calls for exclusion or segregation. We also prohibit the use of harmful stereotypes, which we define as dehumanizing comparisons that have historically been used to attack, intimidate, or exclude specific groups, and that are often linked with offline violence. We consider age a protected characteristic when referenced along with another protected characteristic. We also protect refugees, migrants, immigrants and asylum seekers from the most severe attacks, though we do allow commentary and criticism of immigration policies. Similarly, we provide some protections for characteristics like occupation, when they’re referenced along with a protected</p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>characteristic. Sometimes, based on local nuance, we consider certain words or phrases as code words for PC groups.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We recognize that people sometimes share content that includes someone else’s hate speech to condemn it or raise awareness. In other cases, speech that might otherwise violate our standards can be used self-referentially or in an empowering way. Our policies are designed to allow room for these types of speech, but we require people to clearly indicate their intent. If the intention is unclear, we may remove content.</p>
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Tier 1</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content targeting a person or group of people (including all groups except those who are considered non-protected groups described as having carried out violent crimes or sexual offenses or representing less than half of a group) on the basis of their aforementioned protected characteristic(s) or immigration status with:</p>
              <ul id="l75">
                <ul id="l76">
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Violent speech or support in written or visual form</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Dehumanizing speech or imagery in the form of comparisons, generalizations, or unqualified behavioral statements (in written or visual form) to or about:</p>
                    <ul id="l77">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Insects.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Animals that are culturally perceived as intellectually or physically inferior.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Filth, bacteria, disease and feces.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Sexual predator.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Subhumanity.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Violent and sexual criminals</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Other criminals (including but not limited to “thieves,” “bank</p>
                        <p style={{paddingTop: '1pt', paddingLeft: '77pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>robbers,” or saying “All [protected characteristic or quasi-protected characteristic] are ‘criminals’”).</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Statements denying existence.</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Mocking the concept, events or victims of hate crimes even if no real person is depicted in an image.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Designated dehumanizing comparisons, generalizations, or behavioral statements (in written or visual form) that include:</p>
                    <ul id="l78">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Black people and apes or ape-like creatures.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Black people and farm equipment.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Caricatures of Black people in the form of blackface.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Jewish people and rats.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Jewish people running the world or controlling major institutions such as media networks, the economy or the government.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Denying or distorting information about the Holocaust.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Muslim people and pigs.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Muslim person and sexual relations with goats or pigs.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Mexican people and worm-like creatures.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Women as household objects or referring to women as property or "objects".</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Transgender or non-binary people referred to as "it".</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Dalits, scheduled caste or ‘lower caste’ people as menial laborers.</p>
                        <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Tier 2</h3>
                        <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content targeting a person or group of people on the basis of their protected characteristic(s) with:</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Generalizations that state inferiority (in written or visual form) in the following ways:</p>
                    <ul id="l79">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Physical deficiencies are defined as those about:</p>
                        <ul id="l80">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Hygiene, including but not limited to: filthy, dirty, smelly.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Physical appearance, including but not limited to: ugly, hideous.</p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Mental deficiencies are defined as those about:</p>
                        <ul id="l81">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Intellectual capacity, including but not limited to: dumb, stupid, idiots.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Education, including but not limited to: illiterate, uneducated.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Mental health, including but not limited to: mentally ill, retarded, crazy, insane.</p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Moral deficiencies are defined as those about:</p>
                        <ul id="l82">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Character traits culturally perceived as negative, including but not limited to: coward, liar, arrogant, ignorant.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Derogatory terms related to sexual activity, including but not limited to: whore, slut, perverts.</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Other statements of inferiority, which we define as:</p>
                    <ul id="l83">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Expressions about being less than adequate, including but not limited to: worthless, useless.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Expressions about being better/worse than another protected characteristic, including but not limited to: "I believe that males are superior to females."</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Expressions about deviating from the norm, including but not limited to: freaks, abnormal.</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Expressions of contempt (in written or visual form), which we define as:</p>
                    <ul id="l84">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Self-admission to intolerance on the basis of a protected characteristics, including but not limited to: homophobic, islamophobic, racist.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Expressions that a protected characteristic shouldn't exist.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Expressions of hate, including but not limited to: despise, hate.</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Expressions of dismissal, including but not limited to: don´t respect, don't like, don´t care for</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Expressions of disgust (in written or visual form), which we define as:</p>
                    <ul id="l85">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Expressions that suggest the target causes sickness, including but not limited to: vomit, throw up.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Expressions of repulsion or distaste, including but not limited to: vile, disgusting, yuck.</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Cursing, except certain gender-based cursing in a romantic break-up context, defined as:</p>
                    <ul id="l86">
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Referring to the target as genitalia or anus, including but not limited to: cunt, dick, asshole.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Profane terms or phrases with the intent to insult, including but not limited to: fuck, bitch, motherfucker.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Terms or phrases calling for engagement in sexual activity, or contact with genitalia, anus, feces or urine, including but not limited to: suck my dick, kiss my ass, eat shit.</p>
                        <h3 style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Tier 3</h3>
                        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Content targeting a person or group of people on the basis of their protected characteristic(s) with any of the following:</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Segregation in the form of calls for action, statements of intent, aspirational or conditional statements, or statements advocating or supporting segregation.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Exclusion in the form of calls for action, statements of intent, aspirational or conditional statements, or statements advocating or supporting, defined as</p>
                    <ul id="l87">
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Explicit exclusion, which means things like expelling certain groups or saying they are not allowed.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Political exclusion, which means denying the right to right to political participation.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Economic exclusion, which means denying access to economic entitlements and limiting participation in the labour market.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Social exclusion, which means things like denying access to spaces (physical and online) and social services, except for gender-based exclusion in health and positive support Groups.</p>
                        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>Content that describes or negatively targets people with slurs, where slurs are defined as words that are inherently offensive and used as insulting labels for the above characteristics.</p>
                        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                        <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content explicitly providing or offering to provide products or services that</p>
                    <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>aim to change people’s sexual orientation or gender identity.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content attacking concepts, institutions, ideas, practices, or beliefs associated with protected characteristics, which are likely to contribute to imminent physical harm, intimidation or discrimination against the people associated with that protected characteristic. MyOpinion looks at a range of signs to determine whether there is a threat of harm in the content. These include but are not limited to: content that could incite imminent violence or intimidation; whether there is a period of heightened tension such as an election or ongoing conflict; and whether there is a recent history of violence against the targeted protected group. In some cases, we may also consider whether the speaker is a public figure or occupies a position of authority.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content targeting a person or group of people on the basis of their protected characteristic(s) with claims that they have or spread the novel coronavirus, are responsible for the existence of the novel coronavirus, are deliberately spreading the novel coronavirus, or mocking them for having or experiencing the novel coronavirus.</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, we will allow content that may otherwise violate the Community Standards when it is determined that the content is satirical. Content will only be allowed if the violating elements of the content are being satirized or attributed to something or someone else in order to mock or criticize them.</p>
                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Violent and Graphic Content</h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We remove content that glorifies violence or celebrates the suffering or humiliation of others because it may create an environment that discourages participation. We allow graphic content (with some limitations) to help people raise awareness about these issues.</p>
                    <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We know that people value the ability to discuss important issues like human rights abuses or acts of terrorism. We also know that people have different sensitivities with regard to graphic and violent content. For that reason, we add a warning label to especially graphic or violent content so that it is not available to people under the age of 18 and so people are aware of the graphic or violent nature before they click to see it.</p>
                    <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                    <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Imagery of people</h3>
                    <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Videos of people or dead bodies in non-medical settings if they</p>
                    <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>depict</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Dismemberment.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Visible internal organs; partially decomposed bodies.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Charred or burning people unless in the context of cremation or self-immolation when that action is a form of political speech or newsworthy.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Victims of cannibalism.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Throat-slitting.</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Live streams of capital punishment of a person</p>
                    <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Sadistic Remarks</h3>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Sadistic remarks towards imagery that is deleted or put behind a warning screen under this policy, unless there is a self-defense context or explicit educational context in a medical setting.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Sadistic remarks towards imagery of one or more persons subjected to violence and/or humiliating acts by one or more uniformed personnel doing a police function.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Offering or soliciting imagery that is deleted or put behind a warning screen under this policy, when accompanied by sadistic remarks.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Explicit sadistic remarks towards the suffering of animals depicted in the imagery.</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we include a warning screen so that people are aware the content may be disturbing. We also limit the ability to view the content to adults, ages 18 and older:</p>
                    <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Imagery of people</h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Videos of people or dead bodies in a medical setting if they depict:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Dismemberment.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Visible internal organs; partially decomposed bodies.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Charred or burning people, including cremation or self-immolation when that action is a form of political speech or newsworthy.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Victims of cannibalism.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Throat-slitting.</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Photos of wounded or dead people if they show:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Dismemberment.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Visible internal organs; partially decomposed bodies.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Charred or burning people.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Victims of cannibalism.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Throat-slitting.</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Imagery that shows the violent death of a person or people by accident or murder</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Imagery that shows capital punishment of a person</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Imagery that shows acts of torture committed against a person or people</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Imagery of non-medical foreign objects (such as Bah Blesson &amp; Companyl objects, knives, nails) involuntarily inserted or stuck into people causing grievous injury</p>
                    <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Imagery of animals</h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>The following content involving animals:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Videos depicting humans killing animals if there is no explicit manufacturing, hunting, food consumption, processing or preparation context.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Imagery of animal to animal fights, when there are visible innards or dismemberment of non-regenerating body, unless in the wild.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Imagery of humans committing acts of torture or abuse against live animals.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Imagery of animals showing wounds or cuts that render visible innards or dismemberment, if there is no explicit manufacturing, hunting, taxidermy, medical treatment, rescue or food consumption, preparation or processing context, or the animal is already skinned or with its outer layer fully removed.</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>For the following content, we include a label so that people are aware the content may be sensitive:</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Imagery of non-medical foreign objects voluntarily inserted into people through skin in religious or cultural context</p>
                    <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '168%', textAlign: 'left'}}>Imagery of visible innards in a birthing context Imagery of fetuses and newborn babies that show:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Dismemberment.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Visible innards.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>An abortion or abandonment context.</p>
                    <p style={{paddingLeft: '76pt', textIndent: '0pt', lineHeight: '22pt', textAlign: 'left'}}>Imagery of newborn babies in an abandonment context Imagery of animals in a ritual slaughter context showing</p>
                    <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>dismemberment, or visible innards, or charring or burning</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>We remove:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Videos and photos that show the violent death of someone when a family member requests its removal.</p>
                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Adult Nudity and Sexual Activity</h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '50pt', lineHeight: '108%', textAlign: 'left'}}>We restrict the display of nudity or sexual activity because some people in our community may be sensitive to this type of content. Additionally, we default to removing sexual imagery to prevent the sharing of non-consensual or underage content. Restrictions on the display of sexual activity also apply to digitally created content unless it is posted for educational, humorous, or satirical purposes.</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>Our nudity policies have become more nuanced over time. We understand that nudity can be shared for a variety of reasons, including as a form of protest, to raise awareness about a cause, or for educational or medical reasons.</p>
                    <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Where such intent is clear, we make allowances for the content. For example, while we restrict some images of female breasts that include the nipple, we allow other images, including those depicting acts of protest, women actively engaged in breast-feeding and photos of post-mastectomy scarring. For images depicting visible genitalia or the anus in the context of birth and after-birth moments or health-related situations we include a warning label so that people are aware that the content may be sensitive. We also allow photographs of paintings, sculptures, and other art that depicts nude figures.</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Imagery of real nude adults, if it depicts:</p>
                    <ul id="l88">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Visible genitalia except in the context of birth giving and after-birth moments or health-related situations (for example, gender confirmation surgery, examination for cancer or disease prevention/assessment).</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Visible anus and/or fully nude close-ups of buttocks unless photoshopped on a public figure.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Uncovered female nipples except in the context of breastfeeding, birth giving and after-birth moments, health-related situations (for example, post-mastectomy, breast cancer awareness or gender confirmation surgery) or an act of protest.</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Imagery of sexual activity, including:</p>
                    <ul id="l89">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Explicit sexual activity and stimulation</p>
                        <ul id="l90">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Explicit sexual intercourse or oral sex, defined as mouth or genitals entering or in contact with another person's genitals or anus, where at least one person's genitals are nude.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Explicit stimulation of genitalia or anus, defined as stimulating genitalia or anus or inserting objects, including sex toys, into genitalia or anus, where the genitalia and contact with the genitalia is directly visible.</p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Implied sexual activity and stimulation</p>
                        <ul id="l91">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Implied sexual intercourse or oral sex, defined as mouth or genitals entering or in contact with another person's genitals or anus, when the genitalia is not directly visible, except in cases of a sexual health context, advertisements and recognized fictional images or with indicators of fiction.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Implied stimulation of genitalia or anus, defined as stimulating genitalia or anus or inserting objects, including sex toys, into or above genitalia or anus, when the activity or contact is not directly visible, except in cases of sexual health context, advertisements, and recognized fictional images or with indicators of fiction.</p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Other activities including but not limited to:</p>
                        <ul id="l92">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Erections</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Presence of by-products of sexual activity.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Sex toys placed upon or inserted into mouth.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Stimulation of naked human nipples.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Squeezing female breasts, defined as a grabbing motion with curved fingers that shows both marks and clear shape change of the breasts. We allow squeezing in breastfeeding contexts.</p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Fetish content that involves:</p>
                        <ul id="l93">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Acts that are likely to lead to the death of a person or animal.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Dismemberment.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Cannibalism.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Feces, urine, spit, snot, menstruation or vomit.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Bestiality.</p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Digital content that meets our definition of sexual activity, except when posted in an educational or scientific context, or when it meets one of the criteria below and shown only to individuals 18 years and older.</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Extended audio of sexual activity</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>We only show this content to individuals 18 and older:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Real world art that depicts sexual activity.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Posting photographs or videos of objects that depict sexual activity in real-world art.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Imagery depicting bestiality in real-world art provided it is shared neutrally or in condemnation and the people or animals depicted are not real.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Implied sexual activity in advertisements.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Implied sexual activity in recognized fictional images or with indicators of fiction.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Digital content that meets our definition of sexual activity, where:</p>
                    <ul id="l94">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>The sexual activity (intercourse or other sexual activities) isn’t</p>
                        <p style={{paddingTop: '1pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'left'}}>directly visible.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>The content was posted in a satirical or humorous context.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Only body shapes or contours are visible.</p>
                        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                        <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Sexual Solicitation</h3>
                        <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>As noted in Section 8 of our Community Standards (Adult Sexual Exploitation), people use MyOpinion to discuss and draw attention to sexual violence and exploitation. We recognize the importance of and allow for this discussion. We also allow for the discussion of sex worker rights advocacy and sex work regulation. We draw the line, however, when content facilitates, encourages or coordinates sexual encounters or commercial sexual services between adults. We do this to avoid facilitating transactions that may involve trafficking, coercion and non-consensual sexual acts.</p>
                        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We also restrict sexually-explicit language that may lead to sexual solicitation because some audiences within our global community may be sensitive to this type of content, and it may impede the ability for people to connect with their friends and the broader community.</p>
                        <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                        <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that offers or asks for adult commercial services, such as requesting, offering or asking for rates for escort service and paid sexual fetish or domination services. (Content that recruits or offers other people for third-party commercial sex work is separately considered under the Human Exploitation policy.</p>
                        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Attempted coordination of or recruitment for, adult sexual activities, except when promoting an event or venue, including but not limited to:</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Filmed sexual activities.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Pornographic activities, strip club shows, live sex performances or erotic dances.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sexual, erotic or tantric massages.</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Explicit sexual solicitation by, including but not limited to the following, offering or asking for:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Offering or asking for sex or sexual partners (including partners who share fetish or sexual interests).</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sex chat or conversations.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Nude photos/videos/imagery/sexual fetish items.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Sexual slang terms.</p>
                    <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We allow expressing desire for sexual activity, promoting sex education, discussing sexual practices or experiences, or offering classes or programs that teach techniques or discuss sex.</p>
                    <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that is implicitly or indirectly offering or asking for sexual solicitation and meets both of the following criteria. If both criteria are not met, it is not deemed to be violating. For example, if content is a hand-drawn image depicting sexual activity but does not ask or offer sexual solicitation, it is not violating:</p>
                  </li>
                  <li data-list-text="">
                    <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Criteria 1: Offer or ask</h3>
                    <ul id="l95">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content that implicitly or indirectly (typically through providing a method of contact) offers or asks for sexual solicitation.</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Criteria 2: Suggestive Elements</h3>
                    <ul id="l96">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content that makes the aforementioned offer or ask using one of the following sexually suggestive elements:</p>
                        <ul id="l97">
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Contextually specific and commonly sexual emojis or emoji strings, or</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Regional sexualized slang, or</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Mentions or depictions of sexual activity (including hand drawn, digital, or real-world art) such as sexual roles, sex</p>
                            <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>positions, fetish scenarios, state of arousal, act of sexual intercourse or activity (e.g. sexual penetration or self-pleasuring), or</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>Poses.</p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Audio of sexual activity or other content that violates the Adult Nudity and Sexual Activity.</p>
                            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>An offer or ask for pornographic material (including, but not limited to, sharing of links to external pornographic websites)</p>
                            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Sexually-explicit language that goes into graphic detail beyond mere reference to:</p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>A state of sexual arousal (e.g wetness or erection) or</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>An act of sexual intercourse (e.g sexual penetration, self-pleasuring or exercising fetish scenarios).</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Except for content shared in a humorous, satirical, or educational context, as a sexual Bah Blesson &amp; Companyphor or as sexual cursing.</p>
                    <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, we will allow content that may otherwise violate the Community Standards when it is determined that the content is satirical. Content will only be allowed if the violating elements of the content are being satirized or attributed to something or someone else in order to mock or criticize them.</p>
                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                  </li>
                </ul>
              </ul>
            </li>
            <li data-list-text="11.D">
              <p style={{paddingLeft: '71pt', textIndent: '-30pt', textAlign: 'left'}}>INTEGRITY AND AUTHENTICITY</p>
            </li>
          </ol>
        </ol>
        <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Account Integrity and Authentic Identity</h3>
        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Authenticity is the cornerstone of our community. We believe that authenticity helps create a community where people are accountable to each other, and to MyOpinion, in meaningful ways. We want to allow for the range of diverse ways that identity is expressed across our global community, while also preventing impersonation and identity misrepresentation. That is why we require people to connect on MyOpinion using the name they go by in everyday life. Our authenticity policies are intended to create a safe environment where people can trust and hold one another accountable.</p>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In order to maintain a safe environment and empower free expression, we remove accounts that are harmful to the community, including those that compromise the security of other accounts and our services. We have built a combination of automated and manual systems to block and remove accounts that are used to persistently or egregiously abuse our Community Standards.</p>
        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Because account level removal is a severe action, whenever possible, we aim to give our community a chance to learn our rules and follow our Community</p>
        <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>Standards. Penalties, including account disables, are designed to be proportionate to the severity of the violation and the risk of harm posed to the community. Continued violations, despite repeated warnings and restrictions, or violations that pose severe safety risks will lead to an account being disabled.</p>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We do not allow the use of our services and will restrict or disable accounts or other entities (such as pages, groups, and events) if you:</p>
        <ul id="l98">
          <ul id="l99">
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Severely violate our Community Standards.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Persistently violate our Community Standards.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Coordinate as part of a network of accounts or other entities in order to violate or evade our Community Standards.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Represent Dangerous Individuals or Organizations.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Create or use an account that demonstrates an intent to violate our Community Standards.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Create an account, Page, Group or Event to evade our enforcement actions, including creating an account to bypass a restriction or after we have disabled your previous account, Page, Group or Event.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Impersonate others by:</p>
              <ul id="l100">
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Using their photos with the explicit aim to deceive others.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Creating an account assuming to be or speak for another person or entity.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Creating a Page assuming to be or speak for another person or entity for whom the user is not authorized to do so.</p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Are under 13 years old.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Are a convicted sex offender.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Are prohibited from receiving our products, services or software under applicable laws.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, such as those outlined below, we will seek further information about an account before taking actions ranging from temporarily restricting accounts to permanently disabling them.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Accounts misrepresenting their identity by:</p>
              <ul id="l101">
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Using a name that is not the authentic name you go by in everyday life</p>
                  <ul id="l102">
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Using an inherently violating name, containing slurs or any other violations of the Community Standards.</p>
                    </li>
                  </ul>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Providing a false date of birth.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Creating a single account that represents or is used by more than one person.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Maintaining multiple accounts as a single user.</p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Compromised accounts.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Empty accounts with prolonged dormancy.</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Spam</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We work hard to limit the spread of spam because we do not want to allow content that is designed to deceive, or that attempts to mislead users, to increase viewership. This content creates a negative user experience, detracts from people's ability to engage authentically in online communities and can threaten the security, stability and usability of our services. We also aim to prevent people from abusing our platform, products or features to artificially increase viewership or distribute content en masse for commercial gain.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not:</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Post, share, engage with content or create accounts, Groups, Pages, Events or other assets, either manually or automatically, at very high frequencies.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempt to or successfully sell, buy or exchange site privileges or product features, such as accounts, admin roles, permission to post, Pages, Groups, likes, etc., except in the case of clearly identified branded content.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Require or claim that users are required to engage with content (e.g. liking, sharing) before they are able to view or interact with promised content.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'justify'}}>Encourage likes, shares, follows, clicks or the use of apps or websites under false pretenses, such as:</p>
              <ul id="l103">
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Offering false or non-existent services or functionality (e.g. “Get a ‘Dislike’ button!”)</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Failing to direct to promised content (e.g. “Click here for a discount code at Nordstrom”; <i>false play buttons</i>)</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Directing users off of MyOpinion through the deceptive or misleading use of URLs, defined as:</p>
                  <ul id="l104">
                    <li data-list-text="">
                      <h3 style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Cloaking. <span className="p">Presenting different content to MyOpinion users and MyOpinion crawlers or tools.</span></h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Misleading content<span className="p">. Content contains a link that promises one type of content but delivers something substantially different.</span></h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Deceptive pop-up websites<span className="p">. Websites that require an action (e.g. captcha, watch ad, click here) in order to view the expected landing page content and the domain name of the URL changes after the required action is complete.</span></h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Like/share-gating. <span className="p">Landing pages that require users to like, share, or otherwise engage with content before gaining access to off-MyOpinion content.</span></h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Deceptive landing page functionality. <span className="p">Websites that have a misleading user interface, which results in accidental traffic being generated (e.g. pop-ups/unders, clickjacking, etc.).</span></h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Impersonation. <a href="http://www.faceb00k.com/" className="a" target="_blank">The website pretends to be a reputable brand or service by using a name, domain or content that features typos, misspellings or other means to impersonate well-known brands using a landing page similar to another, trusted site to mislead visitors (e.g. www.fac</a><span className="p">eb00k.com, www.face_book.com).</span></h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>And other behaviors that are substantially similar to the above.</p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Cybersecurity</h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We recognize that the safety of our users includes the security of their personal information, accounts, profiles and other MyOpinion entities they may manage, as well as our products and services more broadly. Attempts to gather sensitive personal information or engage in unauthorized access by deceptive or invasive methods are harmful to the authentic, open and safe atmosphere that we want to foster. Therefore, we do not allow attempts to gather sensitive user information or engage in unauthorized access through the abuse of our platform, products, or services.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not:</p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Attempt to compromise user accounts, profiles, or other MyOpinion entities, abuse our products or services, gather sensitive information through deceptive means, or attempt to engage in unauthorized access, including:</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Gaining access to accounts, profiles MyOpinion, Inc. entities, or user data other than your own through deceptive means or without explicit permission from the account, profile, or entity owner.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Encouraging or deceiving users to download or run files or programs that will compromise a user’s online or data security, including through malicious software or websites. Such files and programs will be deemed malicious software or "malware" if they harm or gain unauthorized access to a computer, device, or network.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempting to obtain, acquire or request another user’s login credentials or other sensitive information, whether explicitly or through deceptive means such as phishing (e.g. fake surveys designed to capture log-in info or links to fake login pages or impostor websites) or the use of malicious software or websites.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Publicly sharing your own or others’ login information, either on platform</p>
              <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>or through a third party service.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Creating, sharing or hosting malicious software including browser extensions and mobile applications, on or off the platform that put our users or products and services at risk.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Providing online infrastructure, including web hosting services, domain name system servers and ad networks that enables abusive links such that a majority of those links on MyOpinion or MyOpinion violate the spam or cybersecurity sections of the Community Standards.</p>
              <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Inauthentic Behavior</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In line with our commitment to authenticity, we do not allow people to misrepresent themselves on MyOpinion, use fake accounts, artificially boost the popularity of content or engage in behaviors designed to enable other violations under our Community Standards. This policy is intended to protect the security of user accounts and our services, and create a space where people can trust the people and communities they interact with.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not:</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Use multiple MyOpinion accounts or share accounts between multiple people</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Misuse MyOpinion or MyOpinion reporting systems to harass others</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Conceal a Page’s purpose by misleading users about the ownership or control of that Page</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Engage in or claim to engage in inauthentic behavior, which is defined as the use of MyOpinion assets (accounts, Pages, Groups, or Events), to mislead people or MyOpinion:</p>
              <ul id="l105">
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>About the identity, purpose, or origin of the entity that they represent.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>About the popularity of MyOpinion content or assets.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>About the purpose of an audience or community.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>About the source or origin of content.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>To evade enforcement under our Community Standards.</p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Engage in, or claim to engage in Coordinated Inauthentic Behavior, defined as the use of multiple MyOpinion assets, working in concert to engage in Inauthentic Behavior (as defined above), where the use of fake accounts is central to the operation</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Engage in, or claim to engage in foreign or government interference, which is Coordinated Inauthentic Behavior conducted on behalf of a foreign or government actor.</p>
              <h3 style={{paddingTop: '4pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Misinformation</h3>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Misinformation is different from other types of speech addressed in our Community Standards because there is no way to articulate a comprehensive list of what is prohibited. With graphic violence or hate speech, for instance, our policies specify the speech we prohibit, and even persons who disagree with those policies can follow them. With misinformation, however, we cannot provide such a line. The world is changing constantly, and what is true one minute may not be true the next minute. People also have different levels of information about the world around them, and may believe something is true when it is not. A policy that simply prohibits “misinformation” would not provide useful notice to the people who use our services and would be unenforceable, as we don’t have perfect access to information.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Instead, our policies articulate different categories of misinformation and try to provide clear guidance about how we treat that speech when we see it. For each category, our approach reflects our attempt to balance our values of expression, safety, dignity, authenticity, and privacy.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We remove misinformation where it is likely to directly contribute to the risk of imminent physical harm. We also remove content that is likely to directly contribute to interference with the functioning of political processes and certain highly deceptive manipulated media. In determining what constitutes misinformation in these categories, we partner with independent experts who possess knowledge and expertise to assess the truth of the content and whether it is likely to directly contribute to the risk of imminent harm. This includes, for instance, partnering with human rights organizations with a presence on the ground in a country to determine the truth of a rumor about civil conflict, and partnering with health organizations during the global COVID-19 pandemic.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For all other misinformation, we focus on reducing its prevalence or creating an environment that fosters a productive dialogue. We know that people often use misinformation in harmless ways, such as to exaggerate a point (“This team has the worst record in the history of the sport!”) or in humor or satire (“My husband just won Husband of the Year.”) They also may share their experience through stories that contain inaccuracies. In some cases, people share deeply-held personal opinions that others consider false or share information that they believe to be true but others consider incomplete or misleading.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Recognizing how common such speech is, we focus on slowing the spread of hoaxes and viral misinformation, and directing users to authoritative information. As part of that effort, we partner with third-party fact checking organizations to review and rate the accuracy of the most viral content on our platforms.</p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Finally, we prohibit content and behavior in other areas that often overlap with the spread of misinformation. For example, our Community Standards prohibit fake accounts, fraud, and coordinated inauthentic behavior.</p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>As online and offline environments change and evolve, we will continue to evolve these policies. Pages, Groups, Profiles, and MyOpinion accounts that repeatedly share the misinformation listed below may, in addition to having their content removed, receive decreased distribution, limitations on their ability to advertise, or be removed from our platforms. Misinformation we remove:</p>
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>We remove the following types of misinformation:</p>
              <ol id="l106">
                <li data-list-text="I.">
                  <h3 style={{paddingTop: '9pt', paddingLeft: '55pt', textIndent: '-13pt', textAlign: 'left'}}>Physical Harm or Violence</h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We remove misinformation or unverifiable rumors that expert partners have determined are likely to directly contribute to a risk of imminent violence or physical harm to people. We define misinformation as content with a claim that is determined to be false by an authoritative third party. We define an unverifiable rumor as a claim whose source expert partners confirm is extremely hard or impossible to trace, for which authoritative sources are absent, where there is not enough specificity for the claim to be debunked, or where the claim is too incredulous or too irrational to be believed.</p>
                  <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We know that sometimes misinformation that might appear benign could, in a specific context, contribute to a risk of offline harm, including threats of violence that could contribute to a heightened risk of death, serious injury, or other physical harm. We work with a global network of non-governmental organizations (NGOs), not-for-profit organizations, humanitarian organizations, and international organizations that have expertise in these local dynamics.</p>
                </li>
                <li data-list-text="II.">
                  <h3 style={{paddingTop: '8pt', paddingLeft: '60pt', textIndent: '-19pt', textAlign: 'left'}}>Harmful Health Misinformation</h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We consult with leading health organizations to identify health misinformation likely to directly contribute to imminent harm to public health and safety. The harmful health misinformation that we remove includes the following:</p>
                </li>
              </ol>
            </li>
            <li data-list-text="">
              <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Misinformation about vaccines. <span className="p">We remove misinformation primarily about vaccines when public health authorities conclude that the information is false and likely to directly contribute to imminent vaccine refusals. They include:</span></h3>
              <ol id="l107">
                <li data-list-text="a.">
                  <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Vaccines cause autism (Ex: “Increased vaccinations are why so many kids have autism these days.”)</p>
                </li>
                <li data-list-text="b.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Vaccines cause Sudden Infant Death Syndrome (Ex: “Don’t you know</p>
                  <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>vaccines cause SIDS?”</p>
                </li>
                <li data-list-text="c.">
                  <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Vaccines cause the disease against which they are meant to protect, or cause the person receiving the vaccine to be more likely to get the disease (Ex: “Taking a vaccine actually makes you more likely to get the disease since there’s a strain of the disease inside. Beware!”)</p>
                </li>
                <li data-list-text="d.">
                  <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Vaccines or their ingredients are deadly, toxic, poisonous, harmful, or dangerous (Ex: “Sure, you can take vaccines, if you don’t mind putting poison in your body.”)</p>
                </li>
                <li data-list-text="e.">
                  <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Natural immunity is safer than vaccine-acquired immunity (Ex: “It’s safest to just get the disease rather than the vaccine.”)</p>
                </li>
                <li data-list-text="f.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>It is dangerous to get several vaccines in a short period of time, even if that timing is medically recommended (Ex: “Never take more than one vaccine at the same time that is dangerous - I don’t care what your doctor tells you!”)</p>
                </li>
                <li data-list-text="g.">
                  <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Vaccines are not effective at preventing the disease against which they purport to protect. However, for the COVID-19, flu, and malaria vaccines, we do not remove claims that those vaccines are not effective in</p>
                  <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>preventing someone from contracting those viruses. (Ex’s: Remove – “The polio vaccine doesn’t do anything to stop you from getting the disease”; Remove – “Vaccines actually don’t do anything to stop you from getting diseases”; Allow – “The vaccine doesn’t stop you from getting COVID-19, that’s why you still need to socially distance and wear a mask when you’re around others.”)</p>
                </li>
                <li data-list-text="h.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Acquiring measles cannot cause death (requires additional information</p>
                  <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>and/or context) (Ex: “Don’t worry about whether you get measles, it can’t be fatal.”)</p>
                </li>
                <li data-list-text="i.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Vitamin C is as effective as vaccines in preventing diseases for which vaccines exist.</p>
                </li>
              </ol>
            </li>
            <li data-list-text="">
              <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Misinformation about health during public health emergencies. <span className="p">We remove misinformation during public health emergencies when public health authorities conclude that the information is false and likely to directly contribute to the risk of imminent physical harm, including by contributing to the risk of individuals getting or spreading a harmful disease or refusing an associated vaccine. We identify public health emergencies in partnership with global and local health authorities. This currently includes false claims related to COVID-19 that are verified by expert health authorities, about the existence or severity of the virus, how to cure or prevent it, how the virus is transmitted or who is immune, and false claims which discourage good health practices related to COVID-19 (such as getting tested, social distancing, wearing a face mask, and getting a vaccine for COVID-19).</span></h3>
            </li>
            <li data-list-text="">
              <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Promoting or advocating for harmful miracle cures for health issues. <span className="p">These include treatments where the recommended application, in a health context, is likely to directly contribute to the risk of serious injury or death, and the treatment has no legitimate health use (ex: bleach, disinfectant, black salve, caustic soda).</span></h3>
              <ol id="l108">
                <li data-list-text="III.">
                  <h3 style={{paddingTop: '7pt', paddingLeft: '67pt', textIndent: '-25pt', textAlign: 'left'}}>Voter or Census Interference</h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>In an effort to promote election and census integrity, we remove misinformation that is likely to directly contribute to a risk of interference with people’s ability to participate in those processes. This includes the following:</p>
                </li>
              </ol>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Misinformation about the dates, locations, times, and methods for voting, voter registration, or census participation.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Misinformation about who can vote, qualifications for voting, whether a vote will be counted, and what information or materials must be provided in order to vote.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Misinformation about whether a candidate is running or not.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Misinformation about who can participate in the census and what information or materials must be provided in order to participate.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Misinformation about government involvement in the census, including, where applicable, that an individual's census information will be shared with another (non-census) government agency.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content falsely claiming that the U.S. Immigration and Customs Enforcement (ICE) is at a voting location.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Explicit false claims that people will be infected by COVID-19 (or another communicable disease) if they participate in the voting process.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We have additional policies intended to cover calls for violence, the promotion of illegal participation, and calls for coordinated interference in elections, which are represented in other sections of our Community Standards.</p>
              <ol id="l109">
                <li data-list-text="IV.">
                  <h3 style={{paddingTop: '8pt', paddingLeft: '63pt', textIndent: '-22pt', textAlign: 'left'}}>Manipulated Media</h3>
                </li>
              </ol>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Media can be edited in a variety of ways. In many cases, these changes are benign, such as content being cropped or shortened for artistic reasons or music being added. In other cases, the manipulation is not apparent and could mislead, particularly in the case of video content. We remove this content because it can go viral quickly and experts advise that false beliefs regarding manipulated media often cannot be corrected through further discourse.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We remove videos under this policy if specific criteria are met: (1) the video has been edited or synthesized, beyond adjustments for clarity or quality, in ways that are not apparent to an average person, and would likely mislead an average person to believe a subject of the video said words that they did not say; and (2) the video is the product of artificial intelligence or machine learning, including deep learning techniques (e.g., a technical deepfake), that merges, combines, replaces, and/or superimposes content onto a video, creating a video that appears authentic.</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Memorialization</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>When someone passes away, friends and family can request that we memorialize the MyOpinion account. Once memorialized, the word</p>
              <p style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>"Remembering" appears above the name on the person's profile so the account is now a memorial site and protects against attempted logins and fraudulent activity. To respect the choices someone made while alive, we aim to preserve their account after they pass away.</p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We have also made it possible for people to identify a legacy contact to look after their account after they pass away. To support the bereaved, in some instances we may remove or change certain content when the legacy contact or family members request it.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For victims of murder and suicide we will remove the following content if it appears on the deceased’s profile photo, cover photo, or among recent timeline posts when requested by a legacy contact or family member of the deceased:</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Visual depiction of the object used in the deceased’s death.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Imagery of the convicted or alleged murderer of the deceased.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content related to the deceased’s death.</p>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For victims of murder, we will also remove the convicted or alleged murderer from the deceased’s profile if referenced in relationship status or among friends.</p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>When brought to our attention by the legacy contact or a family member of the deceased, we will:</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Remove violating comments on a memorialized profile, which would typically require the individual to self report so that we know that they are unwanted.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Remove praise or support for the death, disease, or harm of the deceased person on a memorialized profile.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Change the deceased individual’s privacy settings from public to friends-only when there is harmful content on the profile.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Change a violating account name on the profile of the deceased individual.</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>11. E RESPECTING INTELLECTUAL PROPERTY</p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Intellectual Property</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '70pt', lineHeight: '108%', textAlign: 'left'}}>MyOpinion takes intellectual property rights seriously and believes they are important to promoting expression, creativity, and innovation in our community. You own all of the content and information you post on MyOpinion, and you control how it is shared through your privacy and application settings. However, before sharing content on MyOpinion, please be sure you have the right to do so. We ask that you respect other people’s copyrights, trademarks and other legal rights. We are committed to helping people and organizations promote and protect their intellectual property rights.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>MyOpinion’s Terms of Service do not allow people to post content that violates someone else’s intellectual property rights, including copyright and</p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>trademark. We publish information about the intellectual property reports we receive in our bi-annual transparency report.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Upon receipt of a report from a rights holder or an authorized representative, we will remove or restrict content that engages in:</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Copyright infringement.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Trademark infringement.</p>
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>11.F CONTENT-RELATED AND DECISIONS</p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>User Requests</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>We comply with:</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>User requests for removal of their own account.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Requests for removal of a deceased user’s account from a verified</p>
              <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>immediate family member or executor.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Requests for removal of an incapacitated user’s account from an</p>
              <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>authorized representative.</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Additional Protection of Minors</h3>
              <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>We comply with:</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Requests for removal of an underage account.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Government requests for removal of child abuse imagery depicting, for example, beating by an adult or strangling or suffocating by an adult.</p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Legal guardian requests for removal of attacks on unintentionally famous minors.</p>
            </li>
          </ul>
        </ul>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We may remove content created for the purpose of identifying a private minor if there may be a risk to the minor’s safety when requested by a user, government, law enforcement or external child safety experts.</p>
      </div>
      </div>
  )
}

export default TermsConditions