import  { useSelector, useDispatch } from 'react-redux';

export const useAccessToken =()=>{
    const {data: authReducerData} = useAuthReducer()
    const headers = {
       Authorization: authReducerData?.authorization,
     }
    return headers
 }

export const useAuthReducer =()=>{
    const store = useDispatch()
    const data = useSelector(state => state.AuthReducer);
    return {data, store}
}

export const useAccountReducer =()=>{
    const dispatch = useDispatch()
    const state = useSelector(state => state.AccountReducer);
    return {state, dispatch}
}

export const useHomeReducer =()=>{
    const store = useDispatch()
    const data = useSelector(state => state.HomeReducer);
    return {data, store}
}

export const useRandomReducer =()=>{
    const store = useDispatch()
    const data = useSelector(state => state.RandomReducer);
    return {data, store}
}

export const useRecentReducer =()=>{
    const store = useDispatch()
    const data = useSelector(state => state.RecentReducer);
    return {data, store}
}

export const useViralReducer =()=>{
    const store = useDispatch()
    const data = useSelector(state => state.ViralReducer);
    return {data, store}
}

export const useNotificationReducer = () => {
    const data = useSelector(state => state.ReducerNotification)
    const store = useDispatch()
    return { data, store }
}

export const usePeopleMatchReducer = () => {
    const data = useSelector(state => state.PeopleMatchReducer)
    const store = useDispatch()
    return { data, store }
}

export const useOneToOneReducer = () => {
    const data = useSelector(state => state.OneToOneReducer)
    const store = useDispatch()
    return { data, store }
}

