import React, { memo, useMemo, useState } from 'react'
import { FaRegPlayCircle } from 'react-icons/fa'

const Video = ({attachment}) => {

    const [play, setPlay] = useState(false)


    if (!play) {
        return (
            <div style={{
                backgroundImage: `url(${attachment.attach_thumbnail})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                minHeight: '350px',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px'
            }}>
                <p style={{ color: 'white' }}>
                    <div onClick={() => setPlay(true)} style={{ cursor: 'pointer' }}>
                        <FaRegPlayCircle size={60} color='white' />
                    </div>
                </p>

            </div>
        )

    } else {
        return (
            <div>
                <video width="100%" height="100%" controls >
                    <source src={attachment.attach_url} />
                </video>
            </div>
        )
    }
}

export default memo(Video)