import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const LoadingPage = ({
    text = 'Loading...'
}) => {

    const { t } = useTranslation()


    return (
        <div style={{backgroundColor: 'white', width: '100%', height: '45vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ textAlign: 'center' }}>
                <Spinner />
                <h6 style={{fontWeight: '600', color: '#22', marginTop: '10px', fontSize: '14px' }}>
                    {text}
                </h6>
            </div>

        </div>
    )
}

export default LoadingPage