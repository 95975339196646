import React from 'react'

const ConditionsGenerales = () => {
  return (
    <div>

      <div className="container">
        <h1 style={{paddingTop: '100px', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Conditions Générales d'Utilisation de l'application mobile MyOpinion</font>
          </font>
        </h1>
        <p style={{paddingTop: '14pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>MYOPINION SARL (« la Société »), société de droit ivoirien, développe des systèmes et solutions à la pointe de la technologie. </font>
            <font style={{verticalAlign: 'inherit'}}>MYOPINION SARL développe des technologies et des services qui permettent à chacun de se connecter, de partager des opinions et de créer des communautés. </font>
            <font style={{verticalAlign: 'inherit'}}>Les présentes Conditions régissent votre utilisation de MyOpinion, des produits, fonctionnalités, applications, services, technologies et logiciels que nous proposons (les Produits MyOPINION ou les Produits), sauf si nous indiquons expressément que des conditions distinctes (et non celles-ci) s'appliquent. </font>
            <font style={{verticalAlign: 'inherit'}}>Ces Produits vous sont fournis par MYOPINION SARL.</font>
          </font>
        </p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Vous devez lire les présentes Conditions Générales d'Utilisation (CGU) avant de créer un compte pour bénéficier des services proposés par myopinion.ci. </font>
            <font style={{verticalAlign: 'inherit'}}>La Société se réserve le droit de modifier, à tout moment et sans préavis, les présentes conditions. </font>
            <font style={{verticalAlign: 'inherit'}}>La création d'un compte sur myopinion.ci implique l'acceptation pleine et entière des présentes conditions sans aucune restriction. </font>
            <font style={{verticalAlign: 'inherit'}}>Les présentes CGU sont soumises aux dispositions de la loi n°2014-546 du 30 juillet 2013 relative aux transactions électroniques, du code des droits et obligations et des actes uniformes OHADA applicables à ces relations.</font>
          </font>
        </p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Vous devez prendre connaissance des présentes Conditions Générales d'Utilisation (CGU) avant de créer un compte pour bénéficier des services proposés par l'application « MyOpinion ». </font>
            <font style={{verticalAlign: 'inherit'}}>La Société se réserve le droit de modifier ces conditions à tout moment et sans préavis. </font>
            <font style={{verticalAlign: 'inherit'}}>La création d'un compte sur « MyOpinion » implique l'acceptation pleine et entière des présentes conditions, sans aucune restriction. </font>
            <font style={{verticalAlign: 'inherit'}}>Les présentes CGU sont soumises aux dispositions de la loi n°2014-546 du 30 juillet 2013 relative aux transactions électroniques, du code des droits et obligations et des actes uniformes OHADA applicables à ces relations.</font>
          </font>
        </p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Article 1 : Objet</font>
          </font>
        </h2>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Les présentes CGU ont pour objet de définir les modalités de mise à disposition de l'application « MyOpinion » (ci-après dénommée « l'application ») et les conditions d'utilisation applicables aux utilisateurs. </font>
            <font style={{verticalAlign: 'inherit'}}>En utilisant l'application "MyOpinion", vous acceptez les présentes Conditions Générales d'Utilisation dans leur intégralité. </font>
            <font style={{verticalAlign: 'inherit'}}>Si vous n'êtes pas d'accord avec ces Conditions d'utilisation ou toute partie de ces Conditions d'utilisation, vous ne devez pas utiliser l'application "MyOpinion" dans ce cas.</font>
          </font>
        </p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Article 2 : Mentions légales</font>
          </font>
        </h2>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Les mentions légales de la Société sont les suivantes :</font>
          </font>
        </p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <ul id="l1">
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Dénomination sociale : MYOPINION SARL ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Capital social : 1.000.000 Fcfa ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Siège social : Résidence GYAM, 7e étage Porte E, Plateau, Abidjan (Côte</font>
              </font>
            </p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>d'Ivoire);</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Courriel&nbsp;: support@myopinion.ci&nbsp;;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Téléphone : +225 25 20 00 27 11 / +225 05 05 34 03 03 ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Numéro registre du crédit commercial et immobilier : CI-ABJ-03-2022-B13-07853 ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Numéro de compte du contribuable&nbsp;: 2243482K&nbsp;;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Numéro d'agrément pour le traitement des données personnelles : 2020-0529, délivré</font>
              </font>
            </p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>par l'ARTCI.</font>
              </font>
            </p>
            <h2 style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 3 : Définition</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Chaque fois qu'ils sont utilisés dans le texte des présentes, y compris dans la description préliminaire ci-dessus, le titre des Articles et des clauses, les termes et expressions utilisés dans les Conditions Générales de Services avec une majuscule initiale ont leur signification. </font>
                <font style={{verticalAlign: 'inherit'}}>données par les CGU, sans qu'il soit besoin de distinguer selon que le terme ou l'expression défini est utilisé au singulier ou au pluriel :</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Compte : désigne l'espace personnel qu'un utilisateur se crée pour accéder au site et bénéficier des services proposés. </font>
                <font style={{verticalAlign: 'inherit'}}>Il est reconnaissable par un identifiant et un mot de passe ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu ou Contenu Utilisateur : désigne les données transmises par l'Utilisateur dans les différentes rubriques du Site ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Force majeure : désigne tout évènement imprévisible, irrésistible et</font>
              </font>
            </p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>extérieur à la volonté de la Société et de l'Utilisateur, qui empêche la Société d'exécuter son obligation et l'exonère de toute responsabilité&nbsp;;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Force majeure : désigne tout événement imprévisible, irrésistible, extérieur à la volonté de la Société et de l'Utilisateur, qui empêche la Société de remplir son obligation et l'exonère de toute responsabilité.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Dans le cadre des présentes, les cas de force majeure sont, sans que cette liste soit exhaustive, les guerres, émeutes, troubles civils, tremblements de terre, incendies, explosions, tempêtes, inondations ou autres catastrophes naturelles. </font>
                <font style={{verticalAlign: 'inherit'}}>, confiscations, etc.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Information : désigne l'ensemble des données diffusées lors de la création d'un compte ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Membre : désigne un utilisateur identifié disposant d'un compte ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Site : désigne l'application MyOpinion ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Utilisateur : désigne toute personne qui utilise l'application ou l'un des services proposés sur l'application.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 4 : Utilisation du site</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>L'accès à certaines informations diffusées sur l'application est subordonné à la création d'un Compte. </font>
                <font style={{verticalAlign: 'inherit'}}>L'Utilisateur n'a pas le droit de diffuser ou de reproduire, à titre gratuit ou onéreux, les informations auxquelles il a accès. </font>
                <font style={{verticalAlign: 'inherit'}}>L'Utilisateur n'est pas autorisé à copier ou transférer les informations diffusées sur quelque support que ce soit. </font>
                <font style={{verticalAlign: 'inherit'}}>L'utilisateur s'engage à ne pas porter atteinte aux informations, images ou marques contenues dans l'application. </font>
                <font style={{verticalAlign: 'inherit'}}>L'Utilisateur s'engage à respecter les présentes CGU et les autres normes en vigueur sur l'application </font>
              </font><span className="s1">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>.</font>
                </font>
              </span>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 5 : Accès à l'application et aux services</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>L'accès à l'application se fait par la création d'un compte. </font>
                <font style={{verticalAlign: 'inherit'}}>Les membres sont titulaires d'un compte. </font>
                <font style={{verticalAlign: 'inherit'}}>Vous vous engagez à utiliser un mot de passe fort et à le garder confidentiel et à ne pas transférer une ou plusieurs parties de votre compte. </font>
                <font style={{verticalAlign: 'inherit'}}>L'utilisateur est responsable de la protection de son compte et nous déclinons toute responsabilité pour toute perte ou dommage qui pourrait en résulter. </font>
                <font style={{verticalAlign: 'inherit'}}>L'Utilisateur pourra accéder à l'application, 24 heures sur 24, 7 jours sur 7, sauf cas de force majeure, et/ou événement extérieur au centre serveur et sous réserve d'éventuels dysfonctionnements du Service. </font>
                <font style={{verticalAlign: 'inherit'}}>L'utilisateur</font>
              </font>
            </p>
            <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>assume tous les risques et contraintes liés à toute utilisation de l'application. </font>
                <font style={{verticalAlign: 'inherit'}}>La Société exclut toute garantie expresse ou implicite, notamment toute garantie implicite de qualité, d'adéquation à un usage particulier. </font>
                <font style={{verticalAlign: 'inherit'}}>L'utilisateur est responsable de la protection de son compte et nous déclinons toute responsabilité pour toute perte ou dommage qui pourrait en résulter. </font>
                <font style={{verticalAlign: 'inherit'}}>La création d'un Compte Utilisateur est nécessaire afin de permettre l'identification de l'Utilisateur et de faciliter son accès aux différents services de l'application. </font>
                <font style={{verticalAlign: 'inherit'}}>Lors de cette étape, l'Utilisateur doit prendre connaissance des présentes CGU et les valider afin de finaliser la création de son compte.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 6 : Règles d'utilisation</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>En vous connectant à l'application, vous déclarez accepter les caractéristiques et les limites de ladite application et vous reconnaissez :</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Que la Société n'assume aucune responsabilité quant à la qualité et la véracité des informations accessibles sur l'application et qu'elle n'exerce aucun contrôle, sous quelque forme que ce soit, sur la nature ou les caractéristiques des données qui sont contenues sur l'application ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Que les informations circulant sur l'application ne sont pas protégées par des droits de propriété intellectuelle.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 7 : Responsabilité</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>L'Utilisateur accepte le fait que la Société, ses sociétés mères, filiales, sociétés associées, dirigeants, administrateurs, employés, agents, représentants, partenaires et concédants, ne pourront en aucun cas être tenus responsables de tout dommage direct ou indirect de quelque nature que ce soit et/ ou résultant de l'utilisation ou de l'impossibilité d'utiliser l'application. </font>
                <font style={{verticalAlign: 'inherit'}}>La Société n'est en aucun cas responsable, sans que cette liste soit limitative :</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>L'exhaustivité, l'exactitude, la disponibilité, l'actualité, la sécurité ou la fiabilité des Services ou de tout Contenu&nbsp;;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le contenu des informations consultées et, d'une manière générale, toute information et/ou donnée diffusée sur les services consultés ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le fonctionnement de l'application ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Messages et informations transmis via des boîtes aux lettres&nbsp;;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tout cas de force majeure ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>En cas d'interruption des réseaux d'accès ou accessibles par tout autre moyen technologique ;</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>En cas de perte de données.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>La Société ne pourra être tenue responsable en cas de poursuites judiciaires à l'encontre de l'Utilisateur et/ou de ses éventuels préposés, du fait de l'utilisation des données collectées sur l'application. </font>
                <font style={{verticalAlign: 'inherit'}}>La société n'est pas responsable de toute utilisation d'informations insultantes, illégales, trompeuses ou inappropriées que vous, ou un autre utilisateur ou un tiers créez, téléchargez, publiez, envoyez, recevez ou stockez sur ou via nos services. </font>
                <font style={{verticalAlign: 'inherit'}}>L'Utilisateur est responsable vis-à-vis de la Société et/ou des tiers de tout dommage, direct ou indirect, de quelque nature que ce soit, causé par lui-même et/ou par ses éventuels préposés lors de l'utilisation de l'application par ses soins. </font>
                <font style={{verticalAlign: 'inherit'}}>. </font>
                <font style={{verticalAlign: 'inherit'}}>La Société décline toute responsabilité en cas d'utilisation des informations</font>
              </font>
            </p>
            <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>obtenu sur l'application en violation des règles spécifiées dans les présentes Conditions d'utilisation.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 8 : Confidentialité</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Notre politique de confidentialité décrit la manière dont nous traitons les informations que vous nous fournissez lorsque vous utilisez nos services. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous vous encourageons à porter une attention particulière à la politique de confidentialité car, en utilisant nos services, vous acceptez que MYOPINION SARL collecte, partage et utilise vos informations conformément à cette politique.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 9 : Droit d'accès aux données personnelles - Réclamations</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}><a href="mailto:support@myopinion.ci" className="a" target="_blank">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Les informations demandées et collectées à votre sujet sont nécessaires et obligatoires pour créer votre compte utilisateur et pour faciliter l'identification et la reconnaissance de l'Utilisateur. </font>
                  <font style={{verticalAlign: 'inherit'}}>Conformément à la loi n°2013-450 du 19 juin 2013 relative à la protection des données personnelles, l'Utilisateur dispose d'un droit d'accès, de rectification, de modification et de suppression des données le concernant. </font>
                  <font style={{verticalAlign: 'inherit'}}>Cependant, il ne peut en aucune manière modifier l'adresse e-mail qu'il a fournie. </font>
                  <font style={{verticalAlign: 'inherit'}}>Pour exercer les droits ci-dessus, l'Utilisateur utilisera les onglets correspondants de l'application. </font>
                  <font style={{verticalAlign: 'inherit'}}>De manière générale, toute réclamation doit être formulée par écrit et envoyée à l'adresse email suivante :</font>
                </font>
              </a>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}> support@myopinion.ci. </font>
                <font style={{verticalAlign: 'inherit'}}>La communication des données de l'Utilisateur et la validation des présentes CGU autorisent la Société à utiliser lesdites données jusqu'à ce que l'Utilisateur en décide autrement.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 10 : Droit applicable - Juridiction compétente</font>
              </font>
            </h2>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>La loi applicable est la loi ivoirienne. </font>
                <font style={{verticalAlign: 'inherit'}}>En cas de litige entre l'utilisateur et la Société dans l'exécution ou l'interprétation des présentes CGU, ceux-ci s'engagent à une résolution amiable préalable. </font>
                <font style={{verticalAlign: 'inherit'}}>Si à l'issue d'un délai de quinze (15) jours à compter du début de la résolution amiable, les Parties ne parviennent pas à un accord, elles seront libres de saisir le tribunal ivoirien compétent.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h2 style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Article 11 : Standards communautaires MyOpinion</font>
              </font>
            </h2>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Chaque jour, les gens utiliseront MyOpinion pour partager leurs expériences, se connecter avec</font>
              </font>
            </p>
            <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>amis et la famille, et construire des communautés. </font>
                <font style={{verticalAlign: 'inherit'}}>C'est un service permettant à des millions de personnes de s'exprimer librement à travers les pays et les cultures et dans de nombreuses langues.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Bah Blesson &amp; Company reconnaît à quel point il est important pour MyOpinion d'être un lieu où les gens se sentent habilités à communiquer, et nous assumons notre rôle</font>
              </font>
            </p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>au sérieux pour empêcher les abus du service. </font>
                <font style={{verticalAlign: 'inherit'}}>C'est pourquoi nous avons développé des normes</font>
              </font>
            </p>
            <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>pour ce qui est et n'est pas autorisé sur MyOpinion.</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Ces normes sont basées sur les commentaires des personnes et les conseils d'experts dans des domaines tels que la technologie, la sécurité publique et les droits de l'homme. </font>
                <font style={{verticalAlign: 'inherit'}}>Pour nous assurer que la voix de chacun est valorisée, nous prenons grand soin de créer des normes qui incluent différents points de vue et croyances, en particulier de personnes et de communautés qui pourraient autrement être négligées ou marginalisées.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Veuillez noter que la version anglaise des normes communautaires reflète l'ensemble de politiques le plus à jour et doit être utilisée comme document principal.</font>
              </font>
            </h3>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Notre engagement pour la liberté d'expression et d'expression</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>L'objectif de nos normes communautaires est de créer un lieu d'expression et de donner la parole aux gens. </font>
                <font style={{verticalAlign: 'inherit'}}>MyOpinion veut que les gens puissent parler ouvertement des problèmes qui les intéressent, même si certains peuvent être en désaccord ou les trouver répréhensibles. </font>
                <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous autorisons le contenu, qui autrement irait à l'encontre de nos normes, s'il est digne d'intérêt et dans l'intérêt public. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous ne le faisons qu'après avoir pesé la valeur de l'intérêt public par rapport au risque de préjudice, et nous nous tournons vers les normes internationales des droits de l'homme pour porter ces jugements.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Notre engagement envers l'expression est primordial, mais nous reconnaissons qu'Internet crée de nouvelles opportunités d'abus. </font>
                <font style={{verticalAlign: 'inherit'}}>Pour ces raisons, lorsque nous limitons l'expression, nous le faisons au service d'une ou plusieurs des valeurs suivantes&nbsp;:</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>RESPONSABILITÉ</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous voulons nous assurer que les gens assument l'entière responsabilité du contenu qu'ils partagent ou publient sur MyOpinion. </font>
                <font style={{verticalAlign: 'inherit'}}>Chacun doit être tenu responsable de son comportement sur MyOpinion et assumer pleinement ses conséquences.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>AUTHENTICITÉ</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous voulons nous assurer que le contenu que les gens voient sur MyOpinion est authentique. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous pensons que l'authenticité crée un meilleur environnement de partage, et c'est pourquoi nous ne voulons pas que les personnes utilisant MyOpinion dénaturent qui elles sont ou ce qu'elles font.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>SÉCURITÉ</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous nous engageons à faire de MyOpinion un endroit sûr. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons les contenus qui pourraient contribuer à un risque d'atteinte à la sécurité physique des personnes. </font>
                <font style={{verticalAlign: 'inherit'}}>Le contenu qui menace les gens a le potentiel d'intimider, d'exclure ou de faire taire les autres et n'est pas autorisé sur MyOpinion.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>INTIMITÉ</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous nous engageons à protéger la confidentialité et les informations personnelles. </font>
                <font style={{verticalAlign: 'inherit'}}>La confidentialité donne aux gens la liberté d'être eux-mêmes, de choisir comment et quand partager sur MyOpinion et de se connecter plus facilement.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>DIGNITÉ</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous croyons que toutes les personnes sont égales en dignité et en droits. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous nous attendons à ce que les gens respectent la dignité des autres et ne harcèlent ou ne dégradent pas les autres.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <p style={{paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>normes communautaires</font>
              </font>
            </p>
            <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nos normes communautaires s'appliquent à tout le monde, partout dans le monde et à tous les types de contenu.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Chaque section de nos normes communautaires commence par une «&nbsp;justification de la politique&nbsp;» qui</font>
              </font>
            </p>
            <p style={{paddingTop: '1pt', paddingLeft: '9pt', textIndent: '-3pt', lineHeight: '167%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>définit les objectifs de la politique suivis de lignes politiques spécifiques qui décrivent&nbsp;: le contenu qui n'est pas autorisé&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>et</font>
              </font>
            </p>
            <p style={{paddingLeft: '5pt', textIndent: '3pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui nécessite des informations ou un contexte supplémentaires pour être appliqué, contenu autorisé avec un écran d'avertissement ou contenu autorisé mais qui ne peut être consulté que par des adultes âgés de 18 ans et plus.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <ol id="l2">
              <ol id="l3">
                <li data-list-text="11.A">
                  <p style={{paddingLeft: '70pt', textIndent: '-29pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>VIOLENCE ET COMPORTEMENT CRIMINEL</font>
                    </font>
                  </p>
                  <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Violence et incitation</font>
                    </font>
                  </h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Notre objectif est de prévenir les dommages hors ligne potentiels pouvant être liés au contenu de MyOpinion. </font>
                      <font style={{verticalAlign: 'inherit'}}>Bien que nous comprenions que les gens expriment généralement leur mépris ou leur désaccord en menaçant ou en appelant à la violence de manière non sérieuse, nous supprimons le langage qui incite ou facilite la violence grave. </font>
                      <font style={{verticalAlign: 'inherit'}}>Nous supprimons du contenu, désactivons des comptes et collaborons avec les forces de l'ordre lorsque nous estimons qu'il existe un risque réel de préjudice physique ou de menace directe pour la sécurité publique. </font>
                      <font style={{verticalAlign: 'inherit'}}>Nous essayons également de tenir compte de la langue et du contexte afin de distinguer les déclarations informelles du contenu qui constitue une menace crédible pour la sécurité publique ou personnelle. </font>
                      <font style={{verticalAlign: 'inherit'}}>Pour déterminer si une menace est crédible, nous pouvons également prendre en compte des informations supplémentaires telles que la visibilité publique d'une personne et les risques pour sa sécurité physique.</font>
                    </font>
                  </p>
                  <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous voyons des menaces ambitieuses ou conditionnelles dirigées contre des terroristes et d'autres acteurs violents (par exemple, «&nbsp;les terroristes méritent d'être tués&nbsp;»), et nous les jugeons non crédibles, en l'absence de preuves spécifiques du contraire.</font>
                    </font>
                  </p>
                  <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                    </font>
                  </p>
                  <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Menaces pouvant entraîner la mort (et autres formes de violence de grande gravité) et admission de violences passées ciblant des personnes ou des lieux où la menace est définie comme l'un des éléments suivants&nbsp;:</font>
                    </font>
                  </p>
                  <ul id="l4">
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention de commettre des actes de violence de grande gravité. </font>
                          <font style={{verticalAlign: 'inherit'}}>Cela inclut le contenu où un symbole représente la cible et/ou inclut un visuel d'un armement ou d'une méthode pour représenter la violence.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Appels à des violences très graves, y compris des contenus où aucune cible n'est spécifiée mais un symbole représente la cible et/ou inclut un visuel d'un armement ou d'une méthode qui représente la violence.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations prônant une violence de grande gravité.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations ambitieuses ou conditionnelles pour commettre des actes de violence de haute gravité.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations admettant avoir commis des violences de haute gravité, sauf lorsqu'elles sont partagées dans un contexte de rédemption, d'autodéfense ou lorsqu'elles sont commises par des forces de l'ordre, des militaires ou des membres de la sécurité de l'État.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui demande, admet offrir ou propose des services de location pour tuer d'autres personnes (par exemple, des tueurs à gages, des mercenaires, des assassins) ou qui préconise l'utilisation d'un tueur à gages, d'un mercenaire ou d'un assassin contre une cible</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Admissions, déclarations d'intention ou de plaidoyer, appels à l'action ou déclarations d'aspiration ou conditionnelles pour kidnapper ou enlever une cible ou qui promeuvent, soutiennent ou plaident pour un enlèvement ou un enlèvement</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui représente des enlèvements ou des enlèvements s'il est clair que le contenu n'est pas partagé par une victime ou sa famille comme un appel à l'aide, ou partagé à des fins d'information, de condamnation ou de sensibilisation</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Menaces de violence de grande gravité utilisant des images produites numériquement ou modifiées pour cibler des personnes vivantes avec des armes, des méthodes de violence ou de démembrement</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Menaces entraînant des blessures graves (violence de gravité moyenne) et admission de violences passées envers des particuliers, des personnes spécifiées non nommées, des personnalités publiques mineures, des personnes à haut risque ou des groupes à haut risque, lorsque la menace est définie comme l'un des éléments suivants&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention de commettre des actes de violence, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations faisant l'apologie de la violence, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Appels à la violence de gravité moyenne, y compris le contenu où aucune cible n'est spécifiée mais un symbole représente la cible, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations ambitieuses ou conditionnelles pour commettre des actes de violence, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations admettant avoir commis des violences de gravité moyenne, sauf lorsqu'elles sont partagées dans un contexte de rédemption, d'autodéfense, de sports de combat ou lorsqu'elles sont commises par des forces de l'ordre, des militaires ou des membres de la sécurité de l'État.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu concernant d'autres cibles que des particuliers, des personnalités publiques mineures, des personnes à haut risque ou des groupes à haut risque et tout contenu crédible&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention de commettre des actes de violence, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Appels à l'action de la violence, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations prônant la violence, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '94pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations ambitieuses ou conditionnelles à commettre des actes de violence</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Menaces entraînant des blessures physiques (ou d'autres formes de violence de moindre gravité) envers des particuliers (auto-déclaration requise) ou des personnalités publiques mineures, lorsque la menace est définie comme l'un des éléments suivants&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Particuliers (une correspondance de nom et/ou de visage est requise) ou personnalités publiques mineures, notamment&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l5">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '130pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention, appels à l'action, plaidoyer, déclarations d'aspiration ou conditionnelles pour commettre des actes de violence de faible gravité</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Instructions sur la façon de fabriquer ou d'utiliser des armes s'il existe des preuves d'un objectif de blesser gravement ou de tuer des personnes à travers&nbsp;:</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-94pt', textAlign: 'right'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Langage énonçant explicitement cet objectif, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Photos ou vidéos qui montrent ou simulent le résultat final (blessure grave ou décès) dans le cadre de l'instruction.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '94pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>À moins que le contenu susmentionné ne soit partagé dans le cadre de l'autodéfense récréative, à des fins d'entraînement militaire, de jeux vidéo commerciaux ou de couverture de l'actualité (publiée par une Page ou avec un logo d'actualité).</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Fournir des instructions sur la façon de fabriquer ou d'utiliser des explosifs, à moins qu'il n'y ait un contexte clair indiquant que le contenu est à des fins non violentes (par exemple, une partie de jeux vidéo commerciaux, un objectif scientifique/éducatif clair, des feux d'artifice ou spécifiquement pour la pêche)</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Tout contenu contenant des déclarations d'intention, des appels à l'action, des déclarations conditionnelles ou ambitieuses, ou prônant la violence en raison du vote, de l'inscription des électeurs ou de l'administration ou du résultat d'une élection</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention ou de plaidoyer, appels à l'action ou déclarations d'aspiration ou conditionnelles pour apporter des armes à des endroits, y compris, mais sans s'y limiter, des lieux de culte, des établissements d'enseignement, des bureaux de vote ou des lieux utilisés pour compter les votes ou administrer une élection (ou encourager les autres à faire de même).</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                  </ul>
                </li>
              </ol>
            </ol>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-41pt', textAlign: 'right'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Menaces violentes contre les forces de l'ordre.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Menaces violentes contre des personnes accusées d'un crime. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons ce contenu lorsque nous avons des raisons de croire que le contenu est destiné à causer des dommages physiques.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Déclarations codées où la méthode de violence ou de préjudice n'est pas clairement articulée, mais la menace est voilée ou implicite. </font>
                <font style={{verticalAlign: 'inherit'}}>Bah Blesson &amp; Company examine les signaux ci-dessous pour déterminer s'il existe une menace de préjudice dans le contenu.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Partagé dans un contexte de représailles (p. ex., expression du désir de faire quelque chose de préjudiciable à autrui en réponse à un grief ou à une menace qui peut être réel, perçu ou anticipé)</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Références à des incidents de violence historiques ou fictifs (par exemple, contenu qui menace les autres en faisant référence à des incidents de violence historiques connus qui ont été exécutés tout au long de l'histoire ou dans des contextes fictifs)</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Agit comme une incitation à l'action menaçante (par exemple, un contenu invitant ou encourageant d'autres personnes à commettre des actes nuisibles ou à participer à l'exécution d'actes nuisibles)</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Indique la connaissance ou le partage d'informations sensibles qui pourraient exposer d'autres personnes à un préjudice (par exemple, un contenu qui note ou implique la connaissance d'informations personnelles susceptibles de rendre une menace de violence physique plus crédible. Cela inclut la connaissance implicite de l'adresse résidentielle d'une personne, de son lieu d'emploi ou d'études, trajets quotidiens ou emplacement actuel)</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le contexte local ou l'expertise en la matière confirme que la déclaration en question pourrait être menaçante et/ou pourrait entraîner une violence imminente ou des dommages physiques.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le sujet de la menace nous signale le contenu.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Menaces contre les responsables électoraux</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention ou de plaidoyer implicites, appels à l'action ou déclarations d'aspiration ou conditionnelles pour apporter des armements à des endroits, y compris, mais sans s'y limiter, des lieux de culte, des établissements d'enseignement, des bureaux de vote ou des lieux utilisés pour compter les votes ou administrer une élection (ou encourager d'autres à faire de même). </font>
                <font style={{verticalAlign: 'inherit'}}>Nous pouvons également limiter les appels pour apporter des armements à certains endroits où il y a temporairement des signaux d'un risque accru de violence ou de dommages hors ligne. </font>
                <font style={{verticalAlign: 'inherit'}}>Cela peut être le cas, par exemple, lorsqu'une manifestation connue et une contre-manifestation sont prévues ou que des violences ont éclaté lors d'une manifestation dans la même ville au cours des 7 derniers jours</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Individus et organisations dangereux</font>
              </font>
            </h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Dans un effort pour prévenir et perturber les dommages dans le monde réel, nous n'autorisons pas les organisations ou les individus qui proclament une mission violente ou qui sont engagés dans la violence à être présents sur MyOpinion. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous évaluons ces entités en fonction de leur comportement en ligne et hors ligne, et plus particulièrement, de leurs liens avec la violence. </font>
                <font style={{verticalAlign: 'inherit'}}>Dans le cadre de cette politique, nous désignons des individus, des organisations et des réseaux de personnes. </font>
                <font style={{verticalAlign: 'inherit'}}>Ces désignations sont divisées en trois niveaux qui indiquent le niveau d'application du contenu, le niveau 1 entraînant l'application la plus étendue car nous pensons que ces entités ont les liens les plus directs avec les dommages hors ligne.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le niveau 1 </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>se concentre sur les entités qui se livrent à de graves préjudices hors ligne - y compris l'organisation ou la promotion de la violence contre les civils,</font>
                </font>
              </span>
            </h3>
            <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>déshumanisant à plusieurs reprises ou préconisant de nuire à des personnes sur la base de caractéristiques protégées, ou se livrant à des opérations criminelles systématiques. </font>
                <font style={{verticalAlign: 'inherit'}}>Les entités de niveau 1 comprennent les organisations terroristes, haineuses et criminelles. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons les éloges, le soutien substantiel et la représentation des entités de niveau 1 ainsi que de leurs dirigeants, fondateurs ou membres éminents. </font>
                <font style={{verticalAlign: 'inherit'}}>Le niveau 1 comprend les organisations haineuses ; </font>
                <font style={{verticalAlign: 'inherit'}}>les organisations criminelles, y compris celles désignées par le gouvernement des États-Unis comme les piliers du trafic de stupéfiants spécialement désignés (SDNTK); </font>
                <font style={{verticalAlign: 'inherit'}}>et les organisations terroristes, y compris les entités et les individus désignés par le gouvernement des États-Unis comme des organisations terroristes étrangères (FTO) ou des terroristes mondiaux spécialement désignés (SDGT). </font>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons les éloges, le soutien substantiel et la représentation des entités de niveau 1 ainsi que de leurs dirigeants, fondateurs,</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>De plus, nous n'autorisons pas le contenu qui loue, soutient de manière substantielle ou représente des événements que MyOpinion désigne comme des événements violents violents - y compris les attaques terroristes, les événements haineux, la violence à victimes multiples ou les tentatives de violence à victimes multiples, les meurtres multiples ou les crimes de haine. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas non plus les éloges, le soutien substantiel ou la représentation du ou des auteurs de telles attaques. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons également le contenu qui loue, soutient de manière substantielle ou représente des idéologies qui promeuvent la haine, telles que le nazisme et la suprématie blanche.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le niveau 2 </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>se concentre sur les entités qui se livrent à la violence contre des acteurs étatiques ou militaires mais ne ciblent généralement pas les civils - ce que nous appelons les «&nbsp;acteurs non étatiques violents&nbsp;». </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous supprimons tout soutien substantiel et toute représentation de ces entités, de leurs dirigeants et de leurs membres éminents. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous supprimons tout éloge des activités violentes de ces groupes.</font>
                </font>
              </span>
            </h3>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le niveau 3 </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>se concentre sur les entités susceptibles de violer à plusieurs reprises nos politiques sur le discours de haine ou les organisations dangereuses sur ou hors de la plate-forme ou qui démontrent une forte intention de se livrer à la violence hors ligne dans un avenir proche, mais qui n'ont pas nécessairement commis de violence à ce jour ou plaidé pour la violence contre les autres en raison de leurs caractéristiques protégées. </font>
                  <font style={{verticalAlign: 'inherit'}}>Cela inclut les mouvements sociaux militarisés, les réseaux de complot incitant à la violence et les individus et groupes interdits pour avoir encouragé la haine. </font>
                  <font style={{verticalAlign: 'inherit'}}>Les entités de niveau 3 peuvent ne pas être présentes ou coordonnées sur nos plateformes.</font>
                </font>
              </span>
            </h3>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous reconnaissons que les utilisateurs peuvent partager du contenu qui inclut des références à des organisations et des individus dangereux désignés pour signaler, condamner ou discuter de manière neutre d'eux ou de leurs activités. </font>
                <font style={{verticalAlign: 'inherit'}}>Nos politiques sont conçues pour laisser place à ces types de discussions tout en limitant simultanément les risques de dommages potentiels hors ligne. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous exigeons donc que les personnes indiquent clairement leur intention lors de la création ou du partage de tels contenus. </font>
                <font style={{verticalAlign: 'inherit'}}>Si l'intention d'un utilisateur est ambiguë ou peu claire, nous supprimons par défaut le contenu.</font>
              </font>
            </p>
            <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Conformément au droit international des droits de l'homme, nos politiques autorisent les discussions sur les droits de l'homme d'individus désignés ou de membres d'entités dangereuses désignées, à moins que le contenu ne comprenne d'autres éloges, un soutien substantiel ou la représentation d'entités désignées ou d'autres violations de politiques, telles que l'incitation à la violence.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons&nbsp;:</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons les éloges, le soutien substantiel et la représentation de diverses organisations dangereuses. </font>
                <font style={{verticalAlign: 'inherit'}}>Ces concepts s'appliquent aux organisations elles-mêmes, à leurs activités et à leurs membres. </font>
                <font style={{verticalAlign: 'inherit'}}>Ces concepts ne proscrivent pas le plaidoyer pacifique pour des résultats politiques particuliers.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Éloge </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>, défini comme l'un des éléments ci-dessous&nbsp;:</font>
                </font>
              </span>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Parlez positivement d'une entité ou d'un événement désigné;</font>
              </font>
            </p>
            <ul id="l6">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, «&nbsp;Les combattants de l'État islamique sont vraiment courageux&nbsp;!&nbsp;»</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Donner à une entité ou à un événement désigné un sentiment d'accomplissement&nbsp;;</font>
              </font>
            </p>
            <ul id="l7">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Timothy McVeigh est un martyr."</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Légitimer la cause d'une entité désignée en affirmant que sa conduite haineuse, violente ou criminelle est légalement, moralement ou autrement justifiée ou acceptable&nbsp;;</font>
              </font>
            </p>
            <ul id="l8">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Hitler n'a rien fait de mal."</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>S'aligner idéologiquement sur une entité ou un événement désigné.</font>
              </font>
            </p>
            <ul id="l9">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Je suis avec Brenton Tarrant."</font>
                  </font>
                </p>
                <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous supprimons les éloges des entités de niveau 1 et des événements désignés. </font>
                    <font style={{verticalAlign: 'inherit'}}>Nous supprimerons également l'éloge de la violence faite par les entités de niveau 2.</font>
                  </font>
                </p>
                <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Soutien substantiel </font>
                  </font><span className="p">
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>, défini comme l'un des éléments ci-dessous&nbsp;:</font>
                    </font>
                  </span>
                </h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tout acte qui améliore la situation financière d'une entité désignée - y compris l'acheminement d'argent vers ou à partir d'une entité désignée&nbsp;;</font>
              </font>
            </p>
            <ul id="l10">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Faites un don au KKK&nbsp;!"</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tout acte qui fournit une aide matérielle à une entité ou un événement désigné&nbsp;;</font>
              </font>
            </p>
            <ul id="l11">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Si vous souhaitez envoyer des colis de soins au cartel de Sinaloa, utilisez cette adresse&nbsp;:"</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Lancer un appel à l'action au nom d'une entité ou d'un événement désigné&nbsp;;</font>
              </font>
            </p>
            <ul id="l12">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Contactez la division Atomwaffen - (XXX) XXX-XXXX"</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Recrutement pour le compte d'une entité ou d'un événement désigné&nbsp;;</font>
              </font>
            </p>
            <ul id="l13">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Si vous voulez vous battre pour le califat, DM moi"</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Canaliser des informations ou des ressources, y compris des communications officielles, au nom d'une entité ou d'un événement désigné</font>
              </font>
            </p>
            <ul id="l14">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, citant directement une entité désignée sans légende qui condamne, discute de manière neutre ou fait partie d'un reportage.</font>
                  </font>
                </p>
                <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous supprimons le soutien substantiel des entités de niveau 1 et de niveau 2 et des événements désignés.</font>
                  </font>
                </p>
                <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Représentation </font>
                  </font><span className="p">
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>, définie comme l'un des éléments ci-dessous&nbsp;:</font>
                    </font>
                  </span>
                </h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Déclarant que vous êtes membre d'une entité désignée ou que vous êtes une entité désignée&nbsp;;</font>
              </font>
            </p>
            <ul id="l15">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, "Je suis un grand dragon du KKK."</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Création d'une Page, d'un Profil, d'un Événement, d'un Groupe ou d'une autre entité MyOpinion qui est ou prétend être la propriété d'une Entité désignée ou qui est exécutée en son nom, ou qui est ou prétend être un événement désigné.</font>
              </font>
            </p>
            <ul id="l16">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Par exemple, une page nommée "Parti nazi américain".</font>
                  </font>
                </p>
                <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous supprimons la représentation des organisations désignées de niveaux 1 et 2, des entités interdites par la haine et des événements désignés.</font>
                  </font>
                </p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Types et niveaux d'organisations dangereuses</font>
                  </font>
                </p>
                <p className="s2" style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Niveau 1 : Terrorisme, haine organisée, activité criminelle à grande échelle, tentative de violence à victimes multiples, violence à victimes multiples, meurtres multiples et violence violente</font>
                  </font>
                </p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas les individus ou les organisations impliqués dans le crime organisé, y compris ceux désignés par le gouvernement des États-Unis comme pivots du trafic de stupéfiants spécialement désignés (SDNTK)&nbsp;; </font>
                    <font style={{verticalAlign: 'inherit'}}>détester; </font>
                    <font style={{verticalAlign: 'inherit'}}>ou le terrorisme, y compris les entités désignées par le gouvernement des États-Unis comme des organisations terroristes étrangères (FTO) ou des terroristes mondiaux spécialement désignés (SDGT), pour avoir une présence sur la plate-forme. </font>
                    <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas non plus d'autres personnes à représenter ces entités. </font>
                    <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas les dirigeants ou les membres éminents de ces organisations à avoir une présence sur la plateforme, des symboles qui les représentent à utiliser sur la plateforme, ou du contenu qui les loue ou leurs actes. </font>
                    <font style={{verticalAlign: 'inherit'}}>De plus, nous supprimons toute coordination du soutien substantiel pour ces individus et organisations.</font>
                  </font>
                </p>
                <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas le contenu qui loue, soutient de manière substantielle ou représente des événements que Bah Blesson &amp; Company désigne comme des attaques terroristes, des événements haineux, des violences à victimes multiples ou des tentatives de violence à victimes multiples, des meurtres en série, des crimes haineux ou des événements violents violents.</font>
                  </font>
                </p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas non plus les éloges, le soutien substantiel ou la représentation d'idéologies haineuses désignées.</font>
                  </font>
                </p>
                <h3 style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Organisations et individus terroristes </font>
                  </font><span className="p">
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>, définis comme un acteur non étatique qui :</font>
                    </font>
                  </span>
                </h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Se livre à, préconise ou apporte un soutien substantiel à des actes de violence délibérés et planifiés,</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Qui cause ou tente de causer la mort, des blessures ou des atteintes graves à des civils, ou à toute autre personne ne participant pas directement aux hostilités dans une situation de conflit armé, et/ou des dommages importants aux biens liés à la mort, des blessures graves ou des atteintes graves à civils</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Avec l'intention de contraindre, d'intimider et/ou d'influencer une population civile, un gouvernement ou une organisation internationale</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour atteindre un but politique, religieux ou idéologique.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Organisations haineuses </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>, définies comme une association de trois personnes ou plus qui&nbsp;:</font>
                </font>
              </span>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>est organisé sous un nom, un signe ou un symbole&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>et</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>a une idéologie, des déclarations ou des actions physiques qui attaquent des individus en fonction de caractéristiques, notamment la race, l'appartenance religieuse, l'origine nationale, le handicap, l'origine ethnique, le sexe, le sexe, l'orientation sexuelle ou une maladie grave.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Organisations criminelles </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>, définies comme une association de trois personnes ou plus qui :</font>
                </font>
              </span>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>est unie sous un nom, une ou des couleurs, un ou des gestes de la main ou des indices reconnus&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>et</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>s'est livré ou menace de se livrer à des activités criminelles telles qu'un homicide, un trafic de drogue ou un enlèvement.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Violence à victimes multiples et meurtres multiples</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous considérons qu'un événement est de la violence à victimes multiples ou une tentative de violence à victimes multiples s'il entraîne trois victimes ou plus dans un incident, défini comme des décès ou des blessures graves. </font>
                <font style={{verticalAlign: 'inherit'}}>Tout individu ayant commis une telle agression est considéré comme auteur ou tentative d'auteur de violences multi-victimes.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous considérons toute personne qui a commis deux meurtres ou plus au cours de plusieurs incidents ou lieux comme un meurtrier multiple.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Idéologies haineuses</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Bien que nos désignations d'organisations et d'individus se concentrent sur le comportement, nous reconnaissons également qu'il existe certaines idéologies et croyances qui sont intrinsèquement liées à la violence et aux tentatives d'organiser les gens autour d'appels à la violence ou à l'exclusion d'autrui en fonction de leurs caractéristiques protégées. </font>
                <font style={{verticalAlign: 'inherit'}}>Dans ces cas, nous désignons l'idéologie elle-même et supprimons le contenu qui soutient cette idéologie de notre plateforme. </font>
                <font style={{verticalAlign: 'inherit'}}>Ces idéologies comprennent :</font>
              </font>
            </p>
            <ul id="l17">
              <li data-list-text="">
                <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>nazisme</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Suprémacie blanche</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nationalisme blanc</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Séparatisme blanc</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimons les éloges explicites, le soutien substantiel et la représentation de ces idéologies, et supprimons les individus et les organisations qui souscrivent à une ou plusieurs de ces idéologies haineuses.</font>
              </font>
            </p>
            <p className="s2" style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Niveau 2&nbsp;: Acteurs non étatiques violents</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Les organisations et les individus désignés par Bah Blesson &amp; Company comme acteurs non étatiques violents ne sont pas autorisés à avoir une présence sur MyOpinion, ou à avoir une présence maintenue par d'autres en leur nom. </font>
                <font style={{verticalAlign: 'inherit'}}>Comme ces groupes sont activement engagés dans la violence, un soutien substantiel à ces entités n'est pas non plus autorisé. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous supprimerons également les louanges de la violence exercée par ces entités.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Acteurs non étatiques violents </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>, définis comme tout acteur non étatique qui&nbsp;:</font>
                </font>
              </span>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>se livre à des actes de violence délibérés et planifiés principalement contre une armée gouvernementale ou d'autres groupes armés&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>et</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>qui provoque ou tente de</font>
              </font>
            </p>
            <ul id="l18">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>causer la mort de personnes prenant directement part aux hostilités dans un conflit armé, et/ou</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>priver les communautés de l'accès aux infrastructures vitales et aux ressources naturelles, et/ou causer des dommages importants aux biens, liés à la mort, aux blessures graves ou aux dommages graves aux civils</font>
                  </font>
                </p>
                <p className="s2" style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Niveau 3 : Mouvements sociaux militarisés, réseaux de complot induisant la violence et entités interdites par la haine</font>
                  </font>
                </p>
                <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Les pages, groupes, événements et profils ou autres entités MyOpinion qui sont - ou prétendent être - maintenus par ou au nom de mouvements sociaux militarisés et de réseaux de conspiration induisant la violence sont interdits. </font>
                    <font style={{verticalAlign: 'inherit'}}>Les administrateurs de ces pages, groupes et événements seront également supprimés.</font>
                  </font>
                </p>
                <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas la représentation d'organisations et d'individus désignés par Bah Blesson &amp; Company comme entités interdites par la haine.</font>
                  </font>
                </p>
                <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Mouvements sociaux militarisés (MSM) </font>
                  </font><span className="p">
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>, qui comprennent&nbsp;:</font>
                    </font>
                  </span>
                </h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Milices </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>, définies comme des acteurs non étatiques qui utilisent des armes dans le cadre de leur formation, de leur communication ou de leur présence&nbsp;; </font>
                  <font style={{verticalAlign: 'inherit'}}>et sont structurées ou fonctionnent comme des forces militaires ou de sécurité non officielles et&nbsp;:</font>
                </font>
              </span>
            </h3>
            <ul id="l19">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Coordonner en vue de la violence ou de la guerre civile&nbsp;; </font>
                    <font style={{verticalAlign: 'inherit'}}>ou</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Diffuser des informations sur l'utilisation tactique des armes de combat&nbsp;; </font>
                    <font style={{verticalAlign: 'inherit'}}>ou</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Coordonner la coordination tactique militarisée dans un conflit civil armé présent ou futur ou une guerre civile.</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Groupes soutenant des actes de violence dans le cadre de manifestations </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>, définis comme des acteurs non étatiques qui, à plusieurs reprises&nbsp;:</font>
                </font>
              </span>
            </h3>
            <ul id="l20">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Coordonner, promouvoir, admettre ou participer à&nbsp;:</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Actes de violence de rue contre des civils ou des forces de l'ordre&nbsp;; </font>
                    <font style={{verticalAlign: 'inherit'}}>ou</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Incendie criminel, pillage ou autre destruction de propriété&nbsp;; </font>
                    <font style={{verticalAlign: 'inherit'}}>ou</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Menacer de perturber violemment un processus électoral&nbsp;; </font>
                    <font style={{verticalAlign: 'inherit'}}>ou</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Promouvoir le fait d'apporter des armes à un endroit lorsque l'intention déclarée est d'intimider les gens au cours d'une manifestation.</font>
                  </font>
                </p>
                <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Réseaux de complot induisant la violence (VICN) </font>
                  </font><span className="p">
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>, définis comme un acteur non étatique qui&nbsp;:</font>
                    </font>
                  </span>
                </h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>S'organise sous un nom, un signe, un énoncé de mission ou un symbole&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>et</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Promouvoir des théories qui attribuent un comportement violent ou déshumanisant à des personnes ou à des organisations qui ont été démystifiées par des sources crédibles&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>et</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>A inspiré de multiples incidents de violence dans le monde réel par des adhérents motivés par le désir d'attirer l'attention sur ou de réparer les préjudices supposés promus par ces théories démystifiées.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Entités interdites par la haine </font>
              </font><span className="p">
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>, définies comme des entités qui se livrent à des comportements ou à des discours haineux répétés, mais qui n'atteignent pas le niveau d'une entité de niveau 1 parce qu'elles ne se sont pas livrées à la violence ou ne l'ont pas explicitement préconisée, ou parce qu'elles n'ont pas suffisamment de liens avec des personnes précédemment désignées. organisations ou personnalités.</font>
                </font>
              </span>
            </h3>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous autoriserons le contenu qui pourrait autrement enfreindre les normes de la communauté lorsqu'il est déterminé que le contenu est satirique. </font>
                <font style={{verticalAlign: 'inherit'}}>Le contenu ne sera autorisé que si les éléments de violation du contenu sont satirisés ou attribués à quelque chose ou à quelqu'un d'autre afin de se moquer d'eux ou de les critiquer.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Coordination du mal et promotion du crime</font>
              </font>
            </h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Afin de prévenir et de perturber les comportements malveillants et imitateurs hors ligne, nous interdisons aux personnes de faciliter, d'organiser, de promouvoir ou d'admettre certaines activités criminelles ou nuisibles ciblant des personnes, des entreprises, des biens ou des animaux. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous permettons aux gens de débattre et de défendre la légalité des activités criminelles et nuisibles, ainsi que d'attirer l'attention sur les activités nuisibles ou criminelles qu'ils pourraient</font>
              </font>
            </p>
            <p style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>témoin ou expérience tant qu'ils ne préconisent pas ou ne coordonnent pas le mal.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '168%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Ne publiez pas de contenu entrant dans les catégories suivantes&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '13pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Sortie:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui expose l'identité ou les lieux affiliés à toute personne présumée&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Être membre d'un groupe de sorties à risque; </font>
                <font style={{verticalAlign: 'inherit'}}>et/ou</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Partager des relations familiales et/ou amoureuses avec un ou plusieurs membres d'un groupe à risque&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>et/ou</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Avoir exercé des activités professionnelles en faveur d'un groupe à risque de sorties (hors personnalités politiques)</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui expose le statut d'infiltration des forces de l'ordre, de l'armée ou du personnel de sécurité si&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Le contenu contient le nom complet de l'agent ou une autre identification explicite</font>
              </font>
            </p>
            <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>et mentionne explicitement leur statut d'infiltration.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>L'imagerie identifie les visages des membres des forces de l'ordre et mentionne explicitement leur statut d'infiltré.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Écraser, en particulier les déclarations d'intention, les appels à l'action, représenter, soutenir, défendre, décrire, admettre ou en parler positivement.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Représenter, promouvoir, défendre ou encourager la participation à un défi viral à haut risque.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Atteinte aux animaux</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention, appels à l'action, représentant, soutenant ou préconisant, ou décrivant, admettant ou promouvant&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Actes de violence physique contre des animaux commis par vous ou vos associés, sauf en cas de chasse, de pêche, de sacrifice religieux, de préparation ou de transformation d'aliments, de ravageurs ou de vermine, d'autodéfense ou de rédemption.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '-52pt', lineHeight: '168%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Mise en scène de combats d'animaux contre animaux, y compris les actes commis par un tiers. </font>
                <font style={{verticalAlign: 'inherit'}}>Atteinte à la propriété</font>
              </font>
            </p>
            <p style={{paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention, appels à l'action, représentation, soutien ou plaidoyer pour des atteintes à la propriété qui décrivent, admettent ou promeuvent les actes suivants commis par vous ou vos associés&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Vandalisme.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Piratage lorsque l'intention est de détourner un domaine, de corrompre ou de perturber les cybersystèmes, de demander des rançons ou d'obtenir un accès non autorisé aux systèmes de données.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Vol lorsqu'il est commis par vous ou vos associés, ainsi que des déclarations positives sur le vol lorsqu'il est commis par un tiers.</font>
              </font>
            </p>
            <p className="s3" style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Fraude électorale et/ou de recensement</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Offres d'achat ou de vente de votes avec de l'argent ou des cadeaux</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Déclarations qui préconisent, fournissent des instructions ou montrent une intention explicite de participer illégalement à un processus de vote ou de recensement</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous incluons une étiquette afin que les gens sachent que le contenu peut être sensible&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Images illustrant un défi viral à haut risque si elles sont partagées avec une légende qui condamne ou sensibilise aux risques associés.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu mettant en danger les personnes LGBTQI+ en révélant leur identité sexuelle contre leur gré ou sans autorisation.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui met en danger les femmes non voilées en dévoilant leurs images sans voile contre leur gré ou sans autorisation.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu mettant en danger des personnes non condamnées en révélant leur identité et leur statut de cible d'une opération d'infiltration en tant que prédateur sexuel.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu révélant l'identité d'une personne en tant que témoin, informateur, activiste ou personnes dont l'identité ou l'implication dans une affaire judiciaire n'a pas été rendue publique</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Imagerie susceptible de tromper le public quant à son origine si&nbsp;:</font>
              </font>
            </p>
            <ul id="l21">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>L'entité représentée ou un représentant autorisé s'oppose à l'imagerie, et</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>L'imagerie a le potentiel de causer du tort aux membres du public.</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>appelle à une ingérence coordonnée qui affecterait la capacité d'un individu à</font>
              </font>
            </p>
            <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>participer à un recensement officiel ou à une élection.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu indiquant qu'un recensement ou une participation électorale peut entraîner ou entraînera des conséquences en matière d'application de la loi (par exemple, arrestation, expulsion ou emprisonnement).</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention, de soutien ou de plaidoyer pour se rendre sur un site électoral, un lieu de vote ou un lieu de dépouillement lorsque le but de se rendre sur le site est de surveiller ou de surveiller l'activité des électeurs ou des responsables électoraux en utilisant des</font>
              </font>
            </p>
            <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>langage (par exemple «&nbsp;guerre&nbsp;», «&nbsp;armée&nbsp;» ou «&nbsp;soldat&nbsp;») ou un objectif exprimé de</font>
              </font>
            </p>
            <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>intimider, exercer un contrôle ou faire étalage de pouvoir (par exemple «&nbsp;Montrons-leur qui est le patron&nbsp;!&nbsp;», «&nbsp;S'ils ont peur, ils ne voteront pas&nbsp;!&nbsp;»).</font>
              </font>
            </p>
            <h3 style={{paddingTop: '4pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Biens et services restreints</font>
              </font>
            </h3>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour encourager la sécurité et dissuader les activités potentiellement dangereuses, nous interdisons aux particuliers, aux fabricants et aux détaillants d'acheter, de vendre, de tirer au sort, de donner, de transférer ou d'échanger certains biens et services sur notre plateforme. </font>
                <font style={{verticalAlign: 'inherit'}}>Les détaillants physiques et en ligne peuvent promouvoir des armes à feu, de l'alcool et des articles de tabac disponibles à la vente en dehors de nos services&nbsp;; </font>
                <font style={{verticalAlign: 'inherit'}}>cependant, nous restreignons la visibilité de ce contenu aux mineurs. </font>
                <font style={{verticalAlign: 'inherit'}}>Nous autorisons les discussions sur la vente de ces biens dans les magasins ou par les détaillants en ligne, ainsi que la promotion de modifications de la réglementation des biens et services couverts par cette politique.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
              </font>
            </p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Armes à feu</font>
              </font>
            </h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tentatives d'achat, de vente ou d'échange d'armes à feu, de pièces d'armes à feu, de munitions, d'explosifs ou d'améliorations létales, sauf lorsqu'elles sont publiées par une page, un groupe ou un profil MyOpinion représentant des entités physiques légitimes, y compris des commerces de détail, des sites Web, des marques ou le gouvernement agences (par exemple, service de police, pompiers) ou un particulier partageant du contenu au nom d'entités physiques légitimes.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tentatives de don ou de don d'armes à feu, de pièces d'armes à feu, de munitions, d'explosifs ou d'améliorations mortelles, sauf lorsqu'elles sont publiées dans les contextes suivants&nbsp;:</font>
              </font>
            </p>
            <ul id="l22">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Donner, échanger ou racheter des armes à feu et des munitions par une page, un groupe ou un profil MyOpinion représentant des entités physiques légitimes, y compris des commerces de détail, des sites Web, des marques ou des agences gouvernementales, ou un particulier partageant du contenu au nom d'entités physiques légitimes. et entités de mortier.</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Une vente aux enchères ou une tombola d'armes à feu par des entités physiques légitimes, y compris des commerces de détail, des organisations affiliées au gouvernement ou des organisations à but non lucratif, ou des particuliers affiliés ou parrainés par des entités physiques légitimes.</font>
                  </font>
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Demande des armes à feu, des pièces d'armes à feu, des munitions, des explosifs ou des améliorations mortelles</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Vend, offre, échange, transfère, coordonne, promeut (nous entendons par là parle positivement de, encourage l'utilisation de) ou donne accès à des instructions d'impression 3D ou de fabrication assistée par ordinateur pour des armes à feu ou des pièces d'armes à feu, quel que soit le contexte ou l'affiche.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Médicaments non médicaux</font>
              </font>
            </h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tente d'acheter, de vendre, d'échanger, de coordonner le commerce, de donner, de donner ou de demander des médicaments non médicaux.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Admet l'achat, le commerce ou la coordination du commerce de médicaments non médicaux par l'affichage du contenu par eux-mêmes ou par d'autres.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Admet une utilisation personnelle sans reconnaissance ni référence à la récupération, au traitement ou à toute autre assistance pour lutter contre l'utilisation. </font>
                <font style={{verticalAlign: 'inherit'}}>Ce contenu ne peut pas parler positivement de, encourager l'utilisation de, coordonner ou fournir des instructions pour fabriquer ou utiliser des médicaments non médicaux.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Coordinates or promotes (by which we mean speaks positively about, encourages the use of, or provides instructions to use or make) non-medical drugs.</p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Pharmaceutical drugs</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Attempts to buy, sell or trade pharmaceutical drugs except when:</p>
            <ul id="l23">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Listing the price of vaccines in an explicit education or discussion context.</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Offering delivery when posted by legitimate healthcare e-commerce businesses.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Attempts to donate or gift pharmaceutical drugs</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Asks for pharmaceutical drugs except when content discusses the affordability, accessibility or efficacy of pharmaceutical drugs in a medical context</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Marijuana</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>Content that attempts to buy, sell, trade, donate or gift or asks for marijuana.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Endangered species (wildlife and plants):</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempts to buy, sell, trade, donate,or gift or asks for endangered species or their parts.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Admits to poaching, buying or trading of endangered species or their parts committed by the poster of the content either by themselves or their associates through others. This does not include depictions of poaching by strangers.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Depicts poaching of endangered species or their parts committed by the poster of the content by themselves or their associates.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Shows coordination or promotion (by which we mean speaks positively about, encourages the poaching of, or provides instructions to use or make products from endangered species or their parts)</p>
            <h3 style={{paddingTop: '3pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Live non-endangered animals excluding livestock</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that attempts to buy, sell or trade live non-endangered animals except when:</p>
            <ul id="l24">
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Posted by a Page, Group or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, legitimate websites, brands, or rehoming shelters, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Posted in the context of donating or rehoming live non-endangered animals, including rehoming fees for peer-to-peer adoptions, selling an animal for a religious offering, or offering a reward for lost pets.</p>
                <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Human blood</h3>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content that attempts to buy, sell or trade human blood.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Content that asks for human blood except for a donation or gift.</p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Alcohol / tobacco</h3>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Content that:</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Attempts to buy, sell or trade alcohol or tobacco except when:</p>
            <ul id="l25">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Posted by a Page, Group, or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, websites or brands, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>Content refers to alcohol/tobacco that will be exchanged or consumed on location at an event, restaurant, bar, party and so on.</p>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Attempts to donate or gift alcohol or tobacco except when posted by a Page, Group, or MyOpinion profile representing legitimate brick-and-mortar entities, including retail businesses, websites or brands, or a private individual sharing content on behalf of legitimate brick-and-mortar entities.</p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Asks for alcohol or tobacco</p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Weight loss products</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Content about weight loss that contains a miracle claim and attempts to buy, sell, trade, donate or gift weight loss products.</p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Historical artifacts</h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui tente d'acheter, de vendre, d'échanger, de donner ou de donner ou qui demande des artefacts historiques.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Sauf lorsque l'un des éléments ci-dessus se produit dans un contexte fictif ou documentaire</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Marchandises et matières dangereuses</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui tente d'acheter, de vendre, d'échanger, de donner ou de donner ou qui demande des marchandises et matières dangereuses</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Sauf lorsque l'un des éléments ci-dessus se produit dans un contexte fictif ou documentaire</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous limitons la visibilité aux adultes de 21&nbsp;ans et plus&nbsp;:</font>
              </font>
            </p>
            <h3 style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Armes à feu</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu publié ou faisant la promotion de magasins physiques légitimes, d'entités, y compris de sites Web de commerces de détail, de marques ou d'agences gouvernementales qui tentent d'acheter, de vendre, d'échanger, de donner ou de donner (y compris dans le cadre d'une vente aux enchères ou d'une tombola) des armes à feu , des pièces d'armes à feu, des munitions, des explosifs ou des améliorations mortelles.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous restreignons la visibilité aux adultes de 18&nbsp;ans et plus&nbsp;:</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Alcool/tabac</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu publié ou faisant la promotion d'entités physiques légitimes, y compris des sites Web ou des marques de commerces de détail, qui tentent d'acheter, de vendre, d'échanger, de donner ou de donner de l'alcool ou des produits du tabac.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Armes blanches</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui tente d'acheter, de vendre, d'échanger, de donner ou d'offrir des armes blanches.</font>
              </font>
            </p>
            <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Produits amaigrissants et procédures cosmétiques potentiellement dangereuses</font>
              </font>
            </h3>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tentatives d'achat, de vente, d'échange, de don ou de cadeau de produits amaigrissants ou de procédures cosmétiques potentiellement dangereuses.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Admet ou décrit l'utilisation d'un produit amaigrissant ou de procédures cosmétiques potentiellement dangereuses, sauf en cas d'admission d'utilisation dans un contexte de désapprobation.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Fait preuve de coordination ou de promotion (nous entendons par là parler positivement, encourager l'utilisation ou fournir des instructions pour utiliser ou fabriquer un produit diététique ou effectuer des procédures cosmétiques dangereuses).</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Jouets sexuels et produits d'amélioration sexuelle</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui tente d'acheter, de vendre, d'échanger, de donner ou d'offrir des jouets sexuels et des produits d'amélioration sexuelle</font>
              </font>
            </p>
            <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Jeu d'argent réel</font>
              </font>
            </h3>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui tente de vendre ou de promouvoir des services de jeu et de jeu en ligne où quelque chose de valeur monétaire (y compris de l'argent ou</font>
              </font>
            </p>
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>monnaies numériques/virtuelles, par exemple bitcoin) est nécessaire pour jouer et tout ce qui a une valeur monétaire fait partie du prix.</font>
              </font>
            </p>
            <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous autoriserons le contenu qui pourrait autrement enfreindre les normes de la communauté lorsqu'il est déterminé que le contenu est satirique. </font>
                <font style={{verticalAlign: 'inherit'}}>Le contenu ne sera autorisé que si les éléments de violation du contenu sont satirisés ou attribués à quelque chose ou à quelqu'un d'autre afin de se moquer d'eux ou de les critiquer.</font>
              </font>
            </p>
            <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
            <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Fraude et tromperie</font>
              </font>
            </h3>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Afin d'empêcher toute activité frauduleuse pouvant nuire à des personnes ou à des entreprises, nous supprimons les contenus qui trompent délibérément, dénaturent délibérément ou fraudent ou exploitent d'autres personnes pour de l'argent ou des biens. </font>
                <font style={{verticalAlign: 'inherit'}}>Cela inclut le contenu qui cherche à coordonner ou à promouvoir ces activités en utilisant nos services.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Nous permettons aux gens de sensibiliser et d'éduquer les autres ainsi que de condamner ces activités, sauf si cela inclut du contenu contenant des informations sensibles, telles que des informations personnellement identifiables.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui fournit des instructions sur, s'engage dans, promeut, coordonne, encourage, facilite, admet, recrute ou admet l'offre ou la sollicitation de l'une des activités suivantes&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Tromper les autres pour générer un avantage financier ou personnel au détriment d'un tiers ou d'une entité par :</font>
              </font>
            </p>
            <ul id="l26">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Escroqueries financières ou d'investissement&nbsp;:</font>
                  </font>
                </p>
                <ul id="l27">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries aux prêts</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries aux frais anticipés.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries au jeu</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Schémas de Ponzi ou pyramidaux.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Money ou cash flips ou money muling.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries à l'investissement avec promesse de taux de rendement élevés.</font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Escroqueries à l'identité non authentique&nbsp;:</font>
                  </font>
                </p>
                <ul id="l28">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries caritatives.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries amoureuses ou par usurpation d'identité</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Création de fausses entreprises ou entités.</font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Escroqueries aux produits ou aux récompenses&nbsp;:</font>
                  </font>
                </p>
                <ul id="l29">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries aux subventions et aux avantages sociaux.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries tangibles, spirituelles ou illuminati.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries à l'assurance, y compris le courtage fantôme</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Faux emplois, travail à domicile ou escroqueries pour devenir riche rapidement.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Escroqueries à l'allégement de la dette ou à la réparation du crédit.</font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>S'engager et se coordonner avec d'autres pour générer frauduleusement un avantage financier ou personnel à perte pour un tiers, tel que des personnes, des entreprises ou des organisations par&nbsp;:</font>
              </font>
            </p>
            <ul id="l30">
              <li data-list-text="">
                <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Faux documents ou instruments financiers par :</font>
                  </font>
                </p>
                <ul id="l31">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Création, vente ou achat de :</font>
                      </font>
                    </p>
                    <ul id="l32">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Documents faux ou falsifiés.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Monnaie ou bons falsifiés ou contrefaits.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '149pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Diplômes d'études et professionnels faux ou falsifiés.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '149pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Blanchiment d'argent</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Informations, biens ou services volés par&nbsp;:</font>
              </font>
            </p>
            <ul id="l33">
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Fraude à la carte de crédit et achats de biens ou de biens avec des informations financières volées</font>
                  </font>
                </p>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Commerce, vente ou achat de :</font>
                  </font>
                </p>
                <ul id="l34">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Informations personnelles identifiables.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Avis ou évaluations d'utilisateurs faux et trompeurs.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Identifiants pour les services d'abonnement.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Coupons.</font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="">
                <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Partage, vente, échange ou achat de&nbsp;:</font>
                  </font>
                </p>
                <ul id="l35">
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Futurs documents d'examen ou feuilles de réponses.</font>
                      </font>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Manipulation de paris (par exemple match truqué).</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Manipulation d'appareils de mesure tels que des compteurs d'électricité ou d'eau afin de contourner leur utilisation autorisée ou légale.</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
              </font>
            </p>
            <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
              </font>
            </p>
            <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Contenu qui s'engage dans, promeut, encourage, facilite ou admet les activités suivantes&nbsp;:</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Corruption.</font>
              </font>
            </p>
          </li>
          <li data-list-text="">
            <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
              <font style={{verticalAlign: 'inherit'}}>
                <font style={{verticalAlign: 'inherit'}}>Détournement de fonds.</font>
              </font>
            </p>
          </li>
        </ul>
        <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>In certain cases, we will allow content that may otherwise violate the Community Standards when it is determined that the content is satirical. Content will only be allowed if the violating elements of the content are being satirized or attributed to something or someone else in order to mock or criticize them.</p>
        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
        <ol id="l36">
          <ol id="l37">
            <li data-list-text="11.B">
              <p style={{paddingLeft: '70pt', textIndent: '-29pt', textAlign: 'left'}}>SAFETY</p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Suicide and Self-Injury</h3>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We care deeply about the safety of the people who use our apps. We regularly consult with experts in suicide and self-injury to help inform our policies and enforcement, and work with organizations around the world to provide assistance to people in distress.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>While we do not allow people to intentionally or unintentionally celebrate or promote suicide or self-injury, we do allow people to discuss these topics because we want MyOpinion to be a space where people can share their experiences, raise awareness about these issues, and seek support from one another.</p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We define self-injury as the intentional and direct injuring of the body, including self-mutilation and eating disorders. We remove any content that encourages suicide or self-injury, including fictional content such as memes or illustrations and any self-injury content which is graphic, regardless of context. We also remove content that identifies and negatively targets victims or survivors of suicide or self-injury seriously, humorously or rhetorically, as well as real time depictions of suicide or self-injury. Content about recovery of suicide or self-harm that is allowed, but may contain imagery that could be upsetting, such as a healed scar, is placed behind a sensitivity screen.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>When people post or search for suicide or self-injury- related content, we will direct them to local organizations that can provide support and if our Community Operations team is concerned about immediate harm we will contact local emergency services to get them help.</p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>With respect to live content, experts have told us that if someone is saying they intend to attempt suicide on a livestream, we should leave the content up for as long as possible, because the longer someone is talking to a camera, the more opportunity there is for a friend or family member to call emergency services.</p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>However, to minimize the risk of others being negatively impacted by viewing this content, we will stop the livestream at the point at which the threat turns into an attempt. As mentioned above, in any case, we will contact emergency services if we identify someone is at immediate risk of harming themselves.</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>for</p>
              <p style={{paddingTop: '3pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
              <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>Content that promotes, encourages, coordinates, or provides instructions</p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <ul id="l38">
                <li data-list-text="">
                  <p style={{paddingLeft: '18pt', textIndent: '-18pt', textAlign: 'left'}}>Suicide.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '-18pt', textAlign: 'left'}}>Self-injury.</p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '-18pt', textAlign: 'left'}}>Eating disorders.</p>
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>Content that depicts graphic self-injury imagery</p>
                  <p style={{paddingTop: '9pt', paddingLeft: '18pt', textIndent: '0pt', textAlign: 'left'}}>It is against our policies to post content depicting a person who engaged in</p>
                  <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>a suicide attempt or death by suicide</p>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that focuses on depiction of ribs, collar bones, thigh gaps, hips, concave stomach, or protruding spine or scapula when shared together with terms associated with eating disorders</p>
                  <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that contains instructions for drastic and unhealthy weight loss when shared together with terms associated with eating disorders.</p>
                  <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that mocks victims or survivors of suicide, self-injury or eating disorders who are either publicly known or implied to have experienced suicide or self-injury</p>
                  <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we restrict content to adults over the age of 18, and include a sensitivity screen so that people are aware the content may be upsetting:</p>
                  <ul id="l39">
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Photos or videos depicting a person's death by suicide or suicide attempt that are determined to be newsworthy.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Photos or videos depicting a person who engaged in euthanasia/assisted suicide in a medical setting.</p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following content, we include a sensitivity screen so that people are aware the content may be upsetting to some:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that depicts older instances of self-harm such as healed cuts or other non-graphic self-injury imagery in a self-injury, suicide or recovery context.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Content that depicts ribs, collar bones, thigh gaps, hips, concave stomach, or protruding spine or scapula in a recovery context.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>We provide resources to people who post written or verbal admissions of engagement in self injury, including:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Suicide.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Euthanasia/assisted suicide.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Self-harm.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Eating disorders.</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>Vague, potentially suicidal statements or references (including memes or stock imagery about sad mood or depression) in a suicide or self-injury context.</p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>For the following Community Standards, we require additional information and/or context to enforce:</p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous pouvons supprimer les notes de suicide lorsque nous avons la confirmation d'un suicide ou d'une tentative de suicide. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous essayons d'identifier les notes de suicide en utilisant plusieurs facteurs, y compris, mais sans s'y limiter, les demandes de la famille ou du représentant légal, les rapports des médias, les rapports des forces de l'ordre ou d'autres sources tierces (par exemple, les agences gouvernementales, les ONG).</font>
                        </font>
                      </p>
                      <ul id="l40">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Une note de suicide peut également être supprimée lorsqu'elle est signalée via le bouton Signaler de chaque opinion.</font>
                            </font>
                          </p>
                          <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                          <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Exploitation sexuelle des enfants, abus et nudité</font>
                            </font>
                          </h3>
                          <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas les contenus qui exploitent sexuellement ou mettent en danger les enfants.</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Lorsque nous prenons connaissance d'une apparente exploitation d'enfants, nous la signalons au National Center for Missing and Exploited Children (NCMEC), conformément à la loi applicable. </font>
                              <font style={{verticalAlign: 'inherit'}}>Nous savons que parfois les gens partagent des images nues de leurs propres enfants avec de bonnes intentions ; </font>
                              <font style={{verticalAlign: 'inherit'}}>cependant, nous supprimons généralement ces images en raison du potentiel d'abus par d'autres et pour aider à éviter la possibilité que d'autres personnes réutilisent ou détournent les images.</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Nous travaillons également avec des experts externes pour discuter et améliorer nos politiques et leur application concernant les problèmes de sécurité en ligne, en particulier en ce qui concerne les enfants.</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                            </font>
                          </p>
                          <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Exploitation sexuelle des enfants</font>
                            </font>
                          </h3>
                          <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Contenu qui menace, représente, loue, soutient, fournit des instructions, fait des déclarations d'intention, admet la participation ou partage des liens d'exploitation sexuelle d'enfants (mineurs réels ou non réels, tout-petits ou bébés), y compris, mais sans s'y limiter&nbsp;:</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Rapports sexuels</font>
                        </font>
                      </p>
                      <ul id="l41">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Rapport sexuel explicite ou sexe oral, défini comme la bouche ou les organes génitaux entrant ou en contact avec les organes génitaux ou l'anus d'une autre personne, où au moins les organes génitaux d'une personne sont nus.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Rapports sexuels ou rapports sexuels oraux implicites, y compris lorsque le contact est imminent ou non directement visible.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Stimulation des organes génitaux ou de l'anus, y compris lorsque l'activité est imminente ou non directement visible.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Présence de sous-produits de l'activité sexuelle.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Tout ce qui précède impliquant un animal.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Enfants présentant des éléments sexuels, y compris, mais sans s'y limiter&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l42">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Contraintes.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Concentrez-vous sur les organes génitaux.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Présence d'un adulte éveillé.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Présence de jouets sexuels.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Costume sexualisé.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Décapage.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Environnement mis en scène (par exemple, sur un lit) ou prise de vue professionnelle (qualité/mise au point/angles).</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Baiser à pleine bouche.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu des enfants dans un contexte de fétichisme sexuel.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui soutient, promeut, préconise ou encourage la participation à la pédophilie à moins qu'il ne soit discuté de manière neutre dans un contexte de santé académique ou vérifié.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui identifie ou se moque des victimes présumées d'exploitation sexuelle d'enfants par leur nom ou leur image.</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Sollicitation</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui sollicite</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Matériel d'exploitation sexuelle d'enfants (CSAM)</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Images nues d'enfants</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Images sexualisées d'enfants</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Rencontres sexuelles réelles avec des enfants</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Interactions inappropriées avec les enfants</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui constitue ou facilite des interactions inappropriées avec des enfants, tels que&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Organiser ou planifier des rencontres sexuelles réelles avec des enfants</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Exposer délibérément des enfants à un langage ou à du matériel sexuel explicite</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>S'engager dans des conversations implicitement sexuelles dans des messages privés avec des enfants</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Obtenir ou demander du matériel sexuel d'enfants dans des messages privés</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Imagerie intime exploitante et sextorsion</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui tente d'exploiter des mineurs en&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contrainte d'obtenir de l'argent, des faveurs ou des images intimes avec des menaces d'exposer des images ou des informations intimes.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Partager, menacer ou déclarer l'intention de partager des conversations sexuelles privées ou des images intimes.</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Sexualisation des enfants</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui sollicite des images d'exploitation sexuelle d'enfants, ou des images ou des vidéos nues ou sexualisées d'enfants</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu (y compris des photos, des vidéos, de l'art du monde réel, du contenu numérique et des représentations verbales) qui montre des enfants dans un contexte sexualisé.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Groupes, pages et profils destinés à sexualiser les enfants.</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nudité enfantine</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui représente la nudité des enfants où la nudité est définie comme&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Gros plans sur les organes génitaux des enfants</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>De vrais tout-petits nus, montrant&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l43">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Organes génitaux visibles, même couverts ou masqués par des vêtements transparents.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Anus visible et/ou gros plan des fesses entièrement dénudé.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>De vrais mineurs nus, montrant&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l44">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Organes génitaux visibles (y compris les organes génitaux masqués uniquement par des poils pubiens ou des vêtements transparents)</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Anus visible et/ou gros plan des fesses entièrement dénudé.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Mamelons féminins découverts.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Pas de vêtements du cou au genou - même si aucun organe génital ou mamelon féminin n'est visible.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Représentations créées numériquement de mineurs, de tout-petits ou de bébés nus, sauf si l'image est à des fins sanitaires ou éducatives.</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Maltraitance non sexuelle des enfants</font>
                        </font>
                      </h3>
                      <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '22pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Images illustrant la maltraitance non sexuelle d'enfants, quelle que soit l'intention de partage Contenu qui loue, soutient, promeut, défend, fournit</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '1pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>des instructions ou encourage la participation à des abus non sexuels sur des enfants.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous incluons un écran d'avertissement afin que les gens soient conscients que le contenu peut être dérangeant et limiter la possibilité de voir le contenu aux adultes, âgés de dix-huit ans et plus&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Vidéos ou photos montrant des policiers ou des militaires commettant des abus non sexuels sur des enfants.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Images d'abus non sexuels d'enfants, lorsque les forces de l'ordre, les agences de protection de l'enfance ou des partenaires de sécurité de confiance nous demandent de laisser le contenu sur la plate-forme dans le but exprès de ramener un enfant en sécurité.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous incluons un écran de sensibilité afin que les gens sachent que le contenu peut déranger certains&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Vidéos ou photos d'immersion violente d'un enfant dans l'eau dans le cadre de rituels religieux.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous incluons une étiquette d'avertissement afin que les gens sachent que le contenu peut être sensible&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Image publiée par une agence de presse qui représente des enfants nus dans un contexte de famine, de génocide, de crimes de guerre ou de crimes contre l'humanité, sauf si elle est accompagnée d'une légende non conforme ou partagée dans un contexte non conforme, auquel cas le contenu est supprimé.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous pouvons également supprimer les images illustrant les conséquences d'abus non sexuels d'enfants lorsqu'elles sont signalées par des partenaires médiatiques, des ONG ou d'autres partenaires de confiance en matière de sécurité.</font>
                        </font>
                      </p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Exploitation sexuelle des adultes</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous reconnaissons l'importance de MyOpinion en tant que lieu de discussion et d'attention sur la violence et l'exploitation sexuelles. </font>
                          <font style={{verticalAlign: 'inherit'}}>Afin de créer un espace pour cette conversation et de promouvoir un environnement sûr, nous permettons aux victimes de partager leurs expériences, mais supprimons le contenu qui représente, menace ou encourage la violence sexuelle, l'agression sexuelle ou l'exploitation sexuelle. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimons également le contenu qui affiche, préconise ou coordonne des actes sexuels avec des parties non consentantes afin d'éviter de faciliter des actes sexuels non consensuels.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour protéger les victimes et les survivants, nous supprimons les images représentant des incidents de violence sexuelle et les images intimes partagées sans le consentement de la ou des personnes photographiées. </font>
                          <font style={{verticalAlign: 'inherit'}}>Comme indiqué dans l'introduction, nous travaillons également avec des experts externes en sécurité pour discuter et améliorer nos politiques et leur application concernant les problèmes de sécurité en ligne, et nous pouvons supprimer du contenu lorsqu'ils fournissent des informations indiquant que le contenu est lié à une activité nuisible.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Dans les cas où le contenu consiste en toute forme d'attouchements sexuels non consensuels, de nécrophilie ou de déshabillage forcé, y compris&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Représentations (y compris de vraies photos/vidéos, sauf dans un contexte artistique réel), ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Partager, offrir, demander ou menacer de partager des images, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Les descriptions, à moins qu'elles ne soient partagées par ou en soutien à la victime/survivante, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Plaidoyer (y compris les déclarations ambitieuses et conditionnelles), ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Déclarations d'intention, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Appels à l'action, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Admettre la participation, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Se moquer des victimes de l'un des éléments ci-dessus.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui tente d'exploiter des personnes par l'un des moyens suivants&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Sextorsion : Obtention d'argent, de faveurs ou d'images intimes de la part de personnes menacées pour exposer leurs images ou informations intimes</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Partager, menacer, déclarer une intention de partager, offrir ou demander des images intimes non consensuelles qui remplissent les 3 conditions suivantes&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l45">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Les images sont non commerciales ou produites dans un cadre privé.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>La personne dans les images est (presque) nue, engagée dans une activité sexuelle ou dans une pose sexuelle.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>L'absence de consentement au partage des images est indiquée par la rencontre de l'un des signaux&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l46">
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contexte vengeur (comme une légende, des commentaires ou un titre de page).</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Sources indépendantes (telles que les dossiers des forces de l'ordre), y compris les médias de divertissement (tels que les fuites d'images confirmées par les médias).</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Une correspondance visible entre la personne représentée dans l'image et la personne qui nous a signalé le contenu.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>La personne qui nous a signalé le contenu partage le même nom que la personne représentée sur l'image.</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Images non commerciales prises secrètement de parties du corps couramment sexualisées d'une personne réelle (seins, aine, fesses ou cuisses) ou d'une personne réelle se livrant à une activité sexuelle. </font>
                          <font style={{verticalAlign: 'inherit'}}>Ces images sont communément appelées "creepshots" ou "upskirts" et comprennent des photos ou des vidéos qui se moquent, sexualisent ou exposent la personne représentée dans les images.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Menacer ou déclarer son intention de partager des conversations sexuelles privées qui répondent aux critères suivants&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l47">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>L'absence de consentement est indiquée par&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l48">
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contexte vengeur et/ou contexte menaçant, ou</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Une correspondance visible entre la personne représentée dans l'image et la personne qui nous a signalé le contenu.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>La personne qui nous a signalé le contenu partage le même nom que la personne représentée sur l'image.</font>
                                </font>
                              </p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous incluons un écran d'avertissement afin que les gens sachent que le contenu peut être dérangeant&nbsp;:</font>
                                </font>
                              </p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Récits et déclarations contenant une description d'attouchements sexuels non consensuels (écrits ou verbaux) comprenant des détails allant au-delà de la simple dénomination ou mention de l'acte si&nbsp;:</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Partagé par la victime, ou</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Partagé par un tiers (autre que la victime) pour soutenir la victime ou condamner l'acte ou pour une prise de conscience générale à déterminer par le contexte/la légende.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu se moquant du concept d'attouchements sexuels non consensuels</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous pouvons limiter la visibilité aux personnes de plus de 18&nbsp;ans et inclure une étiquette d'avertissement sur certaines vidéos fictives (par exemple, des images numériques et dessinées à la main, des scènes de films et d'émissions de télévision, des bandes dessinées, des clips vidéo) qui dépeignent des attouchements sexuels non consensuels.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>En plus de notre politique à grande échelle de suppression du contenu qui menace ou prône le viol ou d'autres attouchements sexuels non consensuels, nous pouvons également désactiver le compte de publication.</font>
                        </font>
                      </p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Intimidation et harcèlement</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>L'intimidation et le harcèlement se produisent dans de nombreux endroits et prennent de nombreuses formes différentes, allant des menaces et de la divulgation d'informations personnellement identifiables à l'envoi de messages menaçants et aux contacts malveillants non désirés. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous ne tolérons pas ce genre de comportement car cela empêche les gens de se sentir en sécurité et respectés sur MyOpinion.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous distinguons les personnalités publiques des personnes privées parce que nous voulons permettre la discussion, qui comprend souvent des commentaires critiques de personnes qui font l'actualité ou qui ont un large public. </font>
                          <font style={{verticalAlign: 'inherit'}}>Pour les personnalités publiques, nous supprimons les attaques graves ainsi que certaines attaques où la personnalité publique est directement taguée dans la publication ou le commentaire.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour les particuliers, notre protection va plus loin&nbsp;: nous supprimons les contenus destinés à dégrader ou à faire honte, y compris, par exemple, les allégations concernant l'activité sexuelle personnelle d'une personne. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous reconnaissons que l'intimidation et le harcèlement peuvent avoir un impact émotionnel plus important sur les mineurs, c'est pourquoi nos politiques offrent une protection accrue aux utilisateurs âgés de 13 à 18 ans.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Le contexte et l'intention sont importants, et nous autorisons les gens à publier et à partager s'il est clair que quelque chose a été partagé afin de condamner ou d'attirer l'attention sur</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>intimidation et harcèlement. </font>
                          <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous exigeons l'auto-déclaration, car cela nous aide à comprendre que la personne ciblée se sent victime d'intimidation ou de harcèlement. </font>
                          <font style={{verticalAlign: 'inherit'}}>En plus de signaler de tels comportements et contenus, nous encourageons les gens à utiliser tous les outils légaux pour s'en protéger.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Ne pas:</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Niveau&nbsp;1&nbsp;: Cibler quelqu'un de manière malveillante en&nbsp;:</font>
                        </font>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contacter quelqu'un à plusieurs reprises d'une manière qui est&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l49">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Indésirable, ou</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Harcèlement sexuel, ou</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Destiné à un grand nombre de personnes sans sollicitation préalable.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Attaquer quelqu'un en raison de son statut de victime d'agression sexuelle, d'exploitation sexuelle, de harcèlement sexuel ou de violence domestique.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Appel à l'automutilation ou au suicide d'une personne spécifique ou d'un groupe de personnes.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Attaquer quelqu'un par des termes péjoratifs liés à l'activité sexuelle (par exemple : putain, salope).</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publier du contenu sur une tragédie violente ou sur des victimes de tragédies violentes qui incluent des affirmations selon lesquelles une tragédie violente ne s'est pas produite.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publier du contenu sur les victimes ou les survivants de tragédies violentes ou d'attentats terroristes par leur nom ou par leur image, en prétendant qu'ils sont&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l50">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Agir/faire semblant d'être victime d'un événement.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Autrement payé ou employé pour tromper les gens sur leur rôle dans l'événement.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Menacer de divulguer le numéro de téléphone privé, l'adresse résidentielle ou l'adresse e-mail d'un individu.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Faire des déclarations d'intention de se livrer à une activité sexuelle ou les inciter à se livrer à une activité sexuelle.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Faire des commentaires sexualisés sévères</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Partager des images ou des dessins photoshoppés sexualisés désobligeants</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Appeler ou faire des déclarations d'intention de se livrer à l'intimidation et/ou au harcèlement.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publier du contenu qui dégrade davantage ou exprime du dégoût envers les personnes qui sont représentées pendant ou juste après avoir leurs règles, uriner, vomir ou déféquer</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Créer des pages ou des groupes dédiés à attaquer des individus en&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l51">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Appeler à la mort, ou contracter ou développer une condition médicale.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Faire des déclarations d'intention de préconiser de s'engager dans une activité sexuelle.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Affirmer que la personne a ou pourrait avoir une maladie sexuellement transmissible.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Sexualiser un autre adulte.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Envoi de messages contenant les attaques suivantes lorsqu'ils sont dirigés contre un individu ou un groupe d'individus dans le fil&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l52">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Attaques référencées dans les niveaux 1, 2 et 4 de cette politique.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Injures ciblées.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Appels à la mort, à une maladie grave, à un handicap, à une maladie épidémique ou à un préjudice physique.</font>
                            </font>
                          </p>
                          <h3 style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Niveau 2 : Ciblez des particuliers, des personnalités publiques à portée limitée (par exemple, des personnes dont la notoriété principale se limite à leur activisme, leur journalisme ou celles qui sont devenues célèbres par des moyens involontaires) ou des personnalités publiques mineures ayant&nbsp;:</font>
                            </font>
                          </h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Appels à la mort, ou à contracter ou développer une condition médicale.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Termes de malédiction de sexe féminin lorsqu'ils sont utilisés de manière péjorative.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Allégations concernant l'activité sexuelle ou les maladies sexuellement transmissibles, sauf dans le contexte d'allégations criminelles contre des adultes concernant des attouchements sexuels non consensuels.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pages ou groupes créés pour attaquer via&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l53">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Injures ciblées.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Descriptions physiques négatives.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Revendications d'identité religieuse ou de blasphème.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Expressions de mépris ou de dégoût.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Termes de malédiction de sexe féminin lorsqu'ils sont utilisés de manière péjorative.</font>
                            </font>
                          </p>
                          <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Niveau 3 : Cibler les personnalités publiques en les exposant délibérément à :</font>
                            </font>
                          </h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour les adultes et les mineurs :</font>
                        </font>
                      </p>
                      <ul id="l54">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Appels à la mort, ou à contracter ou développer une condition médicale.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Allégations sur les maladies sexuellement transmissibles</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Termes de malédiction de sexe féminin lorsqu'ils sont utilisés de manière péjorative.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Contenu qui loue, célèbre ou se moque de leur mort ou de leur état de santé.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Attaques à travers des descriptions physiques négatives.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour les mineurs :</font>
                        </font>
                      </p>
                      <ul id="l55">
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Comparaisons avec des animaux ou des insectes culturellement perçus comme intellectuellement ou physiquement inférieurs ou avec un objet inanimé (« vache », « singe », « pomme de terre »).</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Contenu manipulé pour mettre en évidence, encercler ou attirer l'attention de manière négative sur des caractéristiques physiques spécifiques (nez, oreille, etc.).</font>
                            </font>
                          </p>
                          <h3 style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Niveau 4&nbsp;: Ciblez des particuliers ou des personnalités publiques à portée limitée (par exemple, des personnes dont la notoriété principale se limite à leur activisme, leur journalisme ou celles qui sont devenues célèbres par des moyens involontaires) avec&nbsp;:</font>
                            </font>
                          </h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Comparaisons avec des animaux ou des insectes culturellement perçus comme intellectuellement ou physiquement inférieurs ou avec un objet inanimé (« vache », « singe », « pomme de terre »).</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu manipulé pour mettre en évidence, encercler ou attirer l'attention de manière négative sur des caractéristiques physiques spécifiques (nez, oreille, etc.).</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Attaques à travers des descriptions physiques négatives.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui classe les individus en fonction de leur apparence physique ou de leur personnalité.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu sexualisant un autre adulte.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui dégrade davantage les personnes qui sont décrites comme étant victimes d'intimidation physique, sauf dans des contextes d'autodéfense et de sport de combat.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui loue, célèbre ou se moque de leur mort ou de blessures physiques graves.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>En plus de ce qui précède, les attaques via des Pages ou des Groupes&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l56">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Revendications négatives de caractère ou de capacité.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Intimidation à la première personne uniquement si l'objet cible plusieurs personnes.</font>
                            </font>
                          </p>
                          <h3 style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Niveau 5 : Cibler les adultes privés (qui doivent se déclarer eux-mêmes) ou tout mineur privé ou personnalité publique mineure involontaire avec :</font>
                            </font>
                          </h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Injures ciblées.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Allégations sur les relations amoureuses, l'orientation sexuelle ou l'identité de genre.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Coordination, plaidoyer ou promotion de l'exclusion.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Revendications négatives de caractère ou de capacité, sauf dans le contexte d'allégations criminelles et d'avis commerciaux contre des adultes. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous autorisons les allégations criminelles afin que les gens puissent attirer l'attention sur des expériences personnelles ou des événements hors ligne. </font>
                          <font style={{verticalAlign: 'inherit'}}>Toutefois, dans les cas où des allégations criminelles causent un préjudice hors ligne à la personne nommée, nous pouvons les supprimer.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Expressions de mépris ou de dégoût, sauf dans le cadre d'allégations criminelles contre des adultes.</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Niveau 6 : Cibler les particuliers mineurs avec :</font>
                        </font>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Allégations de comportement criminel ou illégal.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Vidéos de harcèlement physique partagées dans un contexte non condamnant.</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Niveau 7 : Cibler les particuliers (qui doivent se déclarer eux-mêmes) avec :</font>
                        </font>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Intimidation vocale à la première personne.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Images manipulées indésirables.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Comparaison avec d'autres personnes publiques, fictives ou privées sur la base de l'apparence physique</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Revendications d'identité religieuse ou de blasphème.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Les comparaisons avec des animaux ou des insectes qui ne sont pas culturellement perçus comme</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>intellectuellement ou physiquement inférieur (« tigre », « lion »).</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Descriptions physiques neutres ou positives.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Revendications de caractère ou de capacité non négatives.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Toute violation d'intimidation ou de harcèlement, lorsqu'elle est partagée dans un contexte attachant.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-17pt', lineHeight: '168%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Attaques par des termes péjoratifs liés à un manque d'activité sexuelle. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous ajoutons une couverture à ce contenu afin que les utilisateurs puissent choisir de le voir&nbsp;:</font>
                        </font>
                      </p>
                      <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '13pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Des vidéos de harcèlement physique envers des mineurs partagées dans un contexte condamnant</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Ne pas:</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publiez du contenu qui cible les particuliers via des pages, des groupes et des événements indésirables. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimons ce contenu lorsqu'il est signalé par la victime ou un représentant autorisé de la victime.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Créez des comptes pour contacter quelqu'un qui vous a bloqué.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publiez des attaques qui utilisent des termes péjoratifs liés aux jurons de genre féminin. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimons ce contenu lorsque la victime ou un représentant autorisé de la victime nous informe du contenu, même si la victime ne l'a pas signalé directement.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publier du contenu qui obligerait autrement la victime à signaler le contenu ou un indicateur que l'affiche vise directement la victime (par exemple&nbsp;: la victime est taguée dans la publication ou le commentaire). </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimerons ce contenu si nous avons la confirmation de la victime ou d'un représentant autorisé de la victime que le contenu est indésirable.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publiez du contenu faisant l'éloge, célébrant ou se moquant de la mort de quelqu'un. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimons également le contenu ciblant une personne décédée que nous aurions normalement demandé à la victime de signaler.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publier du contenu appelant ou indiquant une intention d'adopter un comportement qui serait qualifié d'intimidation et de harcèlement au sens de nos politiques. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimerons ce contenu lorsque nous aurons la confirmation de la victime ou d'un représentant autorisé de la victime que le contenu est indésirable.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Publier du contenu sexualisant une personnalité publique. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimerons ce contenu lorsque nous aurons la confirmation de la victime ou d'un représentant autorisé de la victime que le contenu est indésirable.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contacter quelqu'un à plusieurs reprises pour le harceler sexuellement. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimerons ce contenu lorsque nous aurons la confirmation de la victime ou d'un représentant autorisé de la victime que le contenu est indésirable.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Se livrer à un harcèlement de masse contre des individus qui les cible en fonction de leur décision de prendre ou non le vaccin COVID-19 avec&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l57">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Déclarations d'infériorité mentale ou morale fondées sur leur décision, ou</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Déclarations qui préconisent ou allèguent un résultat négatif à la suite de leur décision, à l'exception des symptômes de la COVID-19 largement prouvés et/ou acceptés ou des effets secondaires du vaccin.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Supprimer le harcèlement de masse dirigé, lorsque&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l58">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Ciblant, via n'importe quelle surface, les "individus présentant un risque accru de préjudice hors ligne", définis comme&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l59">
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Défenseurs des droits de l'homme</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Mineurs</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Victimes d'événements violents/drames</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Chiffres de l'opposition dans les pays à risque en période électorale</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Les dissidents du gouvernement qui ont été ciblés en raison de leur statut de dissident</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Minorités ethniques et religieuses dans les zones de conflit</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Membre d'un groupe à risque désigné et reconnaissable</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Cibler tout individu via des surfaces personnelles, telles que la boîte de réception ou les profils, avec&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l60">
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contenu qui enfreint les politiques d'intimidation et de harcèlement pour les particuliers ou,</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contenu répréhensible basé sur une caractéristique protégée</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Désactiver les comptes engagés dans le harcèlement de masse dans le cadre de l'une ou l'autre</font>
                        </font>
                      </p>
                      <ul id="l61">
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Réseaux d'État ou affiliés à l'État ciblant tout individu via n'importe quelle surface.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Des réseaux antagonistes ciblant n'importe quel individu via n'importe quelle surface avec&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l62">
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contenu qui enfreint les politiques d'intimidation et de harcèlement pour les particuliers ou,</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contenu qui les cible en fonction d'une caractéristique protégée, ou,</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contenu ou comportement autrement jugé répréhensible dans le contexte local</font>
                                </font>
                              </p>
                              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Exploitation humaine</font>
                                </font>
                              </h3>
                              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Après avoir consulté des experts externes du monde entier, nous consolidons plusieurs politiques d'exploitation existantes qui étaient auparavant hébergées dans différentes sections des normes communautaires dans une section dédiée qui se concentre sur l'exploitation humaine et capture un large éventail d'activités nuisibles qui peuvent se manifester sur notre Plate-forme. </font>
                                  <font style={{verticalAlign: 'inherit'}}>Les experts pensent et parlent de ces questions sous un même parapluie, l'exploitation humaine.</font>
                                </font>
                              </p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Afin de perturber et de prévenir les dommages, nous supprimons le contenu qui facilite ou coordonne l'exploitation des êtres humains, y compris la traite des êtres humains. </font>
                                  <font style={{verticalAlign: 'inherit'}}>Nous définissons la traite des êtres humains comme le fait de priver quelqu'un de liberté à des fins lucratives. </font>
                                  <font style={{verticalAlign: 'inherit'}}>C'est l'exploitation des êtres humains dans le but de les forcer à s'engager dans le commerce du sexe, le travail ou d'autres activités contre leur volonté. </font>
                                  <font style={{verticalAlign: 'inherit'}}>Elle s'appuie sur la tromperie, la force et la coercition, et dégrade les humains en les privant de leur liberté tout en profitant économiquement ou matériellement aux autres.</font>
                                </font>
                              </p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>La traite des êtres humains est multiforme et mondiale; </font>
                                  <font style={{verticalAlign: 'inherit'}}>elle peut affecter n'importe qui, quel que soit son âge, son milieu socio-économique, son origine ethnique, son sexe ou son lieu de résidence. </font>
                                  <font style={{verticalAlign: 'inherit'}}>Elle prend de nombreuses formes, et toute situation de traite donnée peut impliquer différents stades de développement. </font>
                                  <font style={{verticalAlign: 'inherit'}}>De par la nature coercitive de cet abus, les victimes ne peuvent pas consentir.</font>
                                </font>
                              </p>
                              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Bien que nous devions veiller à ne pas confondre la traite des êtres humains et le passage de clandestins, ils peuvent être liés et se chevaucher. </font>
                                  <font style={{verticalAlign: 'inherit'}}>Les Nations Unies définissent le passage de clandestins comme l'obtention ou la facilitation de l'entrée illégale dans un État à travers les frontières internationales. </font>
                                  <font style={{verticalAlign: 'inherit'}}>Sans qu'il soit nécessaire de recourir à la coercition ou à la force, cela peut néanmoins entraîner l'exploitation d'individus vulnérables qui tentent de quitter leur pays d'origine, souvent à la recherche d'une vie meilleure. </font>
                                  <font style={{verticalAlign: 'inherit'}}>Le trafic d'êtres humains est un crime contre un État, reposant sur le mouvement, et la traite des êtres humains est un crime contre une personne, reposant sur l'exploitation.</font>
                                </font>
                              </p>
                              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                                </font>
                              </p>
                              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Contenu ou comportement affiché pouvant conduire à l'exploitation humaine, qui comprend l'un des éléments suivants&nbsp;:</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Trafic sexuel, couvrant les mineurs et les adultes.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Ventes d'enfants ou adoption illégale.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Trafic d'orphelinats et volontariat d'orphelinats.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Mariages forcés.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Exploitation du travail (y compris le travail servile).</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Servitude domestique.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Trafic d'organes non régénératif.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Activité criminelle forcée (par exemple mendicité forcée, trafic forcé de drogue).</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-17pt', lineHeight: '168%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Recrutement d'enfants soldats. </font>
                          <font style={{verticalAlign: 'inherit'}}>Contenu orienté vers :</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Recrutement de victimes potentielles par la force, la fraude, la coercition, la séduction, la tromperie, le chantage ou d'autres actes non consensuels.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Facilitation de l'exploitation humaine par la coordination, le transport, le transfert, l'hébergement ou le courtage de victimes avant ou pendant l'exploitation.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-17pt', lineHeight: '168%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Exploitation des êtres humains par la promotion, la représentation ou la défense de celle-ci. </font>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui propose de fournir ou de faciliter le trafic d'êtres humains</font>
                        </font>
                      </p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Violation de la vie privée</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>La confidentialité et la protection des informations personnelles sont des valeurs fondamentales pour MyOpinion. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous travaillons dur pour protéger votre identité et vos informations personnelles et nous n'autorisons pas les gens à publier des informations personnelles ou confidentielles sur vous-même ou sur d'autres.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous supprimons le contenu qui partage, offre ou sollicite des informations personnellement identifiables ou d'autres informations privées qui pourraient entraîner un préjudice physique ou financier, y compris des informations financières, résidentielles et médicales, ainsi que des informations privées obtenues de sources illégales. </font>
                          <font style={{verticalAlign: 'inherit'}}>Nous reconnaissons également que des informations privées peuvent être rendues publiques par le biais de reportages, de dossiers judiciaires, de communiqués de presse ou d'autres sources. </font>
                          <font style={{verticalAlign: 'inherit'}}>Lorsque cela se produit, nous pouvons autoriser la publication des informations.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous fournissons également aux utilisateurs des moyens de signaler les images qu'ils pensent être en violation de leurs droits à la vie privée.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui partage ou sollicite l'une des informations privées suivantes, soit sur MyOpinion, soit via des liens externes&nbsp;:</font>
                        </font>
                      </p>
                      <h3 style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Informations personnellement identifiables sur vous-même ou sur d'autres personnes</font>
                        </font>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Identité personnelle&nbsp;: identification des personnes par le biais de numéros émis par le gouvernement.</font>
                        </font>
                      </p>
                      <ul id="l63">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Numéro d'identification national (par exemple numéro de sécurité sociale (SSN), numéro de passeport, numéro d'assurance nationale/de service de santé, numéro personnel de service public (PPS), numéro d'identification fiscale individuel (ITIN)).</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Pièces d'identité gouvernementales des forces de l'ordre, de l'armée ou du personnel de sécurité.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Informations personnelles&nbsp;: identification directe d'un individu, en indiquant le numéro d'identification ou les informations d'enregistrement et le nom de l'individu.</font>
                        </font>
                      </p>
                      <ul id="l64">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Actes ou documentation officielle des informations de l'état civil (mariage, naissance, décès, changement de nom ou reconnaissance du sexe, etc.).</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Documents d'immigration et de travail (par exemple, cartes vertes, permis de travail, visas ou documents d'immigration).</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Permis de conduire ou plaques d'immatriculation, sauf lorsque les plaques d'immatriculation sont</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '1pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'center'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>partagées pour faciliter la recherche de personnes ou d'animaux disparus.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Numéro de confidentialité du crédit (CPN).</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Identité numérique : authentifier l'accès à une identité en ligne</font>
                        </font>
                      </p>
                      <ul id="l65">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Adresses e-mail avec mots de passe.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Identités numériques avec mots de passe.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Mots de passe, broches ou codes pour accéder à des informations privées.</font>
                            </font>
                          </p>
                          <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Autres informations privées</font>
                            </font>
                          </h3>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Les coordonnées personnelles d'autrui telles que les numéros de téléphone, les adresses et les adresses e-mail, sauf lorsqu'elles sont partagées ou sollicitées pour promouvoir des causes caritatives, retrouver des personnes, des animaux ou des objets disparus, ou contacter des prestataires de services aux entreprises.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>L'information financière.</font>
                        </font>
                      </p>
                      <ul id="l66">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Informations financières personnelles sur vous-même ou sur d'autres, y compris&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l67">
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Documents ou états financiers non publics.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Numéros de compte bancaire avec codes de sécurité ou codes PIN.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Informations sur le mode de paiement numérique avec informations de connexion, codes de sécurité ou codes PIN.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Informations de carte de crédit ou de débit avec dates de validité ou codes PIN ou codes de sécurité.</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Informations financières sur les entreprises ou les organisations, à moins qu'elles ne soient initialement partagées par l'organisation elle-même, y compris&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l68">
                            <li data-list-text="">
                              <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Documents ou états financiers, sauf lorsque les documents financiers de l'entreprise sont accessibles au public (par exemple, cotés en bourse ou par des organismes de réglementation, etc.)</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Numéros de compte bancaire accompagnés de codes de sécurité ou de code PIN.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Informations sur le mode de paiement numérique accompagnées des informations de connexion, des codes de sécurité ou des codes PIN.</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Informations résidentielles</font>
                        </font>
                      </p>
                      <ul id="l69">
                        <li data-list-text="">
                          <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Imagerie qui affiche la vue extérieure des résidences privées si toutes les conditions suivantes s'appliquent&nbsp;:</font>
                            </font>
                          </p>
                          <ul id="l70">
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>La résidence est une maison unifamiliale ou le numéro d'unité du résident est identifié dans l'image/la légende.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>La ville/le quartier ou la broche GPS (par exemple, une broche de Google Maps) sont identifiées.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Le contenu identifie le(s) résident(s).</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Ce même résident s'oppose à l'exposition de sa résidence privée, ou il existe un contexte d'organisation de manifestations contre le résident (cela n'inclut pas les ambassades qui servent également de résidences).</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Contenu qui expose des informations sur les refuges en partageant l'un des éléments ci-dessous, à moins que le refuge ne fasse activement la promotion d'informations sur son installation</font>
                            </font>
                          </p>
                          <ul id="l71">
                            <li data-list-text="">
                              <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Adresse réelle (Remarque&nbsp;: "Boîte postale uniquement" est autorisé.)</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Images de la maison sécurisée.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Ville/quartier identifiable de la maison sécurisée.</font>
                                </font>
                              </p>
                            </li>
                            <li data-list-text="">
                              <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                                <font style={{verticalAlign: 'inherit'}}>
                                  <font style={{verticalAlign: 'inherit'}}>Informations révélant l'identité des résidents de la maison sécurisée.</font>
                                </font>
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Information médicale</font>
                        </font>
                      </p>
                      <ul id="l72">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Dossiers ou documents officiels affichant l'hérédité médicale, psychologique, biométrique ou génétique d'autrui.</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Informations obtenues à partir de sources piratées.</font>
                        </font>
                      </p>
                      <ul id="l73">
                        <li data-list-text="">
                          <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Sauf dans des cas limités d'intérêt pour l'actualité, le contenu revendiqué ou confirmé provenir d'une source piratée, que la personne concernée soit une personnalité publique ou un particulier.</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Le contenu suivant peut également être supprimé&nbsp;:</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Une photo ou une vidéo signalée de personnes où la personne représentée dans l'image est&nbsp;:</font>
                        </font>
                      </p>
                      <ul id="l74">
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Un mineur de moins de 13 ans, et le contenu a été signalé par le mineur ou un parent ou tuteur légal.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Un mineur entre 13 et 18 ans, et le contenu a été signalé par le mineur.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Un adulte, lorsque le contenu a été signalé par l'adulte en dehors des États-Unis et que la loi applicable peut prévoir des droits de suppression.</font>
                            </font>
                          </p>
                        </li>
                        <li data-list-text="">
                          <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Toute personne frappée d'incapacité et incapable de signaler le contenu par elle-même.</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                            </font>
                          </p>
                          <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                            <font style={{verticalAlign: 'inherit'}}>
                              <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                            </font>
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu qui met un transfuge en danger en divulguant à l'individu des informations personnellement identifiables lorsque le contenu est signalé par des canaux gouvernementaux crédibles.</font>
                        </font>
                      </p>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Représentations d'une personne dans un établissement médical ou de santé si elles sont signalées par la personne photographiée ou un représentant autorisé.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous autoriserons le contenu qui pourrait autrement enfreindre les normes de la communauté lorsqu'il est déterminé que le contenu est satirique. </font>
                          <font style={{verticalAlign: 'inherit'}}>Le contenu ne sera autorisé que si les éléments de violation du contenu sont satirisés ou attribués à quelque chose ou à quelqu'un d'autre afin de se moquer d'eux ou de les critiquer.</font>
                        </font>
                      </p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li data-list-text="11.C">
              <p style={{paddingLeft: '70pt', textIndent: '-29pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>CONTENU OBJECTIONNEL</font>
                </font>
              </p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Discours de haine</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous croyons que les gens utilisent leur voix et se connectent plus librement lorsqu'ils ne se sentent pas attaqués sur la base de qui ils sont. </font>
                  <font style={{verticalAlign: 'inherit'}}>C'est pourquoi nous n'autorisons pas les discours de haine sur MyOpinion. </font>
                  <font style={{verticalAlign: 'inherit'}}>Cela crée un environnement d'intimidation et d'exclusion et, dans certains cas, peut favoriser la violence hors ligne.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous définissons le discours de haine comme une attaque directe contre des personnes — plutôt que des concepts ou des institutions — sur la base de ce que nous appelons des caractéristiques protégées&nbsp;: race, origine ethnique, origine nationale, handicap, appartenance religieuse, caste, orientation sexuelle, sexe, identité de genre et gravité. maladie. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous définissons les attaques comme des discours violents ou déshumanisants, des stéréotypes nuisibles, des déclarations d'infériorité, des expressions de mépris, de dégoût ou de rejet, des jurons et des appels à l'exclusion ou à la ségrégation. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous interdisons également l'utilisation de stéréotypes nuisibles, que nous définissons comme des comparaisons déshumanisantes qui ont historiquement été utilisées pour attaquer, intimider ou exclure des groupes spécifiques, et qui sont souvent liées à la violence hors ligne. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous considérons l'âge comme une caractéristique protégée lorsqu'il est référencé avec une autre caractéristique protégée. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous protégeons également les réfugiés, les migrants, </font>
                  <font style={{verticalAlign: 'inherit'}}>les immigrés et les demandeurs d'asile des attaques les plus graves, même si nous autorisons les commentaires et les critiques des politiques d'immigration. </font>
                  <font style={{verticalAlign: 'inherit'}}>De même, nous fournissons certaines protections pour des caractéristiques telles que la profession, lorsqu'elles sont référencées avec un élément protégé</font>
                </font>
              </p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>caractéristique. </font>
                  <font style={{verticalAlign: 'inherit'}}>Parfois, en fonction des nuances locales, nous considérons certains mots ou expressions comme des mots de code pour les groupes PC.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous reconnaissons que les gens partagent parfois du contenu qui inclut le discours de haine de quelqu'un d'autre pour le condamner ou sensibiliser. </font>
                  <font style={{verticalAlign: 'inherit'}}>Dans d'autres cas, un discours qui pourrait autrement violer nos normes peut être utilisé de manière autoréférentielle ou de manière habilitante. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nos politiques sont conçues pour laisser de la place à ces types de discours, mais nous exigeons que les gens indiquent clairement leur intention. </font>
                  <font style={{verticalAlign: 'inherit'}}>Si l'intention n'est pas claire, nous pouvons supprimer le contenu.</font>
                </font>
              </p>
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                </font>
              </p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Niveau 1</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Contenu ciblant une personne ou un groupe de personnes (y compris tous les groupes à l'exception de ceux qui sont considérés comme des groupes non protégés décrits comme ayant commis des crimes violents ou des infractions sexuelles ou représentant moins de la moitié d'un groupe) sur la base de leur(s) caractéristique(s) protégée(s) ) ou statut d'immigrant avec&nbsp;:</font>
                </font>
              </p>
              <ul id="l75">
                <ul id="l76">
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Discours violent ou soutien sous forme écrite ou visuelle</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Discours ou images déshumanisants sous forme de comparaisons, de généralisations ou de déclarations comportementales non qualifiées (sous forme écrite ou visuelle) à ou à propos de&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l77">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Insectes.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Animaux culturellement perçus comme intellectuellement ou physiquement inférieurs.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Saleté, bactéries, maladies et matières fécales.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Prédateur sexuel.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Sous-humanité.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Criminels violents et sexuels</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>D'autres criminels (y compris, mais sans s'y limiter, les "voleurs",</font>
                          </font>
                        </p>
                        <p style={{paddingTop: '1pt', paddingLeft: '77pt', textIndent: '0pt', lineHeight: '109%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>voleurs », ou dire « Tous [caractéristiques protégées ou quasi-protégées] sont des 'criminels' »).</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Déclarations niant l'existence.</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Se moquer du concept, des événements ou des victimes de crimes haineux même si aucune personne réelle n'est représentée dans une image.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Comparaisons déshumanisantes désignées, généralisations ou déclarations comportementales (sous forme écrite ou visuelle) qui incluent&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l78">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les Noirs et les singes ou les créatures ressemblant à des singes.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les Noirs et le matériel agricole.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Caricatures de Noirs sous forme de blackface.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les juifs et les rats.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Le peuple juif dirige le monde ou contrôle les grandes institutions telles que les réseaux médiatiques, l'économie ou le gouvernement.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Nier ou déformer des informations sur l'Holocauste.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les musulmans et les porcs.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Personne musulmane et relations sexuelles avec des chèvres ou des cochons.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les Mexicains et les créatures ressemblant à des vers.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les femmes en tant qu'objets ménagers ou se référant aux femmes en tant que propriété ou "objets".</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les personnes transgenres ou non binaires appelées "ça".</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Dalits, castes répertoriées ou personnes de «caste inférieure» en tant que travailleurs subalternes.</font>
                          </font>
                        </p>
                        <h3 style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Niveau 2</font>
                          </font>
                        </h3>
                        <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Contenu ciblant une personne ou un groupe de personnes sur la base de leur(s) caractéristique(s) protégée(s) avec&nbsp;:</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Généralisations qui énoncent l'infériorité (sous forme écrite ou visuelle) des manières suivantes&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l79">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les déficiences physiques sont définies comme celles concernant :</font>
                          </font>
                        </p>
                        <ul id="l80">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Hygiène, y compris mais sans s'y limiter&nbsp;: sale, sale, malodorant.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Apparence physique, y compris mais sans s'y limiter : laid, hideux.</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les déficiences mentales sont définies comme celles concernant :</font>
                          </font>
                        </p>
                        <ul id="l81">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Capacité intellectuelle, y compris mais sans s'y limiter&nbsp;: idiots, stupides, idiots.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Éducation, y compris mais sans s'y limiter&nbsp;: analphabètes, sans instruction.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Santé mentale, y compris, mais sans s'y limiter&nbsp;: les malades mentaux, les retardés, les fous, les aliénés.</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les déficiences morales sont définies comme celles concernant :</font>
                          </font>
                        </p>
                        <ul id="l82">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Traits de caractère culturellement perçus comme négatifs, y compris, mais sans s'y limiter : lâche, menteur, arrogant, ignorant.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Termes péjoratifs liés à l'activité sexuelle, y compris, mais sans s'y limiter&nbsp;: pute, salope, pervers.</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Autres déclarations d'infériorité, que nous définissons comme&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l83">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Expressions sur le fait d'être moins qu'adéquat, y compris mais sans s'y limiter&nbsp;: sans valeur, inutile.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Expressions sur le fait d'être meilleur/pire qu'une autre caractéristique protégée, y compris, mais sans s'y limiter&nbsp;: "Je crois que les hommes sont supérieurs aux femmes."</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Expressions sur le fait de s'écarter de la norme, y compris mais sans s'y limiter&nbsp;: monstres, anormaux.</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Expressions de mépris (sous forme écrite ou visuelle), que nous définissons comme&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l84">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Auto-admission à l'intolérance sur la base d'une caractéristique protégée, y compris, mais sans s'y limiter : homophobe, islamophobe, raciste.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Expressions qu'une caractéristique protégée ne devrait pas exister.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Expressions de haine, y compris, mais sans s'y limiter : mépris, haine.</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Expressions de rejet, y compris, mais sans s'y limiter&nbsp;: ne respecte pas, n'aime pas, ne s'intéresse pas à</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Expressions de dégoût (sous forme écrite ou visuelle), que nous définissons comme&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l85">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Expressions suggérant que la cible provoque la maladie, y compris, mais sans s'y limiter&nbsp;: vomir, vomir.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Expressions de répulsion ou de dégoût, y compris, mais sans s'y limiter&nbsp;: ignoble, dégoûtant, beurk.</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Les jurons, à l'exception de certains jurons basés sur le genre dans un contexte de rupture amoureuse, définis comme&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l86">
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Faire référence à la cible en tant qu'organes génitaux ou anus, y compris, mais sans s'y limiter&nbsp;: chatte, bite, trou du cul.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Termes ou phrases grossiers avec l'intention d'insulter, y compris mais sans s'y limiter&nbsp;: baise, salope, enfoiré.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Termes ou phrases appelant à une activité sexuelle ou à un contact avec les organes génitaux, l'anus, les excréments ou l'urine, y compris, mais sans s'y limiter&nbsp;: sucer ma bite, embrasser mon cul, manger de la merde.</font>
                          </font>
                        </p>
                        <h3 style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Niveau 3</font>
                          </font>
                        </h3>
                        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Contenu ciblant une personne ou un groupe de personnes sur la base de leur(s) caractéristique(s) protégée(s) avec l'un des éléments suivants&nbsp;:</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Ségrégation sous la forme d'appels à l'action, de déclarations d'intention, de déclarations ambitieuses ou conditionnelles, ou de déclarations prônant ou soutenant la ségrégation.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Exclusion sous la forme d'appels à l'action, de déclarations d'intention, de déclarations ambitieuses ou conditionnelles, ou de déclarations prônant ou soutenant, définies comme</font>
                      </font>
                    </p>
                    <ul id="l87">
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Exclusion explicite, ce qui signifie des choses comme expulser certains groupes ou dire qu'ils ne sont pas autorisés.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>L'exclusion politique, c'est-à-dire la négation du droit au droit à la participation politique.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>L'exclusion économique, qui signifie refuser l'accès aux droits économiques et limiter la participation au marché du travail.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>L'exclusion sociale, ce qui signifie des choses comme le refus d'accès aux espaces (physiques et en ligne) et aux services sociaux, à l'exception de l'exclusion fondée sur le sexe dans les groupes de santé et de soutien positif.</font>
                          </font>
                        </p>
                        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Contenu qui décrit ou cible négativement les personnes avec des insultes, où les insultes sont définies comme des mots intrinsèquement offensants et utilisés comme étiquettes insultantes pour les caractéristiques ci-dessus.</font>
                          </font>
                        </p>
                        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                          </font>
                        </p>
                        <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Contenu fournissant ou proposant explicitement de fournir des produits ou des services qui</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>visent à changer l'orientation sexuelle ou l'identité de genre des personnes.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Contenu attaquant des concepts, des institutions, des idées, des pratiques ou des croyances associés à des caractéristiques protégées, susceptibles de contribuer à un préjudice physique imminent, à l'intimidation ou à la discrimination à l'encontre des personnes associées à cette caractéristique protégée. </font>
                        <font style={{verticalAlign: 'inherit'}}>MyOpinion examine une série de signes pour déterminer s'il existe une menace de préjudice dans le contenu. </font>
                        <font style={{verticalAlign: 'inherit'}}>Ceux-ci incluent, mais sans s'y limiter&nbsp;: le contenu susceptible d'inciter à la violence ou à l'intimidation imminente&nbsp;; </font>
                        <font style={{verticalAlign: 'inherit'}}>s'il y a une période de tension accrue telle qu'une élection ou un conflit en cours&nbsp;; </font>
                        <font style={{verticalAlign: 'inherit'}}>et s'il y a des antécédents récents de violence contre le groupe protégé ciblé. </font>
                        <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous pouvons également considérer si l'orateur est une personnalité publique ou occupe une position d'autorité.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Contenu ciblant une personne ou un groupe de personnes sur la base de leurs caractéristiques protégées avec des allégations selon lesquelles ils ont ou propagent le nouveau coronavirus, sont responsables de l'existence du nouveau coronavirus, propagent délibérément le nouveau coronavirus ou se moquent d'eux pour avoir ou éprouver le nouveau coronavirus.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous autoriserons le contenu qui pourrait autrement enfreindre les normes de la communauté lorsqu'il est déterminé que le contenu est satirique. </font>
                        <font style={{verticalAlign: 'inherit'}}>Le contenu ne sera autorisé que si les éléments de violation du contenu sont satirisés ou attribués à quelque chose ou à quelqu'un d'autre afin de se moquer d'eux ou de les critiquer.</font>
                      </font>
                    </p>
                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Contenu violent et graphique</font>
                      </font>
                    </h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nous supprimons le contenu qui glorifie la violence ou célèbre la souffrance ou l'humiliation d'autrui, car cela peut créer un environnement qui décourage la participation. </font>
                        <font style={{verticalAlign: 'inherit'}}>Nous autorisons le contenu graphique (avec certaines limitations) pour aider les gens à sensibiliser à ces problèmes.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nous savons que les gens apprécient la possibilité de discuter de questions importantes telles que les violations des droits de l'homme ou les actes de terrorisme. </font>
                        <font style={{verticalAlign: 'inherit'}}>Nous savons également que les gens ont des sensibilités différentes en ce qui concerne le contenu graphique et violent. </font>
                        <font style={{verticalAlign: 'inherit'}}>Pour cette raison, nous ajoutons une étiquette d'avertissement au contenu particulièrement graphique ou violent afin qu'il ne soit pas accessible aux personnes de moins de 18 ans et que les gens soient conscients de la nature graphique ou violente avant de cliquer pour le voir.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                      </font>
                    </p>
                    <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie de personnes</font>
                      </font>
                    </h3>
                    <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Vidéos de personnes ou de cadavres dans un cadre non médical s'ils</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>représenter</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Démembrement.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Organes internes visibles&nbsp;; </font>
                        <font style={{verticalAlign: 'inherit'}}>corps partiellement décomposés.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Des personnes carbonisées ou brûlées sauf dans le contexte de la crémation ou de l'auto-immolation lorsque cette action est une forme de discours politique ou digne d'intérêt.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Victimes de cannibalisme.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Tranchant la gorge.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Diffusions en direct de la peine capitale d'une personne</font>
                      </font>
                    </p>
                    <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Remarques sadiques</font>
                      </font>
                    </h3>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Remarques sadiques à l'égard d'images supprimées ou placées derrière un écran d'avertissement en vertu de cette politique, sauf s'il existe un contexte d'autodéfense ou un contexte éducatif explicite dans un contexte médical.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Propos sadiques à l'égard d'images d'une ou plusieurs personnes victimes de violences et/ou d'actes humiliants de la part d'un ou plusieurs personnels en tenue exerçant une fonction policière.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Offrir ou solliciter des images qui sont supprimées ou placées derrière un écran d'avertissement en vertu de cette politique, lorsqu'elles sont accompagnées de remarques sadiques.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Remarques sadiques explicites sur la souffrance des animaux représentés dans les images.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous incluons un écran d'avertissement afin que les gens sachent que le contenu peut être dérangeant. </font>
                        <font style={{verticalAlign: 'inherit'}}>Nous limitons également la possibilité de visionner le contenu aux adultes de 18&nbsp;ans et plus&nbsp;:</font>
                      </font>
                    </p>
                    <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie de personnes</font>
                      </font>
                    </h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Vidéos de personnes ou de cadavres dans un cadre médical si elles représentent&nbsp;:</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Démembrement.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Organes internes visibles&nbsp;; </font>
                        <font style={{verticalAlign: 'inherit'}}>corps partiellement décomposés.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Des personnes carbonisées ou brûlées, y compris la crémation ou l'auto-immolation lorsque cette action est une forme de discours politique ou digne d'intérêt.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Victimes de cannibalisme.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Tranchant la gorge.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Photos de personnes blessées ou décédées si elles montrent :</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Démembrement.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Organes internes visibles&nbsp;; </font>
                        <font style={{verticalAlign: 'inherit'}}>corps partiellement décomposés.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Personnes carbonisées ou brûlantes.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Victimes de cannibalisme.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Tranchant la gorge.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Images montrant la mort violente d'une personne ou de personnes par accident ou meurtre</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Images montrant la peine capitale d'une personne</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Images montrant des actes de torture commis contre une personne ou des personnes</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie d'objets étrangers non médicaux (tels que des objets Bah Blesson &amp; Companyl, des couteaux, des clous) involontairement insérés ou coincés dans des personnes causant des blessures graves</font>
                      </font>
                    </p>
                    <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie d'animaux</font>
                      </font>
                    </h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Le contenu suivant impliquant des animaux&nbsp;:</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Vidéos montrant des humains tuant des animaux s'il n'y a pas de contexte explicite de fabrication, de chasse, de consommation alimentaire, de transformation ou de préparation.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie de combats d'animaux à animaux, lorsqu'il y a des entrailles visibles ou un démembrement d'un corps non régénérant, sauf dans la nature.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Images d'humains commettant des actes de torture ou d'abus contre des animaux vivants.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Images d'animaux montrant des blessures ou des coupures qui rendent visibles les entrailles ou le démembrement, s'il n'y a pas de contexte explicite de fabrication, de chasse, de taxidermie, de traitement médical, de sauvetage ou de consommation alimentaire, de préparation ou de transformation, ou si l'animal est déjà écorché ou avec sa couche externe entièrement supprimé.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Pour le contenu suivant, nous incluons une étiquette afin que les gens sachent que le contenu peut être sensible&nbsp;:</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie d'objets étrangers non médicaux insérés volontairement dans des personnes à travers la peau dans un contexte religieux ou culturel</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', lineHeight: '168%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie des entrailles visibles dans un contexte d'accouchement Imagerie de fœtus et de nouveau-nés montrant :</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Démembrement.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Intérieurs visibles.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Un contexte d'avortement ou d'abandon.</font>
                      </font>
                    </p>
                    <p style={{paddingLeft: '76pt', textIndent: '0pt', lineHeight: '22pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie de nouveau-nés dans un contexte d'abandon Imagerie d'animaux dans un contexte d'abattage rituel montrant</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>démembrement, ou entrailles visibles, ou carbonisation ou brûlure</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nous supprimons :</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Vidéos et photos qui montrent la mort violente d'une personne lorsqu'un membre de la famille demande son retrait.</font>
                      </font>
                    </p>
                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                    <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nudité adulte et activité sexuelle</font>
                      </font>
                    </h3>
                    <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '50pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nous restreignons l'affichage de la nudité ou de l'activité sexuelle car certaines personnes de notre communauté peuvent être sensibles à ce type de contenu. </font>
                        <font style={{verticalAlign: 'inherit'}}>De plus, nous supprimons par défaut les images sexuelles pour empêcher le partage de contenu non consensuel ou mineur. </font>
                        <font style={{verticalAlign: 'inherit'}}>Les restrictions sur l'affichage d'activités sexuelles s'appliquent également au contenu créé numériquement, sauf s'il est publié à des fins éducatives, humoristiques ou satiriques.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'justify'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nos politiques en matière de nudité sont devenues plus nuancées au fil du temps. </font>
                        <font style={{verticalAlign: 'inherit'}}>Nous comprenons que la nudité peut être partagée pour diverses raisons, notamment comme forme de protestation, pour sensibiliser à une cause, ou pour des raisons éducatives ou médicales.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Lorsque cette intention est claire, nous tenons compte du contenu. </font>
                        <font style={{verticalAlign: 'inherit'}}>Par exemple, bien que nous restreignions certaines images de seins féminins incluant le mamelon, nous autorisons d'autres images, y compris celles représentant des actes de protestation, des femmes qui allaitent activement et des photos de cicatrices post-mastectomie. </font>
                        <font style={{verticalAlign: 'inherit'}}>Pour les images représentant des organes génitaux visibles ou l'anus dans le contexte des moments de naissance et d'après-naissance ou de situations liées à la santé, nous incluons une étiquette d'avertissement afin que les gens sachent que le contenu peut être sensible. </font>
                        <font style={{verticalAlign: 'inherit'}}>Nous autorisons également les photographies de peintures, de sculptures et d'autres œuvres d'art représentant des personnages nus.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Ne postez pas&nbsp;:</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Images de vrais adultes nus, si elles représentent&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l88">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Organes génitaux visibles, sauf dans le contexte de l'accouchement et des moments après l'accouchement ou dans des situations liées à la santé (par exemple, chirurgie de confirmation du sexe, examen pour le cancer ou prévention/évaluation de la maladie).</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Anus visible et/ou gros plans de fesses entièrement nus, sauf si photoshoppé sur une personnalité publique.</font>
                          </font>
                        </p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Mamelons féminins découverts sauf dans le contexte de l'allaitement, de l'accouchement et des moments après l'accouchement, des situations liées à la santé (par exemple, post-mastectomie, sensibilisation au cancer du sein ou chirurgie de confirmation du genre) ou un acte de protestation.</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Images d'activité sexuelle, y compris&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l89">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Activité sexuelle explicite et stimulation</font>
                          </font>
                        </p>
                        <ul id="l90">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Rapport sexuel explicite ou sexe oral, défini comme la bouche ou les organes génitaux entrant ou en contact avec les organes génitaux ou l'anus d'une autre personne, où au moins les organes génitaux d'une personne sont nus.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Stimulation explicite des organes génitaux ou de l'anus, définie comme la stimulation des organes génitaux ou de l'anus ou l'insertion d'objets, y compris des jouets sexuels, dans les organes génitaux ou l'anus, où les organes génitaux et le contact avec les organes génitaux sont directement visibles.</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Activité sexuelle et stimulation implicites</font>
                          </font>
                        </p>
                        <ul id="l91">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Rapports sexuels implicites ou rapports sexuels oraux, définis comme la bouche ou les organes génitaux entrant ou en contact avec les organes génitaux ou l'anus d'une autre personne, lorsque les organes génitaux ne sont pas directement visibles, sauf dans les cas d'un contexte de santé sexuelle, de publicités et d'images fictives reconnues ou avec des indicateurs de fiction .</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Stimulation implicite des organes génitaux ou de l'anus, définie comme la stimulation des organes génitaux ou de l'anus ou l'insertion d'objets, y compris des jouets sexuels, dans ou au-dessus des organes génitaux ou de l'anus, lorsque l'activité ou le contact n'est pas directement visible, sauf en cas de contexte de santé sexuelle, de publicités et de images fictives ou avec des indices de fiction.</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Autres activités, y compris, mais sans s'y limiter&nbsp;:</font>
                          </font>
                        </p>
                        <ul id="l92">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Érections</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Présence de sous-produits de l'activité sexuelle.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Jouets sexuels placés ou insérés dans la bouche.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Stimulation des mamelons humains nus.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Presser les seins féminins, défini comme un mouvement de saisie avec des doigts courbés qui montre à la fois des marques et un changement de forme clair des seins. </font>
                                <font style={{verticalAlign: 'inherit'}}>Nous autorisons la compression dans les contextes d'allaitement.</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Contenu fétichiste qui implique&nbsp;:</font>
                          </font>
                        </p>
                        <ul id="l93">
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Actes susceptibles d'entraîner la mort d'une personne ou d'un animal.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Démembrement.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Cannibalisme.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '9pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Excréments, urine, crachats, morve, menstruation ou vomi.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Bestialité.</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Contenu numérique qui répond à notre définition de l'activité sexuelle, sauf lorsqu'il est publié dans un contexte éducatif ou scientifique, ou lorsqu'il répond à l'un des critères ci-dessous et qu'il est affiché uniquement aux personnes de 18&nbsp;ans et plus.</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Audio étendu de l'activité sexuelle</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nous ne montrons ce contenu qu'aux personnes de 18&nbsp;ans et plus&nbsp;:</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Art du monde réel qui représente l'activité sexuelle.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Publier des photos ou des vidéos d'objets illustrant une activité sexuelle dans l'art du monde réel.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Imagerie représentant la bestialité dans l'art du monde réel à condition qu'elle soit partagée de manière neutre ou dans la condamnation et que les personnes ou les animaux représentés ne soient pas réels.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Activité sexuelle implicite dans les publicités.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Activité sexuelle implicite dans des images fictives reconnues ou avec des indicateurs de fiction.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Contenu numérique qui répond à notre définition de l'activité sexuelle, où&nbsp;:</font>
                      </font>
                    </p>
                    <ul id="l94">
                      <li data-list-text="">
                        <p style={{paddingTop: '8pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>L'activité sexuelle (rapports sexuels ou autres activités sexuelles) n'est pas</font>
                          </font>
                        </p>
                        <p style={{paddingTop: '1pt', paddingLeft: '77pt', textIndent: '0pt', textAlign: 'left'}}>directly visible.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>The content was posted in a satirical or humorous context.</p>
                      </li>
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>Only body shapes or contours are visible.</p>
                        <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                        <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>Sexual Solicitation</h3>
                        <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>As noted in Section 8 of our Community Standards (Adult Sexual Exploitation), people use MyOpinion to discuss and draw attention to sexual violence and exploitation. We recognize the importance of and allow for this discussion. We also allow for the discussion of sex worker rights advocacy and sex work regulation. We draw the line, however, when content facilitates, encourages or coordinates sexual encounters or commercial sexual services between adults. We do this to avoid facilitating transactions that may involve trafficking, coercion and non-consensual sexual acts.</p>
                        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>We also restrict sexually-explicit language that may lead to sexual solicitation because some audiences within our global community may be sensitive to this type of content, and it may impede the ability for people to connect with their friends and the broader community.</p>
                        <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>Do not post:</p>
                        <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>Content that offers or asks for adult commercial services, such as requesting, offering or asking for rates for escort service and paid sexual fetish or domination services. (Content that recruits or offers other people for third-party commercial sex work is separately considered under the Human Exploitation policy.</p>
                        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>Attempted coordination of or recruitment for, adult sexual activities, except when promoting an event or venue, including but not limited to:</p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>Filmed sexual activities.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>Pornographic activities, strip club shows, live sex performances or erotic dances.</p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Massages sexuels, érotiques ou tantriques.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Sollicitation sexuelle explicite par, y compris mais sans s'y limiter, ce qui suit, en offrant ou en demandant&nbsp;:</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Offrir ou demander des relations sexuelles ou des partenaires sexuels (y compris des partenaires qui partagent des intérêts fétichistes ou sexuels).</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Chat ou conversations sexuelles.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Photos nues/vidéos/images/articles de fétichisme sexuel.</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Termes d'argot sexuel.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Nous permettons d'exprimer le désir d'activité sexuelle, de promouvoir l'éducation sexuelle, de discuter de pratiques ou d'expériences sexuelles, ou d'offrir des cours ou des programmes qui enseignent des techniques ou discutent de sexe.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Contenu qui offre ou demande implicitement ou indirectement une sollicitation sexuelle et qui répond aux deux critères suivants. </font>
                        <font style={{verticalAlign: 'inherit'}}>Si les deux critères ne sont pas remplis, il n'est pas considéré comme enfreignant. </font>
                        <font style={{verticalAlign: 'inherit'}}>Par exemple, si le contenu est une image dessinée à la main illustrant une activité sexuelle mais qui ne demande ni n'offre de sollicitation sexuelle, il n'enfreint pas&nbsp;:</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Critère 1 : Offrez ou demandez</font>
                      </font>
                    </h3>
                    <ul id="l95">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Contenu qui, implicitement ou indirectement (généralement en fournissant une méthode de contact), offre ou demande une sollicitation sexuelle.</font>
                          </font>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Critère 2 : Éléments suggestifs</font>
                      </font>
                    </h3>
                    <ul id="l96">
                      <li data-list-text="">
                        <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Contenu qui fait l'offre ou la demande susmentionnée en utilisant l'un des éléments à caractère sexuel suivants&nbsp;:</font>
                          </font>
                        </p>
                        <ul id="l97">
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Emojis ou chaînes d'emoji contextuellement spécifiques et généralement sexuels, ou</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Argot sexualisé régional, ou</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Mentions ou représentations d'activités sexuelles (y compris l'art dessiné à la main, numérique ou du monde réel) telles que les rôles sexuels, le sexe</font>
                              </font>
                            </p>
                            <p style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>positions, scénarios fétichistes, état d'excitation, acte de rapport sexuel ou activité (par exemple pénétration sexuelle ou auto-plaisir), ou</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Poses.</font>
                              </font>
                            </p>
                          </li>
                          <li data-list-text="">
                            <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Audio d'activité sexuelle ou autre contenu qui viole la nudité adulte et l'activité sexuelle.</font>
                              </font>
                            </p>
                            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Une offre ou une demande de matériel pornographique (y compris, mais sans s'y limiter, le partage de liens vers des sites Web pornographiques externes)</font>
                              </font>
                            </p>
                            <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                              <font style={{verticalAlign: 'inherit'}}>
                                <font style={{verticalAlign: 'inherit'}}>Langage sexuellement explicite qui va dans les détails graphiques au-delà de la simple référence à&nbsp;:</font>
                              </font>
                            </p>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Un état d'excitation sexuelle (par exemple, humidité ou érection) ou</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Un acte de rapport sexuel (p. ex. pénétration sexuelle, auto-plaisir ou exercice de scénarios fétichistes).</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>À l'exception du contenu partagé dans un contexte humoristique, satirique ou éducatif, en tant que Bah Blesson &amp; Companyphor sexuel ou en tant que juron sexuel.</font>
                      </font>
                    </p>
                    <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                      </font>
                    </p>
                  </li>
                  <li data-list-text="">
                    <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>Dans certains cas, nous autoriserons le contenu qui pourrait autrement enfreindre les normes de la communauté lorsqu'il est déterminé que le contenu est satirique. </font>
                        <font style={{verticalAlign: 'inherit'}}>Le contenu ne sera autorisé que si les éléments de violation du contenu sont satirisés ou attribués à quelque chose ou à quelqu'un d'autre afin de se moquer d'eux ou de les critiquer.</font>
                      </font>
                    </p>
                    <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                  </li>
                </ul>
              </ul>
            </li>
            <li data-list-text="11.D">
              <p style={{paddingLeft: '71pt', textIndent: '-30pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>INTÉGRITÉ ET AUTHENTICITÉ</font>
                </font>
              </p>
            </li>
          </ol>
        </ol>
        <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Intégrité du compte et identité authentique</font>
          </font>
        </h3>
        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>L'authenticité est la pierre angulaire de notre communauté. </font>
            <font style={{verticalAlign: 'inherit'}}>Nous croyons que l'authenticité aide à créer une communauté où les gens sont responsables les uns envers les autres et envers MyOpinion, de manière significative. </font>
            <font style={{verticalAlign: 'inherit'}}>Nous voulons permettre l'éventail des diverses façons dont l'identité s'exprime dans notre communauté mondiale, tout en empêchant l'usurpation d'identité et la fausse représentation de l'identité. </font>
            <font style={{verticalAlign: 'inherit'}}>C'est pourquoi nous demandons aux gens de se connecter sur MyOpinion en utilisant le nom qu'ils portent dans la vie de tous les jours. </font>
            <font style={{verticalAlign: 'inherit'}}>Nos politiques d'authenticité visent à créer un environnement sûr où les gens peuvent se faire confiance et se tenir mutuellement responsables.</font>
          </font>
        </p>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Afin de maintenir un environnement sûr et de favoriser la liberté d'expression, nous supprimons les comptes nuisibles à la communauté, y compris ceux qui compromettent la sécurité d'autres comptes et de nos services. </font>
            <font style={{verticalAlign: 'inherit'}}>Nous avons construit une combinaison de systèmes automatisés et manuels pour bloquer et supprimer les comptes qui sont utilisés pour abuser de manière persistante ou flagrante de nos Standards de la communauté.</font>
          </font>
        </p>
        <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Parce que la suppression au niveau du compte est une action sévère, dans la mesure du possible, nous visons à donner à notre communauté une chance d'apprendre nos règles et de suivre notre communauté</font>
          </font>
        </p>
        <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Normes. </font>
            <font style={{verticalAlign: 'inherit'}}>Les sanctions, y compris les désactivations de compte, sont conçues pour être proportionnelles à la gravité de la violation et au risque de préjudice posé à la communauté. </font>
            <font style={{verticalAlign: 'inherit'}}>Des violations continues, malgré des avertissements et des restrictions répétés, ou des violations qui présentent de graves risques pour la sécurité entraîneront la désactivation d'un compte.</font>
          </font>
        </p>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Nous n'autorisons pas l'utilisation de nos services et limiterons ou désactiverons les comptes ou autres entités (telles que les pages, les groupes et les événements) si vous&nbsp;:</font>
          </font>
        </p>
        <ul id="l98">
          <ul id="l99">
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Violer gravement nos normes communautaires.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Violer constamment nos normes communautaires.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Coordonnez-vous dans le cadre d'un réseau de comptes ou d'autres entités afin de violer ou d'échapper à nos normes communautaires.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Représenter des personnes ou des organisations dangereuses.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Créez ou utilisez un compte qui démontre une intention de violer nos normes communautaires.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Créez un compte, une page, un groupe ou un événement pour échapper à nos mesures d'application, y compris la création d'un compte pour contourner une restriction ou après que nous ayons désactivé votre compte, page, groupe ou événement précédent.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Usurper l'identité des autres en&nbsp;:</font>
                </font>
              </p>
              <ul id="l100">
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Utiliser leurs photos dans le but explicite de tromper les autres.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Créer un compte en supposant être ou parler pour une autre personne ou entité.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Créer une Page en supposant être ou parler pour une autre personne ou entité pour laquelle l'utilisateur n'est pas autorisé à le faire.</font>
                    </font>
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Sont âgés de moins de 13 ans.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Sont un délinquant sexuel condamné.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Sont interdits de recevoir nos produits, services ou logiciels en vertu des lois applicables.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Dans certains cas, tels que ceux décrits ci-dessous, nous demanderons des informations supplémentaires sur un compte avant de prendre des mesures allant de la restriction temporaire des comptes à leur désactivation permanente.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Comptes déformant leur identité en&nbsp;:</font>
                </font>
              </p>
              <ul id="l101">
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Utiliser un nom qui n'est pas le nom authentique que vous utilisez dans la vie de tous les jours</font>
                    </font>
                  </p>
                  <ul id="l102">
                    <li data-list-text="">
                      <p style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Utiliser un nom intrinsèquement contraire à la loi, contenant des insultes ou toute autre violation des Standards de la communauté.</font>
                        </font>
                      </p>
                    </li>
                  </ul>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Fournir une fausse date de naissance.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Créer un compte unique qui représente ou est utilisé par plusieurs personnes.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '3pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Gestion de plusieurs comptes en tant qu'utilisateur unique.</font>
                    </font>
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Comptes compromis.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Comptes vides avec inactivité prolongée.</font>
                </font>
              </p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Spam</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous nous efforçons de limiter la propagation du spam, car nous ne voulons pas permettre que le contenu conçu pour tromper ou qui tente d'induire les utilisateurs en erreur augmente le nombre de vues. </font>
                  <font style={{verticalAlign: 'inherit'}}>Ce contenu crée une expérience utilisateur négative, nuit à la capacité des personnes à s'engager de manière authentique dans les communautés en ligne et peut menacer la sécurité, la stabilité et la convivialité de nos services. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous visons également à empêcher les gens d'abuser de notre plate-forme, de nos produits ou de nos fonctionnalités pour augmenter artificiellement le nombre de téléspectateurs ou distribuer du contenu en masse à des fins commerciales.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Ne pas:</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Publiez, partagez, interagissez avec du contenu ou créez des comptes, des groupes, des pages, des événements ou d'autres actifs, manuellement ou automatiquement, à des fréquences très élevées.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Tenter ou réussir à vendre, acheter ou échanger des privilèges de site ou des fonctionnalités de produit, tels que des comptes, des rôles d'administrateur, l'autorisation de publier, des pages, des groupes, des mentions J'aime, etc., sauf dans le cas d'un contenu de marque clairement identifié.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Exiger ou prétendre que les utilisateurs sont tenus d'interagir avec le contenu (par exemple, aimer, partager) avant de pouvoir afficher ou interagir avec le contenu promis.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'justify'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Encouragez les likes, les partages, les suivis, les clics ou l'utilisation d'applications ou de sites Web sous de faux prétextes, tels que&nbsp;:</font>
                </font>
              </p>
              <ul id="l103">
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Offrir des services ou des fonctionnalités faux ou inexistants (par exemple "Obtenez un bouton 'Je n'aime pas'&nbsp;!")</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Ne pas diriger vers le contenu promis (par exemple "Cliquez ici pour un code de réduction chez Nordstrom"&nbsp;; </font>
                    </font><i>
                      <font style={{verticalAlign: 'inherit'}}>
                        <font style={{verticalAlign: 'inherit'}}>faux boutons de lecture</font>
                      </font>
                    </i>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}> )</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Diriger les utilisateurs hors de MyOpinion par l'utilisation trompeuse ou trompeuse d'URL, définies comme&nbsp;:</font>
                    </font>
                  </p>
                  <ul id="l104">
                    <li data-list-text="">
                      <h3 style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Masquage. </font>
                        </font><span className="p">
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Présenter différents contenus aux utilisateurs de MyOpinion et aux robots ou outils MyOpinion.</font>
                          </font>
                        </span>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Contenu trompeur </font>
                        </font><span className="p">
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>. </font>
                            <font style={{verticalAlign: 'inherit'}}>Le contenu contient un lien qui promet un type de contenu mais offre quelque chose de sensiblement différent.</font>
                          </font>
                        </span>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Sites Web pop-up trompeurs </font>
                        </font><span className="p">
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>. </font>
                            <font style={{verticalAlign: 'inherit'}}>Les sites Web qui nécessitent une action (par exemple, captcha, regarder l'annonce, cliquez ici) afin d'afficher le contenu de la page de destination attendu et le nom de domaine de l'URL change une fois l'action requise terminée.</font>
                          </font>
                        </span>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '3pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Aimez/partagez-gating. </font>
                        </font><span className="p">
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Pages de destination qui obligent les utilisateurs à aimer, partager ou interagir avec le contenu avant d'avoir accès au contenu hors MyOpinion.</font>
                          </font>
                        </span>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '8pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Fonctionnalité de page de destination trompeuse. </font>
                        </font><span className="p">
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Les sites Web qui ont une interface utilisateur trompeuse, ce qui entraîne la génération de trafic accidentel (par exemple, pop-ups/under, clickjacking, etc.).</font>
                          </font>
                        </span>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <h3 style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Imitation. </font>
                        </font><a href="http://www.faceb00k.com/" className="a" target="_blank">
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>Le site Web prétend être une marque ou un service de bonne réputation en utilisant un nom, un domaine ou un contenu comportant des fautes de frappe, des fautes d'orthographe ou d'autres moyens pour usurper l'identité de marques bien connues en utilisant une page de destination similaire à un autre site de confiance pour induire les visiteurs en erreur (par exemple, www.fac </font>
                          </font>
                        </a><span className="p">
                          <font style={{verticalAlign: 'inherit'}}>
                            <font style={{verticalAlign: 'inherit'}}>eb00k.com, www.face_book.com).</font>
                          </font>
                        </span>
                      </h3>
                    </li>
                    <li data-list-text="">
                      <p style={{paddingTop: '7pt', paddingLeft: '113pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Et d'autres comportements qui sont sensiblement similaires à ce qui précède.</font>
                        </font>
                      </p>
                      <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
                      <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>La cyber-sécurité</font>
                        </font>
                      </h3>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Nous reconnaissons que la sécurité de nos utilisateurs inclut la sécurité de leurs informations personnelles, comptes, profils et autres entités MyOpinion qu'ils peuvent gérer, ainsi que nos produits et services plus largement. </font>
                          <font style={{verticalAlign: 'inherit'}}>Les tentatives de collecte d'informations personnelles sensibles ou d'accès non autorisé par des méthodes trompeuses ou invasives sont préjudiciables à l'atmosphère authentique, ouverte et sûre que nous souhaitons favoriser. </font>
                          <font style={{verticalAlign: 'inherit'}}>Par conséquent, nous n'autorisons pas les tentatives de collecte d'informations sensibles sur les utilisateurs ou d'accès non autorisé par le biais de l'utilisation abusive de notre plate-forme, de nos produits ou de nos services.</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Ne pas:</font>
                        </font>
                      </p>
                      <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                        <font style={{verticalAlign: 'inherit'}}>
                          <font style={{verticalAlign: 'inherit'}}>Tenter de compromettre les comptes d'utilisateurs, les profils ou d'autres entités MyOpinion, abuser de nos produits ou services, recueillir des informations sensibles par des moyens trompeurs ou tenter de se livrer à un accès non autorisé, y compris&nbsp;:</font>
                        </font>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Accéder à des comptes, des profils d'entités MyOpinion, Inc. ou à des données d'utilisateur autres que les vôtres par des moyens trompeurs ou sans l'autorisation explicite du propriétaire du compte, du profil ou de l'entité.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Encourager ou tromper les utilisateurs à télécharger ou à exécuter des fichiers ou des programmes susceptibles de compromettre la sécurité en ligne ou des données d'un utilisateur, y compris par le biais de logiciels ou de sites Web malveillants. </font>
                  <font style={{verticalAlign: 'inherit'}}>Ces fichiers et programmes seront considérés comme des logiciels malveillants ou des « logiciels malveillants » s'ils endommagent ou obtiennent un accès non autorisé à un ordinateur, un appareil ou un réseau.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Tenter d'obtenir, d'acquérir ou de demander les informations de connexion d'un autre utilisateur ou d'autres informations sensibles, que ce soit explicitement ou par des moyens trompeurs tels que le phishing (par exemple, de faux sondages conçus pour capturer des informations de connexion ou des liens vers de fausses pages de connexion ou des sites Web d'imposteurs) ou l'utilisation de logiciels ou sites Web malveillants.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Partager publiquement vos propres informations de connexion ou celles d'autres personnes, soit sur la plate-forme</font>
                </font>
              </p>
              <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>ou via un service tiers.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Créer, partager ou héberger des logiciels malveillants, y compris des extensions de navigateur et des applications mobiles, sur ou en dehors de la plateforme, qui mettent en danger nos utilisateurs ou nos produits et services.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Fournir une infrastructure en ligne, y compris des services d'hébergement Web, des serveurs de système de noms de domaine et des réseaux publicitaires qui permettent des liens abusifs tels qu'une majorité de ces liens sur MyOpinion ou MyOpinion violent les sections spam ou cybersécurité des normes communautaires.</font>
                </font>
              </p>
              <h3 style={{paddingTop: '8pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Comportement inauthentique</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Conformément à notre engagement envers l'authenticité, nous n'autorisons pas les gens à se présenter sous un faux jour sur MyOpinion, à utiliser de faux comptes, à augmenter artificiellement la popularité du contenu ou à adopter des comportements conçus pour permettre d'autres violations de nos normes communautaires. </font>
                  <font style={{verticalAlign: 'inherit'}}>Cette politique vise à protéger la sécurité des comptes d'utilisateurs et de nos services, et à créer un espace où les gens peuvent faire confiance aux personnes et aux communautés avec lesquelles ils interagissent.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Ne pas:</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Utilisez plusieurs comptes MyOpinion ou partagez des comptes entre plusieurs personnes</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Utiliser à mauvais escient MyOpinion ou les systèmes de signalement MyOpinion pour harceler les autres</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Dissimuler l'objectif d'une Page en induisant les utilisateurs en erreur sur la propriété ou le contrôle de cette Page</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Se livrer ou prétendre se livrer à un comportement non authentique, qui est défini comme l'utilisation des actifs de MyOpinion (comptes, Pages, Groupes ou Événements), pour tromper les gens ou MyOpinion&nbsp;:</font>
                </font>
              </p>
              <ul id="l105">
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>À propos de l'identité, du but ou de l'origine de l'entité qu'ils représentent.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '7pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>À propos de la popularité du contenu ou des actifs MyOpinion.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>À propos de l'objectif d'un public ou d'une communauté.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>À propos de la source ou de l'origine du contenu.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="">
                  <p style={{paddingTop: '9pt', paddingLeft: '77pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Pour échapper à l'application de nos normes communautaires.</font>
                    </font>
                  </p>
                </li>
              </ul>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Se livrer ou prétendre se livrer à un comportement inauthentique coordonné, défini comme l'utilisation de plusieurs actifs MyOpinion, travaillant de concert pour se livrer à un comportement inauthentique (tel que défini ci-dessus), où l'utilisation de faux comptes est au cœur de l'opération</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Se livrer ou prétendre se livrer à une ingérence étrangère ou gouvernementale, qui est un comportement coordonné inauthentique mené au nom d'un acteur étranger ou gouvernemental.</font>
                </font>
              </p>
              <h3 style={{paddingTop: '4pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Désinformation</font>
                </font>
              </h3>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>La désinformation est différente des autres types de discours abordés dans nos normes communautaires, car il n'existe aucun moyen d'articuler une liste complète de ce qui est interdit. </font>
                  <font style={{verticalAlign: 'inherit'}}>Avec la violence graphique ou le discours de haine, par exemple, nos politiques spécifient le discours que nous interdisons, et même les personnes qui ne sont pas d'accord avec ces politiques peuvent les suivre. </font>
                  <font style={{verticalAlign: 'inherit'}}>Avec la désinformation, cependant, nous ne pouvons pas fournir une telle ligne. </font>
                  <font style={{verticalAlign: 'inherit'}}>Le monde change constamment, et ce qui est vrai une minute peut ne pas être vrai la minute suivante. </font>
                  <font style={{verticalAlign: 'inherit'}}>Les gens ont également différents niveaux d'information sur le monde qui les entoure et peuvent croire que quelque chose est vrai alors que ce n'est pas le cas. </font>
                  <font style={{verticalAlign: 'inherit'}}>Une politique qui interdit simplement la «&nbsp;désinformation&nbsp;» ne fournirait pas d'avis utile aux personnes qui utilisent nos services et serait inapplicable, car nous n'avons pas un accès parfait à l'information.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Au lieu de cela, nos politiques articulent différentes catégories de désinformation et tentent de fournir des indications claires sur la manière dont nous traitons ce discours lorsque nous le voyons. </font>
                  <font style={{verticalAlign: 'inherit'}}>Pour chaque catégorie, notre approche reflète notre tentative d'équilibrer nos valeurs d'expression, de sécurité, de dignité, d'authenticité et de confidentialité.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous supprimons les informations erronées lorsqu'elles sont susceptibles de contribuer directement au risque de préjudice physique imminent. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous supprimons également les contenus susceptibles de contribuer directement à interférer avec le fonctionnement des processus politiques et certains médias manipulés hautement trompeurs. </font>
                  <font style={{verticalAlign: 'inherit'}}>Pour déterminer ce qui constitue de la désinformation dans ces catégories, nous nous associons à des experts indépendants qui possèdent les connaissances et l'expertise nécessaires pour évaluer la véracité du contenu et déterminer s'il est susceptible de contribuer directement au risque de préjudice imminent. </font>
                  <font style={{verticalAlign: 'inherit'}}>Cela comprend, par exemple, un partenariat avec des organisations de défense des droits de l'homme présentes sur le terrain dans un pays pour déterminer la véracité d'une rumeur de conflit civil, et un partenariat avec des organisations de santé pendant la pandémie mondiale de COVID-19.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Pour toutes les autres informations erronées, nous nous concentrons sur la réduction de sa prévalence ou la création d'un environnement qui favorise un dialogue productif. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous savons que les gens utilisent souvent la désinformation de manière inoffensive, comme pour exagérer un point (« Cette équipe a le pire bilan de l'histoire du sport ! ») ou dans l'humour ou la satire (« Mon mari vient de remporter le prix Mari de l'année. ”) Ils peuvent également partager leur expérience à travers des histoires qui contiennent des inexactitudes. </font>
                  <font style={{verticalAlign: 'inherit'}}>Dans certains cas, les gens partagent des opinions personnelles profondément ancrées que d'autres considèrent comme fausses ou partagent des informations qu'ils croient être vraies mais que d'autres considèrent comme incomplètes ou trompeuses.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Reconnaissant à quel point ce discours est courant, nous nous concentrons sur le ralentissement de la propagation des canulars et de la désinformation virale, et orientons les utilisateurs vers des informations faisant autorité. </font>
                  <font style={{verticalAlign: 'inherit'}}>Dans le cadre de cet effort, nous nous associons à des organisations tierces de vérification des faits pour examiner et évaluer l'exactitude du contenu le plus viral sur nos plateformes.</font>
                </font>
              </p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Enfin, nous interdisons le contenu et le comportement dans d'autres domaines qui se chevauchent souvent avec la propagation de la désinformation. </font>
                  <font style={{verticalAlign: 'inherit'}}>Par exemple, nos normes communautaires interdisent les faux comptes, la fraude et les comportements inauthentiques coordonnés.</font>
                </font>
              </p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>À mesure que les environnements en ligne et hors ligne changent et évoluent, nous continuerons à faire évoluer ces politiques. </font>
                  <font style={{verticalAlign: 'inherit'}}>Les pages, groupes, profils et comptes MyOpinion qui partagent à plusieurs reprises les informations erronées répertoriées ci-dessous peuvent, en plus de voir leur contenu supprimé, recevoir une distribution réduite, des limitations sur leur capacité à faire de la publicité ou être supprimés de nos plateformes. </font>
                  <font style={{verticalAlign: 'inherit'}}>Informations erronées que nous supprimons&nbsp;:</font>
                </font>
              </p>
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous supprimons les types de désinformation suivants&nbsp;:</font>
                </font>
              </p>
              <ol id="l106">
                <li data-list-text="I.">
                  <h3 style={{paddingTop: '9pt', paddingLeft: '55pt', textIndent: '-13pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Blessures physiques ou violence</font>
                    </font>
                  </h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Nous supprimons les informations erronées ou les rumeurs invérifiables qui, selon des partenaires experts, sont susceptibles de contribuer directement à un risque imminent de violence ou de dommages physiques aux personnes. </font>
                      <font style={{verticalAlign: 'inherit'}}>Nous définissons la désinformation comme un contenu avec une allégation jugée fausse par un tiers faisant autorité. </font>
                      <font style={{verticalAlign: 'inherit'}}>Nous définissons une rumeur invérifiable comme une affirmation dont la source confirmée par les partenaires experts est extrêmement difficile ou impossible à retracer, pour laquelle les sources faisant autorité sont absentes, lorsqu'il n'y a pas suffisamment de spécificité pour que l'affirmation soit démystifiée, ou lorsque l'affirmation est trop incrédule ou trop irrationnel à croire.</font>
                    </font>
                  </p>
                  <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Nous savons que parfois, des informations erronées qui peuvent sembler bénignes peuvent, dans un contexte spécifique, contribuer à un risque de préjudice hors ligne, y compris des menaces de violence qui pourraient contribuer à un risque accru de décès, de blessures graves ou d'autres dommages physiques. </font>
                      <font style={{verticalAlign: 'inherit'}}>Nous travaillons avec un réseau mondial d'organisations non gouvernementales (ONG), d'organisations à but non lucratif, d'organisations humanitaires et d'organisations internationales qui ont une expertise dans ces dynamiques locales.</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="II.">
                  <h3 style={{paddingTop: '8pt', paddingLeft: '60pt', textIndent: '-19pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Désinformation nocive sur la santé</font>
                    </font>
                  </h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Nous consultons les principaux organismes de santé pour identifier la désinformation sur la santé susceptible de contribuer directement à un préjudice imminent à la santé et à la sécurité publiques. </font>
                      <font style={{verticalAlign: 'inherit'}}>Les fausses informations nuisibles à la santé que nous supprimons incluent les éléments suivants&nbsp;:</font>
                    </font>
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="">
              <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Désinformation sur les vaccins. </font>
                </font><span className="p">
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous supprimons principalement les informations erronées sur les vaccins lorsque les autorités de santé publique concluent que les informations sont fausses et susceptibles de contribuer directement à des refus imminents de vaccins. </font>
                    <font style={{verticalAlign: 'inherit'}}>Ils comprennent:</font>
                  </font>
                </span>
              </h3>
              <ol id="l107">
                <li data-list-text="a.">
                  <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Les vaccins causent l'autisme (Ex: "L'augmentation des vaccinations est la raison pour laquelle tant d'enfants sont autistes de nos jours.")</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="b.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Les vaccins causent le syndrome de mort subite du nourrisson (Ex&nbsp;: «&nbsp;Vous ne savez pas</font>
                    </font>
                  </p>
                  <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>les vaccins provoquent le SMSN ?</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="c.">
                  <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Les vaccins provoquent la maladie contre laquelle ils sont censés protéger, ou font que la personne qui reçoit le vaccin est plus susceptible de contracter la maladie (Ex : « Prendre un vaccin vous rend en fait plus susceptible de contracter la maladie car il existe une souche de la maladie à l'intérieur. Attention&nbsp;!&nbsp;»)</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="d.">
                  <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Les vaccins ou leurs ingrédients sont mortels, toxiques, toxiques, nocifs ou dangereux (Ex&nbsp;: "Bien sûr, vous pouvez prendre des vaccins, si cela ne vous dérange pas de mettre du poison dans votre corps.")</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="e.">
                  <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>L'immunité naturelle est plus sûre que l'immunité acquise par le vaccin (Ex&nbsp;: "Il est plus sûr de simplement contracter la maladie plutôt que le vaccin.")</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="f.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Il est dangereux de se faire vacciner plusieurs fois en peu de temps, même si ce moment est médicalement recommandé (Ex : « Ne prenez jamais plus d'un vaccin en même temps qui est dangereux - je me fiche de ce que votre médecin vous dit ! ”)</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="g.">
                  <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Les vaccins ne sont pas efficaces pour prévenir la maladie contre laquelle ils prétendent protéger. </font>
                      <font style={{verticalAlign: 'inherit'}}>Cependant, pour les vaccins contre le COVID-19, la grippe et le paludisme, nous ne supprimons pas les allégations selon lesquelles ces vaccins ne sont pas efficaces pour</font>
                    </font>
                  </p>
                  <p style={{paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>empêcher quelqu'un de contracter ces virus. </font>
                      <font style={{verticalAlign: 'inherit'}}>(Ex&nbsp;: Supprimer - "Le vaccin contre la poliomyélite ne fait rien pour vous empêcher de contracter la maladie"&nbsp;; Supprimer - "Les vaccins ne font en réalité rien pour vous empêcher de contracter la maladie"&nbsp;; Autoriser - "Le vaccin n'arrête pas vous empêche de contracter le COVID-19, c'est pourquoi vous devez toujours vous éloigner socialement et porter un masque lorsque vous êtes avec d'autres.&nbsp;»</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="h.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>L'acquisition de la rougeole ne peut pas causer la mort (nécessite des informations supplémentaires</font>
                    </font>
                  </p>
                  <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>et/ou contexte) (Ex&nbsp;: «&nbsp;Ne vous inquiétez pas si vous attrapez la rougeole, cela ne peut pas être mortel.&nbsp;»)</font>
                    </font>
                  </p>
                </li>
                <li data-list-text="i.">
                  <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>La vitamine C est aussi efficace que les vaccins pour prévenir les maladies pour lesquelles des vaccins existent.</font>
                    </font>
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="">
              <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Désinformation sur la santé pendant les urgences de santé publique.</font>
                </font><span className="p">
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Nous supprimons les informations erronées lors d'urgences de santé publique lorsque les autorités de santé publique concluent que les informations sont fausses et susceptibles de contribuer directement au risque de préjudice physique imminent, notamment en contribuant au risque que des personnes contractent ou propagent une maladie dangereuse ou refusent un vaccin associé. </font>
                    <font style={{verticalAlign: 'inherit'}}>Nous identifions les urgences de santé publique en partenariat avec les autorités sanitaires mondiales et locales. </font>
                    <font style={{verticalAlign: 'inherit'}}>Cela inclut actuellement les fausses allégations liées au COVID-19 qui sont vérifiées par des autorités sanitaires expertes, sur l'existence ou la gravité du virus, comment le guérir ou le prévenir, comment le virus est transmis ou qui est immunisé, et les fausses allégations qui découragent la bonne pratiques de santé liées à la COVID-19 (telles que se faire tester, s'éloigner de la société, porter un masque facial et se faire vacciner contre la COVID-19).</font>
                  </font>
                </span>
              </h3>
            </li>
            <li data-list-text="">
              <h3 style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Promouvoir ou défendre des remèdes miracles nocifs pour les problèmes de santé. </font>
                </font><span className="p">
                  <font style={{verticalAlign: 'inherit'}}>
                    <font style={{verticalAlign: 'inherit'}}>Il s'agit notamment des traitements où l'application recommandée, dans un contexte sanitaire, est susceptible de contribuer directement au risque de blessure grave ou de décès, et le traitement n'a pas d'usage légitime pour la santé (ex : eau de javel, désinfectant, onguent noir, soude caustique).</font>
                  </font>
                </span>
              </h3>
              <ol id="l108">
                <li data-list-text="III.">
                  <h3 style={{paddingTop: '7pt', paddingLeft: '67pt', textIndent: '-25pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Interférence des électeurs ou du recensement</font>
                    </font>
                  </h3>
                  <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Dans un effort pour promouvoir l'intégrité des élections et du recensement, nous supprimons les informations erronées susceptibles de contribuer directement à un risque d'interférence avec la capacité des personnes à participer à ces processus. </font>
                      <font style={{verticalAlign: 'inherit'}}>Cela inclut les éléments suivants&nbsp;:</font>
                    </font>
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '3pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Informations erronées sur les dates, les lieux, les heures et les méthodes de vote, d'inscription des électeurs ou de participation au recensement.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>La désinformation sur qui peut voter, les qualifications pour voter, si un vote sera compté et quelles informations ou documents doivent être fournis pour voter.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Désinformation sur le fait qu'un candidat se présente ou non.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Informations erronées sur qui peut participer au recensement et quelles informations ou matériels doivent être fournis pour participer.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'justify'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Informations erronées sur la participation du gouvernement au recensement, y compris, le cas échéant, que les informations de recensement d'un individu seront partagées avec un autre organisme gouvernemental (hors recensement).</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Contenu prétendant à tort que l'Immigration and Customs Enforcement (ICE) des États-Unis se trouve dans un lieu de vote.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Fausses allégations explicites selon lesquelles des personnes seront infectées par le COVID-19 (ou une autre maladie transmissible) si elles participent au processus de vote.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous avons des politiques supplémentaires destinées à couvrir les appels à la violence, la promotion de la participation illégale et les appels à l'ingérence coordonnée dans les élections, qui sont représentés dans d'autres sections de nos normes communautaires.</font>
                </font>
              </p>
              <ol id="l109">
                <li data-list-text="IV.">
                  <h3 style={{paddingTop: '8pt', paddingLeft: '63pt', textIndent: '-22pt', textAlign: 'left'}}>
                    <font style={{verticalAlign: 'inherit'}}>
                      <font style={{verticalAlign: 'inherit'}}>Médias manipulés</font>
                    </font>
                  </h3>
                </li>
              </ol>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Les médias peuvent être édités de différentes manières. </font>
                  <font style={{verticalAlign: 'inherit'}}>Dans de nombreux cas, ces modifications sont bénignes, comme le recadrage ou le raccourcissement du contenu pour des raisons artistiques ou l'ajout de musique. </font>
                  <font style={{verticalAlign: 'inherit'}}>Dans d'autres cas, la manipulation n'est pas apparente et pourrait induire en erreur, notamment dans le cas d'un contenu vidéo. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous supprimons ce contenu car il peut devenir viral rapidement et les experts conseillent que les fausses croyances concernant les médias manipulés ne peuvent souvent pas être corrigées par un discours plus approfondi.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous supprimons les vidéos en vertu de cette politique si des critères spécifiques sont remplis&nbsp;: (1) la vidéo a été modifiée ou synthétisée, au-delà des ajustements de clarté ou de qualité, d'une manière qui n'est pas évidente pour une personne moyenne, et induirait probablement une personne moyenne en erreur en lui faisant croire un sujet de la vidéo a dit des mots qu'il n'a pas dits&nbsp;; </font>
                  <font style={{verticalAlign: 'inherit'}}>et (2) la vidéo est le produit de l'intelligence artificielle ou de l'apprentissage automatique, y compris des techniques d'apprentissage en profondeur (par exemple, un deepfake technique), qui fusionne, combine, remplace et/ou superpose le contenu sur une vidéo, créant une vidéo qui semble authentique .</font>
                </font>
              </p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Mémorialisation</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Lorsqu'une personne décède, les amis et la famille peuvent nous demander de commémorer le compte MyOpinion. </font>
                  <font style={{verticalAlign: 'inherit'}}>Une fois commémoré, le mot</font>
                </font>
              </p>
              <p style={{paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>"Se souvenir" apparaît au-dessus du nom sur le profil de la personne afin que le compte soit désormais un site commémoratif et protège contre les tentatives de connexion et les activités frauduleuses. </font>
                  <font style={{verticalAlign: 'inherit'}}>Afin de respecter les choix qu'une personne a faits de son vivant, nous visons à préserver son compte après son décès.</font>
                </font>
              </p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous avons également permis aux personnes d'identifier un contact hérité pour s'occuper de leur compte après leur décès. </font>
                  <font style={{verticalAlign: 'inherit'}}>Pour soutenir les personnes en deuil, dans certains cas, nous pouvons supprimer ou modifier certains contenus lorsque le contact hérité ou les membres de la famille le demandent.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Pour les victimes de meurtre et de suicide, nous supprimerons le contenu suivant s'il apparaît sur la photo de profil, la photo de couverture ou parmi les messages récents du défunt à la demande d'un contact hérité ou d'un membre de la famille du défunt&nbsp;:</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Représentation visuelle de l'objet utilisé lors de la mort du défunt.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Imagerie du meurtrier reconnu coupable ou présumé du défunt.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Contenu lié au décès du défunt.</font>
                </font>
              </p>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Pour les victimes de meurtre, nous supprimerons également le meurtrier reconnu coupable ou présumé du profil du défunt s'il est référencé dans le statut relationnel ou entre amis.</font>
                </font>
              </p>
              <p style={{paddingTop: '8pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Lorsqu'ils seront portés à notre attention par le contact hérité ou un membre de la famille du défunt, nous&nbsp;:</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Supprimer les commentaires violents sur un profil commémoratif, ce qui obligerait généralement l'individu à se signaler lui-même afin que nous sachions qu'ils ne sont pas désirés.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '8pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Supprimez les éloges ou le soutien pour le décès, la maladie ou les dommages subis par la personne décédée sur un profil commémoratif.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '109%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Modifiez les paramètres de confidentialité de la personne décédée de public à amis uniquement lorsqu'il y a du contenu préjudiciable sur le profil.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Modifier un nom de compte en infraction sur le profil de la personne décédée.</font>
                </font>
              </p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <p style={{paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>11. E RESPECTANT LA PROPRIÉTÉ INTELLECTUELLE</font>
                </font>
              </p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Propriété intellectuelle</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '5pt', textIndent: '70pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>MyOpinion prend les droits de propriété intellectuelle au sérieux et pense qu'ils sont importants pour promouvoir l'expression, la créativité et l'innovation dans notre communauté. </font>
                  <font style={{verticalAlign: 'inherit'}}>Vous êtes propriétaire de tout le contenu et des informations que vous publiez sur MyOpinion, et vous contrôlez la manière dont ils sont partagés via vos paramètres de confidentialité et d'application. </font>
                  <font style={{verticalAlign: 'inherit'}}>Cependant, avant de partager du contenu sur MyOpinion, assurez-vous que vous en avez le droit. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous vous demandons de respecter les droits d'auteur, les marques de commerce et les autres droits légaux d'autrui. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous nous engageons à aider les personnes et les organisations à promouvoir et à protéger leurs droits de propriété intellectuelle.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Les conditions d'utilisation de MyOpinion n'autorisent pas les personnes à publier du contenu qui viole les droits de propriété intellectuelle de quelqu'un d'autre, y compris le droit d'auteur et</font>
                </font>
              </p>
              <p style={{paddingTop: '3pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>marque déposée. </font>
                  <font style={{verticalAlign: 'inherit'}}>Nous publions des informations sur les rapports de propriété intellectuelle que nous recevons dans notre rapport semestriel sur la transparence.</font>
                </font>
              </p>
              <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Dès réception d'un rapport d'un titulaire de droits ou d'un représentant autorisé, nous supprimerons ou restreindrons le contenu qui s'engage dans&nbsp;:</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Violation de copyright.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Contrefaçon de marque.</font>
                </font>
              </p>
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>11.F CONTENU ET DÉCISIONS</font>
                </font>
              </p>
              <h3 style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Demandes des utilisateurs</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous respectons :</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>L'utilisateur demande la suppression de son propre compte.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Les demandes de suppression du compte d'un utilisateur décédé d'un compte vérifié</font>
                </font>
              </p>
              <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>membre de la famille immédiate ou exécuteur testamentaire.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Les demandes de suppression du compte d'un utilisateur incapable d'un</font>
                </font>
              </p>
              <p style={{paddingTop: '1pt', paddingLeft: '41pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>représentant autorisé.</font>
                </font>
              </p>
              <p style={{textIndent: '0pt', textAlign: 'left'}}><br /></p>
              <h3 style={{paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Protection supplémentaire des mineurs</font>
                </font>
              </h3>
              <p style={{paddingTop: '9pt', paddingLeft: '76pt', textIndent: '0pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Nous respectons :</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Demandes de suppression d'un compte mineur.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '9pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Demandes du gouvernement pour la suppression d'images d'abus d'enfants montrant, par exemple, des coups par un adulte ou des étranglements ou des suffocations par un adulte.</font>
                </font>
              </p>
            </li>
            <li data-list-text="">
              <p style={{paddingTop: '7pt', paddingLeft: '41pt', textIndent: '-18pt', lineHeight: '108%', textAlign: 'left'}}>
                <font style={{verticalAlign: 'inherit'}}>
                  <font style={{verticalAlign: 'inherit'}}>Le tuteur légal demande la suppression des attaques contre des mineurs involontairement célèbres.</font>
                </font>
              </p>
            </li>
          </ul>
        </ul>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '109%', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Pour les normes communautaires suivantes, nous avons besoin d'informations et/ou de contexte supplémentaires pour les appliquer&nbsp;:</font>
          </font>
        </p>
        <p style={{paddingTop: '7pt', paddingLeft: '5pt', textIndent: '35pt', lineHeight: '108%', textAlign: 'left'}}>
          <font style={{verticalAlign: 'inherit'}}>
            <font style={{verticalAlign: 'inherit'}}>Nous pouvons supprimer le contenu créé dans le but d'identifier un mineur privé s'il peut y avoir un risque pour la sécurité du mineur à la demande d'un utilisateur, du gouvernement, des forces de l'ordre ou d'experts externes en matière de sécurité des enfants.</font>
          </font>
        </p>
      </div>
    </div>
  )
}

export default ConditionsGenerales