import React, { useEffect, useState } from 'react'
import styles from './SendModerationRequest.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { api } from 'api/api'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import Header from 'components/Header/Header'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { TbSearch } from 'react-icons/tb'
import { MdSwitchAccount } from 'react-icons/md'
import { color } from 'hooks/Utils/color'
import { Spinner } from 'react-bootstrap'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import ModeratorCard from './ModeratorCard/ModeratorCard'

const SendModerationRequest = () => {

    const { user_id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const accessToken = useAccessToken()

    const controller = new AbortController();
    const signal = controller.signal;

    // REQUEST STATE
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    // SEARCH STATE
    const [searchText, setSearchText] = useState('')
    const [isFocusedSearch, setIsFocusedSearch] = useState(false)

    // NAVIGATION HOOKS
    const goBack = () => navigate(-1)

    useEffect(() => {
        return () => {
            controller.abort()
        }
    }, [])


    const handleSearch = async (e) => {
        try {
            e.preventDefault();
            if (searchText && !isLoading) {
                setIsLoading(true)
                const body = { search: searchText }
                const request = await api(`api/search/search?with_moderation_request&page_id=${user_id}`, 'POST', body, accessToken, signal)
                const response = await request.json()
                console.log('Search user response:', response)
                setIsLoading(false)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        setData(response?.search_person)
                    }
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.log('Search user response:', error)
        }
    }


    const renderEmpty = () => {
        return (
            <div className={styles.emptyContainer}>
                <MdSwitchAccount color={'#808080'} className={styles.emptyIcon} />
                <h6 className={styles.textEmpty}>
                    {t('noUserFound')}
                </h6>
            </div>
        )
    }

    return (
        <div className={styles.screen}>
            <Header title={t('inviteAnAdministrator')} goBack={goBack} />
            <div className={styles.content}>
                <form onSubmit={handleSearch} className={styles.containerSearch}>
                    <TextField
                        hiddenLabel
                        id="outlined-hidden-label-small"
                        variant="outlined"
                        size="small"
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        className={styles.inputSearch}
                    />
                    {isLoading ?
                        <Spinner animation="border" style={{ color: color.primary }} />
                        :
                        <IconButton type='submit' style={{
                            width: '40px',
                            height: '40px', backgroundColor: color.primary
                        }}>
                            <TbSearch color='white' className={styles.searchIcon} />
                        </IconButton>
                    }

                </form>

                <div>
                    {data?.length === 0 && renderEmpty()}
                    <InfiniteScroll
                        dataLength={data.length}
                        loader={<Spinner animation='border' style={{ color: color.primary }} />}>
                        {data.map((item, index) => {
                            return (
                                <div key={index.toString()} >
                                    <ModeratorCard page_id={user_id} account={item} />
                                </div>
                            )
                        })}
                    </InfiniteScroll>
                </div>
            </div>
        </div >
    )
}

export default SendModerationRequest