import { useViralReducer } from "../ReducerHooks/ReducerHooks"

export default function useReducerViralAction() {

    const { store: ViralStore } = useViralReducer()

    const REFRESH_VIRAL_OPINIONS = (data) => {
        if(data.length == 0){
            return
        }
        ViralStore({
            type: 'REFRESH_VIRAL_OPINIONS',
            payload: data
        })
    }


    const ADD_VIRAL_OPINIONS = (data) => {
        if(data.length == 0){
            return
        }
        ViralStore({
            type: 'ADD_VIRAL_OPINIONS',
            payload: data
        })
    }

    const UPDATE_VIRAL_OPINION = (data) => {
        if(!data){
            return
        }
        ViralStore({
            type: 'UPDATE_VIRAL_OPINION',
            payload: data
        })
    }

    const UPDATE_VIRAL_VIEW_OPINION = (data) => {
        if(!data){
            return
        }
        ViralStore({
            type: 'UPDATE_VIRAL_VIEW_OPINION',
            payload: data
        })
    }

    const UPDATE_VIRAL_VOTE = (data) => {
        if(!data){
            return
        }
        ViralStore({
            type: 'UPDATE_VIRAL_VOTE',
            payload: data
        })
    }

    const DELETE_VIRAL_OPINION = (data) => {
        if(!data){
            return
        }
        ViralStore({
            type: 'DELETE_VIRAL_OPINION',
            payload: data
        })
    }

    const MUTED_USER_VIRAL_OPINION = (data) => {
        if (!data) {
            return
        }
        ViralStore({
            type: 'MUTED_USER_VIRAL_OPINION',
            payload: data
        })
    }

    const CLEAR_VIRAL_OPINIONS = () => {
        ViralStore({
            type: 'CLEAR_VIRAL_OPINIONS'
        })
    }

    return { REFRESH_VIRAL_OPINIONS, ADD_VIRAL_OPINIONS, UPDATE_VIRAL_OPINION, UPDATE_VIRAL_VIEW_OPINION, UPDATE_VIRAL_VOTE, DELETE_VIRAL_OPINION, MUTED_USER_VIRAL_OPINION, CLEAR_VIRAL_OPINIONS }

}
