import React, { useEffect, useState } from 'react'
import styles from './SearchRH.module.css'
import Header from 'components/Header/Header'
import { api } from 'api/api'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { TextField } from '@mui/material'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import { useNavigate } from 'react-router-dom'
import { color } from 'hooks/Utils/color'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { IoAdd, IoClose, IoSearchSharp } from 'react-icons/io5'

const SearchRH = () => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authReducerState } = useAuthReducer()

    const [userEmployer, setUserEmployer] = useState(authReducerState?.user?.user_employer)
    const [userDirection, setUserDirection] = useState(authReducerState?.user?.user_direction)
    const [userDepartment, setUserDepartment] = useState(authReducerState?.user?.user_department)
    const [userService, setUserService] = useState(authReducerState?.user?.user_service)


    const [results, setResults] = useState([])
    const [resultText, setResultText] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const [showOption2, setShowOption2] = useState(false)
    const [showOption3, setShowOption3] = useState(false)
    const [showOption4, setShowOption4] = useState(false)

    // DROPDOWN STATE
    const [employersOptions, setEmployersOptions] = useState([])
    const [directionOptions, setDirectionOptions] = useState([])
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])


    const goBack = () => navigate(-1)

    useEffect(() => {
        const fetchEntity = async () => {
            try {
                const request = await api(`api/rh/employers`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch employees response:', response)
                if (request?.ok && request?.status === 200) {
                    setEmployersOptions(response)
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchEntity()
    }, [])



    useEffect(() => {
        const fetchDirection = async () => {
            try {
                const request = await api(`api/rh/directions`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch employees response:', response)
                if (request?.ok && request?.status === 200) {
                    const formattedOptions = response.map((item) => ({
                        value: item.value,
                        label: item.value,
                    }));
                    setDirectionOptions(formattedOptions);
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchDirection()
    }, [])

    useEffect(() => {
        const fetchDepartment = async () => {
            try {
                const request = await api(`api/rh/departments`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch departments response:', response)
                if (request?.ok && request?.status === 200) {
                    const formattedOptions = response.map((item) => ({
                        value: item.value,
                        label: item.value,
                    }));
                    setDepartmentOptions(formattedOptions);
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchDepartment()
    }, [])

    useEffect(() => {
        const fetchService = async () => {
            try {
                const request = await api(`api/rh/services`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch services response:', response)
                if (request?.ok && request?.status === 200) {
                    const formattedOptions = response.map((item) => ({
                        value: item.value,
                        label: item.value,
                    }));
                    setServiceOptions(formattedOptions);
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchService()
    }, [])


    const handleSearch = async () => {
        try {
            setLoading(true)
            const body = {
                userEmployer,
                direction: showOption2 ? userDirection : null,
                department: showOption3 ? userDepartment : null,
                service: showOption4 ? userService : null,
            }
            // console.log(body)
            const request = await api(`api/search/search`, 'POST', body, accessToken)
            const response = await request.json()
            // console.log('Search response:', response)
            setLoading(false)
            if (request?.ok && request?.status === 200) {
                setResults(response?.search_person)
                if (response?.search_person.length === 0) {
                    setResultText(t('searchFriendInput:noResult'))
                } else {
                    setResultText(`${response?.search_person?.length} ${response?.search_person.length === 1 ? t('searchFriendInput:people') : t('searchFriendInput:peoples')}`)
                }
            }
        } catch (error) {
            setLoading(false)
            console.error(error);
        }
    }


    const handleAddOption = () => {
        setResultText(null)
        setResults([])
        if (!showOption2) {
            setShowOption2(true)
        }
        if (showOption2 && !showOption3) {
            setShowOption3(true)
        }
        if (showOption3 && !showOption4) {
            setShowOption4(true)
        }
    }


    return (
        <div className={styles.screen}>
            <Header title={t('hr_search')} goBack={goBack} />
            <div className={styles.container}>

                <div className={styles.searchContainer}>

                    <div className={styles.inputBox}>
                        <div style={{ width: '89%', marginBottom: '20px' }}>
                            <TextField
                                select
                                label="Entreprise ou entité"
                                defaultValue={employersOptions[0]?.value || ''}
                                size='small'
                                SelectProps={{
                                    native: true,
                                }}
                                fullWidth
                                style={{
                                    marginBottom: '20px'
                                }}
                                value={userEmployer || ''}
                                onChange={(event) => setUserEmployer(event.target.value)}>
                                {employersOptions?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option?.value}
                                    </option>
                                ))}
                            </TextField>
                        </div>
                        <button onClick={handleAddOption} className={styles.optionButton}>
                            <IoAdd size={20} color='white' />
                        </button>

                    </div>



                    {showOption2 &&
                        <div className={styles.inputBox}>
                            <div style={{ width: '89%', marginBottom: '20px' }}>
                                <TextField
                                    select
                                    label="Direction"
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    fullWidth
                                    style={{
                                        marginBottom: '20px'
                                    }}
                                    value={userDirection || ''}
                                    onChange={(event) => setUserDirection(event.target.value)}>
                                    {directionOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option?.label}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                            <button onClick={() => setShowOption2(false)} className={styles.optionButton} style={{ backgroundColor: color.danger }}>
                                <IoClose size={20} color='white' />
                            </button>
                        </div>
                    }

                    {showOption3 &&
                        <div className={styles.inputBox}>
                            <div style={{ width: '89%', marginBottom: '20px' }}>
                                <TextField
                                    select
                                    label="Departement"
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    fullWidth
                                    style={{
                                        marginBottom: '20px'
                                    }}
                                    value={userDepartment || ''}
                                    onChange={(event) => setUserDepartment(event.target.value)}>
                                    {departmentOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option?.label}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                            <button onClick={() => setShowOption3(false)} className={styles.optionButton} style={{ backgroundColor: color.danger }}>
                                <IoClose size={20} color='white' />
                            </button>
                        </div>
                    }


                    {showOption4 &&
                        <div className={styles.inputBox}>
                            <div style={{ width: '89%', marginBottom: '20px' }}>
                                <TextField
                                    select
                                    label="Service"
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    fullWidth
                                    style={{
                                        marginBottom: '20px'
                                    }}
                                    value={userService || ''}
                                    onChange={(event) => setUserService(event.target.value)}>
                                    {serviceOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option?.label}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                            <button onClick={() => setShowOption4(false)} className={styles.optionButton} style={{ backgroundColor: color.danger }}>
                                <IoClose size={20} color='white' />
                            </button>
                        </div>
                    }

                    <div className={styles.containerButton}>
                        <ButtonSubmit
                            onClick={handleSearch}
                            value='Rechercher'
                            isLoading={isLoading}
                            style={{
                                width: '50%',
                                color: 'white'
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                        <b style={{ color: color.primary, textAlign: 'center' }}>{resultText}</b>
                    </div>
                </div>
                <div style={{ backgroundColor: 'white', borderTop: '1px #F1F1F1 solid', marginTop: '10px'}}>
                    {results.map((user, index) => {
                        return (
                            <ProfileCard
                                key={index}
                                user={user}
                                pictureSize={50}
                            />
                        )
                    })}
                </div>

            </div>

        </div >
    )
}

export default SearchRH