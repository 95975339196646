import React, { useEffect, useState } from 'react'
import styles from './EventDetails.module.css'
import Header from 'components/Header/Header'
import SondEvent from 'components/Opinion/OpinionBody/SondEvent/SondEvent'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from 'api/api'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import Spinner from 'react-bootstrap/Spinner'
import { useQuery } from 'react-query'
import { color } from 'hooks/Utils/color'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'

const EventDetails = () => {

    // STATE
    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()

    const { event_id } = useParams();

    // const [event, setEvent] = useState({})
    const [hasMorePage, setHasMorePage] = useState(true)
    // const [isLoading, setIsLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)

    const [isLoadingApprove, setIsLoadingApprove] = useState(false)
    const [isLoadingRejet, setIsLoadingRejet] = useState(false)


    // HANDLER
    const goBack = () => navigate(-1)

    // useEffect(() => {
    //     // fetchEvent()
    //     return () => {
    //         controller.abort()
    //     }
    // }, [])


    // const fetchEvent = async (type) => {
    //     try {
    //         setIsLoading(true)
    //         const request = await api(`api/events/${event_id}`, 'GET', {}, { ...accessToken, ...signal })
    //         const response = await request.json()
    //         console.log('Fetch event details response:', response?.data)
    //         setIsLoading(false)
    //         if (request?.ok && request?.status === 200) {
    //             if (response?.success) {
    //                 setEvent(response?.data)
    //             } else {
    //                 setIsEmpty(true)
    //             }
    //         }
    //     } catch (error) {
    //         // // fetchEvent()
    //         console.error('Fetch event details error:', error);
    //     }
    // }

    const fetchEvent = async () => {
        const request = await api(`api/events/${event_id}`, 'GET', {}, { ...accessToken, ...signal })
        const response = await request.json()
        return response
    }

    const { isFetching, data, refetch } = useQuery(['event_details'], () => fetchEvent(), {
        refetchOnWindowFocus: false
    })
    const event = data?.data || []



    const handleAppouve = async (req_id) => {
        try {
            setIsLoadingApprove(true)
            const request = await api(`api/event_participation_request/${req_id}/approve`, 'POST', {}, { ...accessToken, ...signal })
            const response = await request.json()
            // console.log('Approuve requests response:', response)
            setIsLoadingApprove(false)
            if (request?.ok && request?.status === 200) {
                if (response?.success) {
                    refetch()
                }
            }
        } catch (error) {
            setIsLoadingApprove(false)
            console.error('Approuve requests error:', error);
        }
    }


    const handleRejet = async (req_id) => {
        try {
            setIsLoadingRejet(true)
            const request = await api(`api/event_participation_request/${req_id}/refuse`, 'POST', {}, { ...accessToken, ...signal })
            const response = await request.json()
            // console.log('Rejet requests response:', response)
            setIsLoadingRejet(false)
            if (request?.ok && request?.status === 200) {
                if (response?.success) {
                    refetch()
                }
            }
        } catch (error) {
            setIsLoadingRejet(false)
            console.error('Rejet requests error:', error);
        }
    }



    // FETCHING DATA RENDER
    if (isFetching) {
        return (
            <div className={styles.container}>
                <Header title='Event details' goBack={goBack} />
                <div className={styles.loadingContent}>
                    <Spinner animation="border" variant="dark" />
                </div>
            </div>
        )
    }


    return (
        <div className={styles.container}>
            <Header title='Event details' goBack={goBack} />
            <div className={styles.content}>
                <ProfileCard user={event?.owner} />
                <SondEvent
                    ago_id={event?.opinion?.agora?.ago_id}
                    event_opinion_id={event?.event_opinion_id}
                    event_user_id={event?.event_user_id}
                    event_user={event?.owner}
                    event_id={event?.event_id}
                    event_title={event?.event_title}
                    event_descr={event?.event_descr}
                    event_attachments={event?.attachments}
                    event_type={event?.event_type}
                    event_localisation={event?.event_localisation}
                    event_date={event?.event_date}
                    event_time={event?.event_time}
                    approuved_users_count={event?.approuved_users_count}
                    pending_users_count={event?.pending_users_count}
                    user_request={event?.user_request}
                />

                {event?.owner?.user_id === authReducerState?.user?.user_id &&
                    <div>

                        {/* PENDING USERS */}
                        <div className={styles.containerPending}>
                            <h5 style={{ backgroundColor: color.primary }} className={styles.titlePending}>
                                {t('approuved_users')} ({event?.pending_users?.length})
                            </h5>
                            {event?.pending_users?.map((user, index) => {
                                return (
                                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <ProfileCard user={user} />
                                        <div style={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '10px' }}>
                                            <ButtonSubmit
                                                onClick={() => handleAppouve(user?.user_request?.req_id)}
                                                value={t('approve')}
                                                style={{
                                                    color: 'white'
                                                }}
                                            />
                                            <button
                                                onClick={() => handleRejet(user?.user_request?.req_id)}
                                                style={{
                                                    color: color.primary,
                                                    backgroundColor: 'white',
                                                    border: `1px ${color.primary} solid`,
                                                    padding: '4px',
                                                    borderRadius: '3px',
                                                    fontSize: '12px'
                                                }}
                                            >
                                                 {t('dismiss')}
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        {/* APPROVE USERS */}
                        <div className={styles.containerPending}>
                            <h5 style={{ backgroundColor: color.primary }} className={styles.titlePending}>
                            {t('approuved_users')}  ({event?.approuved_users?.length})
                            </h5>
                            {event?.approuved_users?.map((user, index) => {
                                return (
                                    <div key={index}>
                                        <ProfileCard user={user} />
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                }


            </div>
        </div>
    )
}

export default EventDetails