import React, { useState } from 'react'
import styles from './CoverProfilePicture.module.css'
import { FaCamera } from 'react-icons/fa'
import { color } from 'hooks/Utils/color'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { api } from 'api/api'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import ProfilePicture from 'components/ProfilePicture/ProfilePicture'


const CoverProfilePicture = ({
    user,
    type = 'profile',
    onRefresh = () => null
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authReducerState } = useAuthReducer()


    const [file, setFile] = useState('')
    const [fileUri, setFileUri] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const open = () => {
        setIsVisible(true)
    }

    const handleClose = () => {
        setIsVisible(false)
        setFile('')
        setFileUri('')
    }

    const handleSelectFile = (e) => {
        if (e.target.files) {
            const file = e.target.files[0]
            const fileUri = window.URL.createObjectURL(file)
            setFile(file)
            setFileUri(fileUri)
        }
    }


    const uploadCover = async () => {
        try {
            setIsLoading(true)
            const form = new FormData()
            form.append('image', file)
            const request = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/profile-image`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                }
            })
            const response = await request?.data
            //    console.log(response, 'response of insert cover of profil')
            setIsLoading(false)
            if (request.status === 200) {
                if (response.success) {
                    onRefresh(user?.user_id)
                    handleClose()
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error.message)
        }
    }

    return (
        <div className={styles.container}>

            <ProfilePicture
                user_id={user?.user_id}
                uri={user?.profile?.prof_picture}
                size={140}
            />

            {authReducerState?.user?.user_id === user?.user_id &&
                <button onClick={open} className={styles.button}>
                    <FaCamera color={color.dark} className={styles.icon} />
                </button>
            }

            <Dialog open={isVisible} onClose={handleClose}>
                <DialogTitle>{t('profile_picture')}</DialogTitle>
                <DialogContent>
                    <div className={styles.containerImage}>
                        {file &&
                            <img src={fileUri} style={{ width: '100%', height: '220px', objectFit: 'cover' }} alt='cover' />
                        }
                    </div>
                    <div className={styles.loading}>
                        {isLoading && <Spinner size='sm' />}
                    </div>
                    <TextField
                        defaultValue={''}
                        autoFocus
                        margin="dense"
                        accept="image/*"
                        type="file"
                        fullWidth
                        variant="outlined"
                        onChange={(event) => handleSelectFile(event)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={uploadCover}>{t('save')}</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default CoverProfilePicture