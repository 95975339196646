import React from 'react'
import './TextInput.style.css'


function TextInput({
    placeholder = 'Saisir',
    value,
    setValue,
    type = 'text',
    style
}) {
    return (
        <input
            value={value}
            onChange={event => setValue(event.target.value)}
            className='TextInput'
            type={type}
            placeholder={placeholder}
            style={style}
        />
    )
}

export default TextInput