const initialState = {
    opinions: [],
    opinion: {},
};

const HomeReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'ADD_HOME_VIEW_OPINION':
            return {
                ...state,
                opinion: action.payload,
            }

        case 'DELETE_HOME_VIEW_OPINION':
            return {
                ...state,
                opinion: {},
            }

        case 'REFRESH_HOME_OPINIONS':
            return {
                ...state,
                opinions: action?.payload,
            }

        case 'ADD_HOME_OPINIONS':
            return {
                ...state,
                opinions: [...state.opinions, ...action.payload],
            }

        case 'UPDATE_HOME_OPINION':
            return {
                ...state,
                opinions: state.opinions.map(opinion =>
                    opinion.sond_id !== action.payload.sond_id ? opinion : action.payload,
                ),
            }

        case 'UPDATE_HOME_VIEW_OPINION':
            return {
                ...state,
                opinions: state.opinions.map(opinion =>
                    opinion.sond_id !== action?.payload?.sond_id
                        ? opinion
                        : { ...opinion, ...action.payload },
                ),
            }

        case 'UPDATE_HOME_VOTE':
            return {
                ...state,
                opinions: state.opinions.map(opinion =>
                    opinion.sond_id === action.payload
                        ? {
                            ...opinion,
                            votes_count: ++opinion.votes_count,
                            get_user_vote: [{ vote_opinion: action.payload.toString() }],
                        }
                        : opinion,
                ),
            }

        case 'DELETE_HOME_OPINION':
            return {
                ...state,
                opinions: state.opinions.filter(
                    opinion => opinion.sond_id !== action.payload.sond_id,
                ),
            }

        case 'MUTED_USER_HOME_OPINION':
            return {
                ...state,
                opinions: state.opinions.filter(
                    opinion => opinion.sond_user !== action.payload,
                ),
            }

        case 'CLEAR_HOME_OPINIONS':
            return {
                ...state,
                opinions: [],
            }

        default:
            return state;
    }
}

export default HomeReducer;