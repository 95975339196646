import axios from 'axios';

export const apiAxios = async(url, method, data = null, endpointHeaders = {}) => {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        ...endpointHeaders,
    }

    let uploadProgress = 0

    const config = {
        method,
        url: `${process.env.REACT_APP_API_URL}/${url}`,
        data,
        headers,
        timeout: 5000,
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            uploadProgress = Math.round((loaded / total) * 100)
            console.log(uploadProgress, '% progress...')
        },
    };

    // const request = 

    return await axios(config)
};

// export const get = (url, headers = {}) => axiosRequest('get', url, null, headers);
// export const post = (url, data, headers = {}) => axiosRequest('post', url, data, headers);
// export const del = (url, headers = {}) => axiosRequest('delete', url, null, headers);
// export const put = (url, data, headers = {}) => axiosRequest('put', url, data, headers);
