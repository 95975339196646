import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { api } from '../../../api/api'
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit'
import TextInput from '../../../components/TextInput/TextInput'
import './ConfirmPassword.style.css'
import { IoMailUnreadOutline } from 'react-icons/io5'
import { color } from '../../../hooks/Utils/color'

const ConfirmPassword = () => {

    /* STATE */
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { state } = useLocation();
    // const { email } = state; // Read values passed on state
    const [isLoading, setIsloading] = useState(false)
    const [code, setCode] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        if (state?.email) {
            return
        }
        navigate(-1)
    }, [])

    const handleConfirmEmail = async (e) => {
        e.preventDefault();
        if (code.length < 6) {
            setError(t('invalid_confirmation_code_at_least_6_characters'))
            return
        }
        try {
            setIsloading(true)
            const request = await api(`api/email/${code}`, 'GET', null)
            const response = await request.json()
            console.log('Response confirm email:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    navigate('/login', { replace: true })
                } else {
                    setError(t('invalid_confirmation_code'))
                }
            }
            setIsloading(false)
        } catch (error) {
            setIsloading(false)
            setError(t('no_internet_connection'))
            console.warn('Error confirm email', error.message);
        }
    }


    return (
        <div className='confirm-password'>

            <div className='card-form mb-4'>

                <IoMailUnreadOutline size={'20%'} color='gray' />

                <h3 className='title'>{t('account_verification')}</h3>

                <p className='subtitle'>
                    {t('a_6_digit_code_has_just_been_sent')} {' '} <b>{state?.email}</b>.
                </p>

                <div className='error-message'>
                    {error}
                </div>
                <form onSubmit={handleConfirmEmail}>
                    <TextInput
                        value={code}
                        setValue={setCode}
                        type='number'
                        placeholder={t('confirmation_code')}
                        style={{ textAlign: 'center', marginBottom: '10%' }}
                    />

                    <ButtonSubmit
                        value={t('confirm')}
                        isLoading={isLoading}
                        loadBoostrapColor={'light'}
                        style={{
                            width: '100%',
                            color: 'white',
                            marginBottom: '5%',
                            backgroundColor: color.primary
                        }}
                    />

                </form>

            </div>

        </div>
    )
}

export default ConfirmPassword