import React from 'react'
import './Home.style.css'
import Topbar from '../../components/Topbar/Topbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import HomeOpinions from '../../components/Opinions/HomeOpinions/HomeOpinions';
import Endbar from '../../components/Endbar/Endbar';
import { usePeopleMatchReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import { isMobile } from 'react-device-detect';
import TopBar from '../../components/Topbar/Topbar';


function Home() {

  // STATE
  const { t } = useTranslation()
  const { data: peopleMatchData } = usePeopleMatchReducer()

  return (
    <div className='home'>
      {isMobile ?
        <TopBar />
        :
        <div className='header'>
          <h5 className='title'>
            {t('contacts')}
          </h5>
        </div>
      }
      <HomeOpinions />
    </div>
  )
}

export default Home