import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import './SearchBadge.style.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ReactSelect from 'react-select'
import Header from '../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import useBadge from '../../hooks/useBadgeHooks/useBadgeHooks'
import { IoAdd, IoClose, IoSearchSharp } from 'react-icons/io5'
import ButtonSubmit from '../../components/ButtonSubmit/ButtonSubmit'
import { api } from '../../api/api'
import axios from 'axios'
import { color } from '../../hooks/Utils/color'
import ProfileCard from '../../components/ProfileCard/ProfileCard'


const SearchBadge = () => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authData } = useAuthReducer()
    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')
    const { type_badge_data, goal_data, business_data, hobby_data, food_data, color_data } = useBadge()
    const [countriesOption, setCountriesOption] = useState([])
    const optionGenders = [
        {
            value: 'M',
            label: 'Male',
        },
        {
            value: 'F',
            label: 'Female',
        },
        {
            value: 'A',
            label: 'Autre',
        },
    ]

    /* Field */
    const [optionValue1, setOptionValue1] = useState(goal_data)
    const [FieldSelected1, setFieldSelected1] = useState(type_badge_data[0])
    const [badgeSelected1, setBadgeSelected1] = useState(goal_data[0])

    const [optionValue2, setOptionValue2] = useState(business_data)
    const [FieldSelected2, setFieldSelected2] = useState(type_badge_data[1])
    const [badgeSelected2, setBadgeSelected2] = useState(business_data[0])

    const [optionValue3, setOptionValue3] = useState(hobby_data)
    const [FieldSelected3, setFieldSelected3] = useState(type_badge_data[2])
    const [badgeSelected3, setBadgeSelected3] = useState(hobby_data[0])

    const [optionValue4, setOptionValue4] = useState(food_data)
    const [FieldSelected4, setFieldSelected4] = useState(type_badge_data[3])
    const [badgeSelected4, setBadgeSelected4] = useState(food_data[0])

    const [optionValue5, setOptionValue5] = useState(color_data)
    const [FieldSelected5, setFieldSelected5] = useState(type_badge_data[4])
    const [badgeSelected5, setBadgeSelected5] = useState(color_data[0])

    const [genderSelected, setGenderSelected] = useState(optionGenders[0])
    const [countrySelected, setCountrySelected] = useState(countriesOption[0])

    const [showOption2, setShowOption2] = useState(false)
    const [showOption3, setShowOption3] = useState(false)
    const [showOption4, setShowOption4] = useState(false)
    const [showOption5, setShowOption5] = useState(false)
    const [showOption6, setShowOption6] = useState(false)
    const [showOption7, setShowOption7] = useState(false)

    const [people, setPeople] = useState([])
    const [searchResult, setSearchResult] = useState(null)



    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const request = await api('api/ip-countries', 'GET', {}, accessToken)
                const response = await request.json()
                console.log('Response fetch countries:', response)
                if (request.ok && request.status === 200) {
                    const format_countries = response.map(country => ({
                        label: country?.ip_country_name,
                        value: country?.ip_country_name
                    }))
                    setCountriesOption(format_countries)
                }
            } catch (error) {
                fetchCountries()
                console.log('Error get user location:', error)
                throw new Error(error)
            }
        }
        fetchCountries()
    }, []);



    const handleAddOption = () => {
        setSearchResult(null)
        setPeople([])
        if (!showOption2) {
            setShowOption2(true)
        }
        if (showOption2 && !showOption3) {
            setShowOption3(true)
        }
        if (showOption3 && !showOption4) {
            setShowOption4(true)
        }
        if (showOption4 && !showOption5) {
            setShowOption5(true)
        }
        if (showOption5 && !showOption6) {
            setShowOption6(true)
        }
        if (showOption6 && !showOption7) {
            setShowOption7(true)
        }
    }


    const handleChangeData = (field, data) => {
        if (data === 'goal') {
            if (field === 1) {
                setOptionValue1(goal_data)
                setBadgeSelected1(goal_data[0])
            }
            if (field === 2) {
                setOptionValue2(goal_data)
                setBadgeSelected2(goal_data[0])
            }
            if (field === 3) {
                setOptionValue3(goal_data)
                setBadgeSelected3(goal_data[0])
            }
            if (field === 4) {
                setOptionValue4(goal_data)
                setBadgeSelected4(goal_data[0])
            }
            if (field === 5) {
                setOptionValue5(goal_data)
                setBadgeSelected5(goal_data[0])
            }
        }
        if (data === 'business') {
            if (field === 1) {
                setOptionValue1(business_data)
                setBadgeSelected1(business_data[0])
            }
            if (field === 2) {
                setOptionValue2(business_data)
                setBadgeSelected2(business_data[0])
            }
            if (field === 3) {
                setOptionValue3(business_data)
                setBadgeSelected3(business_data[0])
            }
            if (field === 4) {
                setOptionValue4(business_data)
                setBadgeSelected4(business_data[0])
            }
            if (field === 5) {
                setOptionValue5(business_data)
                setBadgeSelected5(business_data[0])
            }
        }
        if (data === 'hobby') {
            if (field === 1) {
                setOptionValue1(hobby_data)
                setBadgeSelected1(hobby_data[0])
            }
            if (field === 2) {
                setOptionValue2(hobby_data)
                setBadgeSelected2(hobby_data[0])
            }
            if (field === 3) {
                setOptionValue3(hobby_data)
                setBadgeSelected3(hobby_data[0])
            }
            if (field === 4) {
                setOptionValue4(hobby_data)
                setBadgeSelected4(hobby_data[0])
            }
            if (field === 5) {
                setOptionValue5(hobby_data)
                setBadgeSelected5(hobby_data[0])
            }
        }
        if (data === 'food') {
            if (field === 1) {
                setOptionValue1(food_data)
                setBadgeSelected1(food_data[0])
            }
            if (field === 2) {
                setOptionValue2(food_data)
                setBadgeSelected2(food_data[0])
            }
            if (field === 3) {
                setOptionValue3(food_data)
                setBadgeSelected3(food_data[0])
            }
            if (field === 4) {
                setOptionValue4(food_data)
                setBadgeSelected4(food_data[0])
            }
            if (field === 5) {
                setOptionValue5(food_data)
                setBadgeSelected5(food_data[0])
            }
        }
        if (data === 'color') {
            if (field === 1) {
                setOptionValue1(color_data)
                setBadgeSelected1(color_data[0])
            }
            if (field === 2) {
                setOptionValue2(color_data)
                setBadgeSelected2(color_data[0])
            }
            if (field === 3) {
                setOptionValue3(color_data)
                setBadgeSelected3(color_data[0])
            }
            if (field === 4) {
                setOptionValue4(color_data)
                setBadgeSelected4(color_data[0])
            }
            if (field === 5) {
                setOptionValue5(color_data)
                setBadgeSelected5(color_data[0])
            }
        }
    }


    const searchBadge = async () => {
        console.log('Searching...')
        setIsLoading(true)
        try {
            /* Form data */
            const formData = new FormData();
            formData.append('badge_field', `user_badge_${FieldSelected1.value}`)
            formData.append('badge_value', badgeSelected1.value)

            if (showOption2) {
                formData.append('badge_field2', `user_badge_${FieldSelected2.value}`)
                formData.append('badge_value2', badgeSelected2.value)
            }

            if (showOption3) {
                formData.append('badge_field3', `user_badge_${FieldSelected3.value}`)
                formData.append('badge_value3', badgeSelected3.value)
            }

            if (showOption4) {
                formData.append('badge_field4', `user_badge_${FieldSelected4.value}`)
                formData.append('badge_value4', badgeSelected4.value)
            }

            if (showOption5) {
                formData.append('badge_field5', `user_badge_${FieldSelected5.value}`)
                formData.append('badge_value5', badgeSelected5.value)
            }

            if (showOption6) {
                formData.append('gender_name', genderSelected.value)
            }

            if (showOption7) {
                formData.append('country_name', countrySelected.value)
            }
            console.log('Form data:', formData)
            const request = await axios.post(`${process.env.REACT_APP_API_URL}/api/search/search`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                },
            });
            const response = await request?.data
            console.log('Response search by badge:', response?.search_person.length + ' people')
            if (request.status === 200) {
                if (response?.success) {
                    if (response?.search_person.length !== 0) {
                        setPeople(response?.search_person)
                        setSearchResult(`${response?.search_person?.length} ${authData?.language === 'en' ? 'People' : response?.search_person?.length == 1 ? 'personne' : 'personnes'}`)
                    } else {
                        setPeople([])
                        setSearchResult(`${authData?.language === 'en' ? 'No result' : 'Aucun résultat'}`)
                    }
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('Error search by badge:', error)
        }
    }



    return (
        <div className='search-badge'>
            <Header title={t('search_by_badge')} goBack={() => navigate(-1)} />
            <div className='content'>

                <div className='input-box'>

                    {/* FIELD 1 */}
                    <div style={{ width: '43%', marginBottom: '20px' }}>
                        <ReactSelect
                            placeholder={t('goal')}
                            options={type_badge_data}
                            isSearchable={false}
                            defaultValue={type_badge_data[0]}
                            onChange={newValue => {
                                setFieldSelected1(newValue)
                                handleChangeData(1, newValue.value)
                            }}
                            components={{
                                Option: (props) => {
                                    const { innerProps, innerRef } = props;
                                    return (
                                        <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                            <img src={props.data.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                        </div>
                                    );
                                },
                                SingleValue: (props) => (
                                    <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                        <img src={props.data.icon} width={25} height={23} />
                                        <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                    </div>
                                )
                            }}
                        />
                    </div>

                    {/* BADGE 1 */}
                    <div style={{ width: '43%', marginBottom: '20px' }}>
                        <ReactSelect
                            placeholder={t('goal')}
                            options={optionValue1}
                            isSearchable={false}
                            defaultValue={badgeSelected1}
                            onChange={newValue => setBadgeSelected1(newValue)}
                            components={{
                                Option: FieldSelected1.value === 'color' ? (props) => {
                                    const { innerProps, innerRef } = props;
                                    return (
                                        <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                            <div style={{ backgroundColor: props.data.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                        </div>
                                    );
                                } : (props) => {
                                    const { innerProps, innerRef } = props;
                                    return (
                                        <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                            <img src={props.data.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                        </div>
                                    );
                                },
                                SingleValue: FieldSelected1.value === 'color' ? (props) => (
                                    <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px', }}>
                                        <div style={{ backgroundColor: badgeSelected1.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                        <div style={{ marginLeft: '10px' }}>{badgeSelected1.label}</div>
                                    </div>
                                ) : (props) => (
                                    <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                        <img src={badgeSelected1.icon} width={25} height={23} />
                                        <div style={{ marginLeft: '10px' }}>{badgeSelected1.label}</div>
                                    </div>
                                )
                            }}
                        />
                    </div>

                    <button onClick={handleAddOption} className='option-button'>
                        <IoAdd size={20} color='white' />
                    </button>

                </div>


                {showOption2 &&
                    <div className='input-box'>

                        {/* FIELD 2 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={type_badge_data}
                                isSearchable={false}
                                defaultValue={FieldSelected2}
                                onChange={newValue => {
                                    setFieldSelected2(newValue)
                                    handleChangeData(2, newValue.value)
                                }}
                                components={{
                                    Option: (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={props.data.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        {/* BADGE 2 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={optionValue2}
                                isSearchable={false}
                                defaultValue={badgeSelected2}
                                onChange={newValue => setBadgeSelected2(newValue)}
                                components={{
                                    Option: FieldSelected2.value === 'color' ? (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <div style={{ backgroundColor: props.data.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    } : (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: FieldSelected2.value === 'color' ? (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px', }}>
                                            <div style={{ backgroundColor: badgeSelected2.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected2.label}</div>
                                        </div>
                                    ) : (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={badgeSelected2.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected2.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        <button onClick={() => setShowOption2(false)} className='option-button' style={{ backgroundColor: color.danger }}>
                            <IoClose size={20} color='white' />
                        </button>

                    </div>
                }

                {showOption3 &&
                    <div className='input-box'>

                        {/* FIELD 3 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={type_badge_data}
                                isSearchable={false}
                                defaultValue={FieldSelected3}
                                onChange={newValue => {
                                    setFieldSelected3(newValue)
                                    handleChangeData(3, newValue.value)
                                }}
                                components={{
                                    Option: (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={props.data.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        {/* BADGE 3 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={optionValue3}
                                isSearchable={false}
                                defaultValue={badgeSelected3}
                                onChange={newValue => setBadgeSelected3(newValue)}
                                components={{
                                    Option: FieldSelected3.value === 'color' ? (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <div style={{ backgroundColor: props.data.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    } : (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: FieldSelected3.value === 'color' ? (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px', }}>
                                            <div style={{ backgroundColor: badgeSelected3.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected3.label}</div>
                                        </div>
                                    ) : (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={badgeSelected3.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected3.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        <button onClick={() => setShowOption3(false)} className='option-button' style={{ backgroundColor: color.danger }}>
                            <IoClose size={20} color='white' />
                        </button>

                    </div>
                }

                {showOption4 &&
                    <div className='input-box'>

                        {/* FIELD 4 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={type_badge_data}
                                isSearchable={false}
                                defaultValue={FieldSelected4}
                                onChange={newValue => {
                                    setFieldSelected4(newValue)
                                    handleChangeData(4, newValue.value)
                                }}
                                components={{
                                    Option: (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={props.data.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        {/* BADGE 4 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={optionValue4}
                                isSearchable={false}
                                defaultValue={badgeSelected4}
                                onChange={newValue => setBadgeSelected4(newValue)}
                                components={{
                                    Option: FieldSelected4.value === 'color' ? (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <div style={{ backgroundColor: props.data.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    } : (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: FieldSelected4.value === 'color' ? (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px', }}>
                                            <div style={{ backgroundColor: badgeSelected4.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected4.label}</div>
                                        </div>
                                    ) : (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={badgeSelected4.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected4.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        <button onClick={() => setShowOption4(false)} className='option-button' style={{ backgroundColor: color.danger }}>
                            <IoClose size={20} color='white' />
                        </button>

                    </div>
                }


                {showOption5 &&
                    <div className='input-box'>

                        {/* FIELD 5 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={type_badge_data}
                                isSearchable={false}
                                defaultValue={FieldSelected5}
                                onChange={newValue => {
                                    setFieldSelected5(newValue)
                                    handleChangeData(5, newValue.value)
                                }}
                                components={{
                                    Option: (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={props.data.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        {/* BADGE 5 */}
                        <div style={{ width: '43%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={optionValue5}
                                isSearchable={false}
                                defaultValue={badgeSelected5}
                                onChange={newValue => setBadgeSelected5(newValue)}
                                components={{
                                    Option: FieldSelected5.value === 'color' ? (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <div style={{ backgroundColor: props.data.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    } : (props) => {
                                        const { innerProps, innerRef } = props;
                                        return (
                                            <div ref={innerRef} {...innerProps} style={{ display: 'flex', padding: '10px', cursor: 'pointer' }}>
                                                <img src={props.data.icon} width={25} height={23} />
                                                <div style={{ marginLeft: '10px' }}>{props.data.label}</div>
                                            </div>
                                        );
                                    },
                                    SingleValue: FieldSelected5.value === 'color' ? (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px', }}>
                                            <div style={{ backgroundColor: badgeSelected5.icon, width: '25px', height: '23px', border: '1px #f1f1f1 solid' }} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected5.label}</div>
                                        </div>
                                    ) : (props) => (
                                        <div {...props} style={{ display: 'flex', cursor: 'pointer', marginTop: '-20px' }}>
                                            <img src={badgeSelected5.icon} width={25} height={23} />
                                            <div style={{ marginLeft: '10px' }}>{badgeSelected5.label}</div>
                                        </div>
                                    )
                                }}
                            />
                        </div>

                        <button onClick={() => setShowOption5(false)} className='option-button' style={{ backgroundColor: color.danger }}>
                            <IoClose size={20} color='white' />
                        </button>

                    </div>
                }



                {showOption6 &&
                    <div className='input-box'>

                        {/* FIELD 5 */}
                        <div style={{ width: '89%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={optionGenders}
                                isSearchable={false}
                                defaultValue={optionGenders[0]}
                                onChange={newValue => setGenderSelected(newValue)}
                            />
                        </div>

                        <button onClick={() => setShowOption6(false)} className='option-button' style={{ backgroundColor: color.danger }}>
                            <IoClose size={20} color='white' />
                        </button>

                    </div>
                }

                {showOption7 &&
                    <div className='input-box'>

                        {/* FIELD 5 */}
                        <div style={{ width: '89%', marginBottom: '20px' }}>
                            <ReactSelect
                                placeholder={t('goal')}
                                options={countriesOption}
                                isSearchable={false}
                                defaultValue={countriesOption[0]}
                                onChange={newValue => setCountrySelected(newValue)}
                            />
                        </div>

                        <button onClick={() => setShowOption7(false)} className='option-button' style={{ backgroundColor: color.danger }}>
                            <IoClose size={20} color='white' />
                        </button>

                    </div>
                }

                <ButtonSubmit
                    value={t('search')}
                    onClick={searchBadge}
                    isLoading={isLoading}
                    style={{
                        width: '40%',
                        color: 'white',
                        fontWeight: '600',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <b style={{ color: color.primary, textAlign: 'center' }}>{searchResult}</b>
                </div>


            </div>

            <div style={{ overflowY: 'scroll', height: '75vh', backgroundColor: 'white'}}>
                {people.map((user, index) => {
                    return (
                        <ProfileCard
                            key={index}
                            user={user}
                            pictureSize={50}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default SearchBadge