import React, { useState } from 'react'
import styles from './Intranet.module.css'
import Header from '../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ModelAlert from '../../components/ModalAlert/ModelAlert'


const Intranet = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const [userLanguage, setUserLanguage] = useState(i18n.language)
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    const [showAlert, setShowAlert] = useState(false)


    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)
    const handleShowAlert = () => setShowAlert(true)
    const goToRequest = () => navigate('/intranet/request')
    const goToHolidayBalance = () => navigate('/intranet/holiday-balance')
    const goToEvents = () => navigate('/intranet/events')
    const goToCalendar = () => navigate('/intranet/calendar')
    const goToSharedDocs = () => {
        window.open('https://sunu-group.com/fr/home', "_blank", "noreferrer");
    }
    const goToAIAssistant = () => navigate('/intranet/ai-assistant')

    return (
        <div className={styles.screen}>
            <Header title='Intranet AfDB' goBack={goBack} />
            <div className={styles.container}>

                <div onClick={goToRequest} className={styles.pressable}>
                    <img src={require('../../assets/icons/rh_request_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myRequests')}</h6>
                        <p className={styles.descPressable}>{t('myRequestDesc')}</p>
                    </div>
                </div>

                <div onClick={goToHolidayBalance} className={styles.pressable}>
                    <img src={require('../../assets/icons/balance_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myBalance')}</h6>
                        <p className={styles.descPressable}>{t('myBalanceDesc')}</p>
                    </div>
                </div>

                <div onClick={goToEvents} className={styles.pressable}>
                    <img src={require('../../assets/icons/event_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myEvents')}</h6>
                        <p className={styles.descPressable}>{t('myEventDesc')}</p>
                    </div>
                </div>

                <div onClick={goToCalendar} className={styles.pressable}>
                    <img src={require('../../assets/icons/calendar_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myCalendar')}</h6>
                        <p className={styles.descPressable}>{t('myCalendarDesc')}</p>
                    </div>
                </div>

                <div onClick={goToSharedDocs} className={styles.pressable}>
                    <img src={require('../../assets/icons/doc_share_icon.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>
                            {/* {t('sharedDocuments')} */}
                            Intranet
                        </h6>
                        <p className={styles.descPressable}>
                            {t('sharedDocumentDesc')}
                        </p>
                    </div>
                </div>

                <div onClick={goToAIAssistant} className={styles.pressable}>
                    <img src={require('../../assets/icons/chat_gpt.png')} className={styles.iconPressable} alt='' />
                    <div>
                        <h6 className={styles.titlePressable}>{t('myAIAssistant')}</h6>
                        <p className={styles.descPressable}>{t('myAIAssistantDesc')}</p>
                    </div>
                </div>

            </div>


            <ModelAlert
                message={userLanguage == 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />

        </div>
    )

}

export default Intranet