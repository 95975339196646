const initialState = {
    notifications: [],
    notifications_number: 0
};


const ReducerNotification = (state = initialState, action) => {
    switch (action.type) {

        case 'UPDATE_NOTIFICATIONS_NUMBER':
            return {
                ...state,
                notifications_number: action?.payload,
            }

        case 'REFRESH_NOTIFICATIONS':
            return {
                ...state,
                notifications: action?.payload,
            }

        case 'ADD_NOTIFICATIONS':
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload.filter(item =>
                    !state.notifications.some(notification => item.notif_id === notification.notif_id)
                )]
            }

        case 'UPDATE_NOTIFICATION':
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.notif_id !== action.payload.notif_id ? notification : action.payload,
                ),
            }

        case 'DELETE_NOTIFICATION':
            return {
                ...state,
                notifications: state.notifications.filter(notification =>
                    notification.notif_id !== action.payload.notif_id,
                ),
            }

        case 'CLEAR_NOTIFICATIONS':
            return {
                ...state,
                notifications: [],
                notifications_number: 0
            }

        default:
            return state;
    }
}

export default ReducerNotification;