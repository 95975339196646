import ProfileCard from 'components/ProfileCard/ProfileCard'
import React from 'react'

const PageCard = ({
    user
}) => {
  return (
    <div style={{borderBottom: '1px solid #f1f1f1'}}>
        <ProfileCard user={user} pictureSize={50} />
    </div>
  )
}

export default PageCard

