import { color } from 'hooks/Utils/color'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const AgoraCard = ({
    agora
}) => {


    const navigate = useNavigate()

    const goToAgora = () => {
        navigate(`/agora/${agora.ago_id}`, { state: { agora_title: agora?.ago_title } })
    }

    return (
        <div onClick={goToAgora} style={{ display: 'flex', padding: '10px', cursor: 'pointer', borderBottom: '1px solid #F1F1F1' }}>
            <div>
                <img
                    src={agora?.ago_picture ? agora?.ago_picture : require('assets/images/background_opinion.jpg')}
                    width={50}
                    height={50}
                    alt=''
                    style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50px',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        border: '2px solid ' + color.primary
                    }}
                />
            </div>
            <div style={{ marginLeft: '10px' }}>
                <b>{agora?.ago_title}</b>
                <p>{agora?.ago_presentation?.length > 100 ? `${agora?.ago_presentation.substring(0, 100)}...` : agora?.ago_presentation} </p>
            </div>
        </div>
    )
}

export default AgoraCard