const initialState = {
    authorization: null,
    user: {},
    audience: [],
    language: 'fr',
    isChangeAccount: false
};


const AuthReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'USER_CHANGE_ACCOUNT':
            return {
                ...state,
                isChangeAccount: action?.payload
            }

        case 'USER_LOGGED':
            return {
                ...state,
                authorization: `${action?.payload?.type} ${action?.payload?.access_token}`,
                user: action?.payload?.user
            }

        case 'USER_LOGOUT':
            return {
                ...state,
                authorization: null,
                user: {},
            }

        case 'UPDATE_USER_PROFILE':
            return {
                ...state,
                user: action?.payload
            }

        case 'UPDATE_USER_AUDIENCE':
            return {
                ...state,
                audience: action?.payload
            }

        case 'UPDATE_USER_LANGUAGE':
            return {
                ...state,
                language: action?.payload
            }

        default:
            return state;
    }
}

export default AuthReducer;