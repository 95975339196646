import React, { useState } from 'react'
import './NavigationBottom.style.css'
import { Link } from 'react-router-dom'
import { FaUsers } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { MdAddBox, MdGroups, MdOutlinePublic } from 'react-icons/md'
import { AiFillMessage } from 'react-icons/ai'
import { IoSearchSharp } from 'react-icons/io5'
import { color } from '../../hooks/Utils/color'
import { useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import ModalCreateOpinion from '../ModalCreateOpinion/ModalCreateOpinion'

const NavigationBottom = () => {

  const { t } = useTranslation()
  const { data: AuthData } = useAuthReducer()
  const [showAddOpinion, setShowAddOpinion] = useState(false)
  const currentPath = window.location.pathname;

  
  return (
    <div>
    <nav className="bottom-nav">
      <ul>

        <li>
          <Link to={'/home'}>
            <div style={{ flexDirection: 'column' }}>
              <FaUsers size={30} color={color.primary} />
              <span style={{ fontWeight: '600', fontSize: '12px', color: color.primary }}>
                {t('contacts')}
              </span>
            </div>
          </Link>
        </li>
        <li>

          <Link to={'/public'}>
            <div style={{ flexDirection: 'column' }}>
              <MdOutlinePublic size={30} color={color.primary} />
              <span style={{ fontWeight: '600', fontSize: '12px', color: color.primary }}>
                IPS-CGRAE
              </span>
            </div>
          </Link>
        </li>

        <li>
          <Link onClick={()=>setShowAddOpinion(true)}>
            <div style={{ flexDirection: 'column' }}>
              <MdAddBox size={30} color={color.primary} />
              <span style={{ fontWeight: '600', fontSize: '12px', color: color.primary }}>
                {t('publish')}
              </span>
            </div>
          </Link>
        </li>

        <li>
          <Link to={'one-to-one'}>
            <div>
              <AiFillMessage size={30} color={color.primary} />
              <span style={{ fontWeight: '600', fontSize: '12px', color: color.primary }}>
                {t('inbox')}
              </span>
            </div>
          </Link>
        </li>

        <li>

          <Link to={'agora'}>
            <div style={{ flexDirection: 'column' }}>
              <MdGroups size={30} color={color.primary} />
              <span style={{ fontWeight: '600', fontSize: '12px', color: color.primary }}>
                {t('groups')}
              </span>
            </div>
          </Link>
        </li>

      </ul>

    </nav>

    <ModalCreateOpinion
        user={AuthData?.user}
        show={showAddOpinion}
        setShow={setShowAddOpinion}
      />
    </div>
  )
}

export default NavigationBottom