import React, { useEffect, useState } from 'react'
import styles from './SondAudio.module.css'
import { color } from 'hooks/Utils/color'


const SondAudio = ({
    name = '',
    uri
}) => {

    return (
        <div>
            <div className={styles.container}>
                <p style={{ marginBottom: '10px', fontWeight: '500', color: color.primary }}>
                    {name || ''}
                </p>
                <audio controls style={{ width: '100%', height: '40px', border: `2px ${color.primary} solid`, borderRadius: '50px', }}>
                    <source src={uri} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        </div>
    )
}

export default SondAudio