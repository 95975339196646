import { api } from '../../api/api'
import axios from 'axios'
import { useAccessToken } from '../ReducerHooks/ReducerHooks'

const useNotificationPush = () => {

    const accessToken = useAccessToken()

    return async (user_id, { title = 'MyOpinion', body = 'Vous avez une intéraction sur votre opinion', url = 'https://www.myopinion.ci/' }) => {
        try {
            const request = await api(`api/user/get-notifications-token/${user_id}`, 'GET', null, accessToken)
            const response = await request.json()
            console.log('Response fetch notification token:', response)
            if (response?.success) {

                const notificationRequest = await fetch('https://exp.host/--/api/v2/push/send', {
                    method: 'POST',
                    headers: {
                        host: 'exp.host',
                        accept: 'application/json',
                        'accept-encoding': 'gzip, deflate',
                        'content-type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    data: JSON.stringify({
                        to: response?.user_notif_token?.user_notif_token,
                        title,
                        body,
                        sound: 'default',
                        priority: 'high',
                        badge: 0,
                        data: { url },
                    }),
                });
                const notificationResponse = notificationRequest.json()
                console.log('Response send notification:', notificationResponse)

            }
        } catch (error) {
            console.error('response of notification sending:', error)
        }
    }
}

export default useNotificationPush