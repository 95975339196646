import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './AppLayout.module.css'
import Endbar from '../../components/Endbar/Endbar'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import { isMobile } from 'react-device-detect';
import NavigationBottom from '../../components/NavigationButton/NavigationBottom'
import TopBar from '../../components/Topbar/Topbar'


function AppLayout() {

    const { innerWidth: width, innerHeight: height } = window;
    const currentPath = window.location.pathname;

    if (isMobile) {
        return (
            <div>
                <Outlet />
                <NavigationBottom />
            </div>
        )
    }

    return (
        <div className={styles.appLayout} style={{maxWidth: width, maxHeight: height}}>

            <div className={styles.sidebar}>
                <Sidebar />
            </div>

            <div className={styles.outlet}>
                <Outlet />
            </div>

            <div className={styles.endbar}>
                <Endbar />
            </div>
        </div>
    )
}

export default AppLayout