import React from 'react'
import styles from './PageSetting.module.css'
import Header from 'components/Header/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TbSend } from 'react-icons/tb'
import { FiMail } from 'react-icons/fi'


const PageSetting = () => {

    const { user_id } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const goBack = () => navigate(-1)
    const goToInviteAdmin = () => navigate(`/profiles/user/pages/${user_id}/settings/moderation-requests/send`)
    const goToRequestList = () => navigate(`/profiles/user/pages/${user_id}/settings/moderation-requests`)


    return (
        <div className={styles.screen}>
            <Header title={t('pageSetting')} goBack={goBack} />
            <div className={styles.content}>
                <div onClick={goToInviteAdmin} className={styles.containerOption} >
                    <TbSend className={styles.iconOption} />
                    <div className={styles.textContainerOption}>
                        <p className={styles.textOption}>
                            {t('inviteAnAdministrator')}
                        </p>
                        <p className={styles.subtextOption}>
                            {t('inviteAnAdministratorDesc')}
                        </p>
                    </div>
                </div>
                <div className={styles.dividerOption} />
                <div onClick={goToRequestList} className={styles.containerOption}>
                    <FiMail className={styles.iconOption} />
                    <div className={styles.textContainerOption}>
                        <p className={styles.textOption}>
                            {t('listOfModerationRequests')}
                        </p>
                        <p className={styles.subtextOption}>
                            {t('listOfModerationRequestsDesc')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageSetting