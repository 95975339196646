const initialState = {
    conversations: [],
    message_number: 0,
    buddies: [],
    messages: [],
};

const OneToOneReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'REFRESH_MESSAGE_NUMBER':
            return {
                ...state,
                message_number: action.payload,
            }

        case 'REFRESH_CONVERSATION':
            return {
                ...state,
                conversations: action.payload,
            }

        case 'CLEAR_CONVERSATION':
            return {
                ...state,
                conversations: [],
            }

        case 'REFRESH_BUDDIES':
            return {
                ...state,
                buddies: action.payload,
            }

        case 'CLEAR_BUDDIES':
            return {
                ...state,
                buddies: [],
            }

        default:
            return state;

    }
}

export default OneToOneReducer;