
import React from 'react'
import './Header.style.css'
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { IoCloseCircleSharp, IoCloseSharp } from 'react-icons/io5';


const Header = ({
  title = 'Screen',
  type = 'screen',
  goBack
}) => {

  return (
    <div className='header-componant'>
      {type === 'screen' ?
        <button onClick={goBack} className='button-back' >
          <MdOutlineKeyboardBackspace size={32} style={{ width: 32, height: 32 }} />
        </button>
        :
        <button onClick={goBack} className='button-back' >
          <IoCloseCircleSharp size={32} style={{ width: 32, height: 32 }} />
        </button>
      }
      <h5 className='title'>
        {title}
      </h5>
    </div>
  )
}

export default Header