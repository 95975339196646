import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineDisconnect } from 'react-icons/ai'
import { FaUserTimes } from 'react-icons/fa'
import { TbPlugConnected } from 'react-icons/tb'
import { api } from '../../../api/api'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import ProfileCard from '../../ProfileCard/ProfileCard'

const FanOf = ({
    item,
    user,
    refresh
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const [isLoading, setIsLoading] = useState(false)


    const becomeOrDeleteFan = async () => {
        try {
            setIsLoading(true)
            const body = { user_id: item?.user_id }
            const request = await api(
                'api/profile/fan-treatment',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            console.log(response, 'RESPONSE OF BECOME OR DELETE FAN')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    refresh()

                    //   if (response?.notification) {
                    //     sendNotification(user_id, {
                    //       title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
                    //       body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} est devenu votre fan.`,
                    //       url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
                    //     })
                    //   }

                }
            }
            setIsLoading(false)
        } catch ({ message }) {
            setIsLoading(false)
            throw new Error(message)
        }
    }

    const renderButton = () => {
        if (authData?.audience?.followers.some(value => value?.user_id == item?.user_id)) {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button'
                    style={{
                        width: '160px',
                        fontSize: "13px",
                        backgroundColor: 'white',
                        color: "#1880a1",
                        fontWeight: '600',
                        border: '1.8px #1880a1 solid'
                    }}>

                    {!isLoading ?
                        <>
                            <AiOutlineDisconnect size={18} style={{ marginRight: '5px' }} />
                            {t('you_are_fan')}
                        </>
                        :
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant="secondary"
                        />
                    }

                </button>
            )
        } else {
            return (
                <button
                    onClick={becomeOrDeleteFan}
                    className='button'
                    style={{
                        width: '160px',
                        fontSize: "13px",
                        backgroundColor: '#1880a1',
                        color: "white",
                    }}>

                    {!isLoading ?
                        <>
                            <TbPlugConnected size={18} style={{ marginRight: '5px' }} />
                            {t('become_fan')}
                        </>
                        :
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant="secondary"
                        />
                    }

                </button>
            )

        }

    }


    return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{ width: '70%' }}>
                <ProfileCard
                    user={item}
                    pictureSize={55}
                />
            </div>
            {authData?.user?.user_id == user?.user_id &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '30%', paddingRight: '15px' }}>
                    {renderButton()}
                </div>
            }
        </div>
    )
}

export default FanOf