import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaUserTimes } from 'react-icons/fa'
import { api } from '../../../api/api'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import ProfileCard from '../../ProfileCard/ProfileCard'

const Buddy = ({
    user,
    item,
    refresh
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const [isLoading, setisLoading] = useState(false)

    const deleteBuddy = async () => {
        try {
            setisLoading(true)
            const body = { user_id: item?.user_id }
            const request = await api(
                'api/profile/delete-buddy',
                'DELETE',
                body,
                accessToken,
            )
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {
                    refresh()
                }
            }
            setisLoading(false)
        } catch ({ message }) {
            setisLoading(false)
            throw new Error(message)
        }
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{ width: '65%' }}>
                <ProfileCard
                    user={item}
                    pictureSize={55}
                />
            </div>
            {authData?.user?.user_id == user?.user_id &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '35%', paddingRight: '15px' }}>
                    <button
                        onClick={deleteBuddy}
                        className='button'
                        style={{
                            width: '200px',
                            backgroundColor: 'white',
                            color: "#1880a1",
                            fontSize: '13px',
                            fontWeight: '600',
                            border: '1.8px #1880a1 solid'
                        }}>

                        {!isLoading ?
                            <>
                                <FaUserTimes size={18} style={{ marginRight: '5px' }} />
                                {t('you_are_buddy')}
                            </>
                            :
                            <Spinner
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                                variant="secondary"
                            />
                        }
                    </button>
                </div>
            }
        </div>
    )
}

export default Buddy