import { useRecentReducer } from "../ReducerHooks/ReducerHooks"


export default function useReducerRecentAction() {

    const { store: RecentStore } = useRecentReducer()

    const REFRESH_RECENT_OPINIONS = (data) => {
        if(data.length == 0){
            return
        }
        RecentStore({
            type: 'REFRESH_RECENT_OPINIONS',
            payload: data
        })
    }


    const ADD_RECENT_OPINIONS = (data) => {
        if(data.length == 0){
            return
        }
        RecentStore({
            type: 'ADD_RECENT_OPINIONS',
            payload: data
        })
    }

    const UPDATE_RECENT_OPINION = (data) => {
        if(!data){
            return
        }
        RecentStore({
            type: 'UPDATE_RECENT_OPINION',
            payload: data
        })
    }

    const UPDATE_RECENT_VIEW_OPINION = (data) => {
        if(!data){
            return
        }
        RecentStore({
            type: 'UPDATE_RECENT_VIEW_OPINION',
            payload: data
        })
    }

    const UPDATE_RECENT_VOTE = (data) => {
        if(!data){
            return
        }
        RecentStore({
            type: 'UPDATE_RECENT_VOTE',
            payload: data
        })
    }

    const DELETE_RECENT_OPINION = (data) => {
        if(!data){
            return
        }
        RecentStore({
            type: 'DELETE_RECENT_OPINION',
            payload: data
        })
    }

    const MUTED_USER_RECENT_OPINION = (data) => {
        if (!data) {
            return
        }
        RecentStore({
            type: 'MUTED_USER_RECENT_OPINION',
            payload: data
        })
    }

    const CLEAR_RECENT_OPINIONS = () => {
        RecentStore({
            type: 'CLEAR_RECENT_OPINIONS'
        })
    }

    return { REFRESH_RECENT_OPINIONS, ADD_RECENT_OPINIONS, UPDATE_RECENT_OPINION, UPDATE_RECENT_VIEW_OPINION, UPDATE_RECENT_VOTE, DELETE_RECENT_OPINION, MUTED_USER_RECENT_OPINION, CLEAR_RECENT_OPINIONS }

}
