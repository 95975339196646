import React, { useState } from 'react'
import styles from './Requests.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useNavigate } from 'react-router-dom'
import Header from '../../../components/Header/Header'
import { color } from '../../../hooks/Utils/color'
import { TbMailForward} from 'react-icons/tb';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
import { AiOutlineFileAdd } from 'react-icons/ai';


const Requests = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const [userLanguage, setUserLanguage] = useState(i18n.language)
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)
    const goToNewRequest = () => navigate('/intranet/request/new')
    const goToRequestSent = () => navigate('/intranet/request/sent')
    const goToRequestReceived = () => navigate('/intranet/request/received')


    return (
        <div className={styles.screen}>
            <Header title={t('myRequests')} goBack={goBack} />
            <div className={styles.container}>

                <div onClick={goToNewRequest} className={styles.pressable}>
                    <AiOutlineFileAdd color={color.info} className={styles.iconPressable} />
                    <div>
                        <h6 className={styles.titlePressable}>{t('newRequest')}</h6>
                        <p className={styles.descPressable}>Rédiger une nouvelle de demande.</p>
                    </div>
                </div>

                <div onClick={goToRequestSent} className={styles.pressable}>
                    <TbMailForward color={color.success} className={styles.iconPressable}/>
                    <div>
                        <h6 className={styles.titlePressable}>{t('requestSent')}</h6>
                        <p className={styles.descPressable}>Liste des demandes envoyées.</p>
                    </div>
                </div>

                <div onClick={goToRequestReceived} className={styles.pressable}>
                    <MdOutlineMarkEmailUnread color={color.danger} className={styles.iconPressable}/>
                    <div>
                        <h6 className={styles.titlePressable}>{t('requestsReceived')}</h6>
                        <p className={styles.descPressable}>Liste des demandes reçues</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Requests