const initialState = {
    people: [],
};

const PeopleMatchReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'REFRESH_PEOPLE':
            return {
                ...state,
                people: action.payload,
            }

        case 'CLEAR_PEOPLE':
            return {
                ...state,
                people: [],
            }

        default:
            return state;

    }
}

export default PeopleMatchReducer;