import React, { useEffect, useState } from 'react'
import './Notifications.style.css'
import { useAccessToken, useAuthReducer, useNotificationReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import NotifItem from './NotifItem/NotifItem'

const Notifications = () => {

  const { data: authData } = useAuthReducer()
  const { data: notifData } = useNotificationReducer()
  const accessToken = useAccessToken()


  return (
    <div className='notifications'>
      {notifData?.notifications?.sort((a, b) => (b?.notif_id > a?.notif_id) ? 1 : -1)?.map((notif) => (
        <NotifItem
          key={notif.notif_id.toString()}
          notif={notif}
        />
      ))}
    </div>
  )
}

export default Notifications