const en = {
    translation: {
        /*=========== LOGIN */
        app_name: "Gandour Social Hub",
        public_posts: "Gandour",
        download_app: 'Téléchargez',
        download_myopinion: "Téléchargez Gandour Social Hub",
        with_myopinion_share_and_stay: "Découvrez Gandour Social Hub - votre passerelle vers une collaboration améliorée, le partage des connaissances et le réseautage. Grâce à notre plateforme sécurisée et conviviale, vous pouvez vous connecter avec vos collègues professionnels de l'Gandour, accéder à des ressources précieuses et participer à des discussions enrichissantes. Que vous recherchiez des informations sur des projets, le partage de bonnes pratiques ou l'établissement de liens au sein de la communauté de l'Gandour, Gandour Social Hub est votre destination privilégiée. Rejoignez-nous dès aujourd'hui et exploitez le pouvoir de la collaboration.",
        login_now_to_interact_with_thousands_of_people: "Connectez-vous maintenant pour interagir avec vos collègues de la Gandour.",
        connect_with: "Connectez vous avec",
        login_with_gmail: "Connexion avec Gmail",
        login_with_facebook: "Connexion avec facebook",
        login_with_apple: "Connexion avec Apple",
        create_an_account: "Créer un compte",
        reset: "Réinitialiser",
        or: "ou",
        and: "et",
        login: "Connexion",
        logout: 'Déconnexion',
        email: "Adresse mail",
        password: "Mot de passe",
        new_password: "Nouveau mot de passe",
        confirm_password: "Confirmez le mot de passe",
        forgot_password: "Mot de passe oublié",
        forgot_your_password: "Mot de passe oublié ?",
        please_enter_your_account_email_below_to_reset_it: "Veuillez entrer l'email de votre compte ci-dessous pour le réinitialisé.",
        terms_and_conditions: "Conditions générales",
        privacy_policy: "Politique de confidentialité",
        french_france: "Français (France)",
        english_us: "Anglais (US)",
        link_terms_of_use: "https://payesha.com/legal/fr/conditions-generales.html",
        link_privacy_policy: "https://payesha.com/legal/fr/conditions-generales.html",
        incorrect_email_address_or_password: "Adresse mail ou mot de passe incorrect.",
        an_error_occurred_please_try_again_later: "Une erreur s'est produite, veuillez réessayer plus tard.",
        trend_for_you: "Tendance pour vous",
        enter_your_new_password: "Entrez votre nouveau mot de passe",

        /*============ REGISTER */
        sign_up: "Inscrivez-vous",
        you_do_not_have_an_account: "Vous n'avez pas de compte ? Créez votre compte, cela prend moins d'une minute",
        name: "Prénom",
        surname: "Nom",
        nickname: "Pseudonyme",
        civility: "Civilité",
        phone_number: "Numéro de téléphone",
        city: "Ville",
        country: "Pays",
        indicative: "Indicatif",
        "register": "S'inscrire",
        "confirm": "Confirmer",
        "continue": "Continuer",
        "confirmation_code": "Code de confirmation",
        "account_verification": "Vérification de compte",
        "a_6_digit_code_has_just_been_sent": "Un code de 6 chiffres vient d'être envoyé à votre adresse mail",
        and_the_telephone_number_associated_with_the_account: "et au numéro de téléphone associé au compte",
        "if_you_dont_see_the_email_look_in_your_spam": "Si vous ne voyez pas le mail regardez dans vos spams.",
        "password_reset": "Réinitialisation de mot de passe.",


        /*========== ERROR */
        "your_surname_must_contain_at_least_4_characters": "Votre nom doit contenir au moins 4 caractères",
        "your_nickname_must_contain_at_least_4_characters_only_underscore": "Votre pseudonyme doit contenir au moins 4 caractères, le tiret du bas ( _ ) est autorisé.",
        'invalid_confirmation_code': "Code de confirmation invalide.",
        'invalid_confirmation_code_at_least_6_characters': "Code de confirmation invalide, le code de confirmation doit contenir au moins 6 caractères.",
        'invalid_email': "Adresse mail invalide.",
        'invalid_password': "Mot de passe invalide, votre mot de passe doit contenir au moins 8 caractères.",
        'passwords_do_not_match': 'Les mots de passe ne correspondent pas.',

        /*============ ERROR REQUEST */
        "the_email_is_already_taken": "L'adresse mail est déjà utilisé.",
        the_email_is_already_taken_or_you_are_not_authorized: "L'adresse mail est déjà utilisé ou vous n'êtes pas autorisé à utiliser cette application.",
        "no_internet_connection": "Aucune connexion internet, vérifiez votre connexion internet et réessayer.",
        "this_email_does_not_have_an_account": "Ce Mail n'a pas de compte.",

        /*========= OPINIONS */
        "audience": "Audience",
        "public": "Publique",
        "recent": 'Récentes',
        "random": 'Aléatoires',
        "viral": 'Virales',
        "for_you": 'Pour toi',
        loading: 'Chargement...',
        "loading_opinion": 'Opinions en chargement...',
        "loading_recent": 'Récentes en chargement...',
        "loading_random": 'Aléatoires en chargement...',
        "loading_for_you": 'Pour toi en chargement...',
        "loading_viral": 'Virales en chargement...',
        "loading_audience": 'Contacts en chargement...',
        "loading_profile": 'Profil en chargement...',

        //=========== PROFILE
        profile: 'Profil',
        profile_picture: 'Photo de profil',
        cover_picture: 'Photo de couverture',
        setting: 'Paramètres',
        language: 'Langue',
        account: 'Compte',
        update: 'Mettre à jour',
        update_your_login_settings_associated_with_your_account: 'Mettez à jour vos paramètres de connexion associés à votre compte.',

        account_suspended: 'Compte suspendu',
        account_suspended_desc: 'MyOpinion suspend les comptes qui enfreignent les Règles de MyOpinion.',
      
        account_blocked: 'Cet utilisateur vous a bloqué',
        account_blocked_desc: 'Vous ne pouvez pas accéder à ce profil car cet utilisateur vous a bloqué.',
      
        account_desactived: 'Compte désactivé',
        account_desactived_desc: 'Vous ne pouvez pas avoir accès à ce profil car cet utilisateur à désactivé son compte.',
        
        for_more_information_please_carefully_read_our: 'Pour plus d\'informations, veuillez lire attentivement notre',


        publish: 'Publier',
        inbox: 'Messagerie',
        groups: 'Groupes',
        group: 'Groupe',
        contact: 'Contact',
        contacts: 'Contacts',
        search: 'Recherche',
        buddy: "Contact",
        buddies: "Contacts",
        become_buddy: 'S\'abonner',
        you_are_buddy: 'Abonné(e)',
        fan: 'Abonné',
        fans: 'Abonnés',
        fan_of: 'Abonnement',
        become_fan: 'Suivre',
        you_are_fan: 'Suivi(e)',
        approve: 'Accepter',
        approve_request: 'Accepter demande',
        Received: 'Reçues',
        request: 'Demande',
        sent: 'Envoyées',
        cancel: ' Annuler',
        block: 'Bloquer',
        blocked: 'Bloqué(s)',
        unlock: 'Débloquer',
        no_buddy: 'Aucun contact',

        network: 'Réseau',
        follower: 'Abonné',
        followers: 'Abonnés',
        following: 'Abonnement',

        like: 'J\'aime',
        likes: 'J\'aimes',
        post: 'Publication',
        posts: 'Publications',
        no_user: 'Aucun contact',

        //========== BADGE

        /* Goal */
        goal: 'Objectif de vie',
        love: 'Amour',
        god: 'Dieu',
        money: 'Argent',
        power: 'Pouvoir',
        fame: 'Célébrité',
        family: 'Famille',
        humanitary: 'Humanitaire',
        patriot: 'Patriote',
        peace: 'Paix',
        freedom: 'Freedom',
        saveThePlanet: 'Sauver La Planète',


        /* Business */
        business: 'Travail',
        technology: 'Technologie',
        finance: 'Management & Finance',
        arts: 'Arts',
        health: 'Santé',
        foodIndustry: 'Nourriture',
        education: 'Éducation',
        law: 'Droit',
        restaurant: 'Restauration',
        communication: 'Communication',
        services: 'Services',
        proAthlete: 'Athlète Pro',
        agriculture: 'Agriculture',


        /* Hooby */
        hobby: 'Loisir',
        travel: 'Voyage',
        sport: 'Sport',
        cinema: 'Cinema',
        nightLife: 'Soirée dansante',
        communityLife: 'Vie communautaire',
        art: 'Art',
        gardening: 'Jardinage',
        hike: 'Randonnée',
        hunting: 'Chasse',
        fishing: 'Pêche',
        music: 'Musique',
        videoGame: 'Jeux vidéo',


        /* Food */
        food: 'Nourriture',
        vegetarian: 'Végétarien',
        meat: 'Viande',
        fish: 'Poisson',
        bread: 'Pain',
        cake: 'Gâteau',
        pasta: 'Pâtes',
        soup: 'Soupe',
        seaFood: 'Fruits de mer',
        cereals: 'Céréales',
        dairy: 'Produits laitiers',
        rice: 'Riz',
        wildMeat: 'Viande de brousse',

        /* Color */
        color: 'Couleur',
        red: 'Rouge',
        blue: 'Bleu',
        green: 'Vert',
        yellow: 'Jaune',
        orange: 'Orange',
        black: 'Noir',
        white: 'Blanc',
        purple: 'Violet',
        pink: 'Rose',
        brown: 'Marron',
        grey: 'Gris',
        cyan: 'Cyan',
        beige: 'Beige',

        select_at_least_one_badge_per_category: 'Sélectionnez au moins un badge par catégorie.',
        you_cannot_modify_your_badges: 'Vous ne pouvez pas modifier vos badges car votre dernière modification date de moins de 30 jours.',

        edit_profile: 'Modifier profil',
        edit: 'Modifier',
        profile_title: 'Titre du profil',
        profile_description: 'Description du profil',
        profile_email: 'Email du profil',
        web_site: 'Site web',

        subscribe_to_email_notification: 'Se désabonner au notification par mail',
        yes: 'Oui',
        no: 'Non',
        security_and_password: 'Sécurité et mot de passe',
        balance: 'Solde',
        subscriptions: 'Abonnements',

        current_password: 'Mot de passe actuel',
        confirmation_of_new_password: 'Confirmation du nouveau mot de passe',
        your_current_password_is_incorrect: 'Votre mot de passe actuel est incorrect.',
        your_password_has_been_updated: 'Votre mot de passe a été mis à jour.',

        coming_soon: 'Bientôt disponible.',
        reload: 'Recharger',
        collect: 'Encaisser',
        no_transactions_available: 'Aucune transaction disponible',

        buying_coins: 'Digital Gifts',
        verified_account: 'Compte vérifié',
        premium_account: 'Compte premium',
        sponsored_opinion: 'Opinion sponsorisée',
        send_gift_to: 'Envoi de gift à',
        gift_received_from: 'Gift reçu de',
        monthly_subscription: 'Souscription mensuelle',
        annual_subscription: 'Souscription annuelle',

        // create opinion
        create_opinion: 'Créer une publication',
        activate_vote: 'Activer le vote',
        enter_your_opinion: 'Entrez votre opinion',
        publishing_in_progress: 'Publication en cours...',
        add_to_your_opinion: 'Ajouter à votre poste',
        publish: 'Publier',
        choice: 'Choix',
        at: 'à',
        mandatory: 'obligatoire',
        you_must_add_a_description_to_your_opinion: 'Vous devez ajouter une description à votre opinion.',
        the_first_two_choices_are_mandatory: 'Les deux premiers choix sont obligatoires.',
        video_too_large_please_select_a_video_less_than_50mb: 'Video trop volumineuse, veuillez sélectionner une vidéo de moins de 50 mb.',

        mute: 'Masquer',
        unmute: 'Démasquer',
        report: 'Signaler',
        download: 'Télécharger',
        delete: 'Supprimer',
        available: 'Disponible',
        unavailable: 'Indisponible',
        sorry: 'Désolé',
        save: 'Enregistrer',
        
        // REPORT
        opinionTitle: "l'opinon",
        commentTitle: 'le commentaire',
        conversationTitle: 'la conversation',
        reportOpinion: {
            sexualContent: 'Contenu à caractère sexuel',
            violent: 'Contenu violent ou répugnant',
            hateful: 'Contenu haineux ou abusif',
            harassment: 'harcèlement ou intimidation',
            harmful: 'Actes nuisibles ou dangereux',
            childAbuse: 'Maltraitance',
            infrigeRight: 'Viole mes droits',
            terrorism: 'Favorise le terrorisme',
            spam: 'Spam ou misleadine',
            other: 'Autres',
        },
        reportComment: {
            commercial: 'Contenu commercial indésirable ou spam',
            sexual: 'Pornographie ou matériel sexuellement explicite',
            childAbuse: 'Maltraitance',
            hateSpeech: 'Discours haineux ou violence graphique',
            harassment: 'harcèlement ou intimidation',
        },
        other: 'Autres',

        responseReportTitle:
            'Nous sommes désolés de cette mauvaise expérience sur Gandour Social Hub.',
        responseReportDescription:
            'Votre signalement a été envoyé avec succès. Nous allons le traiter et prendre les actions adéquates si nécessaires.',

        responseOpinionTitle: 'Opinion signalée',
        responseOpinionDescription:
            'Nous allons analyser et prendre les actions nécessaires.',

        responseCommentTitle: 'Commentaire signalé',
        responseCommentDescription:
            'Nous allons analyser et prendre les actions nécessaires.',

        responseConversationTitle: 'Conversation signalée',
        responseConversationDescription:
            'Nous allons analyser et prendre les actions nécessaires.',

        enter_the_reason: 'Entrez la raison...',


        opinion_for_android: 'Opinion pour Android',
        opinion_for_ios: 'Opinion pour IOS',
        opinion_for_huawei: 'Opinion pour Huawei',
        opinion_for_web: 'Opinion pour Web',
        opinion_for: 'Opinion pour',

        search: 'Recherche',
        person: 'Personne',
        people: 'Personnes',
        search_by_badge: 'Recherche par badge',
        search_by_category: 'Recherche par catégorie',

        my_agoras: 'Mes groupes',
        my_requests: 'Mes demandes',
        my_invites: 'My invites',
        create_agora: 'Créer un goupe',
        create: 'Créer',
        remove: 'Retirer',
        title: 'Titre',
        participate: 'Participer',
        name_of_the_group: 'Nom du groupe',
        description_of_the_group: 'Description du groupe',
        private_group: 'Groupe privé',
        public_group: 'Groupe publique',
        publish_in_the_group: 'Publier dans le groupe',

        no_conversations_available: 'Aucune conversation disponible',
        no_conversations_available_desc: 'Saisissez le nom de votre contact à qui vous voulez écrire.',
        agora_you_might_be_interested_in: 'Des groupes qui pourraient vous intéresser',
        show_your_last_seen: 'Afficher votre dernière connexion',
        hide_your_last_seen: 'Masquer votre dernière connexion',

        sharing_your_last_connection_has_been_successfully_activated: 'Le partage de votre dernière connexion a été activé avec succès.',
        sharing_your_last_login_has_been_successfully_disabled: 'Le partage de votre dernière connexion a été désactivé avec succès.',

        delete_all_messages: 'Supprimer tous les messages',
        write_your_message: 'Écrivez votre message',

        people_you_match: 'Des personnes qui vous correspondent',
        colleagues_who_match: 'Des collègues qui correspondent',

        supervisor: 'Supérieur hiérarchique',
        companyOrEntity: 'Entreprise ou Entité',
        division: 'Direction ',
        departement: 'Département',
        unit: 'Service',

        member: 'Membre',
        members: 'Membres',
        view_all: 'Voir tout',
        poll: 'Sondage',

        privacy: 'Confidentialité',
        event: 'Événement',
        events: 'Événements',
        event_title: 'Titre de l\'événement',
        event_type: 'Type de l\'événement',
        public_event: 'Événement public',
        location_of_the_event: 'Lieu de l\'événement',
        event_date: 'Date de l\'événement',
        event_time: 'heure de l\'évènement',

        enter_the_event_title: 'Entrez le titre de l\'événement.',
        enter_the_event_description: 'Entrez la description de l\'événement.',
        enter_the_location_Of_the_event: 'Entrez le lieu de l\'événement.',
        enter_the_date_of_the_event: 'Entrez la date de l\'événement.',
        enter_the_time_of_the_event: 'Entrez l\'heure de l\'événement.',

        participate: 'Participer',
        request_sent: 'Demande envoyée',

        // INTRANET
        hr_search: 'Recherche RH',
        myRequests: 'Mes demandes',
        myRequestDesc: 'Gestion des demandes RH: contrat,  demande de congé, communication etc.',
        myBalance: 'Mon solde de congé',
        myBalanceDesc: 'Ce service permet de consulter en toute sécurité vos bulletins de solde mensuels.',
        myEvents: 'Mes événements',
        myEventDesc: 'Découvrez toutes nos activités pour vos événements d\'entreprise et réservez un moment incroyable en seulement quelques clics.',
        myCalendar: 'Mon calendrier',
        myCalendarDesc: 'Un outil de planification utile pour planifier les quarts de travail des employes.',
        sharedDocuments: 'Documents partagés',
        sharedDocumentDesc: 'L\'intranet officiel de la Gandour.',
        newRequest: 'Nouvelle demande',
        request: 'Demande',
        requests: 'Demandes',
        requestsSent: 'Demandes envoyées',
        requestSent: 'Demande envoyée',
        requestsReceived: 'Demandes reçues',
        noRequestAvailable: 'Aucune demande disponible',
        noEventAvailable: 'Aucun évènement disponible',
        weEncounteredAnErrorPleaseTryAgain: 'Nous avons rencontré une erreur, veuillez réessayer',
        treatmentFailure: 'Échec du traitement',
        requestSubmitted: 'Demande soumis',
        theRequestHasBeenSubmittedSuccessfully: 'La demande a été soumis avec succès.',
        requestValidated: 'Demande validée',
        validateRequest: 'Valider la requête',
        rejectRequest: 'Rejeter la requête',
        treatRequest: 'Traiter la demande',

        theRequestHasBeenSuccessfullyValidated: 'La demande a été validée avec succès.',
        requestRejected: 'Demande rejetée',
        theRequestWasSuccessfullyRejected: 'La demande a été rejetée avec succès.',
        requestDeleted: 'Demande supprimée',
        theRequestWasSuccessfullyDeleted: 'La demande a été supprimée avec succès.',

        subjectRequest: 'Objet de la demande',
        descriptionRequest: 'Description de la demande',
        optional: 'Optionnel',
        mandatory: 'Obligatoire',

        requestSentBy: 'Demande envoyée par',
        mailAddress: 'Adresse mail',
        approuved_users: 'Utilisateurs approuvés',
        pending_users: 'Utilisateurs en attente',

        // STATUS
        draft: 'Brouillon',
        pending: 'En attente',
        participate: 'Participer',
        approve: 'Approuver',
        dismiss: 'Rejeter',
        validate: 'Valider',
        validated: 'Validée',
        rejected: 'Rejetée',
        treated: 'Traitée',
        requestType: 'Type de demande',
        statusRequest: 'Status de la demande',
        documentType: 'Type de document',
        administrativeDocument: 'Document administratif',

        departureDate: 'Date de départ',
        departureTime: 'Heure de départ',
        returnDate: 'Date de retour',
        returnTime: 'Heure de retour',

        requestDetails: 'Détails de la demande',
        eventDetails: 'Détails de lévènement',

        pendingRequests: 'Demandes en attente',
        pendingRequest: 'Demande en attente',
        requestApproved: 'Demande approuvée',
        requestsApproved: 'Demandes approuvées',

        location: 'Lieu',
        day: 'jour',
        days: 'jours',
        date: 'Date',
        at: 'à',

        mandatory: 'Obligatoire',

        submit: 'Soumettre',
        delete: 'Supprimer',
        numberOfDays: 'Nombre de jour',

        select_the_type_of_request: 'Sélectionnez le type de demande.',
        select_the_document_type: 'Sélectionnez le type de document.',
        enter_departure_date: 'Entrez la date de départ.',
        enter_departure_time: 'Entrez l\'heure de départ.',
        enter_return_date: 'Entrez la date de retour.',
        enter_return_time: 'Entrez l\'heure de retour.',

        selectTheDocument: 'Sélectionnez le document.',
        acquiredDayOff: 'Jour de congé acquis',
        lastupdatedOn: 'Dernière mise à jour le',

        myAIAssistant: 'Mon Assistant IA',
        myAIAssistantDesc: 'Assistant virtuel d\'ordinateur intelligent. Résolvez vos requêtes informatiques les plus courantes en les automatisant avec un chatbot intelligent.',
        chatDesc: 'Apprenez à utiliser des modèles de langage basés sur le chat.',

        textCompletion: 'Modification de text',
        textCompletionDesc: 'Découvrez comment générer ou modifier du texte.',

        embeddings: 'Incrustations',
        embeddingsDesc: 'Apprenez à rechercher, classer et comparer du texte.',

        speechToText: 'Transcription audio en texte',
        speechToTextDesc: 'Apprenez à transformer l\'audio en texte.',


        speechToTranslateInEnglish: 'Traduction d\'audios en texte Anglais.',
        speechToTranslateInEnglishDesc: 'Apprenez à traduire vos audios en du text anglais.',
    

        imageGeneration: 'Génération d\'images',
        imageGenerationDesc: 'Apprenez à générer ou éditer des images.',

        fineTuning: 'Affiner les modèles',
        fineTuningDesc: 'Découvrez comment entraîner un modèle pour votre cas d\'utilisation.',

        history: 'Historique',
        newConversation: 'Nouvelle conversation',
        sorry: 'Désolé',
        anErrorHasOccurredPleaseTryAgain: 'Une erreur est survenue, veuillez réessayer',
    
        youAreNotAuthorizedToUseThisService: 'Vous n\'est pas autorisé à utiliser ce service.',
        youHaveReachedTheAuthorizedLimit: 'Désolé, vous avez atteint votre limite autorisé pour ce service.',
    


        // ADD PAGE
        my_accounts: 'Mes comptes',
        add_an_account: 'Ajouter un compte',
        login_to: 'Se connecter à',
        delete_this_account: 'Supprimer ce compte',
        this_account_has_already_been_added: 'Cet compte à déjà été ajouté.',


        // CREATE PAGE
        my_pages: 'Mes pages',
        create_a_page: 'Créer une page',
        create_a_page_description: 'Vos collègues accèdent à votre Page pour en savoir plus sur vous. Veuillez entrer toutes les informations dont ils pourraient avoir besoin.',
        pageName: 'Nom de la page',
        pageNameError: 'Entrez le nom de la page.',
        pageNickname: 'Pseudonyme de la page',
        pageNicknameError: 'Entrez le pseudonyme de la page.',
        descriptionOfThePage: 'Description de la page.',
        pageVisibility: 'Page visibility',
        descriptionOfThePageError: 'Entrez la description de la page.',
        emailOfThePage: 'Email de la page',
        emailOfThePageError: 'Entrez l\'Email de la page.',
        country: 'Pays',
        countryError: 'Sélectionnez un pays.',
        phone: 'Téléphone',
        category: 'Categorie',
        categories: 'Catégories',
        webSite: 'Site web',
        createPage: 'Créer une page',
        myPages: 'Mes pages',
        pageCreated: 'Page créée',
        pageCreatedSuccessfully: 'Page créée avec succès.',
        thisNicknameIsAlreadyInUse: "Ce pseudonyme est déjà utilisé.",
        loginTo: 'Se connecter à',


        // PAGE MANAGE
        pageSetting: 'Paramètres de la page',
        inviteAnAdministrator: 'Inviter un administrateur',
        inviteAnAdministratorDesc: 'Envoyer une proposition de modération à un utilisateur.',
        listOfModerationRequests: 'Liste des demandes de modérations',
        listOfModerationRequestsDesc: 'Gestion des demandes de modérations.',
        community_Rules_and_Privacy_Policies: 'Règles communautaires et politiques de confidentialité',

        noModerationRequestAvailable: 'Aucune demande de modération disponible',
        noUserFound: 'Aucun utilisateur trouvé, lancez une recherche',
        selectARole: 'Sélectionnez un rôle.',
        inviteConfirmMessage: 'Souhaitez-vous envoyer une demande de moderation  à cet utilisateur ?',
        inviteSentMessageTitle: 'Demande de modération envoyée',
        inviteSentMessageDesc: 'Demande de modération envoyée avec succès.',
        inviteErrorMessage: 'Envoyer une demande de modération un utilisateur.',
        invited: 'Invité(e)',
        invite: 'Inviter',
        confirm: 'Confirmer',
        cancel: 'Annuler',
        delete: 'Supprimer',
        deleteRequest: 'Supprimer demande',
        deleteRequestDesc: 'Voulez-vous vraiment supprimer cette demande ?',

        moderationConfirmMessage: 'vous a envoyé une demande de moderation, souhaitez-vous être un modérateur de cette page ?',
        moderationRequestAccepted: 'Demande de moderation acceptée',
        moderationRequestAcceptedDesc: 'Demande de moderation acceptée avec succès.',

        no_data_available: 'Aucune donnée disponible',
        no_request_available: 'Aucune demande disponible',

    },
}

export default en;