import React, { useState } from 'react'
import styles from './NewRequest.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { useNavigate } from 'react-router-dom'
import Header from '../../../../components/Header/Header'
import DropdownRequestType from '../../../../components/DropdownRequestType/DropdownRequestType'
import TextInput from '../../../../components/TextInput/TextInput'
import ButtonSubmit from '../../../../components/ButtonSubmit/ButtonSubmit'
import DropdownRequestDocType from '../../../../components/DropdownRequestDocType/DropdownRequestDocType'
import dayjs from 'dayjs'
import { api } from '../../../../api/api'

const NewRequest = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const [userLanguage, setUserLanguage] = useState(i18n.language)
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    // const [userSupervisor, setUserSupervisor] = useState(`${user?.upper_hierarchy?.user_surname} ${user?.upper_hierarchy?.user_name}`)

    const [type, setType] = useState(null)
    const [document, setDocument] = useState(null)
    const [object, setObject] = useState(null)
    const [description, setDescription] = useState(null)

    const [departureDate, setDepartureDate] = useState(null)
    const [departureTime, setDepartureTime] = useState(null)
    const [returnDate, setReturnDate] = useState(null)
    const [returnTime, setReturnTime] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [isSaving, setIsSaving] = useState(false)

    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)


    const handleValidateRequest = async (e) => {
        try {
            e.preventDefault();

            if (!type) {
                setErrors({ type: t('select_the_type_of_request') })
                return
            }
            if (type === 3 && !document) {
                setErrors({ document: t('select_the_document_type') })
                return
            }
            if (type !== 3 && type !== 4 && !departureDate) {
                setErrors({ departureDate: t('enter_departure_date') })
                return
            }
            if (type !== 3 && type !== 4 && !departureTime) {
                setErrors({ departureTime: t('enter_departure_date') })
                return
            }
            if (type !== 3 && type !== 4 && !returnDate) {
                setErrors({ returnDate: t('enter_departure_time') })
                return
            }
            if (type !== 3 && type !== 4 && !returnTime) {
                setErrors({ returnTime: t('enter_return_time') })
                return
            }
            setErrors({})

            setIsLoading(true)

            let data = {}

            if (type === 1 || type === 2) {
                data = {
                    hr_req_type_id: type,
                    hr_req_doc_id: document,
                    hr_req_object: object,
                    hr_req_descr: description,
                    hr_req_departure_date: dayjs(departureDate).format('YYYY-MM-DD'),
                    hr_req_departure_hour: departureTime,
                    hr_req_return_date: dayjs(returnDate).format('YYYY-MM-DD'),
                    hr_req_return_hour: returnTime,
                }
            } else {
                data = {
                    hr_req_type_id: type,
                    hr_req_doc_id: document,
                    hr_req_object: object,
                    hr_req_descr: description
                }
            }
            const request = await api('api/rh/hr_requests', 'POST', data, accessToken)
            const response = await request.json()
            console.log('Send new request response:', response)
            if (request?.ok && request?.status === 200) {
                handleSubmit(response?.data?.hr_req_id)
            }else {
            setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Fetch validate request error:', error);
        }
    }

    const handleSubmit = async (hr_req_id) => {
        try {
            const request = await api(`api/rh/hr_requests/${hr_req_id}/submit`, 'POST', {}, accessToken)
            const response = await request.json()
            console.log('Submit requests response:', response)
            setIsLoading(false)
            if (request?.ok && request?.status === 200) {
                navigate(-1)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Submit requests error:', error);
        }
    }


    return (
        <div className={styles.screen}>
            <Header title={t('newRequest')} goBack={goBack} />
            <form onSubmit={handleValidateRequest} className={styles.container}>

                <div className={styles.inputContainer}>
                    <DropdownRequestType
                        title={t('requestType')}
                        value={type}
                        setValue={setType}
                    />
                    <span className={styles.textError}>
                        {errors.type}
                    </span>
                </div>

                {type === 3 &&
                    <div className={styles.inputContainer}>
                        <DropdownRequestDocType
                            title={t('documentType')}
                            value={document}
                            setValue={setDocument}
                        />
                        <span className={styles.textError}>
                            {errors.document}
                        </span>
                    </div>
                }

                {type !== 3 && type !== 4 &&
                    <div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div style={{ width: '48%' }}>
                                <span className={styles.titleInput}>
                                    {t('departureDate')}
                                </span>
                                <div>
                                    <TextInput
                                        placeholder={t('departureDate')}
                                        type='date'
                                        value={departureDate}
                                        setValue={setDepartureDate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <span className={styles.textError}>
                                    {errors.departureDate}
                                </span>
                            </div>

                            <div style={{ width: '48%' }}>
                                <span className={styles.titleInput}>
                                    {t('departureTime')}
                                </span>
                                <div>
                                    <TextInput
                                        placeholder={t('departureTime')}
                                        type='time'
                                        value={departureTime}
                                        setValue={setDepartureTime}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <span className={styles.textError}>
                                    {errors.departureTime}
                                </span>
                            </div>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div style={{ width: '48%' }}>
                                <span className={styles.titleInput}>
                                    {t('returnDate')}
                                </span>
                                <div>
                                    <TextInput
                                        placeholder={t('returnDate')}
                                        type='date'
                                        value={returnDate}
                                        setValue={setReturnDate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <span className={styles.textError}>
                                    {errors.returnDate}
                                </span>
                            </div>

                            <div style={{ width: '48%' }}>
                                <span className={styles.titleInput}>
                                    {t('returnTime')}
                                </span>
                                <div>
                                    <TextInput
                                        placeholder={t('returnTime')}
                                        type='time'
                                        value={returnTime}
                                        setValue={setReturnTime}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <span className={styles.textError}>
                                    {errors.returnTime}
                                </span>
                            </div>
                        </div>

                    </div>
                }

                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('subjectRequest')} ({t('optional')})
                    </span>
                    <div>
                        <TextInput
                            placeholder={t('subjectRequest')}
                            type='text'
                            value={object}
                            setValue={setObject}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>

                <div className={styles.inputContainer}>
                    <span className={styles.titleInput}>
                        {t('descriptionRequest')} ({t('optional')})
                    </span>
                    <div>
                        <textarea
                            placeholder={t('descriptionRequest')}
                            value={description}
                            onChange={setDescription}
                            style={{ width: '100%', minHeight: '70px' }}
                        ></textarea>
                    </div>
                </div>

                <ButtonSubmit
                    value={t('validate')}
                    isLoading={isLoading}
                    style={{
                        width: '200px',
                        color: 'white',
                        marginTop: '20px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />

            </form>
        </div>
    )
}

export default NewRequest