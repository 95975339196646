import React, { memo } from 'react'

const Audio = ({attachment}) => {

    return (
        <div>
            <audio controls style={{ width: '90%', height: '30px' }}>
                <source src={attachment.attach_url} />
            </audio>
        </div>
    )
}

export default memo(Audio)