import { useAuthReducer } from "../ReducerHooks/ReducerHooks"


export default function useReducerAuthAction() {

    const { store: AuthStore } = useAuthReducer()

    const LOGIN = (data) => {
        AuthStore({
            type: 'USER_LOGGED',
            payload: data
        })
    }

    const LOGOUT = () => {
        AuthStore({
            type: 'USER_LOGOUT'
        })
    }

    const UPDATE_PROFILE = (data) => {
        AuthStore({
            type: 'UPDATE_USER_PROFILE',
            payload: data
        })
    }

    const UPDATE_AUDIENCE = (data) => {
        AuthStore({
            type: 'UPDATE_USER_AUDIENCE',
            payload: data
        })
    }

    const UPDATE_LANGUAGE = (data) => {
        AuthStore({
            type: 'UPDATE_USER_LANGUAGE',
            payload: data
        })
    }

    return { LOGIN, LOGOUT, UPDATE_PROFILE, UPDATE_AUDIENCE, UPDATE_LANGUAGE }
}