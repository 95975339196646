import React, { useState } from 'react'
import styles from './ModerationCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import { api } from 'api/api'
import { Spinner } from 'react-bootstrap'


const ModerationCard = ({ 
    account,
    onRefresh =()=> null
}) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()

    const [isLoading, setIsLoading] = useState(false)
    const [isCancelling, setIsCancelling] = useState(false)

    const [showDialog, setShowDialog] = useState(false)

    const handleOpen = () => {
        setShowDialog(true)
    }

    const handleClose = () => {
        setShowDialog(false)
    }

    const handleConfirm = async () => {
        try {
            setIsLoading(true)
            const request = await api(`api/page_moderation/accept_request/${account?.up_id}`, 'POST', {}, accessToken)
            const response = await request.json()
            console.log('Accept moderation request response:', response)
            setIsLoading(false)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    onRefresh()
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.log('Search user response:', error)
        }
    }

    const handleDelete = async () => {
        try {
            setIsCancelling(true)
            const request = await api(`api/page_moderation/revoke_request/${account?.up_id}`, 'POST', {}, accessToken)
            const response = await request.json()
            console.log('Search user response:', response)
            setIsCancelling(false)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    handleClose()
                    onRefresh()
                }
            }
        } catch (error) {
            setIsCancelling(false)
            console.log('Search user response:', error)
        }
    }


    return (
        <div className={styles.container}>
            <ProfileCard user={account?.page} />
            <div className={styles.containerButton}>
                <Button onClick={handleConfirm} variant="contained" className={styles.button}>
                    <span className={styles.textButton}>{t('confirm')}</span>
                </Button>
                <Button onClick={handleOpen} variant="outlined" className={styles.button}>
                    <span className={styles.textButton}>{t('delete')}</span>
                </Button>
            </div>


            <Dialog
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t('deleteRequest')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('deleteRequestDesc')}
                    </DialogContentText>
                    {isCancelling &&
                        <div style={{ textAlign: 'center', paddingTop: '5px' }}>
                            <Spinner size='sm' />
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={handleDelete} autoFocus>
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default ModerationCard