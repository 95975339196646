import React, { useState } from 'react'
import styles from './PageCard.module.css'
import { List, ListItem, AddIcon, Typography, ListItemAvatar, ListItemButton, ListItemText, Dialog, DialogTitle, Avatar } from '@mui/material';
import { BiUserCircle } from 'react-icons/bi'
import { MdOutlineDeleteOutline } from 'react-icons/md'
import { AiOutlineClose } from 'react-icons/ai'
import { color } from 'hooks/Utils/color';
import { useNavigate } from 'react-router-dom';
import { useAccountReducer, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction';
import { useTranslation } from 'react-i18next';
import {AiOutlineSetting} from 'react-icons/ai'



const PageCard = ({
    account
}) => {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const { data: authReducerState } = useAuthReducer()
    const { state: accountReducerState } = useAccountReducer()
    const { state, dispatch } = useAccountReducer()
    const { LOGIN, LOGOUT } = useReducerAuthAction()

    const [isVisible, setIsVisible] = useState(false)

    const handleClickOpen = () => {
        setIsVisible(true);
    };

    const handleClose = () => {
        setIsVisible(false);
    };


    const gotoPageSetting = () => navigate(`/profiles/user/pages/${account?.page?.user_id}/settings`)


    const handleChangeAccount = () => {
        handleClose()
        dispatch({
            type: 'USER_CHANGE_ACCOUNT',
            payload: true
        })
        LOGOUT()
        setTimeout(() => {
            const data = {
                type: 'Bearer',
                access_token: account.access_token,
                user: account.page
            }
            LOGIN(data)
            navigate('/')
        }, 1000);
        setTimeout(() => {
            dispatch({
                type: 'USER_CHANGE_ACCOUNT',
                payload: false
            })
        }, 2000);
    }


    const handleDeleteAccount = () => {
        handleClose()
        dispatch({
            type: 'DELETE_ACCOUNT',
            payload: account?.page?.user_id
        })
    }


    return (
        <div>
            <div onClick={handleClickOpen} className={styles.container} >
                <img src={
                    account?.page?.profile?.prof_picture ?
                        account?.page?.profile?.prof_picture :
                        require('assets/images/background_opinion.jpg')}
                    className={styles.profilePicture}
                    alt=""
                />
                <h6 className={styles.name}>
                    {`${account?.page?.user_surname || ''} ${account?.page?.user_name || ''}`?.length < 15 ?
                        `${account?.page?.user_surname || ''} ${account?.page?.user_name || ''}` :
                        `${account?.page?.user_surname || ''} ${account?.page?.user_name || ''}`?.slice(0, 15) + '...'
                    }
                </h6>


            </div>

            {/* DIALOG */}
            <Dialog onClose={handleClose} open={isVisible}>
                <DialogTitle>
                    <div className={styles.containerTitle}>
                        <b style={{ color: color.primary }}>Page</b>
                        <button onClick={handleClose}>
                            <AiOutlineClose color={color.primary} style={{ width: '25px', height: '25px' }} />
                        </button>
                    </div>
                </DialogTitle>
                <List sx={{ pt: 0 }}>
                    <ListItem >
                        <ListItemButton onClick={handleChangeAccount}>
                            <ListItemAvatar>
                                <BiUserCircle color='#969696' className={styles.icon} />
                            </ListItemAvatar>
                            <ListItemText primary={`${t('login_to')} ${account?.page?.user_surname || ''} ${account?.page?.user_name || ''}`} />
                        </ListItemButton>
                    </ListItem>

                    <ListItem >
                        <ListItemButton onClick={gotoPageSetting}>
                            <ListItemAvatar>
                                <AiOutlineSetting color='#969696' className={styles.icon} />
                            </ListItemAvatar>
                            <ListItemText primary={`Paramètre de la page`} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Dialog>

        </div>
    )
}

export default PageCard