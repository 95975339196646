import React, { useEffect, useState } from 'react'
import styles from './ModerationRequests.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks'
import Header from 'components/Header/Header'
import { api } from 'api/api'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { Spinner } from 'react-bootstrap'
import { FiMail } from 'react-icons/fi'
import InfiniteScroll from 'react-infinite-scroll-component'
import ModerationCard from './ModerationCard/ModerationCard'



const ModerationRequests = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const accessToken = useAccessToken()

  const controller = new AbortController();
  const signal = controller.signal;

  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [isError, setIsError] = useState(false)


  const goBack = () => navigate(-1)



  useEffect(() => {
    fetchData()
    return () => {
      controller.abort()
    }
  }, [])


  const fetchData = async () => {
    try {
      const request = await api('api/page_moderation/pending_requests?page=1', 'GET', {}, accessToken, signal)
      const response = await request.json()
      console.log('Search user response:', response?.data?.data)
      setIsFetching(false)
      if (request.ok && request.status === 200) {
        if (response.success) {
          if (response?.data?.next_page_url) {
            setHasMore(true)
          } else {
            setHasMore(false)
          }
          if (response?.data?.data?.length === 0) {
            setIsEmpty(true)
          }
          setData(response?.data?.data)
          setPage(2)
        }
      }
    } catch (error) {
      fetchData()
      console.log('Search user response:', error)
    }
  }

  const loadMore = async () => {
    try {
      if (hasMore && !isFetching && !isLoading) {
        setIsLoading(true)
        const request = await api(`api/page_moderation/pending_requests?page=${page}`, 'GET', {}, accessToken, signal)
        const response = await request.json()
        // console.log('Search user response:', response?.data?.data[0])
        setIsLoading(false)
        if (request.ok && request.status === 200) {
          if (response.success) {
            if (response?.data?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            setData([...data, ...response?.data?.data])
            setPage(page + 1)
          }
        }
      }
    } catch (error) {
      loadMore()
      console.log('Search user response:', error)
    }
  }


  if (isFetching) {
    return (
      <div className={styles.screen}>
        <Header title={t('listOfModerationRequests')} goBack={goBack} />
        <div className={styles.loadingContainer}>
          <Spinner />
          <h6 className={styles.loadingText}>{t('loading')}</h6>
        </div>
      </div>
    )
  }

  if (isEmpty) {
    return (
      <div className={styles.screen}>
        <Header title={t('listOfModerationRequests')} goBack={goBack} />
        <div className={styles.loadingContainer}>
          <FiMail color='#808080' className={styles.emptyIcon} />
          <h6 className={styles.emptyText}>{t('noModerationRequestAvailable')}</h6>
        </div>
      </div>
    )
  }


  return (
    <div className={styles.screen}>
      <Header title={t('listOfModerationRequests')} goBack={goBack} />
      <div id="scrollableDiv" className={styles.content}>
        <InfiniteScroll
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          loader={<div style={{ textAlign: 'center' }}><Spinner /></div>}
          scrollableTarget="scrollableDiv">
          {data.map((item, index) => {
            return (
              <div key={index.toString()}>
                <ModerationCard account={item} onRefresh={fetchData} />
              </div>
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default ModerationRequests