import React, { useState } from 'react'
import styles from './SondEvent.module.css'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useNavigation } from 'react-router-dom'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../../../hooks/Utils/color'
import { FaBeer } from 'react-icons/fa';
import { GrLocation } from 'react-icons/gr';
import { MdDateRange } from 'react-icons/md';
import moment from 'moment'
import SondAttachment from '../../SondAttachment/SondAttachment'
import ButtonSubmit from '../../../ButtonSubmit/ButtonSubmit'
import { api } from '../../../../api/api'


const SondEvent = ({
    event_opinion_id,
    ago_id = 0,
    event_user_id,
    event_id,
    event_user = {},
    event_title = null,
    event_descr = null,
    event_attachments = [],
    event_type = null,
    event_localisation = null,
    event_date = null,
    event_time = null,
    approuved_users_count = 0,
    pending_users_count = 0,
    user_request = null,
    sourceFrom = 'Opinion',
    onRefresh = () => null
}) => {


    // STATE
    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const MAX_LENGTH = 200

    const { data: authReducerState } = useAuthReducer()
    const [userRequestSended, setUserRequestSended] = useState(user_request)
    const [requestSended, setRequestSended] = useState(user_request ? true : false)
    const [isPartipate, setIsPartipate] = useState(false)


    const goToViewOpinion = () => {
        if (sourceFrom === 'ViewOpinion') {
            return
        }
        navigate(`/opinion/select/${ago_id || 0}/${event_opinion_id}`)
    }


    const handleParticipate = async () => {
        try {
            setIsPartipate(true)
            const request = await api('api/events/send_participation_request', 'POST', { req_event_id: event_id }, accessToken)
            const response = await request.json()
            console.log('Request participation response:', response?.data?.user_request)
            setIsPartipate(false)
            if (request?.ok && request?.status === 200) {
                if (response?.success) {
                    setRequestSended(true)
                    setUserRequestSended(response?.data?.user_request)
                    // if (sourceFrom === 'MyEvent') {
                    //     onRefresh()
                    // }
                } else {
                    alert(t('intranet:treatmentFailure'), t('intranet:weEncounteredAnErrorPleaseTryAgain'))
                }
            } else {
                alert(t('intranet:treatmentFailure'), t('intranet:weEncounteredAnErrorPleaseTryAgain'))
            }
        } catch (error) {
            setIsPartipate(false)
            alert(t('intranet:treatmentFailure'), t('intranet:weEncounteredAnErrorPleaseTryAgain'))
            console.error('Request participation error:', error);
        }
    }


    const handleCancelParticipate = async () => {
        try {
            if (!user_request?.req_id) return
            setIsPartipate(true)
            const request = await api(`api/event_participation_request/${userRequestSended?.req_id}/cancel`, 'POST', {}, accessToken)
            const response = await request.json()
            console.log('Request participation response:', response?.data?.user_request)
            setIsPartipate(false)
            if (request?.ok && request?.status === 200) {
                if (response?.success) {
                    setRequestSended(false)
                    setUserRequestSended(response?.data?.user_request)

                    // if (sourceFrom === 'MyEvent') {
                    //     goBack()
                    // }
                } else {
                    alert(t('intranet:treatmentFailure'), t('intranet:weEncounteredAnErrorPleaseTryAgain'))
                }
            } else {
                alert(t('intranet:treatmentFailure'), t('intranet:weEncounteredAnErrorPleaseTryAgain'))
            }
        } catch (error) {
            setIsPartipate(false)
            alert(t('intranet:treatmentFailure'), t('intranet:weEncounteredAnErrorPleaseTryAgain'))
            console.error('Request participation error:', error);
        }
    }

    function TextWithMentionsAndHashtags({ text }) {
        if (!text) {
            return
        }
        const parts = text.split(/@\[([\w\s]+)\]\((\d+)\)|#(\w+)/g);
        return (
            <>
                {parts.map((part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        {text.match(/@\[([\w\s]+)\]\((\d+)\)/g)?.[index] && (
                            <Mention
                                username={text.match(/@\[([\w\s]+)\]\((\d+)\)/g)[index].match(/@\[([\w\s]+)\]/)[1]}
                                user_id={text.match(/@\[([\w\s]+)\]\((\d+)\)/g)[index].match(/\((\d+)\)/)[1]}
                            />
                        )}
                        {text.match(/#(\w+)/g)?.[index] && (
                            <Hashtag
                                tag={text.match(/#(\w+)/g)[index].replace("#", "")}
                            />
                        )}
                    </React.Fragment>
                ))}
            </>
        );
    }

    const Mention = ({ username, user_id }) => {
        return (
            <Link to={`/profile/${user_id}`} style={{ color: color.primary, textDecoration: 'none', fontWeight: '500' }}>
                @{username}{' '}
            </Link>
        );
    }

    const Hashtag = ({ tag }) => {
        return (
            <Link to={`/search/${tag}`} style={{ color: color.primary, textDecoration: 'none', fontWeight: '500' }}>
                #{tag}{' '}
            </Link>
        );
    }

    return (
        <div className={styles.container}>

            <div className={styles.containerDescription}>
                <span onClick={goToViewOpinion} >
                    {event_title}
                </span>
                <p onClick={goToViewOpinion} className={styles.description} >
                    <TextWithMentionsAndHashtags text={event_descr} />
                </p>
                <p onClick={goToViewOpinion} className={styles.date} >
                    <GrLocation />  {t('location_of_the_event')}: <span style={{ fontWeight: '500' }}>{event_localisation}</span>
                </p>
                <p className={styles.date} onClick={goToViewOpinion} >
                    <MdDateRange /> {t('event_date')}: <span style={{ fontWeight: '500' }}> {moment(event_date, 'yyyy-MM-ddThh:mm:ssZ').format('DD MMM YYYY')} {t('at')} {event_time}</span>
                </p>
            </div>


            {/* RENDER ATTACHMENT */}
            {event_attachments?.length === 1 && (
                <SondAttachment
                    attachment={event_attachments[0]}
                    height='auto'
                />
            )}

            {(event_attachments?.length === 2) && (
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[0]}
                            height={450}
                        />
                    </div>
                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[1]}
                            height={450}
                        />
                    </div>
                </div>
            )}

            {(event_attachments?.length === 3) && (
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[0]}
                            height={400}
                        />
                    </div>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[1]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[2]}
                            height={200}
                        />
                    </div>
                </div>
            )}

            {(event_attachments?.length === 4) && (
                <div style={{ width: '100%', display: 'flex' }}>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[0]}
                            height={300}
                        />
                        <SondAttachment
                            attachment={event_attachments[1]}
                            height={300}
                        />
                    </div>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[2]}
                            height={300}
                        />
                        <SondAttachment
                            attachment={event_attachments[3]}
                            height={300}
                        />
                    </div>
                </div>
            )}


            {(event_attachments?.length === 5) && (
                <div style={{ width: '100%', display: 'flex' }}>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[0]}
                            height={250}
                        />
                        <SondAttachment
                            attachment={event_attachments[1]}
                            height={250}
                        />
                    </div>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[2]}
                            height={166}
                        />
                        <SondAttachment
                            attachment={event_attachments[3]}
                            height={166}
                        />
                        <SondAttachment
                            attachment={event_attachments[4]}
                            height={166}
                        />
                    </div>
                </div>
            )}

            {(event_attachments?.length === 6) && (
                <div style={{ width: '100%', display: 'flex' }}>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[0]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[1]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[2]}
                            height={200}
                        />
                    </div>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[3]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[4]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[5]}
                            height={200}
                        />
                    </div>
                </div>
            )}


            {(event_attachments?.length === 7) && (
                <div style={{ width: '100%', display: 'flex' }}>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[0]}
                            height={266}
                        />
                        <SondAttachment
                            attachment={event_attachments[1]}
                            height={266}
                        />
                        <SondAttachment
                            attachment={event_attachments[2]}
                            height={266}
                        />
                    </div>

                    <div style={{ width: '50%' }}>
                        <SondAttachment
                            attachment={event_attachments[3]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[4]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[5]}
                            height={200}
                        />
                        <SondAttachment
                            attachment={event_attachments[6]}
                            height={200}
                        />
                    </div>
                </div>
            )}

            {authReducerState?.user?.user_id !== event_user?.user_id &&
                <div className={styles.participateContainer}>
                    <div>
                        <p className={styles.participateTitle}>{event_title?.slice(0, MAX_LENGTH)}</p>
                        <p className={styles.participateDescription}>{event_descr?.slice(0, MAX_LENGTH)}</p>
                    </div>
                    {requestSended ?
                        <ButtonSubmit
                            onClick={handleCancelParticipate}
                            value={t('request_sent')}
                            isLoading={isPartipate}
                            style={{
                                width: '150px',
                                marginLeft: '20px',
                                color: 'white',
                                backgroundColor: 'grey',
                                border: `1px`,
                                paddingLeft: '10px',
                                paddingRight: '10px'
                            }}
                        />
                        :
                        <ButtonSubmit
                            onClick={handleParticipate}
                            value={t('participate')}
                            isLoading={isPartipate}
                            style={{
                                width: '150px',
                                marginLeft: '20px',
                                color: 'white',
                                backgroundColor: color.primary,
                                paddingLeft: '10px',
                                paddingRight: '10px'
                            }}
                        />

                    }
                </div>
            }


        </div>
    )
}

export default SondEvent