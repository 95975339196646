import React, { memo, useEffect, useState } from 'react'
import styles from './SondDocs.module.css'
import { access_extensions, audio_extensions, excel_extensions, image_extensions, powerpoint_extensions, video_extensions, word_extensions } from 'constants/formFiles'


const SondDocs = ({
  name = '',
  uri,
  style = {},
  textStyle = {},
}) => {

  const [remoteName, setRemoteName] = useState('')
  const [extension, setExtension] = useState('')

  useEffect(() => {
    const getMetaFile = async () => {
      try {
        if (uri) {
          const lastSlashPosition = uri?.lastIndexOf('/')
          const lastPointPosition = uri?.lastIndexOf('.')
          const documentName = uri?.slice(lastSlashPosition + 1, lastPointPosition)
          const documentExtension = uri?.slice(lastPointPosition)
          setExtension(documentExtension?.replace('.', ''))
          setRemoteName(documentName)
        }
      } catch (error) {
        console.error(error);
      }
    }
    getMetaFile()
  }, [])


  return (
    <a href={uri} target="_blank" className={styles.container} style={style}>
      <img 
      src={
        image_extensions?.includes(extension) ?
            require('assets/docs/image_icon.png') :
            audio_extensions?.includes(extension) ?
                require('assets/docs/audio.png') :
                video_extensions?.includes(extension) ?
                    require('assets/docs/video_icon.png') :
                    word_extensions?.includes(extension) ?
                        require('assets/docs/word_icon.png') :
                        extension === 'pdf' ?
                            require('assets/docs/pdf_icon.png') :
                            excel_extensions?.includes(extension) ?
                                require('assets/docs/excell_icon.png') :
                                powerpoint_extensions?.includes(extension) ?
                                    require('assets/docs/pptx_icon.png') :
                                    access_extensions?.includes(extension) === 'accdb' ?
                                        require('assets/docs/access_icon.png') :
                                        extension === 'txt' ?
                                            require('assets/docs/txt.png') : require('assets/docs/file_icon.png')

    }
        className={styles.image}
        alt=''
      />

      <p className={styles.text} style={textStyle}>
        {name ?
          name?.length < 25 ? name : `${name?.slice(0, 25)}...${extension}`
          :
          remoteName?.length < 13 ? `${remoteName}.${extension}` : `${remoteName?.slice(0, 13)}...${extension}`
        }
      </p>
    </a>
  )
}

export default memo(SondDocs)