import { useHomeReducer } from "../ReducerHooks/ReducerHooks"


export default function useReducerHomeAction() {

    const { store: HomeStore } = useHomeReducer()

    const REFRESH_HOME_OPINIONS = (data) => {
        if (data.length == 0) {
            return
        }
        HomeStore({
            type: 'REFRESH_HOME_OPINIONS',
            payload: data
        })
    }


    const ADD_HOME_OPINIONS = (data) => {
        if (data.length == 0) {
            return
        }
        HomeStore({
            type: 'ADD_HOME_OPINIONS',
            payload: data
        })
    }

    const UPDATE_HOME_OPINION = (data) => {
        if (!data) {
            return
        }
        HomeStore({
            type: 'UPDATE_HOME_OPINION',
            payload: data
        })
    }

    const UPDATE_HOME_VIEW_OPINION = (data) => {
        if (!data) {
            return
        }
        HomeStore({
            type: 'UPDATE_HOME_VIEW_OPINION',
            payload: data
        })
    }

    const UPDATE_HOME_VOTE = (data) => {
        if (!data) {
            return
        }
        HomeStore({
            type: 'UPDATE_HOME_VOTE',
            payload: data
        })
    }

    const DELETE_HOME_OPINION = (data) => {
        if (!data) {
            return
        }
        HomeStore({
            type: 'DELETE_HOME_OPINION',
            payload: data
        })
    }

    const MUTED_USER_HOME_OPINION = (data) => {
        if (!data) {
            return
        }
        HomeStore({
            type: 'MUTED_USER_HOME_OPINION',
            payload: data
        })
    }

    const CLEAR_HOME_OPINIONS = () => {
        HomeStore({
            type: 'CLEAR_HOME_OPINIONS'
        })
    }

    return { REFRESH_HOME_OPINIONS, ADD_HOME_OPINIONS, UPDATE_HOME_OPINION, UPDATE_HOME_VIEW_OPINION, UPDATE_HOME_VOTE, DELETE_HOME_OPINION, MUTED_USER_HOME_OPINION, CLEAR_HOME_OPINIONS }

}
